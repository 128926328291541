import { formatCurrencyAmount, NumberType } from '@uniswap/conedison/format'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import Row from 'components/Row'
import { ArrowRight } from 'react-feather'
import { useLocation } from 'react-router-dom'
import { InterfaceTrade } from 'state/routing/types'
import { Field } from 'state/swap/actions'
import { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { formatNumberWithSuffix } from 'utils/numbers'

export function  TradeSummary({ trade,formattedAmountsLimit }: { trade: any; formattedAmountsLimit?: any  }) {
  const {pathname} = useLocation();

  const theme = useTheme()

  console.log("NOTIFCATION", trade.inputAmount.currency)
  return (
    <Row gap="sm" justify="space-evenly" align="center">
      <CurrencyLogo currency={trade.inputAmount.currency} size="32px" />
      <ThemedText.LabelSmall color="textPrimary">
        {formatNumberWithSuffix(Number(formatCurrencyAmount(trade.inputAmount, NumberType.SwapTradeAmount)))}<br/> <span style={{color:'#5D7279'}}>{trade.inputAmount.currency.symbol} </span>
      </ThemedText.LabelSmall>
      <ArrowRight color={theme.textPrimary} size="24px" />
      <CurrencyLogo currency={trade.outputAmount.currency} size="32px" />
   { pathname !== '/limit' ?  <ThemedText.LabelSmall color="textPrimary">
        {formatNumberWithSuffix( Number(formatCurrencyAmount(trade.outputAmount, NumberType.SwapTradeAmount)))} <br/> <span style={{color:'#5D7279'}}>{trade.outputAmount.currency.symbol} </span>
      </ThemedText.LabelSmall> : <ThemedText.LabelSmall color="textPrimary">
        {formattedAmountsLimit[Field.OUTPUT]}
      </ThemedText.LabelSmall>  }
    </Row>
  )
}
