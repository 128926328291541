import { MouseoverTooltip } from 'components/Tooltip'
import { StyledInfoIcon } from './styled'
import { InfoIconProps } from 'types'


export function InfoIcon(props: any) {
  const { color, size } = props;

  return (
    <MouseoverTooltip text={<div>{props.content}</div>}>
      <StyledInfoIcon className={props.className} color={color} size={size} />
      </MouseoverTooltip>
  )
}