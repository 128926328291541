import { Percent } from "@uniswap/sdk-core";
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import { ArrowUp } from "react-feather";
import { Activity } from "react-feather";

import { Settings } from "react-feather";

import { Menu } from "react-feather";
import { DollarSign } from "react-feather";

import Row, { CustomRow, RowBetween, RowFixed } from "../Row";
import SettingsTab from "../Settings";
import { SwapPageTabs } from "components/NavBar";
import { useLocation } from "react-router-dom";
import { useOrders } from "hooks/limitOrders/useOrders";
import { CountBadge } from "./styleds";
import { useWeb3React } from "@web3-react/core";
import { SupportedChainId } from "constants/chains";
import { Field } from "state/swap/actions";

const StyledSwapHeader = styled(RowBetween)`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-top: 0px;
  color: ${({ theme }) => theme.textSecondary};
`;

const HeaderButtonContainer = styled(RowFixed)`
  gap: 16px;
`;

export const IconContainer = styled(Row)`
  cursor: pointer;
  padding: 14px 18px 14px 14px;
  border-radius: 6px;
  background: #1f2831;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 84px;
  margin-right: 10px;
  height: 27px;
  color: #fff;
  font-size: 10px;
  fill: #fff;
  &:hover {
    background: linear-gradient(
      91deg,
      #19272a 0.97%,
      #0a1e29 95.57%,
      #091e29 100.23%
    );
    color: #53c5f8;
  }
`;

export const IconContainerRight = styled(Row)`
  cursor: pointer;
  padding: 14px 18px 14px 14px;
  border-radius: 6px;
  background: #1f2831;
  display: flex;
  justify-content: center;
  width: 84px;
  /* margin-right: 10px; */
  height: 27px;
  color: #fff;
  font-size: 10px;
  fill: #fff;
  &:hover {
    background: linear-gradient(
      91deg,
      #19272a 0.97%,
      #0a1e29 95.57%,
      #091e29 100.23%
    );
    color: #53c5f8;
  }
`;

export const IconContainerRightOrder = styled(Row)`
  cursor: pointer;
  padding: 14px 18px 14px 14px;
  border-radius: 6px;
  background: #1f2831;
  display: flex;
  justify-content: center;
  width: 100px;
  /* margin-right: 10px; */
  height: 27px;
  color: #fff;
  font-size: 10px;
  fill: #fff;
  &:hover {
    background: linear-gradient(
      91deg,
      #19272a 0.97%,
      #0a1e29 95.57%,
      #091e29 100.23%
    );
    color: #53c5f8;
  }
`;
export const Icon = styled(ArrowUp)`
  height: 14px;
  width: 14px;
  > * {
    stroke: #fff;
  }
`;

export const IconChart = styled(Activity)`
  height: 12px;
  width: 12px;
  > * {
    stroke: #fff;
  }
`;

export const IconSettings = styled(Settings)`
  cursor: pointer;
  height: 18px;
  width: 18px;
  > * {
    stroke: #fff;
  }
`;

const IconOrder = styled(Menu)`
  height: 14px;
  width: 14px;
  > * {
    stroke: #fff;
  }
`;

const IconTransactions = styled(DollarSign)`
  height: 12px;
  width: 12px;
  > * {
    stroke: #fff;
  }
`;

export default function SwapHeader({
  autoSlippage,
  chainId,
  title,
  isshowSettingTab,
  UpdateSetActivity,
  isChartVisible,
  isOrderVisible,
  isTransactionVisible,
  toggleChartVisibility,
  toggleOrderVisibility,
  toggleTransactionsVisibility,
  currencies,
}: {
  autoSlippage: Percent;
  chainId?: number;
  title?: string;
  isshowSettingTab: boolean;
  UpdateSetActivity?: any;
  isChartVisible?: any;
  isOrderVisible?: any;
  isTransactionVisible?: any;
  toggleChartVisibility?: () => void;
  toggleOrderVisibility?: () => void;
  toggleTransactionsVisibility?: () => void;
  currencies?: any;
}) {
  const { pathname } = useLocation();
  const { orders, metaData } = useOrders();

  return (
    <StyledSwapHeader>
      <HeaderButtonContainer>
        <ThemedText.MediumHeader fontSize={18} fontWeight={600}>
          <div>
            <CustomRow>
              <SwapPageTabs />
            </CustomRow>
          </div>
        </ThemedText.MediumHeader>
      </HeaderButtonContainer>
      <RowFixed>
        {pathname !== "/twap" && (
          <IconContainer
            style={{
              background: isChartVisible
                ? "linear-gradient(191deg, #1A3848 5.4%, #39484B 92.07%)"
                : "#1f2831",
            }}
            onClick={() => {
              if (
                (chainId === SupportedChainId.LIGHTLINK_PEGASUS_TESTNET ||
                  chainId === SupportedChainId.LIGHTLINK_PHOENIX_MAINNET) &&
                currencies[Field.INPUT] &&
                currencies[Field.OUTPUT]
              ) {
                toggleChartVisibility && toggleChartVisibility();
              }
            }}
          >
            <IconChart /> &nbsp;
            <div>Chart</div>
          </IconContainer>
        )}

        {pathname === "/swap" && (
          <IconContainer
            style={{
              background: isTransactionVisible
                ? "linear-gradient(191deg, #1A3848 5.4%, #39484B 92.07%)"
                : "#1f2831",
            }}
            onClick={() => {
              if (
                chainId === SupportedChainId.LIGHTLINK_PEGASUS_TESTNET ||
                chainId === SupportedChainId.LIGHTLINK_PHOENIX_MAINNET
              ) {
                toggleTransactionsVisibility && toggleTransactionsVisibility();
              }
            }}
          >
            <IconTransactions /> &nbsp;
            <div>Swaps</div>
          </IconContainer>
        )}

        {isshowSettingTab && (
          <SettingsTab autoSlippage={autoSlippage} chainId={chainId} />
        )}

        {(pathname == "/limit" || pathname == "/twap") && (
          <IconContainerRightOrder
            style={{
              background: isOrderVisible
                ? "linear-gradient(191deg, #1A3848 5.4%, #39484B 92.07%)"
                : "#1f2831",
            }}
            onClick={() => {
              if (
                chainId === SupportedChainId.LIGHTLINK_PEGASUS_TESTNET ||
                chainId === SupportedChainId.LIGHTLINK_PHOENIX_MAINNET
              ) {
                toggleOrderVisibility && toggleOrderVisibility();
              }
            }}
          >
            <IconOrder />
            &nbsp;
            <div>Orders</div>
            {metaData && metaData.count > 0 ? (
              <CountBadge>{metaData.count}</CountBadge>
            ) : null}
          </IconContainerRightOrder>
        )}
      </RowFixed>
    </StyledSwapHeader>
  );
}
