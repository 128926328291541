import { useEffect, useState } from "react";
import { Pool, StakerWallet } from "@eltk/staking-sdk";
import { Signer, providers } from "ethers";
import { useSignerWallet } from "hooks/useSignerWallet";

export const usePool = () => {
  const [pool, setPool] = useState<any>();
  const { wallet: signerWallet } = useSignerWallet();

  useEffect(() => {
    const initializePool = async () => {
      try {
        if (signerWallet) {
          const poolInstance = await Pool.getInstance(signerWallet);
          setPool(poolInstance);
        }
      } catch (error) {
        console.error("Error initializing Pool:", error);
      }
    };

    initializePool();
  }, [signerWallet]);

  return {
    pool,
  };
};
