import { ThemedText } from "theme"
import { Header, Menu, StyledArrow, Title } from "./styled"

export const SlideOutMenu = ({
  children,
  onClose,
  title,
}: {
  onClose: () => void
  title: React.ReactNode
  children: React.ReactNode
  onClear?: () => void
}) => (
  <Menu>
    <Header>
      <StyledArrow data-testid="wallet-back" onClick={onClose} size={24} />
      <Title>
        <ThemedText.SubHeader>{title}</ThemedText.SubHeader>
      </Title>
    </Header>
    {children}
  </Menu>
)
