import { Info } from "react-feather";
import styled, { css, keyframes } from "styled-components";

export const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const RotationStyle = css`
  animation: 2s ${rotateAnimation} linear infinite;
`

export const StyledSVG = styled.svg<{ size: string; stroke?: string; fill?: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  path {
    stroke: ${({ stroke }) => stroke};
    background: ${({ theme }) => theme.textSecondary};
    fill: ${({ fill }) => fill};
  }
`

export const StyledRotatingSVG = styled(StyledSVG)`
  ${RotationStyle}
`

export const StyledInfoIcon = styled(Info)`
  opacity: 1;
  stroke: ${(props) => props.color || '#fff'}; /* Use props.color or fallback to #fff */
  line-height: 0;
  vertical-align: middle;
  transition: opacity 0.2s ease-in-out;
  font-size: ${(props) => props.size}px; /* Set font size based on props.size */

  :hover {
    opacity: 1;
  }
`;
