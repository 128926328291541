import { t, } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { MouseoverTooltip } from "components/Tooltip";
import { useGetConnection } from "connection";
import { ConnectionType } from "connection/types";
import { WalletConnectV2 } from "connection/WalletConnectV2";
import { getChainInfo } from "constants/chainInfo";
import {
  isSupportedChainLightLink,
  SupportedChainId,
  UniWalletSupportedChains,
} from "constants/chains";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import useSelectChain from "hooks/useSelectChain";
import useSyncChainQuery from "hooks/useSyncChainQuery";

import { useRef, useState } from "react";
import { AlertTriangle, ChevronDown, ChevronUp } from "react-feather";
import { useTheme } from "styled-components/macro";
import { ThemedText } from "theme";
import { getSupportedChainIdsFromWalletConnectSession } from "utils/getSupportedChainIdsFromWalletConnectSession";

import * as styles from "./ChainSelector.css";
import ChainSelectorRow from "./ChainSelectorRow";
import { NavDropdown } from "./NavDropdown";
import { useIsMobile } from "utils/common";
import { Column, Row } from "components/Flex";
import { Box } from "components/Box";
import { Portal } from "components/Portal";
import { ChainSelectorProps } from "types";
import { useLocation, useNavigate } from "react-router-dom";

const NETWORK_SELECTOR_CHAINS = [
  SupportedChainId.LIGHTLINK_PHOENIX_MAINNET,
  SupportedChainId.LIGHTLINK_PEGASUS_TESTNET,
];

function useWalletSupportedChains() {
  const { connector } = useWeb3React();

  const getConnection = useGetConnection();

  const connectionType = getConnection(connector).type;

  switch (connectionType) {
    case ConnectionType.WALLET_CONNECT_V2:
      return getSupportedChainIdsFromWalletConnectSession(
        (connector as WalletConnectV2).provider?.session
      );
    case ConnectionType.UNISWAP_WALLET:
      return UniWalletSupportedChains;
    default:
      return NETWORK_SELECTOR_CHAINS;
  }
}

export const ChainSelector = ({ leftAlign }: ChainSelectorProps) => {
  const { chainId } = useWeb3React();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const location = useLocation();
  const navigate = useNavigate();

  const [hover, setHover] = useState(false);

  const theme = useTheme();

  const ref = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setIsOpen(false), [modalRef]);

  const info = chainId ? getChainInfo(chainId) : undefined;

  const selectChain = useSelectChain();
  useSyncChainQuery();

  const [pendingChainId, setPendingChainId] = useState<
    SupportedChainId | undefined
  >(undefined);

  const onSelectChain = async (targetChainId: SupportedChainId) => {
    setPendingChainId(targetChainId);
    await selectChain(targetChainId);
    setPendingChainId(undefined);
    setIsOpen(false);
  
    if (location.pathname.includes("pools/single")) {
      navigate("/pools");
    }
    if (location.pathname.includes("/positions/")) {
      navigate("/pools");
    }
  
    if (location.pathname.includes("/locking/")) {
      navigate("/locking");
    }
  };
  

  const walletSupportsChain = useWalletSupportedChains();

  if (!chainId) {
    return null;
  }

  // const isSupported = !!info && chainId === SupportedChainId.LIGHTLINK_PEGASUS_TESTNET
  const isSupported = !!info && isSupportedChainLightLink(chainId);

  const dropdown = (
    <NavDropdown
      top="56"
      left={leftAlign ? "0" : "auto"}
      right={leftAlign ? "auto" : "0"}
      ref={modalRef}
      style={{ background: "#091012", zIndex: 999 }}
    >
      <Column paddingX="8">
        {NETWORK_SELECTOR_CHAINS.map((selectorChain: SupportedChainId) => (
          <ChainSelectorRow
            disabled={!walletSupportsChain.includes(selectorChain)}
            onSelectChain={onSelectChain}
            targetChain={selectorChain}
            key={selectorChain}
            isPending={selectorChain === pendingChainId}
          />
        ))}
      </Column>
    </NavDropdown>
  );

  const chevronProps = {
    height: 20,
    width: 20,
    color: theme.textSecondary,
  };

  return (
    <Box position="relative" ref={ref}>
      <MouseoverTooltip
        text={t`Your wallet's current network is unsupported.`}
        disabled={isSupported}
      >
        <Row
          as="button"
          gap="4"
          className={styles.ChainSelector}
          background={isOpen ? "accentActiveSoft" : "none"}
          onClick={() => setIsOpen(!isOpen)}
        >
          <ThemedText.SubHeaderSmall
            style={{
              marginLeft: "3px",
              lineHeight: "24px",
              color: hover ? `#A2EEFF` : "#fff",
            }}
            onMouseEnter={() => {
              setHover(true);
            }}
            onMouseLeave={() => {
              setHover(false);
            }}
          ></ThemedText.SubHeaderSmall>
          {!isSupported ? (
            <AlertTriangle size={20} color={theme.textSecondary} />
          ) : (
            <>
              <div>{info.label}</div>
              <img
                src={info.logoUrl}
                alt={info.label}
                className={styles.Image}
                data-testid="chain-selector-logo"
              />
            </>
          )}
          {isOpen ? (
            <ChevronUp {...chevronProps} />
          ) : (
            <ChevronDown {...chevronProps} />
          )}
        </Row>
      </MouseoverTooltip>
      {isOpen && (isMobile ? <Portal>{dropdown}</Portal> : <>{dropdown}</>)}
    </Box>
  );
};
