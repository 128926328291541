import searchIcon from 'assets/svg/search.svg'
import searchBarIcon from 'assets/svg/SearchBarIcon.svg'

import { LoadingRows as BaseLoadingRows } from 'components/Loader/styled'
import styled from 'styled-components/macro'

import Column, { AutoColumn } from '../Column'
import { RowBetween } from '../Row'

export const PaddedColumn = styled(AutoColumn)`
  padding: 20px;
`

export const MenuItem = styled(RowBetween)<{ dim?: boolean }>`
  padding: 4px 20px;
  height: 56px;
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr) auto minmax(0, 72px);
  grid-gap: 16px;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  :hover {
    background-color: ${({ theme }) => theme.hoverDefault};
  }
  opacity: ${({ disabled, selected, dim }) => (dim || disabled || selected ? 0.4 : 1)};
`
export const SearchInput = styled.input`
  background: no-repeat scroll right 7px center; /* Positioning the icon on the right */
  background-image: url(${searchBarIcon});
  background-size: 20px 20px;
  padding: 16px 40px 16px 16px; /* Adjusted padding to accommodate the icon on the right */
  height: 46px;
  width: 100%;
  background-color: #121B1E;
  border: none;
  outline: none;
  border-radius: 12px;
  color: #445156;
  font-size: 16px;

  ::placeholder {
    color: #445156;
    font-size: 16px;
  }

  transition: border 100ms;
  :focus {
    border: 1px solid ${({ theme }) => theme.accentActiveSoft};
    background-color: ${({ theme }) => theme.backgroundSurface};
    outline: none;
  }

  /* Filter to make the icon white */
  &::after {
    content: "";
    background-position: right;
    background-color: #fff;
  }
  `;
// export const SearchInput = styled.input`
//   background: no-repeat scroll 7px 7px;
//   background-image: url(${searchIcon});
//   background-size: 20px 20px;
//   background-position: 12px center;
//   position: relative;
//   display: flex;
//   padding: 16px;
//   padding-left: 40px;
//   height: 40px;
//   align-items: center;
//   width: 100%;
//   white-space: nowrap;
//   background-color: #121B1E;
//   border: none;
//   outline: none;
//   border-radius: 12px;
//   color: #445156;
//   -webkit-appearance: none;

//   font-size: 16px;

//   ::placeholder {
//     color: #445156;;
//     font-size: 16px;
//   }
//   transition: border 100ms;
//   :focus {
//     border: 1px solid ${({ theme }) => theme.accentActiveSoft};
//     background-color: ${({ theme }) => theme.backgroundSurface};
//     outline: none;
//   }
// `

// export const SearchInput = styled.input`
//   background: no-repeat scroll 7px 7px;
//   background-image: url(${searchIcon});
//   background-size: 20px 20px;
//   background-position: 12px center; /* Position icon on the left */
//   padding-left: 40px; /* Add padding to make room for the icon on the left */

//   position: relative;
//   display: flex;
//   padding: 16px;
//   height: 40px;
//   align-items: center;
//   width: 100%;
//   white-space: nowrap;
//   background-color: #121B1E;
//   border: none;
//   outline: none;
//   border-radius: 12px;
//   color: #fff;
//   border: 1px solid ${({ theme }) => theme.backgroundOutline};
//   -webkit-appearance: none;
//   font-size: 16px;

//   ::placeholder {
//     color: #445156;
//     font-size: 16px;
//   }

//   transition: border 100ms;

//   :focus {
//     border: 1px solid ${({ theme }) => theme.accentActiveSoft};
//     background-color: ${({ theme }) => theme.backgroundSurface};
//     outline: none;
//   }
// `;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #5D7279;
  opacity: 0.3;

`

export const LoadingRows = styled(BaseLoadingRows)`
  grid-column-gap: 0.5em;
  grid-template-columns: repeat(12, 1fr);
  max-width: 960px;
  padding: 12px 20px;

  & > div:nth-child(4n + 1) {
    grid-column: 1 / 8;
    height: 1em;
    margin-bottom: 0.25em;
  }
  & > div:nth-child(4n + 2) {
    grid-column: 12;
    height: 1em;
    margin-top: 0.25em;
  }
  & > div:nth-child(4n + 3) {
    grid-column: 1 / 4;
    height: 0.75em;
  } 
`
export const ContentWrapper = styled(Column)`
  
  overflow: hidden;
  margin: 0 0px;
  flex: 1 1;
  position: relative;
  --border-width: 2px;
  --border-radius: 12px;
  background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
  border: 0;
  padding: 10px;
  position: relative;
  border-radius: var(--border-radius);
  z-index: 1;
  &::before {
    content: "";
    display: block;
    height: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    width: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    background:${({ theme }) => theme.backgroundSurface} ;
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: 16px;
    z-index: -1;
`

export const MobileWrapper = styled(AutoColumn)`
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    display: none;
  `};
`

export const BaseWrapper = styled.div<{ disable?: boolean }>`
  border-color: #121B1E;
  background: #121B1E;
  border-radius: 10px;
  display: flex;
  padding: 6px 12px ; 

  align-items: center;
  :hover {
    cursor: ${({ disable }) => !disable && 'pointer'};
    background: linear-gradient(91deg, #39484B 7.28%, #1A3848 89.82%);
  }

  color: ${({ theme, disable }) => disable && theme.accentActive}; 
`
