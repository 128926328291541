import { TraceEvent } from "@uniswap/analytics";
import {
  CopiedSpan,
  HandleCopySpan,
} from "pages/Pool/poolStyle";
import { ReactComponent as CopyIcon } from "assets/svg/copy.svg";

import {
  BrowserEvent,
  InterfaceElementName,
  SharedEventName,
} from "@uniswap/analytics-events";
import { useWeb3React } from "@web3-react/core";
import { useGetConnection } from "connection";
import { ReactComponent as ElektrikLogo } from "assets/svg/elektrik_logo.svg";

import { useCallback, useRef, useState } from "react";
import { ArrowDownRight, ArrowUpRight, Copy, IconProps } from "react-feather";

import { useAppDispatch } from "state/hooks";
import { updateSelectedWallet } from "state/user/reducer";
import styled, { useTheme } from "styled-components/macro";
import { CloseIcon, CopyHelper, ThemedText } from "theme";
import StatusIcon from "../Identicon/StatusIcon";
import { useToggleAccountDrawer } from ".";
import { IconWithConfirmTextButton } from "./IconButton";
import {
  AccountNamesWrapper,
  AuthenticatedHeaderWrapper,
  HeaderWrapper,
  IconContainer,
  LogOutCentered,
  StatusWrapper,
} from "./styled";
import { shortenAddress } from "utils/common";
import { ShowGradient, ShowGradientText } from "components/swap/styleds";
import { useGetEltk } from "hooks/locking/useGetEltk";
import {
  ELektrikImage,
  TokenImage,
  TokenLogoWrapper,
} from "pages/Pool/styleds";
import { formatNumberWithSuffix } from "utils/numbers";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import copy from "copy-to-clipboard";

export const CopyText = styled(CopyHelper).attrs({
  InitialIcon: Copy,
  CopiedIcon: Copy,
  gap: 4,
  iconSize: 14,
  iconPosition: "right",
})``;

export function PortfolioArrow({
  change,
  ...rest
}: { change: number } & IconProps) {
  const theme = useTheme();
  return change < 0 ? (
    <ArrowDownRight color={theme.accentCritical} size={20} {...rest} />
  ) : (
    <ArrowUpRight color={theme.accentSuccess} size={20} {...rest} />
  );
}

export default function AuthenticatedHeader({
  account,
  openSettings,
}: {
  account: string;
  openSettings: () => void;
}) {
  const { connector, ENSName } = useWeb3React();
  const dispatch = useAppDispatch();

  const getConnection = useGetConnection();
  const connection = getConnection(connector);

  const disconnect = useCallback(() => {
    if (connector && connector.deactivate) {
      connector.deactivate();
    }
    connector.resetState();
    dispatch(updateSelectedWallet({ wallet: undefined }));
  }, [connector, dispatch]);

  const toggleWalletDrawer = useToggleAccountDrawer();

  const [showDisconnectConfirm, setShowDisconnectConfirm] = useState(false);
  const { balance: EltkBalance, loading: EltkLoading } = useGetEltk(account);

  const modalRef = useRef(null);
  const handleCloseModal = () => {
    toggleWalletDrawer();
  };

  useOnClickOutside(modalRef, handleCloseModal);

  const [isCopied, setCopied] = useState<any>(false);

  const handleCopy = async (address:string) => {
    if (address) {
      // await navigator.clipboard.writeText(address);
      copy(address)
    }
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  return (
    <AuthenticatedHeaderWrapper ref={modalRef}>
      <HeaderWrapper>
        <ThemedText.DeprecatedLargeHeader>
          Wallet
        </ThemedText.DeprecatedLargeHeader>{" "}
        <div>
          {" "} 
          <CloseIcon
            onClick={toggleWalletDrawer}
            style={{ zIndex: 99 }}
            stroke="#647383"
          />
        </div>
      </HeaderWrapper>
      <HeaderWrapper>
        <StatusWrapper>
          <StatusIcon connection={connection} size={40} />
          {account && (
            <AccountNamesWrapper>
              <ThemedText.SubHeader>
                {/* <CopyText toCopy={ENSName ?? account}> */}
                  <ShowGradientText>
                    {ENSName ?? shortenAddress(account, 4, 4)}
                    {isCopied ? (
                          <CopiedSpan>Copied</CopiedSpan>
                        ) : (
                          <HandleCopySpan
                            onClick={()=>handleCopy(account)}
                            style={{ marginRight: "10px" }}
                          >
                            <CopyIcon />
                          </HandleCopySpan>
                        )} 
                  </ShowGradientText>
                {/* </CopyText> */}
              </ThemedText.SubHeader>
              {ENSName && (
                <ThemedText.BodySmall color="textTertiary">
                  <CopyText toCopy={account}>
                    <ShowGradientText>
                      {shortenAddress(account, 4, 4)}
                    </ShowGradientText>
                  </CopyText>
                </ThemedText.BodySmall>
              )}
            </AccountNamesWrapper>
          )}
        </StatusWrapper>
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <IconContainer> */}
          <TraceEvent
            events={[BrowserEvent.onClick]}
            name={SharedEventName.ELEMENT_CLICKED}
            element={InterfaceElementName.DISCONNECT_WALLET_BUTTON}
          >
            <IconWithConfirmTextButton
              data-testid="wallet-disconnect"
              onConfirm={disconnect}
              onShowConfirm={setShowDisconnectConfirm}
              Icon={LogOutCentered}
              text="Disconnect"
            />
          </TraceEvent>
          {/* </IconContainer> */}
        </div>
      </HeaderWrapper>
      <HeaderWrapper>
        <div>
          <ElektrikLogo width={20} height={20} style={{ marginRight: "0px" }} />
          $ELTK
        </div>
        <div>{formatNumberWithSuffix(EltkBalance)}</div>
      </HeaderWrapper>
    </AuthenticatedHeaderWrapper>
  );
}
