import { useEffect, useState } from "react";
import { Staking, StakerWallet } from "@eltk/staking-sdk";
import { Signer, providers } from "ethers";
import { useSignerWallet } from "hooks/useSignerWallet";
import { useStaking } from "./useStaking";

export const useGetPendingRewards = (tokenId: any) => {
  const [pendingRewards,setPendingRewards] = useState<any>(null);
  

  const { staking } = useStaking();

  useEffect(() => {
    const getPendingRewards = async () => {
      if (!tokenId) return;

      try {
        if (tokenId && staking) {
          const rewards = await staking.checkPendingRewards(tokenId);
          setPendingRewards(parseInt(rewards?._hex, 16))

        }
      } catch (error) {
        console.error(`Error fetching Pending Rewards ${tokenId}:`, error);
      }
    };

    getPendingRewards();
  }, [tokenId,staking]);

  return {
    pendingRewards
  };
};
