import { Currency, CurrencyAmount } from "@uniswap/sdk-core";
import { useWeb3React } from "@web3-react/core";
import { isPhoenixChain } from "constants/chains";
import { MIN_NATIVE_CURRENCY_FOR_GAS } from "constants/misc";
import JSBI from "jsbi";



export function maxAmountSpend(
  currencyAmount?: CurrencyAmount<Currency>,
  chainId?:any
): CurrencyAmount<Currency> | undefined {

  const isPhoenix = isPhoenixChain(chainId);

  if (!currencyAmount) return undefined;

  if (!isPhoenix && currencyAmount.currency.isNative) {
    if (JSBI.greaterThan(currencyAmount.quotient, MIN_NATIVE_CURRENCY_FOR_GAS)) {
      return CurrencyAmount.fromRawAmount(
        currencyAmount.currency,
        JSBI.subtract(currencyAmount.quotient, MIN_NATIVE_CURRENCY_FOR_GAS)
      );
    } else {

      return CurrencyAmount.fromRawAmount(
        currencyAmount.currency,
        JSBI.BigInt(0)
      );
    }
  }
  return currencyAmount;
}