export const formatterPrice = (price: number): string => {
  // Format the price based on its value
  let formattedPriceAsString: string;
  
  if (price >= 1000) {
    formattedPriceAsString = price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 8 });
  } else if (price >= 100 && price < 1000) {
    formattedPriceAsString = price.toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 8 });
  } else if (price >= 1 && price < 100) {
    formattedPriceAsString = price.toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 8 });
  } else {
    // For very small numbers
    formattedPriceAsString = price.toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 8 });
  }

  return formattedPriceAsString;
};
