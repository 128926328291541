import { FACTORY_ADDRESS as V2_FACTORY_ADDRESS } from "@uniswap/v2-sdk";
import { FACTORY_ADDRESS as V3_FACTORY_ADDRESS } from "@uniswap/v3-sdk";

import { SupportedChainId } from "./chains";

type AddressMap = { [chainId: number]: string };

const DEFAULT_NETWORKS = [
  SupportedChainId.MAINNET,
  SupportedChainId.GOERLI,
  SupportedChainId.SEPOLIA,
];

function constructSameAddressMap(
  address: string,
  additionalNetworks: SupportedChainId[] = []
): AddressMap {
  return DEFAULT_NETWORKS.concat(additionalNetworks).reduce<AddressMap>(
    (memo, chainId) => {
      memo[chainId] = address;
      return memo;
    },
    {}
  );
}

export const UNI_ADDRESS: AddressMap = constructSameAddressMap(
  "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984"
);

export const UNISWAP_NFT_AIRDROP_CLAIM_ADDRESS =
  "0x8B799381ac40b838BBA4131ffB26197C432AFe78";

export const V2_FACTORY_ADDRESSES: AddressMap =
  constructSameAddressMap(V2_FACTORY_ADDRESS);
export const V2_ROUTER_ADDRESS: AddressMap = constructSameAddressMap(
  "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D"
);

// celo v3 addresses
const CELO_V3_CORE_FACTORY_ADDRESSES =
  "0xAfE208a311B21f13EF87E33A90049fC17A7acDEc";
const CELO_V3_MIGRATOR_ADDRESSES = "0x3cFd4d48EDfDCC53D3f173F596f621064614C582";
const CELO_MULTICALL_ADDRESS = "0x633987602DE5C4F337e3DbF265303A1080324204";
const CELO_QUOTER_ADDRESSES = "0x82825d0554fA07f7FC52Ab63c961F330fdEFa8E8";
const CELO_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES =
  "0x3d79EdAaBC0EaB6F08ED885C05Fc0B014290D95A";
const CELO_TICK_LENS_ADDRESSES = "0x5f115D9113F88e0a0Db1b5033D90D4a9690AcD3D";

// BNB v3 addresses
const BNB_V3_CORE_FACTORY_ADDRESSES =
  "0xdB1d10011AD0Ff90774D0C6Bb92e5C5c8b4461F7";
const BNB_V3_MIGRATOR_ADDRESSES = "0x32681814957e0C13117ddc0c2aba232b5c9e760f";
const BNB_MULTICALL_ADDRESS = "0x963Df249eD09c358A4819E39d9Cd5736c3087184";
const BNB_QUOTER_ADDRESSES = "0x78D78E420Da98ad378D7799bE8f4AF69033EB077";
const BNB_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES =
  "0x7b8A01B39D58278b5DE7e48c8449c9f4F5170613";
const BNB_TICK_LENS_ADDRESSES = "0xD9270014D396281579760619CCf4c3af0501A47C";

// optimism goerli addresses
const OPTIMISM_GOERLI_V3_CORE_FACTORY_ADDRESSES =
  "0xB656dA17129e7EB733A557f4EBc57B76CFbB5d10";
const OPTIMISM_GOERLI_V3_MIGRATOR_ADDRESSES =
  "0xf6c55fBe84B1C8c3283533c53F51bC32F5C7Aba8";
const OPTIMISM_GOERLI_MULTICALL_ADDRESS =
  "0x07F2D8a2a02251B62af965f22fC4744A5f96BCCd";
const OPTIMISM_GOERLI_QUOTER_ADDRESSES =
  "0x9569CbA925c8ca2248772A9A4976A516743A246F";
const OPTIMISM_GOERLI_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES =
  "0x39Ca85Af2F383190cBf7d7c41ED9202D27426EF6";
const OPTIMISM_GOERLI_TICK_LENS_ADDRESSES =
  "0xe6140Bd164b63E8BfCfc40D5dF952f83e171758e";

// arbitrum goerli v3 addresses
const ARBITRUM_GOERLI_V3_CORE_FACTORY_ADDRESSES =
  "0x4893376342d5D7b3e31d4184c08b265e5aB2A3f6";
const ARBITRUM_GOERLI_V3_MIGRATOR_ADDRESSES =
  "0xA815919D2584Ac3F76ea9CB62E6Fd40a43BCe0C3";
const ARBITRUM_GOERLI_MULTICALL_ADDRESS =
  "0x8260CB40247290317a4c062F3542622367F206Ee";
const ARBITRUM_GOERLI_QUOTER_ADDRESSES =
  "0x1dd92b83591781D0C6d98d07391eea4b9a6008FA";
const ARBITRUM_GOERLI_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES =
  "0x622e4726a167799826d1E1D150b076A7725f5D81";
const ARBITRUM_GOERLI_TICK_LENS_ADDRESSES =
  "0xb52429333da969a0C79a60930a4Bf0020E5D1DE8";

// sepolia v3 addresses
const SEPOLIA_V3_CORE_FACTORY_ADDRESSES =
  "0x0227628f3F023bb0B980b67D528571c95c6DaC1c";
const SEPOLIA_V3_MIGRATOR_ADDRESSES =
  "0x729004182cF005CEC8Bd85df140094b6aCbe8b15";
const SEPOLIA_MULTICALL_ADDRESS = "0xD7F33bCdb21b359c8ee6F0251d30E94832baAd07";
const SEPOLIA_QUOTER_ADDRESSES = "0xEd1f6473345F45b75F8179591dd5bA1888cf2FB3";
const SEPOLIA_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES =
  "0x1238536071E1c677A632429e3655c799b22cDA52";
const SEPOLIA_TICK_LENS_ADDRESSES =
  "0xd7f33bcdb21b359c8ee6f0251d30e94832baad07";

//Change
// lightlink pegasus v3 addresses
const PEGASUS_V3_CORE_FACTORY_ADDRESSES =
  "0x7A5531FC6628e55f22ED2C6AD015B75948fC36F4";
const PEGASUS_V3_MIGRATOR_ADDRESSES =
  "0xE2Ab680A83A99604f9EA86F71e716361E2bE5430";
const PEGASUS_ADVANCE_ORDER_ENGINE_ADDRESS =
  "0x6a70F2D137218485fc84CbB52cFc3022C5A7d5a6";
const PEGASUS_STATIC_ORACLE = "0x7fA185d847b00549fEaD5FC76459EAC2b351008A";
const PEGASUS_USD_ORACLE = "0x1292D0193bE54B5C6070f877AC47198a2528dF0d";
const PEGASUS_MULTICALL_ADDRESS = "0xC8F2B723338dA040c8565b4f0A24113F2420a895";
const PEGASUS_QUOTER_ADDRESSES = "0x8937052BBd5480E3CF372c4f8978778b1b690977";
const PEGASUS_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES =
  "0x5cc8dcb7a2391d07043E5746b55CB1637a67ddf1";
const PEGASUS_TICK_LENS_ADDRESSES =
  "0x5FfDa38aCd6afd19B7A00c871f2615DfcDf26D7E";
const PEGASUS_UNIVERSAL_ROUTER_ADDRESS =
  "0x742d315e929B188e3F05FbC49774474a627b0502";
const PEGASUS_PERMIT_ADDRESS = "0x65b0dE86Df48d72aCdaF7E548b5C836663A0a4fa";

const PEGASUS_OPERATOR_ADDRESS = "0x5adaf849e40B5b1303507299D3d06a4663D3A8b8";
const PEGASUS_PREDICATE_CONTRACT_ADDRESS =
  "0x1b6E15343B01aDfa4ACd7Fb5c8968d20f242674F";
//TODO
// lightlink phoenix v3 addresses
const PHOENIX_V3_CORE_FACTORY_ADDRESSES =
  "0xEE6099234bbdC793a43676D98Eb6B589ca7112D7";
const PHOENIX_V3_MIGRATOR_ADDRESSES =
  "0x69723F3406B83d43ef48820d4a1fE3A7dcab75B7";
const PHOENIX_MULTICALL_ADDRESS = "0xC6a4B6667c44Fd56dC6E15C45108c4e8fC22F517";
const PHOENIX_QUOTER_ADDRESSES = "0x243551e321Dac40508c22de2E00aBECF17F764b5";
const PHOENIX_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES =
  "0x30d454297f03Fb638855A8C8559Ac0a745708ABf";
const PHOENIX_TICK_LENS_ADDRESSES =
  "0x01b604696d932a3151572013BA828A4F8dE23DcA";
const PHOENIX_UNIVERSAL_ROUTER_ADDRESS =
  "0x6B3ea22C757BbF9C78CcAaa2eD9562b57001720B";
const PHOENIX_PERMIT_ADDRESS = "0xB952578f3520EE8Ea45b7914994dcf4702cEe578";
const PHOENIX_ADVANCE_ORDER_ENGINE_ADDRESS =
  "0x5908B94b935C3c919f75ca027848e079160a2129";
const PHOENIX_STATIC_ORACLE = "0x34E9524Fb874393E5672732BB8Ea969390c02B0c";
const PHOENIX_USD_ORACLE = "0x06D1eDeC03cFEf70624aCB52c348e17b976F8fa4";
const PHOENIX_OPERATOR_ADDRESS = "0xf29F5fc83c9310c887EFce9D68Cae1F046B6511B";
const PHOENIX_PREDICATE_CONTRACT_ADDRESS =
  "0x752DC4079B7380B6a0fFB76fcE58D6E43EDAa025";

const PEGASUS_COMPOSABLE_ORDER_ENGINE =
  "0x8400aA4F48138B162e92317e5f70A5AACE42a0bD";
const PHOENIX_COMPOSABLE_ORDER_ENGINE =
  "0x695f8660bB17D0A35F5b2350751BC8315D61E145";

const PEGASUS_EXTENSIBLE_FALLBACK =
  "0xc602d6235350d7409c078f8dd548378b256d01f8";
const PHOENIX_EXTENSIBLE_FALLBACK =
  "0xE595C7Dc71E8E55CA0676427E2a991A6Ef1FCf0f";

const PEGASUS_ENCODE = "0xbaaa650736fd012d7b6db54bff5c93d1c1057b15";
const PHOENIX_ENCODE = "0xbaaa650736fd012d7b6db54bff5c93d1c1057b15";

const PEGASUS_TWAP_HANDLER = "0xAA63D266F8Ef64135C6CA0Cdc7DA5f5B58163FD9";
const PHOENIX_TWAP_HANDLER = "0x6a73Fc4A491701d077CE22c9cE87F24b21417b49";

export const twapHandlerAddress = (
  chainId: string | number | undefined
): string => {
  if (chainId === SupportedChainId.LIGHTLINK_PEGASUS_TESTNET) {
    return PEGASUS_TWAP_HANDLER;
  } else if (chainId === SupportedChainId.LIGHTLINK_PHOENIX_MAINNET) {
    return PHOENIX_TWAP_HANDLER;
  }
  return PHOENIX_TWAP_HANDLER;
};

export const composableOrderAddress = (
  chainId: string | number | undefined
): string => {
  if (chainId === SupportedChainId.LIGHTLINK_PEGASUS_TESTNET) {
    return PEGASUS_COMPOSABLE_ORDER_ENGINE;
  } else if (chainId === SupportedChainId.LIGHTLINK_PHOENIX_MAINNET) {
    return PHOENIX_COMPOSABLE_ORDER_ENGINE;
  }
  return PHOENIX_COMPOSABLE_ORDER_ENGINE;
};

export const extensibleFallbackAddress = (
  chainId: string | number | undefined
): string => {
  if (chainId === SupportedChainId.LIGHTLINK_PEGASUS_TESTNET) {
    return PEGASUS_EXTENSIBLE_FALLBACK;
  } else if (chainId === SupportedChainId.LIGHTLINK_PHOENIX_MAINNET) {
    return PHOENIX_EXTENSIBLE_FALLBACK;
  }
  return PHOENIX_EXTENSIBLE_FALLBACK;
};

export const universalRouterAddress = (
  chainId: string | number | undefined
): string => {
  if (chainId === SupportedChainId.LIGHTLINK_PEGASUS_TESTNET) {
    return PEGASUS_UNIVERSAL_ROUTER_ADDRESS;
  } else if (chainId === SupportedChainId.LIGHTLINK_PHOENIX_MAINNET) {
    return PHOENIX_UNIVERSAL_ROUTER_ADDRESS;
  }
  return PHOENIX_UNIVERSAL_ROUTER_ADDRESS;
};

export const advanceOrderEngineAddress = (
  chainId: string | number | undefined
): string => {
  if (chainId === SupportedChainId.LIGHTLINK_PEGASUS_TESTNET) {
    return PEGASUS_ADVANCE_ORDER_ENGINE_ADDRESS;
  } else if (chainId === SupportedChainId.LIGHTLINK_PHOENIX_MAINNET) {
    return PHOENIX_ADVANCE_ORDER_ENGINE_ADDRESS;
  }
  return PHOENIX_ADVANCE_ORDER_ENGINE_ADDRESS;
};

export const staticOracleAddress = (
  chainId: string | number | undefined
): string => {
  if (chainId === SupportedChainId.LIGHTLINK_PEGASUS_TESTNET) {
    return PEGASUS_STATIC_ORACLE;
  } else if (chainId === SupportedChainId.LIGHTLINK_PHOENIX_MAINNET) {
    return PHOENIX_STATIC_ORACLE;
  }
  return PHOENIX_STATIC_ORACLE;
};
export const staticUsdAddress = (
  chainId: string | number | undefined
): string => {
  if (chainId === SupportedChainId.LIGHTLINK_PEGASUS_TESTNET) {
    return PEGASUS_USD_ORACLE;
  } else if (chainId === SupportedChainId.LIGHTLINK_PHOENIX_MAINNET) {
    return PHOENIX_USD_ORACLE;
  }
  return PHOENIX_USD_ORACLE;
};

export const phoenixPermitAddress = (
  chainId: string | number | undefined
): string => {
  if (chainId === SupportedChainId.LIGHTLINK_PEGASUS_TESTNET) {
    return PEGASUS_PERMIT_ADDRESS;
  } else if (chainId === SupportedChainId.LIGHTLINK_PHOENIX_MAINNET) {
    return PHOENIX_PERMIT_ADDRESS;
  }
  return PHOENIX_PERMIT_ADDRESS;
};

/* V3 Contract Addresses */
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  ...constructSameAddressMap(V3_FACTORY_ADDRESS, [
    SupportedChainId.OPTIMISM,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.CELO]: CELO_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.BNB]: BNB_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.OPTIMISM_GOERLI]: OPTIMISM_GOERLI_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.ARBITRUM_GOERLI]: ARBITRUM_GOERLI_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.SEPOLIA]: SEPOLIA_V3_CORE_FACTORY_ADDRESSES,
  //Change
  [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]:
    PEGASUS_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]:
    PHOENIX_V3_CORE_FACTORY_ADDRESSES,
};

export const V3_MIGRATOR_ADDRESSES: AddressMap = {
  ...constructSameAddressMap("0xA5644E29708357803b5A882D272c41cC0dF92B34", [
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.CELO]: CELO_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.BNB]: BNB_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.OPTIMISM_GOERLI]: OPTIMISM_GOERLI_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.ARBITRUM_GOERLI]: ARBITRUM_GOERLI_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.SEPOLIA]: PEGASUS_V3_MIGRATOR_ADDRESSES,
  //Change
  [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]: PEGASUS_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]: PHOENIX_V3_MIGRATOR_ADDRESSES,
};

export const MULTICALL_ADDRESS: AddressMap = {
  ...constructSameAddressMap("0x1F98415757620B543A52E61c46B32eB19261F984", [
    SupportedChainId.OPTIMISM,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.ARBITRUM_ONE]: "0xadF885960B47eA2CD9B55E6DAc6B42b7Cb2806dB",
  [SupportedChainId.CELO]: CELO_MULTICALL_ADDRESS,
  [SupportedChainId.CELO_ALFAJORES]: CELO_MULTICALL_ADDRESS,
  [SupportedChainId.BNB]: BNB_MULTICALL_ADDRESS,
  [SupportedChainId.OPTIMISM_GOERLI]: OPTIMISM_GOERLI_MULTICALL_ADDRESS,
  [SupportedChainId.ARBITRUM_GOERLI]: ARBITRUM_GOERLI_MULTICALL_ADDRESS,
  [SupportedChainId.SEPOLIA]: SEPOLIA_MULTICALL_ADDRESS,
  //Change
  [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]: PEGASUS_MULTICALL_ADDRESS,
  [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]: PHOENIX_MULTICALL_ADDRESS,
};

/**
 * The oldest V0 governance address
 */
export const GOVERNANCE_ALPHA_V0_ADDRESSES: AddressMap =
  constructSameAddressMap("0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F");
/**
 * The older V1 governance address
 */
export const GOVERNANCE_ALPHA_V1_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: "0xC4e172459f1E7939D522503B81AFAaC1014CE6F6",
};
/**
 * The latest governor bravo that is currently admin of timelock
 */
export const GOVERNANCE_BRAVO_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: "0x408ED6354d4973f66138C91495F2f2FCbd8724C3",
};

export const TIMELOCK_ADDRESS: AddressMap = constructSameAddressMap(
  "0x1a9C8182C09F50C8318d769245beA52c32BE35BC"
);

export const MERKLE_DISTRIBUTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: "0x090D4613473dEE047c3f2706764f49E0821D256e",
};

export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: "0xeca4B0bDBf7c55E9b7925919d03CbF8Dc82537E8",
};

export const UNIVERSAL_ROUTER_ADDRESS: AddressMap = {
  [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]:
    "0x742d315e929B188e3F05FbC49774474a627b0502",
  [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]:
    "0x6B3ea22C757BbF9C78CcAaa2eD9562b57001720B",
};

export const QUOTER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap("0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6", [
    SupportedChainId.OPTIMISM,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.CELO]: CELO_QUOTER_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_QUOTER_ADDRESSES,
  [SupportedChainId.BNB]: BNB_QUOTER_ADDRESSES,
  [SupportedChainId.OPTIMISM_GOERLI]: OPTIMISM_GOERLI_QUOTER_ADDRESSES,
  [SupportedChainId.ARBITRUM_GOERLI]: ARBITRUM_GOERLI_QUOTER_ADDRESSES,
  [SupportedChainId.SEPOLIA]: SEPOLIA_QUOTER_ADDRESSES,
  //Change
  [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]: PEGASUS_QUOTER_ADDRESSES,
  [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]: PHOENIX_QUOTER_ADDRESSES,
};

export const ADVANCE_ORDER_ENGINE_ADDRESSES = {
  [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]:
    PEGASUS_ADVANCE_ORDER_ENGINE_ADDRESS,
  [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]:
    PHOENIX_ADVANCE_ORDER_ENGINE_ADDRESS,

  [SupportedChainId.MAINNET]: PHOENIX_ADVANCE_ORDER_ENGINE_ADDRESS,
};

export const STATIC_ORACLE_ADDRESSES = {
  [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]: PEGASUS_STATIC_ORACLE,
  [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]: PHOENIX_STATIC_ORACLE,

  [SupportedChainId.MAINNET]: PHOENIX_STATIC_ORACLE,
};

export const USD_ORACLE_ADDRESSES = {
  [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]: PEGASUS_USD_ORACLE,
  [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]: PHOENIX_USD_ORACLE,

  [SupportedChainId.MAINNET]: PHOENIX_USD_ORACLE,
};

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap("0xC36442b4a4522E871399CD717aBDD847Ab11FE88", [
    SupportedChainId.OPTIMISM,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.CELO]: CELO_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]:
    CELO_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.BNB]: BNB_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.OPTIMISM_GOERLI]:
    OPTIMISM_GOERLI_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.ARBITRUM_GOERLI]:
    ARBITRUM_GOERLI_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.SEPOLIA]: SEPOLIA_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  //Change
  [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]:
    PEGASUS_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]:
    PHOENIX_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
};

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e",
  [SupportedChainId.GOERLI]: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e",
};

export const SOCKS_CONTROLLER_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: "0x65770b5283117639760beA3F867b69b3697a91dd",
};

export const TICK_LENS_ADDRESSES: AddressMap = {
  [SupportedChainId.ARBITRUM_ONE]: "0xbfd8137f7d1516D3ea5cA83523914859ec47F573",
  [SupportedChainId.ARBITRUM_GOERLI]: ARBITRUM_GOERLI_TICK_LENS_ADDRESSES,
  [SupportedChainId.CELO]: CELO_TICK_LENS_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_TICK_LENS_ADDRESSES,
  [SupportedChainId.BNB]: BNB_TICK_LENS_ADDRESSES,
  [SupportedChainId.OPTIMISM_GOERLI]: OPTIMISM_GOERLI_TICK_LENS_ADDRESSES,
  [SupportedChainId.SEPOLIA]: SEPOLIA_TICK_LENS_ADDRESSES,
  //Change
  [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]: PEGASUS_TICK_LENS_ADDRESSES,
  [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]: PHOENIX_TICK_LENS_ADDRESSES,
};

export const OPERATOR_ADDRESS: AddressMap = {
  [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]: PEGASUS_OPERATOR_ADDRESS,
  [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]: PHOENIX_OPERATOR_ADDRESS,
};

export const PREDICATE_CONTRACT_ADDRESS: AddressMap = {
  [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]:
    PEGASUS_PREDICATE_CONTRACT_ADDRESS,
  [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]:
    PHOENIX_PREDICATE_CONTRACT_ADDRESS,
};

export const addressToArray = [
  "0xdac17f958d2ee523a2206206994597c13d831ec7",
  "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
  "0x6b175474e89094c44da98b954eedeac495271d0f",
  "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
  "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  "0xb50721bcf8d664c30412cfbc6cf7a15145234ad1",
  "0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0",
  "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
  "0x0649Cef6D11ed6F88535462E147304d3FE5ae14D",
  "0x514910771af9ca656af840dff83e8264ecf986ca",
  "0x418d75f65a02b3d53b2418fb8e1fe493759c7605",
  "0x4fabb145d64652a948d72533023f6e7a623c7c53",
];

export const contractAddress: any = {
  USDC: "0x544a0103F4aD5c3FF82460620173ED14951A8c23",
  DAI: "0xE09ACCf2524E7D933085F3DaD217CeC6cB86F89F",
  ARB: "0x77fe0045AE0e6D9DB2acD99eF567EFFF60E31EDa",
  MATIC: "0x8eCF9907bda8d5e61de2959965583536ce91b561",
};
//TK-9
export const contractAddressesChain: any = {
  ETH: {
    [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]:
      "0x66516A359DD8A75e6988B1190F8Dac5f1BA76eEF",
    [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]:
      "0x7EbeF2A4b1B09381Ec5B9dF8C5c6f2dBECA59c73",
  },
  WETH: {
    [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]:
      "0x66516A359DD8A75e6988B1190F8Dac5f1BA76eEF",
    [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]:
      "0x7EbeF2A4b1B09381Ec5B9dF8C5c6f2dBECA59c73",
  },
  USDC: {
    [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]:
      "0x544a0103F4aD5c3FF82460620173ED14951A8c23",
    [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]:
      "0x18fB38404DADeE1727Be4b805c5b242B5413Fa40",
  },
  DAI: {
    [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]:
      "0xE09ACCf2524E7D933085F3DaD217CeC6cB86F89F",
    [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]:
      "0x49F65C3FfC6e45104ff5cB00e6030C626157a90b",
  },
  ARB: {
    [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]:
      "0x77fe0045AE0e6D9DB2acD99eF567EFFF60E31EDa",
    [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]:
      "0x8fd8bc93518ea586be25c31a8973636192734555",
  },
  MATIC: {
    [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]:
      "0x8eCF9907bda8d5e61de2959965583536ce91b561",
    [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]:
      "0x0B0a417dC62721b16A8A2a6a3807b97F557D6209",
  },
  USDT: {
    [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]:
      "0x057e8e2bC40ECff87e6F9b28750D5E7AC004Eab9",
    [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]:
      "0x6308fa9545126237158778e74AE1b6b89022C5c0",
  },
  WBTC: {
    [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]:
      "0x9Ee1Aa18F3FEB435f811d6AE2F71B7D2a4Adce0B",
    [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]:
      "0x46A5e3Fa4a02B9Ae43D9dF9408C86eD643144A67",
  },
  OP: {
    [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]:
      "0xecf6Bdde77C77863Ae842b145f9ab296E5eAcAF9",
    [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]:
      "0xecf6Bdde77C77863Ae842b145f9ab296E5eAcAF9",
  },
  UNI: {
    [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]:
      "0x8488c316e23504B8554e4BdE9651802CD45aea24",
    [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]:
      "0xb4c16Cc8d80fdD59B6937Ce9072f4863DCe20077",
  },
  LINK: {
    [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]:
      "0xeDc98fc6240671dF8e7eD035CE39143320c1A174",
    [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]:
      "0x0b0edac05440669c5ee81ff8ad98b8d2e7f3d57b",
  },
  KUB: {
    [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]:
      "0xeEf8e3c318fb3d86489FB258847d028adC629e14",
    [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]:
      "0xd80bef7938ddfd95ba0c13410fb7708e63f6f204",
  },
  "ETH-LO": {
    [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]:
      "0x54390D6FD8C4d4e77c13Ac1E051C9A06eC19112f",
    [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]:
      "0xeEf8e3c318fb3d86489FB258847d028adC629e14",
  },
  "LINK-LO": {
    [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]:
      "0x46624eA6a260714df1E1EF4c13575Bc7AAdf0f0e",
    [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]:
      "0xeEf8e3c318fb3d86489FB258847d028adC629e14",
  },
  "BNB-LO": {
    [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]:
      "0xab16485D5fC7140b01440C4a1dE5007e9321DB5F",
    [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]:
      "0xab16485D5fC7140b01440C4a1dE5007e9321DB5F",
  },
  "USDT-LO": {
    [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]:
      "0xA4CBbd0f503720a0cA3C950D647A8c23FBE9702B",
    [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]:
      "0xA4CBbd0f503720a0cA3C950D647A8c23FBE9702B",
  },
  "CSIX.b": {
    [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]:
      "0xA4CBbd0f503720a0cA3C950D647A8c23FBE9702B",
    [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]:
      "0xe03bA6851335D01d38Dd6087c46C26008D16aF92",
  },
  "WBNB.b": {
    [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]:
      "0xA4CBbd0f503720a0cA3C950D647A8c23FBE9702B",
    [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]:
      "0x81A1f39f7394c4849E4261Aa02AaC73865d13774",
  },
};

// export const FilterWrapper = [
//   "0xd9d7123552fa2bedb2348bb562576d67f6e8e96e",
//   "0x7ebef2a4b1b09381ec5b9df8c5c6f2dbeca59c73",
//   "0x18fb38404dadee1727be4b805c5b242b5413fa40",
//   "0x81a1f39f7394c4849e4261aa02aac73865d13774",
//   "0xe03ba6851335d01d38dd6087c46c26008d16af92"
// ]

export const FilterWrapper = [
  "0x54390d6fd8c4d4e77c13ac1e051c9a06ec19112f",
  "0xab16485d5fc7140b01440c4a1de5007e9321db5f",
  "0xa4cbbd0f503720a0ca3c950d647a8c23fbe9702b",
  "0x46624ea6a260714df1e1ef4c13575bc7aadf0f0e",
];

export const USDT_LO = "0xA4CBbd0f503720a0cA3C950D647A8c23FBE9702B";
export const USDT_MAIN = "0x6308fa9545126237158778e74AE1b6b89022C5c0";
export const WETH_LO = "0x54390d6fd8c4d4e77c13ac1e051c9a06ec19112f";
export const WETH_MAIN = "0x7EbeF2A4b1B09381Ec5B9dF8C5c6f2dBECA59c73";

export const getUSDTAddressForChain = (
  chainId: string | number | undefined
): string => {
  if (chainId === SupportedChainId.LIGHTLINK_PEGASUS_TESTNET) {
    return USDT_LO;
  } else if (chainId === SupportedChainId.LIGHTLINK_PHOENIX_MAINNET) {
    return USDT_MAIN;
  }
  return USDT_MAIN;
};

export const getWETHAddressForChain = (
  chainId: string | number | undefined
): string => {
  if (chainId === SupportedChainId.LIGHTLINK_PEGASUS_TESTNET) {
    return WETH_LO;
  } else if (chainId === SupportedChainId.LIGHTLINK_PHOENIX_MAINNET) {
    return WETH_MAIN;
  }
  return WETH_MAIN;
};

export const decimalsMapping: Record<string, number> = {
  "ETH-LO": 18,
  "LINK-LO": 18,
  "BNB-LO": 18,
  "USDT-LO": 6,
  "CSIX.b": 18,
  WETH: 18,
  USDC: 6,
  "WBNB.b": 18,
  LINK: 18,
  KUB: 18,
  UNI: 18,
  MATIC: 18,
  ARB: 18,
  WBTC: 8,
  USDT: 6,
  DAI: 18,
  "BUSD.b": 18,
  "ELTK.e": 18,
};

export const decimalsMappingAddress: Record<string, number> = {
  "0x54390d6fd8c4d4e77c13ac1e051c9a06ec19112f": 18,
  "0x46624ea6a260714df1e1ef4c13575bc7aadf0f0e": 18,
  "0xab16485d5fc7140b01440c4a1de5007e9321db5f": 18,
  "0xa4cbbd0f503720a0ca3c950d647a8c23fbe9702b": 6,
  "0x7EbeF2A4b1B09381Ec5B9dF8C5c6f2dBECA59c73": 18,
  "0xe03bA6851335D01d38Dd6087c46C26008D16aF92": 6,
  "0x18fB38404DADeE1727Be4b805c5b242B5413Fa40": 6,
  "0x81A1f39f7394c4849E4261Aa02AaC73865d13774": 18,
};
export const STAKER_CONTRACT = "0xca78f56bd79ec56be60e068e209a25843a795d58";
export const EOA_ADDRESSES = "0x742d315e929B188e3F05FbC49774474a627b0502";
