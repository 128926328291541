import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { apolloClient, apolloClientMainnet, apolloClientTestnet } from "graphql/thegraph/apollo";
import { useEffect } from "react";

const GET_POOL_DATA = gql`
  query MyQuery {
    pools(where: { liquidity_not: "0" }) {
      id
      liquidity
      feeTier
      token0Price
      token1Price
      token0 {
        id
        symbol
        name
      }
      token1 {
        id
        symbol
        name
      }
      poolDayData(orderDirection: desc, first: 1) {
        volumeUSD
        feesUSD
      }
      feesUSD
      totalValueLockedUSD
      volumeToken0
      volumeToken1
      totalValueLockedToken0
      totalValueLockedToken1
      volumeUSD
    }
  }
`;

const GET_POSITION_DATA = gql`
query MyQuery($positionId: String!) {
  positions(where: {id: $positionId}) {
    depositedToken0
    depositedToken1
    withdrawnToken0
    withdrawnToken1
  }
}
`;

export const usePoolData = (chainId: any) => {
  const { loading, error, data } = useQuery(
    GET_POOL_DATA,
    {
      client: chainId == 1890 ? apolloClientMainnet : apolloClientTestnet,
      fetchPolicy: 'network-only',
      variables: { chainId }, 
    }
  );

  return {
    data,
    loading,
    error,
  };
};


export const usePositionData = (tokenId: any,) => {
  const { loading, error, data } = useQuery(GET_POSITION_DATA, {
    client:apolloClient,
    variables: { positionId: tokenId },
    fetchPolicy:'network-only'
  });

  return {
    data,
    loading,
    error,
  };
};
