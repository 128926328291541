import { getChainInfo } from "constants/chainInfo";
import { SupportedChainId } from "constants/chains";
import { ChainUrlMapping } from "constants/tokens";
import useTokenLogoSource from "hooks/useAssetLogoSource";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components/macro";

export const MissingImageLogo = styled.div<{ size?: string }>`
  --size: ${({ size }) => size};
  border-radius: 100px;
  color: ${({ theme }) => theme.textPrimary};
  background-color: ${({ theme }) => theme.backgroundInteractive};
  font-size: calc(var(--size) / 3);
  font-weight: 500;
  height: ${({ size }) => size ?? "24px"};
  line-height: ${({ size }) => size ?? "24px"};
  text-align: center;
  width: ${({ size }) => size ?? "24px"};
`;

export const LogoImage = styled.img<{ size: string; useBG?: boolean }>`
  width: ${({size})=> size ? size : '22px'};
  height: ${({size})=> size ? size : '22px'};
  border-radius: 50%;

  ${({ useBG }) =>
    useBG &&
    css`
      background: radial-gradient(white 60%, #ffffff00 calc(70% + 1px));
      box-shadow: 0 0 1px white;
    `}
`;

export type AssetLogoBaseProps = {
  symbol?: string | null;
  backupImg?: string | null;
  size?: string;
  style?: React.CSSProperties;
  hideL2Icon?: boolean;
};
type AssetLogoProps = AssetLogoBaseProps & {
  isNative?: boolean;
  address?: string | null;
  chainId?: number;
};

const LogoContainer = styled.div`
  position: relative;
  display: flex;
`;

const L2NetworkLogo = styled.div<{ networkUrl?: string; parentSize: string }>`
  --size: ${({ parentSize }) => `calc(${parentSize} / 2)`};
  width: var(--size);
  height: var(--size);
  position: absolute;
  left: 50%;
  bottom: 0;
  background: url(${({ networkUrl }) => networkUrl});
  background-repeat: no-repeat;
  background-size: ${({ parentSize }) =>
    `calc(${parentSize} / 2) calc(${parentSize} / 2)`};
  display: ${({ networkUrl }) => !networkUrl && "none"};
`;

/**
 * Renders an image by prioritizing a list of sources, and then eventually a fallback triangle alert
 */
export default function AssetLogo({
  isNative,
  address,
  chainId = SupportedChainId.MAINNET,
  symbol,
  backupImg,
  size = '22px',
  style,
  hideL2Icon = false,
}: AssetLogoProps) {

  const imageProps = {
    alt: `${symbol ?? "token"} logo`,
    size,
  };

  const [src, nextSrc] = useTokenLogoSource(
    address,
    chainId,
    isNative,
    backupImg
  );
  const L2Icon = getChainInfo(chainId)?.circleLogoUrl;
  const [srcData, setSrc] = useState("");
  const urlSuffix = ChainUrlMapping[chainId];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${urlSuffix}${address}`
        );
        const data = await response.json();
        setSrc(data.icon_url);
      } catch (error) {
        console.error("Error fetching data from API:", error);
      }
    };

    if (src === undefined) {
      fetchData();
    }
  }, [src, address]);

  return (
    <LogoContainer style={style}>
      {src || srcData ? (
        <LogoImage
          {...imageProps}
          src={src ?? srcData}
          onError={nextSrc}
          useBG={true}
          size={size}
        />
      ) : (
        <MissingImageLogo size={size}>
          {/* use only first 3 characters of Symbol for design reasons */}
          {symbol
            ?.toUpperCase()
            .replace("$", "")
            .replace(/\s+/g, "")
            .slice(0, 3)}
        </MissingImageLogo>
      )}
      {!hideL2Icon && <L2NetworkLogo networkUrl={L2Icon} parentSize={size} />}
    </LogoContainer>
  );
}
