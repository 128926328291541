import styled from "styled-components";

export const GraphLogoHeader = styled.div`
  display: flex;
  color: #fff;
  font-family: Inter;
  justify-content: center;
  align-items: center;
  position: relative;
  font-weight: 500;
  left: -10;
  font-size: 15px;
`;

export const DataSource = styled.div`
  color: #00ea96;
  text-align: right;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 22.5px */
`;

export const DataSourceSpan = styled.span`
  color: #00ea96;
  text-align: right;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 22.5px */
`;

export const DataNameSpan = styled.span`
  color: #445156;
  text-align: right;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 22.5px */
`;
export const PriceLabel = styled.div`
  color: #fff;
  text-align: right;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 45px */
  @media (max-width: 768px) {
    font-size: 20px; /* Smaller font size for smaller screens */
  }
`;

export const PriceChangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
`;

export const GraphInnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  /* margin: 0px 0px 20px 0px; */
  flex-wrap: wrap;
`;
export const GraphTimeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
  margin:22px 0px 26px 0px;
`;
export const ToggleGlobalWrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
`;
export const NoData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #5d6785;
  font-weight: bold;
`;

export const NoPreview = styled.div`
  position: relative;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;


`;
