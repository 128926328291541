import AnimatedDropdown from 'components/AnimatedDropdown'
import Column from 'components/Column'
import { PropsWithChildren, ReactElement } from 'react'
import { RowBetween } from '../Row'
import { ButtonContainer, Content, ExpandIcon } from './styled'



export default function Expand({
  header,
  button,
  children,
  testId,
  isOpen,
  onToggle,
}: PropsWithChildren<{
  header: ReactElement
  button: ReactElement
  testId?: string
  isOpen: boolean
  onToggle: () => void
}>) {
  return (
    <Column>
      <RowBetween>
        {header}
        <ButtonContainer data-testid={testId} onClick={onToggle} aria-expanded={isOpen}>
          {button}
          {/* <ExpandIcon $isOpen={isOpen} /> */}
        </ButtonContainer>
      </RowBetween>
      <AnimatedDropdown open={isOpen}>
        <Content gap="md">{children}</Content>
      </AnimatedDropdown>
    </Column>
  )
}
