import { TraceEvent } from "@uniswap/analytics";
import { BrowserEvent, InterfaceEventName } from "@uniswap/analytics-events";
import { atom } from "jotai";
import { useAtomValue, useUpdateAtom } from "jotai/utils";
import { useCallback, useEffect, useRef } from "react";

import DefaultMenu from "./DefaultMenu";
import { AccountDrawerScrollWrapper, AccountDrawerWrapper } from "./styled";
import Modal from "components/Modal";
import { Backdrop } from "components/IntroModal/styled";
import useToggle from "hooks/useToggle";
import { useOnClickOutside } from "hooks/useOnClickOutside";

const accountDrawerOpenAtom = atom(false);

export function useToggleAccountDrawer() {
  const updateAccountDrawerOpen = useUpdateAtom(accountDrawerOpenAtom);
  return useCallback(() => {
    updateAccountDrawerOpen((open) => !open);
  }, [updateAccountDrawerOpen]);
}

export function useCloseAccountDrawer() {
  const updateAccountDrawerOpen = useUpdateAtom(accountDrawerOpenAtom);
  return useCallback(
    () => updateAccountDrawerOpen(false),
    [updateAccountDrawerOpen]
  );
}

export function useAccountDrawer(): [boolean, () => void] {
  const accountDrawerOpen = useAtomValue(accountDrawerOpenAtom);
  return [accountDrawerOpen, useToggleAccountDrawer()];
}

function AccountDrawer() {
  const [walletDrawerOpen, toggleWalletDrawer] = useAccountDrawer();
  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!walletDrawerOpen) {
      scrollRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [walletDrawerOpen]);

  // close on escape keypress
  useEffect(() => {
    const escapeKeyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Escape" && walletDrawerOpen) {
        event.preventDefault();
        toggleWalletDrawer();
      }
    };

    document.addEventListener("keydown", escapeKeyDownHandler);

    return () => {
      document.removeEventListener("keydown", escapeKeyDownHandler);
    };
  }, [walletDrawerOpen, toggleWalletDrawer]);

  // close on escape keypress
  useEffect(() => {
    const escapeKeyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Escape" && walletDrawerOpen) {
        event.preventDefault();
        toggleWalletDrawer();
      }
    };

    document.addEventListener("keydown", escapeKeyDownHandler);

    return () => {
      document.removeEventListener("keydown", escapeKeyDownHandler);
    };
  }, [walletDrawerOpen, toggleWalletDrawer]);


  

  return (
    <>
      {walletDrawerOpen && <Backdrop />}

      <Modal isOpen={walletDrawerOpen}>
        {walletDrawerOpen && (
          <TraceEvent
            events={[BrowserEvent.onClick]}
            name={InterfaceEventName.MINI_PORTFOLIO_TOGGLED}
            properties={{ type: "close" }}
          ></TraceEvent>
        )}
        <AccountDrawerWrapper open={walletDrawerOpen}>
          <AccountDrawerScrollWrapper
            ref={scrollRef}
            id="wallet-dropdown-scroll-wrapper"
          >
            <DefaultMenu />
          </AccountDrawerScrollWrapper>
        </AccountDrawerWrapper>
      </Modal>
    </>
  );
}

export default AccountDrawer;
