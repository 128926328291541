import Tooltip from 'components/Tooltip'
import useCopyClipboard from 'hooks/useCopyClipboard'
import { ThemedText } from 'theme'
import { GitVersionContainer } from './styled'

export function GitVersionRow() {
  const [isCopied, staticCopy] = useCopyClipboard()
  return process.env.REACT_APP_GIT_COMMIT_HASH ? (
    <GitVersionContainer
      onClick={() => {
        staticCopy(process.env.REACT_APP_GIT_COMMIT_HASH as string)
      }}
    >
      <Tooltip text="Copied" show={isCopied}>
        <ThemedText.BodySmall color="textTertiary">
          <div>Version: </div>
          {' ' + process.env.REACT_APP_GIT_COMMIT_HASH.substring(0, 6)}
        </ThemedText.BodySmall>
      </Tooltip>
    </GitVersionContainer>
  ) : null
}
