import { AutoColumn } from "components/Column";
import Row from "components/Row";
import { Text } from "rebass";
import styled from "styled-components";
import { Z_INDEX } from "theme/zIndex";
import { ButtonText } from "components/Button";

export const StyledTextButton = styled(ButtonText) <{ active?: boolean }>`
  color: ${({ active }) => (active ? "#a2eeff" : "#fff")};
  background: ${({ active }) =>
    active
      ? "linear-gradient(191deg, #263c42 5.4%, #092d3f 92.07%)"
      : "#121b1e"};
  border-radius: 6px;
  padding: 6px;
  gap: 5px;
  opacity: 1 !important;
  font-weight: 500;
  margin: 0px 5px 0px 0px;
  font-size: 10px;
  width: 36px;
  height: 20px;
  &:focus {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:hover {
    background: linear-gradient(
      91deg,
      #19272a 0.97%,
      #0a1e29 95.57%,
      #091e29 100.23%
    );
    color: #53C5F8;
  }
`;
export const GradientTextOrange = styled.span`
  background: var(
    --Gradient-1,
    linear-gradient(191deg, #ffdfa1 5.4%, #f08302 92.07%)
  );
  -webkit-background-clip: text;
  color: transparent;
  font-size: 10px;
  font-weight: 400;
  gap: 10px;
  text-decoration: none;
`;

export const LockingHeader = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1.2fr;
  padding: 36px;
  @media (max-width: 1199px) {
    grid-template-columns: 1fr;
  }
`;

export const LockingBalance = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
  border-radius: 10px;
  background: linear-gradient(
    194deg,
    rgba(217, 109, 255, 0.1) 3.9%,
    rgba(173, 0, 255, 0.1) 98.12%
  );
  align-items: center;
  padding: 0 25px 0 16px;
  margin-bottom: 20px;
  color: #d96dff;
  position: relative; /* Needed for the ::before pseudo-element */

  &::before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 10px;
    border: 1px solid transparent;
    background: linear-gradient(194deg, #d96dff 15%, #ad00ff 98.12%);
    -webkit-mask:
      linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    mask:
      linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }

  @media (max-width: 1199px) {
    /* Add any styles needed for smaller screens here */
  }
`;

export const LockingMain = styled.div`
  display: flex;
  margin-bottom: 10px;
  width: 100%;
  flex-wrap: wrap;
  @media (max-width: 1199px) {
    max-width: 500px;
    margin: 0 auto;
  }
`;

export const ImageHolder = styled.div`
  display: flex;
`;
export const ContentHolder = styled.div`
  @media (max-width: 1199px) {
    width: 100%;
    margin: 0 auto;
  }
`;

export const DetailsSection = styled.div`
  width: 100%;
  padding: 45px;
  margin-top: 50px;
  @media (max-width: 1199px) {
    border-left: none;
    padding: 20px;
  }
`;

export const LockingSection = styled.div`
  width: 100%;
  grid-template-columns: 1fr 1fr;
  display: grid;
  position: relative;
  --border-width: 2px;
  --border-radius: 12px;
  background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
  border: 0;
  padding: 34px 60px 45px 60px;
  position: relative;
  border-radius: var(--border-radius);
  z-index: ${Z_INDEX.default};
  &::before {
    content: "";
    display: block;
    height: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    width: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    background: ${({ theme }) => theme.backgroundSurface};
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: calc(var(--border-radius) - var(--border-width));
    z-index: ${Z_INDEX.negative};
  }
  @media (max-width: 1199px) {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    padding: 15px;
  }
`;

export const WrappingPara = styled.div``;

export const LockingSectionPara = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin: 0px 0px 32px 0px;
`;

export const LockingSectionInner = styled.div``;

export const Column = styled.div`
  flex: 1;
  box-sizing: border-box;
  padding: 10px;
  @media screen and (max-width: 600px) {
    .column {
      flex-basis: 100%;
    }
  }
`;

export const LockingPageWrapper = styled(AutoColumn)`
  padding: 28px 65px;
  width: 100%;
  overflow: hidden;
  max-width: 1600px;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
  `};

  @media (max-width: 1199px) {
    padding: 28px 20px;
  }
  margin-bottom: 100px;
`;

export const LockingPageWrapperDetails = styled(AutoColumn)`
  /* padding: 68px 65px; */
  width: 100%;
  overflow: hidden;
  max-width: 1600px;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
  `};

  @media (max-width: 1199px) {
    padding: 28px 20px;
  }
  margin-bottom: 100px;
`;

export const SubtitleHeader = styled(AutoColumn)`
  font-weight: 500;
  font-size: 12px;
  font-family: Inter;
  padding: 0 20px 0 0;
  max-width: 524px;
  margin: 17px 10px 20px 0;
  @media (max-width: 1199px) {
    max-width: 384px;
    margin: 10px auto;
  }
`;

export const TitleHeader = styled(AutoColumn)`
  font-weight: 600;
  font-size: 28px;
  font-family: Inter;
  margin: 0;
  @media (max-width: 1199px) {
    text-align: center;
  }
`;

export const ContainerTitleHeader = styled(AutoColumn)`
  font-weight: 600;
  font-size: 24px;
  font-family: Inter;
  padding: 0 25px 0 16px;
  @media (max-width: 1199px) {
    font-size: 20px;
  }
`;

export const LockOverviewContainer = styled(AutoColumn)`
  /* display:flex;
justify-content:space-between; */
  position: relative;
  margin: 29px 0px 0px 0px;
  padding: 0 0px 0 0px;
  &::before {
    content: "";
    position: absolute;
    left: 50%; /* Center the pseudo-element */
    top: 0;
    transform: translateX(
      -50%
    ); /* Shift it back by half its own width to center */
    width: calc(100% - 32px);
    border-top: 1px solid #22282a;
  }
  @media (max-width: 1199px) {
  }
`;

export const DurationButton = styled(AutoColumn)`
  font-weight: 600;
  padding: 6px;
  background: #121b1e;
  color: #ffffff !important;
  border-radius: 6px; /* Updated border-radius */
  cursor: pointer;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 20px;
  opacity: 0.8; /* Adjusted for non-hover state for better contrast */

  &:hover {
    border-radius: 6px;
    background: var(
      --Gradient-1,
      linear-gradient(191deg, #253b41 5.4%, #0a2d3f 92.07%)
    );
  }

  @media (max-width: 1199px) {
    /* Responsive adjustments if needed */
  }
`;
export const DurationButtonExtend = styled.button`
  padding: 6px;
  background: #121b1e;
  color: #ffffff;
  border-radius: 6px; /* Updated border-radius */
  cursor: pointer;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 20px;
  opacity: 0.8; /* Adjusted for non-hover state for better contrast */

  &:hover {
    border-radius: 6px;
    background: var(
      --Gradient-1,
      linear-gradient(191deg, #253b41 5.4%, #0a2d3f 92.07%)
    );
  }

  @media (max-width: 1199px) {
    /* Responsive adjustments if needed */
  }
`;

export const DurationText = styled(AutoColumn)`
  color: #ffffff;

  &:hover {
    background: var(
      --Gradient-1,
      linear-gradient(191deg, #a1eeff 5.4%, #029af0 92.07%)
    );
    background-clip: text !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (max-width: 1199px) {
    /* Responsive adjustments if needed */
  }
`;

export const ElektriktText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: "#D96DFF";

  font-size: 16px;
  font-weight: 600;
  background: var(
    --Gradient-1,
    linear-gradient(191deg, #a1eeff 5.4%, #029af0 92.07%)
  );
  background-clip: text !important;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 1199px) {
    /* Responsive adjustments if needed */
  }
`;

export const GradientText = styled.div`
  background: var(
    --Gradient-1,
    linear-gradient(191deg, #a1eeff 5.4%, #029af0 92.07%)
  );
  -webkit-background-clip: text;
  color: transparent;
  display: inline;
  font-size: 10px;
  font-weight: bold;
  gap: 10px;
  margin: 8px;
  cursor: pointer;
  text-decoration: none;
`;

export const GradientTextUnderline = styled.div`
  position: relative;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  display: inline-block; /* Ensure inline-block for proper positioning */
  background-image: linear-gradient(191deg, #a1eeff 5.4%, #029af0 92.07%);
  -webkit-background-clip: text;
  color: transparent;

  &:after {
    content: "";
    position: absolute;
    left: 37px;
    bottom: -2px; /* Adjust this value to position the underline properly */
    width: 57%;
    height: 2px; /* Adjust this value to control the thickness of the underline */
    background-image: linear-gradient(191deg, #a1eeff 5.4%, #029af0 92.07%);
    opacity: 0.5; /* Adjust the opacity to control the intensity of the gradient */
    z-index: -1; /* Ensure the underline is below the text */
  }
`;

export const GradientTextPurple = styled.span`
  background: linear-gradient(194deg, #d96dff 3.9%, #ad00ff 98.12%);
  -webkit-background-clip: text;
  color: transparent;
  display: inline;
  font-size: 15px;
  font-weight: 600;
  gap: 10px;
  cursor: pointer;
  text-decoration: none;
`;

export const GradientTextDuration = styled.div`
  background: var(
    --Gradient-1,
    linear-gradient(191deg, #a1eeff 5.4%, #029af0 92.07%)
  );
  -webkit-background-clip: text;
  color: transparent;
  font-size: 10px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
`;

export const LightTextSubtitle = styled(AutoColumn)`
  font-weight: 500;
  font-size: 10px;
  font-family: Inter;
  color: #566368;
  align-items: center;
  @media (max-width: 1199px) {
    display: none;
  }
`;

export const PrimaryButtonConteiner = styled(AutoColumn)`
  @media (max-width: 1199px) {
    justify-content: center;
  }
`;

export const PoolTableWrapper = styled.div`
  height: 400px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding: 34px 31px 26px 27px;
  --border-width: 2px;
  --border-radius: 12px;
  background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
  border: 0;

  border-radius: var(--border-radius);
  z-index: 1;

  &::before {
    content: "";
    display: block;
    height: calc(100% - (var(--border-width) * 2));
    width: calc(100% - (var(--border-width) * 2));
    background: ${({ theme }) => theme.backgroundSurface};
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: calc(var(--border-radius) - var(--border-width));
    z-index: -1;
  }
`;

export const PoolTableHeader = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-left: 30px;
`;
export const PoolTableContainer = styled.div`
  width: 100%;
  height: 100%;
`;
export const TableHead = styled.div`
  width: 100%;
  min-height: 30px;
`;

export const TableBody = styled.div`
  width: 100%;
  height: auto;
`;

export const TableText = styled(Text)`
  :hover {
    cursor: pointer;
  }
  text-align: left;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 22.5px */
  color: ${({ theme }) => theme.white};
  padding: 1rem 1rem 1rem 0;
`;

export const TableRow = styled.div`
  width: 100%;
  border-bottom: 1px solid #22282a;
  &:last-child {
    border-bottom: none;
  }
`;

export const PoolNameWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center !important;
  justify-content: flex-start;
  /* padding-left: 5px; */
`;

export const LogoOutlineImage = styled.img``;

export const PaginationWrapper = styled.section`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0 1rem 0;
  margin-top: 1rem;
`;

export const NumbersContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 150px;
`;

export const PageNumber = styled.div<{ selected?: boolean }>`
  width: 20px;
  height: 20px;
  padding: 1rem;
  margin: 0.3rem;
  background: ${(props) =>
    props.selected
      ? "linear-gradient(191deg, rgba(161, 238, 255, 0.20) 5.4%, rgba(2, 154, 240, 0.20) 92.07%)"
      : "transparent"};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
`;

export const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  grid-template-columns: 0.5fr 1fr 1fr repeat(3, 1fr) 1fr 1fr 1fr; // Initial column setup for large screens

  @media screen and (max-width: 940px) {
    grid-template-columns: 1.5fr 2fr repeat(2, 1fr);
    & > *:nth-last-child(-n + 3) {
      // Example: hides the last three elements
      display: none;
    }
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: 2fr 1.5fr; // Simplify to essential columns

    & > *:not(:first-child):not(:nth-child(2)) {
      display: none;
    }
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
    & > * {
      display: block;
    }
  }
`;
export const PoolFilterWrapper = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* padding: 1rem 2rem; */
`;

export const IconInput = styled.input`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  font-size: 16px;
  border: 0;
  outline: none;
  background: transparent;
  text-align: left;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  ::placeholder {
    color: #797f80;
  }
`;

export const IconInputContainer = styled(Row) <{ error?: boolean }>`
  padding: 8px 16px;
  border-radius: 10px;
  background: #121b1e;
  /* min-width: 280px; */
  margin: 0.5rem;
  width: auto;
  height: 38px;
  input {
    color: ${({ theme, error }) =>
    error ? theme.accentFailure : theme.textPrimary};
  }
  border: 1px solid #121b1e;
  ${({ theme, error }) =>
    error
      ? `
        border: 1px solid ${theme.accentFailure};
        :focus-within {
          border-color: ${theme.accentFailureSoft};
        }
      `
      : `
        border: 1px solid #121B1E;
        :focus-within {
          border-color: ${theme.accentActiveSoft};
        }
      `}
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ResponsiveGridLock = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  grid-template-columns: 2fr 2fr 2fr repeat(3, 2fr);

  @media screen and (max-width: 940px) {
    grid-template-columns: 1.5fr 2fr 1.5fr repeat(3, 1fr);
    & > *:nth-child(5) {
      display: none;
    }
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 2.2fr 2fr 1.5fr;
    & > *:nth-child(5) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
    & > *:nth-child(4) {
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 2fr 1.5fr;
    & > *:nth-child(5) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
    & > *:nth-child(4) {
      display: none;
    }
    & > *:nth-child(2) {
      display: none;
    }
    & > *:nth-child(6) {
      display: none;
    }
  }
`;

export const LockModalWrapper = styled.div`
  --border-width: 2px;
  --border-radius: 17px;
  background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
  border: 0;
  padding: 2rem 3rem;
  position: relative;
  border-radius: var(--border-radius);
  z-index: 1;
  &::before {
    content: "";
    display: block;
    height: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    width: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    background: ${({ theme }) => theme.backgroundSurface};
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: calc(var(--border-radius) - var(--border-width));
    z-index: -1;
  }
`;
export const LockModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LockModalCurrencySection = styled.div``;

export const LockModalTImelineSection = styled.div``;

export const LockModalBody = styled.div`
  margin: "10px 0px";
`;

export const LockingTabsWrapper = styled.div`
  max-width: 345px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #192427;
  padding: 5px;
  gap: 10px;
`;

export const LockTabsItem = styled.div<{ active: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: ${(props) =>
    props.active
      ? "linear-gradient(95.72deg, #a2eeff 13.1%, #029bf0 98.51%)"
      : ""};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StylingHr = styled.hr`
  opacity: 0.2;
  margin-bottom: 20;
  margin-top: 20;
`;

export const VoteInputContainer = styled.div<{ error: boolean }>`
  height: 45px;
  border-radius: 10px;
  border: ${({ error }) => (error ? "1px solid red" : "1px solid #192427")};
  background: #091012;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  &::after {
    content: "%";
    position: absolute;
    right: 10px; /* Position the % symbol inside the input field */
    top: 50%;
    transform: translateY(-50%);
    color: #868e9b;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
`;

export const PowerValue = styled.p`
  background: transparent;
  width: 90%;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Percent = styled.input`
  width: 40%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  color: #868e9b;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  padding-right: 20px;
  position: relative;
  &::-webkit-textfield-decoration-container {
    flex-direction: row-reverse;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    margin-right: 5px;
  }
`;

export const StyledInput = styled.input`
  text-align: right;
  border: none;
  appearance: none;
  outline: none;
  background: none;
  color: #fff;

  &:disabled {
    cursor: not-allowed;
  }

  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
`;

export const CurrencyLogoWrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #192427;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ScrollFeesDiv = styled.div`
  flex: 1;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #121b1e; /* Updated color */
    border-radius: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: #091012;
    border-radius: 12px;
  }
`;

export const BalanceDivModal = styled.div`
  margin-top: 25;
`;

export const BalancePercentagecontainer = styled.div`
  border-right: 1px solid #22282a;
  padding-right: 20px;
`;

export const BalancePercentagecontainerInner = styled.div`
  margin: 10px 0px;
  padding: 0 0px 0 0px;
`;

export const LockingMainDiv = styled.div`
 display: flex;
justify-content: space-between;
 margin: 4px 0px;
`;

export const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: #121b1e;
  border-radius: 10px;
  height: 46px;
  align-items: center;
  padding: 12px 18px;
`;

export const DurationWrapper = styled.div`
  margin: 20px 0px;
  padding: 0 0px 0 0px;
`;

export const DurationMessage = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 4px 0px;
`;

export const ExpiryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background: #121b1e;
  border-radius: 10px;
  height: 46px;
  align-items: center;
  padding: 12px 18px;
  margin-top: 6px;
`;

export const LockPositionsContainer = styled.div`
              display: flex;
              justify-content: center;
              align-items: center;
              min-height: 400px;
              text-align: center;
`;

export const LockPositionsContainerInner = styled.div`
`;

export const WalletConnectText = styled.p`
  font-size: 26px;
  font-weight: 500;
`;

export const WalletConnectSubText = styled.p`
  font-size: 15;
  font-weight: 400;
  color: "#ffffffb3";
`;

export const ConnectWalletWrapper = styled.div`
  display: inline-block;
`;

export const LockingTimeDiv = styled.div`
  display: flex;
  gap: 5px;
  margin-right: 5px;
`;




export const BlinkingSpan = styled.span`
  animation: blink 1s linear infinite;

  @keyframes blink {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 0.5;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;