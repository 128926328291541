import { createChart } from "lightweight-charts";
import React, { useEffect, useRef } from "react";
import moment from "moment";
import { formatLimitPrice } from "utils/limitPrice";
import { formatterPrice } from "utils/formatterPrice";

function transformData(data: any[]): any[] {
  // Helper function to get the timestamp key
  const getTimestamp = (entry: any): number => {
    return entry.date || entry.periodStartUnix;
  };

  // Sort data based on the timestamp key
  const sortedData = [...data].sort((a, b) => {
    const timestampA = getTimestamp(a);
    const timestampB = getTimestamp(b);
    return timestampA - timestampB;
  });

  // Helper function to convert string to number
  const toNumber = (value: string) => parseInt(value);

  // Aggregate data by timestamp
  const aggregated: { [key: number]: any } = {};

  sortedData.forEach(entry => {
    const timestamp = getTimestamp(entry);
    const open = entry.open;
    const close = entry.close;
    const high = entry.high;
    const low = entry.low;

    if (!aggregated[timestamp]) {
      // Initialize entry if it doesn't exist
      aggregated[timestamp] = {
        open: open,
        close: close,
        high: high,
        low: low,
        isFirst: true
      };
    } else {
      // Update high and low values
      aggregated[timestamp].high = Math.max(aggregated[timestamp].high, high);
      aggregated[timestamp].low = Math.min(aggregated[timestamp].low, low);
      // Update close value to the last entry's close
      aggregated[timestamp].close = close;
    }

    // Update open value only for the first entry with the same timestamp
    if (aggregated[timestamp].isFirst) {
      aggregated[timestamp].open = open;
      aggregated[timestamp].isFirst = false;
    }
  });

  console.log("KKKK", aggregated)

  // Format the final output
  return Object.keys(aggregated).map(timestampStr => {
    const timestamp = parseInt(timestampStr, 10);
    const { open, close, high, low } = aggregated[timestamp];
    return {
      date: timestamp,
      open: open,
      close: close,
      high: high,
      low: low,
    };
  });
}

interface CandleData {
  time: string;
  open: number;
  high: number;
  low: number;
  close: number;
}

type Props = {
  data: CandleData[];
  isPhoenix: boolean;
  isLimit?: any;
  selectedQueryType?: any;
  dataType?: any;
};
const convertDataToCandle = (
  data: any[],
  selectedQueryType: any,
  isLimit: any
): CandleData[] => {
  if (isLimit) {

    return data.map((item) => {    
      return {
        time: item?.date,
        open: formatLimitPrice(parseFloat(item.open)),
        high: formatLimitPrice(parseFloat(item.high)),
        low: formatLimitPrice(parseFloat(item.low)),
        close: formatLimitPrice(parseFloat(item.close)),
      };
    });
  } else {
    if (selectedQueryType === "1M" || selectedQueryType === "1Y") {

      return data.map((item) => {
        return {
          time: item?.date,
          open: formatLimitPrice(parseFloat(item.open)),
          high: formatLimitPrice(parseFloat(item.high)),
          low: formatLimitPrice(parseFloat(item.low)),
          close: formatLimitPrice(parseFloat(item.close)),
        };
      });
    } else {
      return data.map((item) => {
        return {
          time: item?.date,
          open: formatLimitPrice(parseFloat(item.open)),
          high: formatLimitPrice(parseFloat(item.high)),
          low: formatLimitPrice(parseFloat(item.low)),
          close: formatLimitPrice(parseFloat(item.close)),
        };
      });
    }
  }
};

export const ChartComponent: React.FC<Props> = (props) => {
  const { data, isPhoenix, isLimit, selectedQueryType } = props;

  let transfromedData = transformData(data)


  const chartContainerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const candleColors = {
    up: "#36FBCC",
    down: "#FF4F4F",
  };

  useEffect(() => {
    let chart: any;

    if (chartContainerRef.current) {
      const handleResize = () => {
        if (chart && chartContainerRef.current) {
          chart.applyOptions({ width: chartContainerRef.current.clientWidth });
        }
      };

      chart = createChart(chartContainerRef.current);

      chart.applyOptions({
        layout: {
          background: { color: "#091012" },
          textColor: "#929292",
          borderRadius: 12,
        },
        grid: {
          vertLines: { color: "transparent" },
          horzLines: { color: "transparent" },
        },
        width: chartContainerRef.current.clientWidth,
        height: isLimit ? 350 : 350,
        rightPriceScale: {
          borderColor: "#162225",
          visible: false,
        },
        leftPriceScale: {
          borderColor: "#162225",
          visible: true,
        },
        crosshair: {
          vertLine: {
            width: 1.5,
            color: "#C3BCDB44",
            labelBackgroundColor: "#9B7DFF",
          },
          horzLine: {
            color: "#9B7DFF",
            labelBackgroundColor: "#9B7DFF",
          },
        },
      });

      chart.timeScale().applyOptions({
        borderColor: "#162225",
        barSpacing: 15,
        minBarSpacing: 5,
        timeVisible: true,
      });

      chart.timeScale().fitContent();

      chart.applyOptions({
        localization: {
          locale: "en-US",
          priceFormatter: (price: any) => formatterPrice(formatLimitPrice(price)),
        },
      });

      chart.applyOptions({
        layout: {
          fontFamily: "'Roboto', sans-serif",
        },
      });

      const candlestickSeries = chart.addCandlestickSeries({
        wickUpColor: "#36FBCC",
        upColor: "#36FBCC",
        wickDownColor: "#FF4F4F",
        downColor: "#FF4F4F",
        borderVisible: true,
        borderWidth: 3,
        handleScale: false,
        priceFormat: {
          minMove: 0.00000000001,
          precision: 8,
        },
      });
      const candleStickSeriesData = convertDataToCandle(
        transfromedData,
        selectedQueryType,
        isLimit
      );


      candlestickSeries.setData(candleStickSeriesData);

      function getCandleColor(data: any) {
        const open = parseFloat(data.open);
        const close = parseFloat(data.close);

        return close > open ? candleColors.up : candleColors.down;
      }

      chart.subscribeCrosshairMove((param: any) => {
        if (!param.time && tooltipRef.current) {
          tooltipRef.current.style.display = "none";
        }

        if (param?.time && tooltipRef.current && chartContainerRef.current) {
          const data = param?.seriesData.get(candlestickSeries);
          const date = new Date(data?.time * 1000);



          function getDateLabel(date: any, selectedQueryType: any) {
            if (
              selectedQueryType === "1D" ||
              selectedQueryType === "1M" ||
              selectedQueryType === "1Y"
            ) {
              return moment(date).format("MMM DD YYYY");
            } else {
              return moment(date).format("MMM DD YYYY hh:mm A");
            }
          }

          if (
            param.point.x < 0 ||
            param.point.x > chartContainerRef.current.clientWidth ||
            param.point.y < 0 ||
            param.point.y > chartContainerRef.current.clientHeight
          ) {
            tooltipRef.current.style.display = "none";
          } else {
            if (data) {
              const coordinate = candlestickSeries.priceToCoordinate(data.high);
              const shiftedCoordinate = param.point.x;
              const candleColor = getCandleColor(data);

              const tooltipWidth = 200;
              const containerWidth = chartContainerRef.current.clientWidth;
              let tooltipLeft = shiftedCoordinate;

              if (shiftedCoordinate + tooltipWidth > containerWidth) {
                tooltipLeft = containerWidth - tooltipWidth;
              }

              const tooltipHeight = 60;
              let tooltipTop = coordinate;

              if (
                tooltipTop + tooltipHeight >
                chartContainerRef.current.clientHeight
              ) {
                tooltipTop = coordinate - tooltipHeight;
              }

              const cursorX = param.point.x;
              const cursorY = param.point.y;

              const distanceX = Math.abs(cursorX - tooltipLeft);
              const distanceY = Math.abs(cursorY - tooltipTop);

              if (distanceX < tooltipWidth && distanceY < tooltipHeight) {
                if (cursorX < tooltipLeft + tooltipWidth / 2) {
                  tooltipLeft -= 20;
                } else {
                  tooltipLeft += 20;
                }

                if (cursorY < tooltipTop + tooltipHeight / 2) {
                  tooltipTop -= 20;
                } else {
                  tooltipTop += 20;
                }
              }

              tooltipRef.current.style.display = "block";
              tooltipRef.current.style.left = `${tooltipLeft}px`;
              tooltipRef.current.style.top = `${tooltipTop}px`;
              tooltipRef.current.style.backgroundColor = "#091012";
              tooltipRef.current.style.boxShadow =
                "0 8px 16px rgba(0, 0, 0, 0.6), 0 16px 32px rgba(0, 0, 0, 0.8)";

                tooltipRef.current.innerHTML = `
                <div style="display: flex; align-items: center;  margin-bottom: 10px;">
                  <div style="width: 10px; height: 10px; background-color: ${candleColor}; border-radius: 50%; margin-right: 5px;"></div>
                  <span style="color: #fff; font-size: 12px;">Date: ${getDateLabel(
                    date,
                    selectedQueryType
                  )}</span>
                </div>
                <span style="display: block; color: #fff; font-size: 12px; margin-left: 15px;">Open: ${
                  data.open
                }</span>
                <span style="display: block; color: #fff; font-size: 12px;  margin-left: 15px;">Close: ${
                  data.close
                }</span>
                 <span style="display: block; color: #fff; font-size: 12px;  margin-left: 15px;">High: ${
                   data.high
                 }</span>
                 <span style="display: block; color: #fff; font-size: 12px;  margin-left: 15px;">Low: ${
                   data.low
                 }</span>
              `;
            }
          }
        }
      });

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);

        if (chart) {
          chart.remove();
        }
      };
    }

    return () => {};
  }, [data, selectedQueryType]);

  return (
    <div>
      <div ref={chartContainerRef} style={{ position: "relative" }}>
        <div
          ref={tooltipRef}
          style={{
            position: "absolute",
            width: 200,
            borderRadius: 16,
            color: "#fff",
            zIndex: 10000,
            fontSize: 10,
            display: "none",
            backgroundColor: "#091012",
            padding: "15px 10px",
            pointerEvents: "none",
          }}
        ></div>
      </div>
    </div>
  );
};