import { ApolloError, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useEffect, useMemo } from "react";
import { arrangeTokens } from "./PairMinLimitOHLCs";
import { apolloOHLCsClient } from "./ohlcClient";

export const enum queryTypeAPI {
  HOUR = "1h",
}

export const enum queryType {
  OneMIN = "1Min",
  OneHour = "1H",
  FiveMin = "5Min",
  OneDay = "1D",
  OneMonth = "1M",
  OneYear = "1Y",
}

/* Minute Data Queries */
export const updatedMinDataQuery = gql`
  query pairMinDatas($token0: String, $token1: String, $timestamp: Int) {
    poolMinDatas(
      where: {
        pool_: { token0: $token0, token1: $token1 }
        periodStartUnix_gte: $timestamp
      }
      orderBy: periodStartUnix
      orderDirection: desc
    ) {
      periodStartUnix
      id
      high
      low
      open
      close
      __typename
    }
  }
`;

/* Hour Data Queries   */
export const updatedHourDataQuery = gql`
  query pairHourDatas($token0: String, $token1: String, $timestamp: Int) {
    poolHourDatas(
      where: {
        pool_: { token0: $token0, token1: $token1 }
        periodStartUnix_gte: $timestamp
      }
      orderBy: periodStartUnix
      orderDirection: desc
    ) {
      periodStartUnix
      id
      high
      low
      open
      close
      __typename
    }
  }
`;

/* Day Data Queries */

export const updatedDayDataQuery = gql`
  query pairDayDatas($token0: String, $token1: String, $timestamp: Int) {
    poolDayDatas(
      where: {
        pool_: { token0: $token0, token1: $token1 }
        date_gte: $timestamp
      }
      first: 1000
      orderDirection: desc
    ) {
      date
      id
      high
      low
      open
      close
      __typename
    }
  }
`;

export const monthDataQuery = gql`
  query pairMonthDatas($token0: String, $token1: String, $timestamp: Int) {
    poolMonthDatas(
      where: {
        pool_: { token0: $token0, token1: $token1 }
        date_gte: $timestamp
      }
      first: 1000
      orderDirection: desc
    ) {
      date
      id
      high
      low
      open
      close
      __typename
    }
  }
`;

export default function useChartDataQuery(
  token0: string | undefined,
  token1: string | undefined,
  interval: number,
  kind: queryType, // Ensure 'kind' is of type 'queryType'
  chainId?: any,
  setTokenA?: any,
  setTokenB?: any
): any {
  const { tokenA, tokenB } = arrangeTokens(
    token0,
    token1,
    "0xA4CBbd0f503720a0cA3C950D647A8c23FBE9702B"
  );

  console.log("UUUU", tokenA, tokenB)

  const OneHourAgoTimestamp = () =>
    Math.floor((Date.now() - 1 * 60 * 60 * 1000) / 1000);
  const TwentyFourHoursAgoTimestamp = () =>
    Math.floor((Date.now() - 24 * 60 * 60 * 1000) / 1000);
  const OneMonthAgoTimestamp = () =>
    Math.floor((Date.now() - 30 * 24 * 60 * 60 * 1000) / 1000);
  const OneYearAgoTimestamp = () =>
    Math.floor((Date.now() - 365 * 24 * 60 * 60 * 1000) / 1000);

  const getTimestamp = () => {
    switch (kind) {
      case queryType.OneMIN:
        return OneHourAgoTimestamp(); // Adjust if needed
      case queryType.OneHour:
        return OneHourAgoTimestamp();
      case queryType.FiveMin:
        return OneHourAgoTimestamp(); // Adjust if needed
      case queryType.OneDay:
        return TwentyFourHoursAgoTimestamp();
      case queryType.OneMonth:
        return OneMonthAgoTimestamp();
      case queryType.OneYear:
        return OneYearAgoTimestamp();
      default:
        return OneMonthAgoTimestamp();
    }
  };

  const variablesA = useMemo(
    () => ({
      token0: tokenA?.toLowerCase(),
      token1: tokenB?.toLowerCase(),
      timestamp: getTimestamp(),
    }),
    [tokenA, tokenB, kind]
  );

  const variablesB = useMemo(
    () => ({
      token0: tokenB?.toLowerCase(),
      token1: tokenA?.toLowerCase(),
      timestamp: getTimestamp(),
    }),
    [tokenA, tokenB, kind]
  );

  const queries = {
    [queryType.OneMIN]: updatedMinDataQuery,
    [queryType.OneHour]: updatedMinDataQuery,
    [queryType.FiveMin]: updatedMinDataQuery,
    [queryType.OneDay]: updatedHourDataQuery,
    [queryType.OneMonth]: updatedDayDataQuery,
    [queryType.OneYear]: monthDataQuery,
  };

  const {
    data: dataA,
    loading: isLoadingA,
    error: errorA,
  } = useQuery(queries[kind], {
    variables: variablesA,
    client: apolloOHLCsClient,
    fetchPolicy: "cache-first",
  });

  const {
    data: dataB,
    loading: isLoadingB,
    error: errorB,
  } = useQuery(queries[kind], {
    variables: variablesB,
    client: apolloOHLCsClient,
    fetchPolicy: "cache-first",
  });

  const isLoading = isLoadingA || isLoadingB;
  const error = errorA || errorB;

  const data = useMemo(() => {
    if (!dataA && !dataB) return null;

    const combinedData = aggregateData(dataA, dataB, kind);

    return combinedData;
  }, [dataA, dataB, kind]);

  const dataFieldMap = {
    [queryType.OneMIN]: "poolMinDatas",
    [queryType.OneHour]: "poolMinDatas",
    [queryType.FiveMin]: "poolMinDatas",
    [queryType.OneDay]: "poolHourDatas",
    [queryType.OneMonth]: "poolDayDatas",
    [queryType.OneYear]: "poolMonthDatas",
  };

  useEffect(() => {
    const dataField = dataFieldMap[kind] || "poolDayDatas";

    console.log("WWWWW", dataA,dataB)

    if ((!dataA && !dataB) || (!dataA && dataB && !dataB[dataField]?.length) ||  (!dataB && dataA && !dataA[dataField]?.length)  ) {
      setTokenA(tokenA);
      setTokenB(tokenB);
    } 
    else {
      if (dataA && dataA[dataField]?.length) {
        setTokenA(variablesA?.token0);
        setTokenB(variablesA?.token1);
      } else if (dataB && dataB[dataField]?.length) {
        setTokenA(variablesB?.token0);
        setTokenB(variablesB?.token1);
      } else if (
        !dataA &&
        !dataA[dataField]?.length &&
        !dataB &&
        !dataB[dataField]?.length
      ) {
        setTokenA(tokenA);
        setTokenA(tokenB);
      }
    }
  }, [dataA, dataB, setTokenA, setTokenB, variablesA, variablesB, kind]);

  return useMemo(() => ({ error, isLoading, data }), [data, error, isLoading]);
}

function aggregateData(dataA: any, dataB: any, kind: queryType) {
  const dataFieldMap = {
    [queryType.OneMIN]: "poolMinDatas",
    [queryType.OneHour]: "poolMinDatas",
    [queryType.FiveMin]: "poolMinDatas",
    [queryType.OneDay]: "poolHourDatas",
    [queryType.OneMonth]: "poolDayDatas",
    [queryType.OneYear]: "poolMonthDatas",
  };

  const dataField = dataFieldMap[kind] || "poolDayDatas";

  const poolDayDatasA = dataA?.[dataField] || [];
  const poolDayDatasB = dataB?.[dataField] || [];

  return {
    [dataField]: [...poolDayDatasA, ...poolDayDatasB],
  };
}
