import { useWeb3React } from '@web3-react/core';
import LIGHTLINK_LOGO_URL from 'assets/images/lightlink_logo.png';

export enum SupportedChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GOERLI = 5,
  KOVAN = 42,

  ARBITRUM_ONE = 42161,
  ARBITRUM_RINKEBY = 421611,
  OPTIMISM = 10,
  OPTIMISTIC_KOVAN = 69,

  CELO = 42220,

  LIGHTLINK_PHOENIX = 1890,
  LIGHTLINK_PEGASUS = 1891,
}

export enum SupportedNetwork {
  ETHEREUM,
  ARBITRUM,
  OPTIMISM,
  POLYGON,
  CELO,
  BNB,
  BASE,
  AVALANCHE,
  LIGHTLINK_PEGASUS,
  LIGHTLINK_PHOENIX,
}


export type NetworkInfo = {
  // chainId: ChainId
  chainId: number;
  id: SupportedNetwork;
  route: string;
  name: string;
  imageURL: string;
  bgColor: string;
  primaryColor: string;
  secondaryColor: string;
};

export const LightlinkPegasusNetworkInfo: NetworkInfo = {
  chainId: SupportedChainId.LIGHTLINK_PEGASUS,
  id: SupportedNetwork.LIGHTLINK_PEGASUS,
  route: 'pegasus',
  name: 'Pegasus',
  imageURL: LIGHTLINK_LOGO_URL,
  bgColor: '#0A294B', //change
  primaryColor: '#0490ED',
  secondaryColor: '#96BEDC',
};
export const LightlinkPhoenixNetworkInfo: NetworkInfo = {
  chainId: SupportedChainId.LIGHTLINK_PHOENIX,
  id: SupportedNetwork.LIGHTLINK_PHOENIX,
  route: 'phoenix',
  name: 'Phoenix',
  imageURL: LIGHTLINK_LOGO_URL,
  bgColor: '#0A294B', //change
  primaryColor: '#0490ED',
  secondaryColor: '#96BEDC',
};
export const SUPPORTED_NETWORK_VERSIONS: NetworkInfo[] = [
  // EthereumNetworkInfo,
  // PolygonNetworkInfo,
  // OptimismNetworkInfo,
  // ArbitrumNetworkInfo
  // CeloNetworkInfo,
  // BNBNetworkInfo,
  // BaseNetworkInfo,
  // AvalancheNetworkInfo,
  LightlinkPhoenixNetworkInfo,
  LightlinkPegasusNetworkInfo,
];

export function useActiveNetworkVersion(): [NetworkInfo] {
  const { chainId } = useWeb3React();
  const activeNetwork = SUPPORTED_NETWORK_VERSIONS.filter(
    (network) => network.chainId === chainId
  )[0];
  return [activeNetwork];
}
