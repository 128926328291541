import { ArrowLeft, ChevronsRight, Info, LogOut } from 'react-feather'
import styled, { css } from 'styled-components/macro'
import { ClickableStyle,  ExternalLink, ThemedText } from 'theme'
import { ScrollBarStyles } from 'components/Common'
import { Link } from 'react-router-dom'
import { Z_INDEX } from 'theme/zIndex'
import Column from 'components/Column'
import { LoadingButtonSpinner,  } from 'components/Button'
import { portfolioFadeInAnimation } from './MiniPortfolio/styled'

export const Menu = styled.div`
  width: 100%;
  overflow: auto;
  margin-top: 4px;
  padding: 14px 16px 16px;
  ${ScrollBarStyles}
  ::-webkit-scrollbar-track {
    margin-top: 40px;
  }
`

export const Title = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const StyledArrow = styled(ArrowLeft)`
  ${ClickableStyle}
`

export const Header = styled.div`
  color: ${({ theme }) => theme.textPrimary};

  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`
export const InternalLinkMenuItem = styled(Link)`
  ${ClickableStyle}
  flex: 1;
  color: ${({ theme }) => theme.textTertiary};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 0;
  justify-content: space-between;
  text-decoration: none;
  color: ${({ theme }) => theme.textPrimary};
`
export const SectionTitle = styled(ThemedText.SubHeader)`
  color: ${({ theme }) => theme.textSecondary};
  padding-bottom: 24px;
`

export const ThemeToggleContainer = styled.div`
  margin: 0 0 6px;
`

export const BalanceToggleContainer = styled.div`
  padding: 16px 0;
  margin-bottom: 26px;
`
 
export const ScrimBackground = styled.div<{ open: boolean }>`
  z-index: ${Z_INDEX.modalBackdrop};
  overflow: hidden;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.backgroundScrim};

  opacity: 0;
  pointer-events: none;
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    opacity: ${({ open }) => (open ? 1 : 0)};
    pointer-events: ${({ open }) => (open ? 'auto' : 'none')};
    transition: opacity ${({ theme }) => theme.transition.duration.medium} ease-in-out;
  }
`

const DRAWER_MARGIN = '8px'
const DRAWER_OFFSET = '10px'
const DRAWER_TOP_MARGIN_MOBILE_WEB = '72px'

export const AccountDrawerScrollWrapper = styled.div`
  padding-right:0px;
  &:hover {
    overflow-y: auto;
  }
 

  overscroll-behavior: contain;
  border-radius: 12px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100% - 2 * ${DRAWER_MARGIN});
  overflow: hidden;
  position: fixed;
  right: ${DRAWER_MARGIN};
  top: ${DRAWER_MARGIN};
  z-index: ${Z_INDEX.fixed};

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    overflow: visible;
  }
`

export const AccountDrawerWrapper = styled.div<{ open: boolean }>`
  width: 100%;
  position: relative;
  --border-width: 2px;
  --border-radius: 12px;
  background: linear-gradient(180deg,#92d5e3 0%,#091920 100%);
  border: 0;
  padding: 33px 34px;
  border-radius: var(--border-radius);
  z-index: 1;

  &::before {
    content: "";
    display: block;
    height: calc( 100% - (var(--border-width) * 2) );
    width: calc( 100% - (var(--border-width) * 2) )!important;
    background: #091012;
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: 16px;
    z-index: -1;
    box-shadow: ${({ theme }) => theme.deepShadow};
    transition: margin-right ${({ theme }) => theme.transition.duration.medium};
    background-color: ${({ theme }) => theme.backgroundSurface};
  }
`;

export const DropDownWrapper = styled.div`
  width: 100%;
  position: relative;
  --border-width: 1px;
  --border-radius: 12px;
  background: linear-gradient(180deg,#92d5e3 0%,#091920 100%);
  border-radius: var(--border-radius);
  z-index: 1;
  padding: 14px 26px;

  &::before {
    content: "";
    display: block;
    height: calc( 100% - (var(--border-width) * 2) );
    width: calc( 100% - (var(--border-width) * 2) )!important;
    background: #091012;
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: 12px;
    z-index: -1;
    box-shadow: ${({ theme }) => theme.deepShadow};
    transition: margin-right ${({ theme }) => theme.transition.duration.medium};
    background-color: ${({ theme }) => theme.backgroundSurface};
  }
`;


export const CloseIcon = styled(ChevronsRight).attrs({ size: 9 })`
  stroke: #647383; 
`

export const CloseDrawer = styled.div`
  ${ClickableStyle}
  cursor: pointer;
  height: 100%;
  // When the drawer is not hovered, the icon should be 18px from the edge of the sidebar.
  padding: 24px calc(18px + ${DRAWER_OFFSET}) 24px 14px;
  border-radius: 20px 0 0 20px;
  transition: ${({ theme }) =>
    `${theme.transition.duration.medium} ${theme.transition.timing.ease} background-color, ${theme.transition.duration.medium} ${theme.transition.timing.ease} margin`};
  &:hover {
    z-index: ${Z_INDEX.negative};
    margin: 0 -8px 0 0;
    background-color: ${({ theme }) => theme.stateOverlayHover};
  }
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    display: none;
  }
`

export const IconHoverText = styled.span`
  color: ${({ theme }) => theme.textPrimary};
  position: absolute;
  top: 28px;
  border-radius: 8px;
  transform: translateX(-50%);
  opacity: 0;
  font-size: 12px;
  padding: 5px;
  left: 10px;
`

export const widthTransition = `width ease-in 80ms`

export const IconStyles = css`
  background-color: #121B1E;
  transition: ${widthTransition};
  border-radius: 8px;
  display: flex;
  padding: 0;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  height: 32px;
  width: 32px;
  color: ${({ theme }) => theme.textPrimary};
  :hover {
    background: linear-gradient(91deg, #1A3848 7.28%, #39484B 89.82%);
    color: #53C5F8;
    transition: ${({
      theme: {
        transition: { duration, timing },
      },
    }) => `${duration.fast} background-color ${timing.in}, ${widthTransition}`};

    ${IconHoverText} {
      opacity: 1;
    }
  }
  :active {
    background-color: ${({ theme }) => theme.backgroundSurface};
    transition: background-color 50ms linear, ${widthTransition};
  }
`

export const IconBlockLink = styled.a`
  ${IconStyles};
`

export const IconBlockButton = styled.button`
  ${IconStyles};
  border: none;
  outline: none;
`

export const IconWrapper = styled.span`
  width: 16px;
  height: 16px;
  margin: auto; 
  display: flex;
`

export const TextWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
  min-width: min-content;
`

export const TextHide = styled.div`
  overflow: hidden;
`
export const GitVersionContainer = styled.div`
  width: 100%;
  cursor: pointer;
`
export const DefaultMenuWrap = styled(Column)`
  width: 100%;
  height: 100%;
`

export const AuthenticatedHeaderWrapper = styled.div`
  /* padding: 20px 16px; */
  display: flex;
  flex-direction: column;
  flex: 1;
`



export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  & > a,
  & > button {
    margin-right: 8px;
  }

  & > button:last-child {
    margin-right: 0px;
    ${IconHoverText}:last-child {
      left: 0px;
    }
  }
`
export const FiatOnrampNotAvailableText = styled(ThemedText.Caption)`
  align-items: center;
  color: ${({ theme }) => theme.textSecondary};
  display: flex;
  justify-content: center;
`
export const FiatOnrampAvailabilityExternalLink = styled(ExternalLink)`
  align-items: center;
  display: flex;
  height: 14px;
  justify-content: center;
  margin-left: 6px;
  width: 14px;
`

export const StatusWrapper = styled.div`
  display: inline-block;
  width: 70%;
  max-width: 70%;
  overflow: hidden;
  padding-right: 14px;
  display: inline-flex;
`

export const AccountNamesWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
`

export const StyledInfoIcon = styled(Info)`
  height: 12px;
  width: 12px;
  flex: 1 1 auto;
`
export const StyledLoadingButtonSpinner = styled(LoadingButtonSpinner)`
  fill: ${({ theme }) => theme.accentAction};
`

export const HeaderWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`


export const FadeInColumn = styled(Column)`
  ${portfolioFadeInAnimation}
`

export const PortfolioDrawerContainer = styled(Column)`
  flex: 1;
`

export const LogOutCentered = styled(LogOut)`
  transform: translateX(2px);
`
