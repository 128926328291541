import { area, curveStepAfter, ScaleLinear } from 'd3';
import { useMemo } from 'react';
import { ChartEntry } from './types';
import styled from 'styled-components'

const Path = styled.path<{ fill?: string }>`
  opacity: 0.5;
  stroke: ${({ fill, theme }) => fill ?? theme.accentFailure};
  fill: ${({ fill, theme }) => fill ?? theme.accentFailure};
`

export const Area = ({
  series,
  xScale,
  yScale,
  xValue,
  yValue,
  fill,
}: {
  series: ChartEntry[];
  xScale: ScaleLinear<number, number>;
  yScale: ScaleLinear<number, number>;
  xValue: (d: ChartEntry) => number;
  yValue: (d: ChartEntry) => number;
  fill?: string; // Pass fill prop
}) =>
  useMemo(() => (
    <Path
    fill={fill} // Use fill prop if provided, otherwise default gradient fill
    d={area()
        .curve(curveStepAfter)
        .x((d: unknown) => xScale(xValue(d as ChartEntry)))
        .y1((d: unknown) => yScale(yValue(d as ChartEntry)))
        .y0(yScale(0))(
          series.filter((d) => {
            const value = xScale(xValue(d));
            return value > 0 && value <= window.innerWidth;
          }) as Iterable<[number, number]>
        ) ?? undefined
      }
    />
  ), [fill, series, xScale, xValue, yScale, yValue]);
