import { Fraction, Percent, Token } from '@uniswap/sdk-core'

import BigNumber from 'bignumber.js'
import JSBI from 'jsbi'
import ms from 'ms.macro'

export const TRADE_STRING = 'SwapRouter'

export const ZERO_BIG_NUMBER = new BigNumber(0)
export const ZERO_FRACTION = new Fraction(0)

export const DEFAULT_SLIPPAGE_BPS = 50 // 0.5%
export const MAX_SLIPPAGE_BPS = 5000 // 50%
export const MIN_SLIPPAGE_BPS = 0 // 0%
export const HIGH_SLIPPAGE_BPS = 100 // 1%
export const LOW_SLIPPAGE_BPS = 5 // 0.05%
export const INITIAL_ALLOWED_SLIPPAGE_PERCENT = new Percent(DEFAULT_SLIPPAGE_BPS, 10_000) // 0.5%
export const RADIX_DECIMAL = 10
export const RADIX_HEX = 16

export const DEFAULT_DECIMALS = 18
export const DEFAULT_PRECISION = 6
export const DEFAULT_SMALL_LIMIT = '0.000001'
export const AMOUNT_PRECISION = 4
export const LONG_PRECISION = 10
export const FULL_PRICE_PRECISION = 20
export const FIAT_PRECISION = 2
export const PERCENTAGE_PRECISION = 2

export const SHORT_LOAD_THRESHOLD = 500
export const LONG_LOAD_THRESHOLD = 2000

export const INTL_NUMBER_FORMAT = new Intl.NumberFormat(navigator.language)

export const DEFAULT_DATE_FORMAT: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

export const DEFAULT_ADD_IN_RANGE_SLIPPAGE_TOLERANCE = new Percent(50, 10_000)

export const THREE_DAYS_IN_SECONDS = ms`3 days` / 1000
export const NUMBERS_ONLY = /^[0-9\b]+$/

export const NUMBER_WITH_MAX_TWO_DECIMAL_PLACES = /^(?:\d*\.\d{0,2}|\d+)$/
export const MINIMUM_RECOMMENDED_SLIPPAGE = new Percent(5, 10_000)
export const MAXIMUM_RECOMMENDED_SLIPPAGE = new Percent(1, 100)

export const MAX_POSITIVE_RATE_IMPACT_PERCENT = 999

export const LOW_RATE_THRESHOLD_PERCENT = -5

export const DEFAULT_MS_BEFORE_WARNING = ms`10m`
export const NETWORK_HEALTH_CHECK_MS = ms`10s`

export const MODAL_TRANSITION_DURATION = 200
export const CURRENCY_AMOUNT_MIN = new Fraction(JSBI.BigInt(1), JSBI.BigInt(1000000))


// flips the handles draggers when close to the container edges
export const FLIP_HANDLE_THRESHOLD_PX = 20

// margin to prevent tick snapping from putting the brush off screen
export const BRUSH_EXTENT_MARGIN_PX = 2


export const MAX_STRENGTH = 5
export const BLUR_STEPS = 20
export const BLUR_FADE = '#fff'

export const NAV_HEIGHT = 72




export const LOGO_SIZE = 20

//TWap
export const DEFAULT_ORDER_DEADLINE: any = { label: '1 Hour', value: ms`1 hour` }

export const ORDER_DEADLINES: any[] = [
  DEFAULT_ORDER_DEADLINE,
  { label: '6 Hours', value: ms`6 hour` },
  { label: '12 Hours', value: ms`12 hour` },
  { label: '24 Hours', value: ms`1d` },
]

export const DEFAULT_NUM_OF_PARTS = 2
export const MAX_TWAP_SLIPPAGE = 100 // 100%
export const DEFAULT_TWAP_SLIPPAGE = new Percent(10, 100) // 10%
