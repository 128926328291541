export default function PrivacyPolicyNotice() {
  return (
    <div style={{ color: "#869196", fontSize: "10px" }}>
      <div>
        By connecting a wallet, you agree to Elektrik Terms of Service and
        consent to its Privacy Policy.
      </div>
    </div>
  );
}
