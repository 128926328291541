import { useEffect, useState } from "react";
import { Staking, StakerWallet } from "@eltk/staking-sdk";
import { Signer, providers } from "ethers";
import { useSignerWallet } from "hooks/useSignerWallet";
import { useStaking } from "./useStaking";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { StakingClient, apolloClient, apolloClientMainnet, apolloClientTestnet, voteEscrowClient } from "graphql/thegraph/apollo";

const GET_POOL_ID= gql`
  query MyQuery($id: String!) {
    position(id: $id) {
      pool {
        id
      }
    }
  }
`;


export const useGetPoolAddressFromTOkenId = (id: any, chainId?:any) => {
  const { data } = useQuery(GET_POOL_ID, {
    variables: { id, chainId },
    client: chainId === 1890 ? apolloClientMainnet : apolloClientTestnet,
    fetchPolicy:'network-only'
  });

  return {
    data,
  };
};
