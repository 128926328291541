import { TraceEvent } from "@uniswap/analytics";
import {
  BrowserEvent,
  InterfaceElementName,
  SwapEventName,
} from "@uniswap/analytics-events";
import { useWeb3React } from "@web3-react/core";
import { AutoColumn } from "components/Column";
import { LoadingOpacityContainer } from "components/Loader/styled";
import { isSupportedChain, isSupportedChainLightLink } from "constants/chains";
import { useCallback, useState } from "react";
import { Lock } from "react-feather";
import { useTheme } from "styled-components/macro";
import { formatCurrencyAmount } from "utils/formatCurrencyAmount";

import { useCurrencyBalance } from "../../state/connection/hooks";
import { ThemedText } from "../../theme";
import DoubleCurrencyLogo from "../DoubleLogo";
import CurrencyLogo from "../Logo/CurrencyLogo";
import { RowBetween, RowFixed } from "../Row";
import CurrencySearchModal from "../SearchModal/CurrencySearchModal";
import { FiatValue } from "./FiatValue";
import { GradientTextHover } from "components/swap/SwapInputHeader";
import {
  Aligner,
  Container,
  CurrencySelect,
  CurrencySelectIncrease,
  FiatRow,
  FixedContainer,
  InputPanel,
  InputRow,
  StyledDropDown,
  StyledNumericalInput,
  StyledTokenName,
} from "./styled";
import { CurrencyInputPanelProps } from "types";
import { useLocation } from "react-router-dom";

export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  onCurrencySelect,
  currency,
  otherCurrency,
  id,
  showCommonBases = false,
  showCurrencyAmount,
  disableNonToken,
  renderBalance,
  fiatValue,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  locked = false,
  loading = false,
  ...rest
}: CurrencyInputPanelProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const { account, chainId } = useWeb3React();
  const selectedCurrencyBalance = useCurrencyBalance(
    account ?? undefined,
    currency ?? undefined
  );
  const theme = useTheme();

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const chainAllowed = isSupportedChain(chainId);
  const { pathname } = useLocation();

  return (
    <InputPanel id={id} hideInput={hideInput} {...rest}>
      {locked && (
        <FixedContainer>
          <AutoColumn gap="sm" justify="center">
            <Lock />
            <ThemedText.DeprecatedLabel
              fontSize="12px"
              textAlign="center"
              padding="0 12px"
            >
              <div>
                The market price is outside your specified price range.
                Single-asset deposit only.
              </div>
            </ThemedText.DeprecatedLabel>
          </AutoColumn>
        </FixedContainer>
      )}
      <Container hideInput={hideInput} disabled={!chainAllowed}>
        <InputRow
          style={hideInput ? { padding: "0", borderRadius: "8px" } : {}}
          selected={!onCurrencySelect}
        >
          {!hideInput && (
            <StyledNumericalInput
              className="token-amount-input"
              value={value}
              onUserInput={onUserInput}
              disabled={!chainAllowed}
              $loading={loading}
            />
          )}

          {pathname.includes("/increase") ? (
            <CurrencySelectIncrease
              disabled={!chainAllowed || !isSupportedChainLightLink(chainId)}
              visible={currency !== undefined}
              selected={!!currency}
              hideInput={hideInput}
              className="open-currency-select-button"
              onClick={() => {
                if (onCurrencySelect) {
                  setModalOpen(true);
                }
              }}
            >
              <Aligner
                isAddLiquidity={!pathname.includes("increase") ? true : false}
              > 
                <RowFixed>
                  {pair ? (
                    <span style={{ marginRight: "0.5rem" }}>
                      <DoubleCurrencyLogo
                        currency0={pair.token0}
                        currency1={pair.token1}
                        size={24}
                        margin={true}
                      />
                    </span>
                  ) : currency ? (
                    <CurrencyLogo
                      style={{ marginRight: "0.5rem" }}
                      currency={currency}
                      size="24px"
                    />
                  ) : null}
                  {pair ? (
                    <StyledTokenName className="pair-name-container">
                      {pair?.token0.symbol}:{pair?.token1.symbol}
                    </StyledTokenName>
                  ) : (
                    <StyledTokenName
                      className="token-symbol-container"
                      active={Boolean(currency && currency.symbol)}
                    >
                      {(currency &&
                      currency.symbol &&
                      currency.symbol.length > 20
                        ? currency.symbol.slice(0, 4) +
                          "..." +
                          currency.symbol.slice(
                            currency.symbol.length - 5,
                            currency.symbol.length
                          )
                        : currency?.symbol) || <div>Choose token</div>}
                    </StyledTokenName>
                  )}
                </RowFixed>
                {onCurrencySelect && <StyledDropDown selected={!!currency} />}
              </Aligner>
            </CurrencySelectIncrease>
          ) : (
            <CurrencySelect
              disabled={!chainAllowed || !isSupportedChainLightLink(chainId)}
              visible={currency !== undefined}
              selected={!!currency}
              hideInput={hideInput}
              className="open-currency-select-button"
              onClick={() => {
                if (onCurrencySelect) {
                  setModalOpen(true);
                }
              }}
            >
              <Aligner
                isAddLiquidity={!pathname.includes("increase") ? true : false}
              >
                <RowFixed>
                  {pair ? (
                    <span style={{ marginRight: "0.5rem" }}>
                      <DoubleCurrencyLogo
                        currency0={pair.token0}
                        currency1={pair.token1}
                        size={24}
                        margin={true}
                      />
                    </span>
                  ) : currency ? (
                    <CurrencyLogo
                      style={{ marginRight: "0.5rem" }}
                      currency={currency}
                      size="24px"
                    />
                  ) : null}
                  {pair ? (
                    <StyledTokenName className="pair-name-container">
                      {pair?.token0.symbol}:{pair?.token1.symbol}
                    </StyledTokenName>
                  ) : (
                    <StyledTokenName
                      className="token-symbol-container"
                      active={Boolean(currency && currency.symbol)}
                    >
                      {(currency &&
                      currency.symbol &&
                      currency.symbol.length > 20
                        ? currency.symbol.slice(0, 4) +
                          "..." +
                          currency.symbol.slice(
                            currency.symbol.length - 5,
                            currency.symbol.length
                          )
                        : currency?.symbol) || <div>Choose token</div>}
                    </StyledTokenName>
                  )}
                </RowFixed>
                {onCurrencySelect && <StyledDropDown selected={!!currency} />}
              </Aligner>
            </CurrencySelect>
          )}
        </InputRow>
        {!hideInput && !hideBalance && currency && (
          <FiatRow>
            <RowBetween>
              <LoadingOpacityContainer $loading={loading}>
                {fiatValue && <FiatValue fiatValue={fiatValue} />}
              </LoadingOpacityContainer>
              {account ? (
                <RowFixed style={{ height: "17px" }}>
                  <ThemedText.DeprecatedBody
                    onClick={onMax}
                    color={theme.textTertiary}
                    fontWeight={500}
                    fontSize={12}
                    style={{ display: "inline", cursor: "pointer" }}
                  >
                    {!hideBalance && currency && selectedCurrencyBalance ? (
                      renderBalance ? (
                        renderBalance(selectedCurrencyBalance)
                      ) : (
                        <div>
                          Balance:{" "}
                          {formatCurrencyAmount(selectedCurrencyBalance, 4)}{" "}
                          &nbsp;
                        </div>
                      )
                    ) : null}
                  </ThemedText.DeprecatedBody>
                  {selectedCurrencyBalance ? (
                    <TraceEvent
                      events={[BrowserEvent.onClick]}
                      name={SwapEventName.SWAP_MAX_TOKEN_AMOUNT_SELECTED}
                      element={InterfaceElementName.MAX_TOKEN_AMOUNT_BUTTON}
                    >
                      <GradientTextHover onClick={onMax}>
                        &nbsp; Max
                      </GradientTextHover>
                    </TraceEvent>
                  ) : null}
                </RowFixed>
              ) : (
                <span />
              )}
            </RowBetween>
          </FiatRow>
        )}
      </Container>
      {onCurrencySelect && (
        <CurrencySearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
          showCurrencyAmount={showCurrencyAmount}
          disableNonToken={disableNonToken}
        />
      )}
    </InputPanel>
  );
}
