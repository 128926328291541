import { useEffect } from 'react'
import { useTheme } from 'styled-components/macro'
import { useRemovePopup } from '../../state/application/hooks'
import FailedNetworkSwitchPopup from './FailedNetworkSwitchPopup'
import TransactionPopup, { CancelOrderFailure, CancelOrderSuccess, EditOrderFailure, EditOrderSuccess, EthSignEnabled, LimitOrderFailedTransactionPopUp, LimitOrderSuccessTransactionPopUp, OrderProcessed,OrderAlreadyProcessed, OrderStatusChange, Error,  Sucess,  } from './TransactionPopup'
import { FailedSwitchNetworkPopupContainer, LimitOrderErrorPopUpContainer, LimitOrderSuccessPopUpContainer, StyledClose, TransactionPopupContainer } from './styled'



export default function PopupItem({
  removeAfterMs,
  content,
  popKey,
}: {
  removeAfterMs: number | null
  content: any
  popKey: string
}) {
  const removePopup = useRemovePopup()
  const theme = useTheme()

  useEffect(() => {
    if (removeAfterMs === null) return undefined

    const timeout = setTimeout(() => {
      removePopup(popKey)
    }, removeAfterMs)

    return () => {
      clearTimeout(timeout)
    }
  }, [popKey, removeAfterMs, removePopup])

  if ('txn' in content) {
    return (
      <TransactionPopupContainer show={true}>
        <StyledClose $padding={16} color={theme.textPrimary} onClick={() => removePopup(popKey)} />
        <TransactionPopup hash={content.txn.hash} />
      </TransactionPopupContainer>
    )
  } else if ('failedSwitchNetwork' in content) {
    return (
      <FailedSwitchNetworkPopupContainer show={true}>
        <StyledClose $padding={20} color={theme.textSecondary} onClick={() => removePopup(popKey)} />
        <FailedNetworkSwitchPopup chainId={content.failedSwitchNetwork} />
      </FailedSwitchNetworkPopupContainer>
    )
  } 

  else if ('Enable_Eth_request' in content) {
    return (
      <LimitOrderSuccessPopUpContainer show={true}>
        <StyledClose $padding={20} color={theme.textSecondary} onClick={() => removePopup(popKey)} />
        <EthSignEnabled hash={content} />
      </LimitOrderSuccessPopUpContainer>
    )
  } 

  else if ('statusChange' in content) {
    return (
      <LimitOrderSuccessPopUpContainer show={true}>
        <StyledClose $padding={20} color={theme.textPrimary} onClick={() => removePopup(popKey)} />
        <OrderStatusChange hash={content} />
      </LimitOrderSuccessPopUpContainer>
    )
  } 

  else if ('claimedProcessed' in content) {
    return (
      <LimitOrderSuccessPopUpContainer show={true}>
        <StyledClose $padding={20} color={theme.textSecondary} onClick={() => removePopup(popKey)} />
        <OrderProcessed hash={content} />
      </LimitOrderSuccessPopUpContainer>
    )
  }
  else if ('claimedalreadyProcessed' in content) {
    return (
      <LimitOrderSuccessPopUpContainer show={true}>
        <StyledClose $padding={20} color={theme.textSecondary} onClick={() => removePopup(popKey)} />
        <OrderAlreadyProcessed hash={content} />
      </LimitOrderSuccessPopUpContainer>
    )
  }
  
  else if ('alreadyClaimed' in content) {
    return (
      <LimitOrderSuccessPopUpContainer show={true}>
        <StyledClose $padding={20} color={theme.textSecondary} onClick={() => removePopup(popKey)} />
        <OrderStatusChange hash={content} />
      </LimitOrderSuccessPopUpContainer>
    )
  } 



  else if ('error' in content) {
    return (
      <LimitOrderErrorPopUpContainer show={true}>
        <StyledClose $padding={20} color={theme.textPrimary} onClick={() => removePopup(popKey)} />
        <Error hash={content} />
      </LimitOrderErrorPopUpContainer>
    )
  } 
  else if ('success' in content) {
    return (
      <LimitOrderSuccessPopUpContainer show={true}>
        <StyledClose $padding={20} color={theme.textPrimary} onClick={() => removePopup(popKey)} />
        <Sucess hash={content} />
      </LimitOrderSuccessPopUpContainer>
    )
  } 
  return null
}
