import { gql, useQuery } from "@apollo/client";
import { currentUnixTime } from "@eltk/staking-sdk";
import { apolloClient, apolloClientMainnet, apolloClientTestnet, apolloOHLCsClient,  } from "graphql/thegraph/apollo"; // Adjust import as per your file structure

const GET_TOKEN_PRICE = gql`
  query MyQuery($tokenId: String!) {
  tokenDayDatas(
      orderDirection: desc
      first: 1
      where: {token: $tokenId}
      skip: 10
    ) {
      priceUSD
      id
      date
    }
  }

`;



export const useGetTokenPrice = (tokenId1: any, tokenId2: any, chainId:any) => {

  const {
    loading: loading1,
    error: error1,
    data: data1,
  } = useQuery(GET_TOKEN_PRICE, {
    client: chainId === 1890 ? apolloClientMainnet :  apolloClientTestnet,
    variables: {
      tokenId: tokenId1 && tokenId1.toLowerCase(),
    },
  });

  const {
    loading: loading2,
    error: error2,
    data: data2,
  } = useQuery(GET_TOKEN_PRICE, {
    client: chainId === 1890 ? apolloClientMainnet :  apolloClientTestnet,
    variables: {
      tokenId: tokenId2 && tokenId2.toLowerCase(),
    },
  });

  const loading = loading1 || loading2;
  const error = error1 || error2;
  // Combine data if both queries have returned data
  const combinedData = {
    data1: data1?.tokenDayDatas[0]?.priceUSD || null,
    data2: data2?.tokenDayDatas[0]?.priceUSD || null,
  };


  return {
    loading,
    error,
    data: combinedData,
  };
};
