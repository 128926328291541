import { AutoColumn } from "components/Column";
import styled from "styled-components/macro";
import { Z_INDEX } from "theme/zIndex";

export const Menu = styled.div`
  position: relative;
`;

export const MenuFlyout = styled(AutoColumn)`
  /* Start from the middle of the parent/button */
  z-index: ${Z_INDEX.century};
  border: 0;
  border-radius: var(--border-radius);
  color: ${({ theme }) => theme.textPrimary};
  padding: 15px 20px;

  position: relative;
  --border-width: 2px;
  --border-radius: 18px;

  background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
  border: 0;
  padding: 34px 36px 36px 36px;
  position: relative;
  border-radius: var(--border-radius);
  z-index: ${Z_INDEX.default};
  &::before {
    content: "";
    display: block;
    height: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    width: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    background: ${({ theme }) => theme.backgroundSurface};
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: calc(var(--border-radius) - var(--border-width));
    z-index: ${Z_INDEX.negative};
  }

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    min-width: 14px;
  `};
`;
