import { Box } from "components/Box";
import { BLUR_FADE, BLUR_STEPS, LOGO_SIZE, MAX_STRENGTH, NAV_HEIGHT } from "constants/common";
import styled from "styled-components";
import { BREAKPOINTS } from "theme";
import { Z_INDEX } from "theme/zIndex";

export const Nav = styled.nav<{ isMobile: boolean }>`
  position: fixed;
  padding: ${({ theme }) => `${theme.navVerticalPad}px 12px`};
  width: 100%;
  height: ${({ theme }) => theme.navHeight}px;
  z-index: ${Z_INDEX.hover};
  padding: 0px 65px;
  @media screen and (max-width: 1000px) {
    padding-left: 30px;
  padding-right: 30px;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    padding: 0px 20px; 
  }
  @media screen and (max-width: 400px) {
    padding: 0px 5px;
  }
`;


export const NavDropdownContainer = styled.div`
  position: relative; 
  padding: 1px;  // This padding is the effective border width
  background: linear-gradient(180deg, #029AF0 10%, #A1EEFF 100%);

  & > div {
    background: #091012;
    border-radius: 12px;  // Adjust radius to match the outer container minus padding
    position: relative;
    z-index: 999;
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;  // Ensure this matches your design requirements
    height: 100%;  // Ensure this matches your design requirements
  }
`;


export const LabelText = styled.nav`
  font-size: 12px;
`;


export const BurgerContainer = styled.div`
display: none; // Default to not displayed
margin-left: 20px;

@media (max-width: 768px) {
  display: block; // Show on screens smaller than 768px
}
`;



export const BlurGroup = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(${BLUR_FADE}, rgba(${BLUR_FADE}, 0));
`

export const BlurLayer = styled.div<{ index: number }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${({ index }) => (NAV_HEIGHT / BLUR_STEPS) * index}px;
  backdrop-filter: blur(${({ index }) => (MAX_STRENGTH / BLUR_STEPS) * (BLUR_STEPS - index)}px);
`

export const Container = styled.button<{ disabled: boolean }>`
  align-items: center;
  background: none;
  border: #091012;
  border-radius: 12px;
  color: ${({ theme }) => theme.textPrimary};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  justify-content: space-between;
  line-height: 24px;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  padding: 10px 8px;
  text-align: left;
  transition: ${({ theme }) => theme.transition.duration.medium} ${({ theme }) => theme.transition.timing.ease}
    background-color;
  width: 240px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    width: 100%;
  }

  &:hover {
    background-color: ${({ disabled, theme }) => (disabled ? 'none' : theme.backgroundOutline)};
  }
`

export const Label = styled.div`
  grid-column: 2;
  grid-row: 1;
  font-size: 16px;
`

export const Status = styled.div`
  grid-column: 3;
  grid-row: 1;
  display: flex;
  align-items: center;
  width: ${LOGO_SIZE}px;
`

export const CaptionText = styled.div`
  color: ${({ theme }) => theme.textSecondary};
  font-size: 12px;
  grid-column: 2;
  grid-row: 2;
`

export const Logo = styled.img`
  height: ${LOGO_SIZE}px;
  width: ${LOGO_SIZE}px;
  margin-right: 12px;
`
export const StyledBox = styled(Box)`
  align-items: center;
  display: flex;
  justify-content: center;
`