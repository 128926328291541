import { Percent } from "@uniswap/sdk-core";
import { useWeb3React } from "@web3-react/core";
import { ReactNode } from "react";
import {
  Link as HistoryLink,
  useLocation,
} from "react-router-dom";
import { Box } from "rebass";
import { useAppDispatch } from "state/hooks";
import { useTheme } from "styled-components/macro";
import { ThemedText } from "theme";
import { resetMintState } from "state/mint/actions";
import { resetMintState as resetMintV3State } from "state/mint/v3/actions";

import Row, { RowBetween } from "../Row";
import SettingsTab from "../Settings";
import {
  ActiveText,
  StyledArrowLeft,
  Tabs,
  StyledChevronArrowLeft,
} from "./styled";

export function FindPoolTabs({ origin }: { origin: string }) {
  return (
    <Tabs>
      <RowBetween style={{ padding: "1rem 1rem 0 1rem", position: "relative" }}>
        <HistoryLink to={origin}>
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <div>Import V2 Pool</div>
        </ActiveText>
      </RowBetween>
    </Tabs>
  );
}

export function AddRemoveTabs({
  adding,
  creating,
  autoSlippage,
  positionID,
  children,
  isBack,
}: {
  adding: boolean;
  creating: boolean;
  autoSlippage: Percent;
  positionID?: string;
  showBackLink?: boolean;
  children?: ReactNode;
  isBack?: boolean;
}) {
  const { chainId } = useWeb3React();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const poolLink = positionID ? "/positions/" + positionID : "/pools";

  const navigateToPosition = () => {
    window.history.back();
  };

  

  return (
    <Tabs>
      <RowBetween style={{ padding: "0px 0px 15px 0px" }}>
        <div>
          {isBack && (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (adding) {
                  dispatch(resetMintState());
                  dispatch(resetMintV3State());
                }
                navigateToPosition();
              }}
            >
              <StyledChevronArrowLeft stroke={theme.textPrimary} />
            </div>
          )}
        </div>
        <ThemedText.DeprecatedMediumHeader
          fontWeight={600}
          fontSize={20}
          style={{
            flex: "1",
            margin: "auto",
            textAlign: children ? "center" : "center",
          }}
        >
          {creating ? (
            <div>Create a pair</div>
          ) : adding && location.pathname == "/add" ? (
            <div>Add Liquidity</div>
          ) : adding ? (
            <div>Add Liquidity</div>
          ) : (
            <div>Remove Liquidity</div>
          )}
        </ThemedText.DeprecatedMediumHeader>
        <Box style={{ marginRight: ".5rem" }}>{children}</Box>
        <SettingsTab
          autoSlippage={autoSlippage}
          chainId={chainId}
          isButton={false}
        />
      </RowBetween>
    </Tabs>
  );
}

export function CreateProposalTabs() {
  return (
    <Tabs>
      <Row style={{ padding: "1rem 1rem 0 1rem" }}>
        <HistoryLink to="/vote">
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText style={{ marginLeft: "auto", marginRight: "auto" }}>
          Create Proposal
        </ActiveText>
      </Row>
    </Tabs>
  );
}
