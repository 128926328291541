import { ThemedText } from "theme";
import { Outlet, useParams } from "react-router-dom";
import { Trace } from "@uniswap/analytics";
import { InterfacePageName } from "@uniswap/analytics-events";
import { useNavigate } from "react-router-dom";
import { AutoColumn } from "components/Column";
import {
  HeaderWrapperSinglePool,
  HeaderContentWrapper,
  LeftSection,
  RightSection,
  TopSectionWrapper,
  PairDetails,
  PairIconsWithName,
  StackedIcons,
  BottomSectionWrapper,
  PoolStatsWrapper,
  PoolStatsItem,
  PoolStatsItemNoBorder,
  LeftContentWrapper,
  PoolPageContentWrapperDetails,
  WalletLink,
  TokenData,
  TokenDelta,
  RangeText,
  PoolInfoContainer,
  TokenHeader,
  PoolNumberText,
  TabsSection,
  WrappingDiv,
  CenterDiv,
  PoolLink,
  CopiedSpan,
  HandleCopySpan,
  FlexDiv,
  WrappingSpan,
  FlexSpan,
  WhiteListedTokenDiv,
  WhiteListedTokenDivInner,
  FeeTierSpan,
  WrappingDivPool,
} from "./poolStyle";
import { ClickableText2 } from "./styleds";
import { useCallback, useEffect, useState } from "react";
import {
  useSinglePoolData,
  useSinglePoolDataUpdated,
} from "graphql/thegraph/PoolQuery";
import { calculatePoolShareInPercentage } from "./PoolDetail";
import { useWeb3React } from "@web3-react/core";
import { feeTierPercent, shortenAddress } from "utils";
import { ReactComponent as LetsIcon } from "assets/svg/lets-icons_out.svg";
import { ReactComponent as CopyIcon } from "assets/svg/copy.svg";
import {
  StyledChevronArrowLeft,
  StyledHistoryLink,
} from "components/NavigationTabs/styled";
import { useTheme } from "styled-components/macro";
import { usegetEltkAmountInEpoch } from "hooks/staking/useCheckHarvestTimeDuration";
import { usePoolId } from "hooks/pool/usePoolId";
import { useGetPoolRewardApr } from "hooks/staking/useGetPoolRewardApr";
import { useGetPoolAllocPoints } from "hooks/staking/useGetPoolAllocPoints";
import { useAllPoolDetails } from "hooks/pool/useAllPoolDetails";
import { useGetPoolDetails } from "hooks/pool/useGetPoolDetails";
import { useToken } from "hooks/Tokens";
import { unwrappedToken } from "utils/unwrappedToken";
import DoubleCurrencyLogo from "components/DoubleLogo";
import { useIsPoolWhitelisted } from "hooks/staking/useIsPoolWhitelisted";
import { useOracle } from "hooks/oracle/useOracle";
import { ethers } from "ethers";

import { formatDollarAmountSuffix, formatToSignificant } from "utils/numbers";
import { useGetTotalVolumesOfTokenPool } from "hooks/pool/useGetTotalVolumesOfTokenPool";
import { formatter, formatterWithoutDollar } from "utils/locking";
import { LoadingBubble } from "components/Tokens/loading";
import { SupportedChainId } from "constants/chains";
import { getExplorerPrefix } from "constants/utilsConstants";

const PoolPage = () => {
  const [tvlUsd, setTvlUsd] = useState(0);
  const [volumeUsd, setVolumeUsd] = useState(0);
  const [tvlToken0, setTvlToken0] = useState(0);
  const [tvlToken1, setTvlToken1] = useState(0);
  const [token0Share, setToken0Share] = useState(0);
  const [token1Share, setToken1Share] = useState(0);
  const [token0Symbol, setToken0Symbol] = useState("");
  const [token1Symbol, setToken1Symbol] = useState("");
  const [aprRewards, setAPrRewards] = useState<any>(null);
  const [whitelistedPools, setWhitelisted] = useState<any>(false);
  const [isCopied, setCopied] = useState<any>(false);
  const [token0Percentage, setToken0Percentage] = useState<any>(null);
  const [token1Percentage, setToken1Percentage] = useState<any>(null);

  const { poolId } = useParams<{ poolId?: string }>();
  const { chainId } = useWeb3React();
  const navigate = useNavigate();
  const theme = useTheme();

  const { oracle } = useOracle();

  const { data: poolData, loading: poolAddressLoading } =
    useSinglePoolDataUpdated(poolId, chainId);

  const { poolId: poolIdPool, loading: poolIdLoading } = usePoolId(poolId);

  const { poolDetails, loading: poolDetailsLoading } =
    useGetPoolDetails(poolIdPool);

  const { poolRewards: totalPoolRewardsAllocPoints } = useGetPoolRewardApr();

  const { poolRewards: poolAllocPoints } = useGetPoolAllocPoints(poolIdPool);

  const { data: totalVolumesOfToken, loading: totalVolumeLoading } =
    useGetTotalVolumesOfTokenPool(poolId, chainId);
  const { data } = usegetEltkAmountInEpoch();
  const { data: poolsArray } = useIsPoolWhitelisted(
    poolData && poolData?.pool?.id
  );
  useEffect(() => {
    (function() {
      if (
        chainId !== SupportedChainId.LIGHTLINK_PEGASUS_TESTNET &&
        chainId !== SupportedChainId.LIGHTLINK_PHOENIX_MAINNET
      ) {
        navigate("/pools");
      }
    })();
  }, [chainId]);
  

  const token0 = useToken(poolData && poolData?.pool?.token0?.id);
  const token1 = useToken(poolData && poolData?.pool?.token1?.id);

  const currency0 = token0 ? unwrappedToken(token0) : undefined;
  const currency1 = token1 ? unwrappedToken(token1) : undefined;





  const calculatePercentages = async ()=>{
    console.log("yahatakaya")
    if (totalVolumesOfToken && token0 && token1 && oracle) {
      try{
      let tokenAddress0 = token0?.wrapped?.address;
      let tokenAddress1 = token1?.wrapped?.address;
      
      let decimal0 = token0?.wrapped?.decimals;
      let decimal1 = token1?.wrapped?.decimals;

      let token0Price = totalVolumesOfToken?.pools[0]?.totalValueLockedToken0
      let token1Price = totalVolumesOfToken?.pools[0]?.totalValueLockedToken1
      console.log(tokenAddress0 ,tokenAddress1 ,  decimal0, decimal1 ,token0Price , token1Price ,  "USDK")
      console.log(ethers.utils.parseUnits(token1Price.toString(), decimal1).toString() , "CHECKGHEGEG")
      const usdPrice0 = await oracle.getUsdPrice(ethers.utils.parseUnits(token0Price.toString(), decimal0).toString(), tokenAddress0, 1);
      const usdPrice1 = await oracle.getUsdPrice(ethers.utils.parseUnits(token1Price.toString(), decimal1).toString(), tokenAddress1, 1);

      console.log(usdPrice0,  usdPrice1 , "USDPRICE")

      // const totalVolume =
      //   Number(totalVolumesOfToken?.pools[0]?.totalValueLockedToken0) +
      //   Number(totalVolumesOfToken?.pools[0]?.totalValueLockedToken1);
      const totalVolume =
        parseFloat(usdPrice0) +
        parseFloat(usdPrice1);

      // const percentageToken0 =
      //   (Number(totalVolumesOfToken?.pools[0]?.totalValueLockedToken0) /
      //     totalVolume) *
      //   100;
      
      const percentageToken0 =
      (parseFloat(usdPrice0)/
        totalVolume) *
      100;

      // const percentageToken1 =
      //   (Number(totalVolumesOfToken?.pools[0]?.totalValueLockedToken1) /
      //     totalVolume) *
      //   100;

      const percentageToken1 = (parseFloat(usdPrice1)/
      totalVolume) *
    100;

      setToken0Percentage(percentageToken0);
      setToken1Percentage(percentageToken1);
    }catch(error){
      console.log(error?.message , "PRICEERROR")
    }
    }
  }
  useEffect(() => {
    calculatePercentages()
  }, [totalVolumesOfToken, chainId, token0 , token1, oracle]);

  useEffect(() => {
    if (poolDetails?.v3Pool === poolId) {
      setWhitelisted(true);
    } else {
      setWhitelisted(false);
    }
  }, [poolDetails]);

  useEffect(() => {
    if (poolAllocPoints && totalPoolRewardsAllocPoints) {
      const rewards = (poolAllocPoints / totalPoolRewardsAllocPoints) * 100;
      setAPrRewards(rewards);
    }
  }, [totalPoolRewardsAllocPoints, poolAllocPoints]);

  useEffect(() => {
    if (poolData?.pool) {
      const { token0Share: t0s, token1Share: t1s } =
        calculatePoolShareInPercentage(
          poolData?.pool?.tvlToken0,
          poolData[0]?.pool?.tvlToken1
        );

      setToken0Share(t0s);
      setToken1Share(t1s);
      setTvlUsd(poolData?.pool?.totalValueLockedUSD);
      setVolumeUsd(poolData?.pool?.volumeUSD);
      setTvlToken0(poolData?.pool?.tvlToken0);
      setTvlToken1(poolData?.pool?.tvlToken1);
      setToken0Symbol(poolData?.pool?.token0.symbol);
      setToken1Symbol(poolData?.pool?.token1.symbol);
    }
  }, [poolData]);

  const handleCopy = async (poolData:any) => {
    if (poolData) {

      await navigator.clipboard.writeText(poolData?.pool?.id);
    }
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  const navigateToPools = () => {
    navigate("/pools");
  };
  const poolLink = "/pools";

  console.log(data?.newUpkeepPeriods , "data?.newUpkeepPeriods")

  return (
    <Trace page={InterfacePageName.POOL_PAGE} shouldLogImpression>
      <AutoColumn gap="30px" style={{ width: "100%" }}>
        <HeaderWrapperSinglePool>
          <WrappingDiv>
            <StyledHistoryLink
              to={poolLink}
              style={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => navigateToPools()}
            >
              <StyledChevronArrowLeft stroke={theme.textPrimary} />
              <ThemedText.DeprecatedWhite>Back</ThemedText.DeprecatedWhite>
            </StyledHistoryLink>
          </WrappingDiv>
          <HeaderContentWrapper>
            <LeftSection>
              <WrappingDiv>
                <TopSectionWrapper>
                  <PairDetails>
                    <PairIconsWithName>
                      <TokenHeader>
                        <StackedIcons>
                          <DoubleCurrencyLogo
                            currency0={currency0}
                            currency1={currency1}
                            size={40}
                            margin
                          />
                        </StackedIcons>
                        {!token0Symbol && !token1Symbol && (
                          <LoadingBubble
                            height="25px"
                            width="80px"
                            delay="300ms"
                          />
                        )}
                        <ThemedText.HeadlineMedium
                          fontWeight={600}
                          fontSize={24}
                          marginRight={18}
                          minWidth={150}
                        >
                          <div>
                            {token0Symbol}/{token1Symbol}
                          </div>
                        </ThemedText.HeadlineMedium>
                      </TokenHeader>
                      <CenterDiv>
                        &nbsp;
                        {!poolData && (
                          <LoadingBubble
                            height="25px"
                            width="80px"
                            delay="300ms"
                          />
                        )}
                        <WalletLink style={{ fontSize: 10 }}>
                          {poolData && poolData?.pool?.id}
                        </WalletLink>
                        <PoolLink
                          style={{ marginLeft: "10px" }}
                          href={`https://${getExplorerPrefix(chainId!)}.io/address/${
                            poolData && poolData?.pool?.id
                          }`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <LetsIcon />
                        </PoolLink>
                        {isCopied ? (
                          <CopiedSpan>Copied</CopiedSpan>
                        ) : (
                          <HandleCopySpan
                            onClick={()=>handleCopy(poolData)}
                            style={{ marginRight: "10px" }}
                          >
                            <CopyIcon />
                          </HandleCopySpan>
                        )}
                      </CenterDiv>
                    </PairIconsWithName>
                  </PairDetails>
                </TopSectionWrapper>
                <BottomSectionWrapper></BottomSectionWrapper>
              </WrappingDiv>
            </LeftSection>

            <RightSection style={{ display: "grid" }}>
              <TopSectionWrapper>
                <PoolInfoContainer>
                  <WrappingDivPool>
                    <FlexDiv>
                      {!poolData && (
                        <LoadingBubble
                          height="25px"
                          width="80px"
                          delay="300ms"
                        />
                      )}
                      <TokenData>
                        {poolData &&
                          formatterWithoutDollar.format(
                            Number(poolData?.pool?.totalValueLockedToken0)
                          )}{" "}
                        {token0Symbol}
                      </TokenData>
                      {!poolData && (
                        <LoadingBubble
                          height="25px"
                          width="80px"
                          delay="300ms"
                        />
                      )}
                      <WrappingSpan>
                        <FlexSpan>
                          <WalletLink>
                            {poolData &&
                              poolData?.pool?.token0?.id &&
                              shortenAddress(poolData?.pool?.token0?.id, 10)}
                          </WalletLink>
                          <PoolLink
                            href={`https://${getExplorerPrefix(chainId!)}.io/token/${
                              poolData && poolData?.pool?.token0?.id
                            } `}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <LetsIcon />
                          </PoolLink>
                        </FlexSpan>{" "}
                        &nbsp;
                      </WrappingSpan>
                      {!poolData && (
                        <LoadingBubble
                          height="25px"
                          width="80px"
                          delay="300ms"
                        />
                      )}
                      <TokenDelta style={{ color: "#868E9B", fontSize: 14 }}>
                      ({token0Percentage ?  formatToSignificant(token0Percentage):""}%)
                      </TokenDelta>
                    </FlexDiv>
                    <div
                      style={{
                        display: "flex",
                        fontSize: 14,
                        marginTop: "-10px",
                      }}
                    >
                      {!poolData && (
                        <LoadingBubble
                          height="25px"
                          width="80px"
                          delay="300ms"
                        />
                      )}
                      <TokenData>
                        {poolData &&
                          formatterWithoutDollar.format(
                            Number(poolData?.pool?.totalValueLockedToken1)
                          )}{" "}
                        {token1Symbol}
                      </TokenData>
                      <span>
                        <span style={{ fontSize: 10, display: "flex" }}>
                          {!poolData && (
                            <LoadingBubble
                              height="25px"
                              width="80px"
                              delay="300ms"
                            />
                          )}
                          <WalletLink>
                            {poolData &&
                              poolData?.pool?.token1?.id &&
                              shortenAddress(poolData?.pool?.token1?.id, 10)}
                          </WalletLink>
                          <a
                            style={{ marginLeft: "10px" }}
                            href={`https://${getExplorerPrefix(chainId!)}.io/token/${
                              poolData && poolData?.pool?.token1?.id
                            }`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <LetsIcon />
                          </a>
                        </span>{" "}
                        &nbsp;
                        <span style={{ color: "#868E9B", fontSize: 10 }}></span>
                      </span>
                      {!poolData && (
                        <LoadingBubble
                          height="25px"
                          width="80px"
                          delay="300ms"
                        />
                      )}
                      <TokenDelta style={{ color: "#868E9B", fontSize: 14 }}>
                        ({token1Percentage ? formatToSignificant(token1Percentage) : ""}%)
                      </TokenDelta>
                    </div>
                  </WrappingDivPool>
                  <WhiteListedTokenDiv>
                    <WhiteListedTokenDivInner>
                      {!poolsArray && (
                        <LoadingBubble
                          height="25px"
                          width="80px"
                          delay="300ms"
                        />
                      )}
                      {poolsArray?.pools?.length ? (
                        <PoolNumberText>
                          Pool {poolsArray?.pools[0]?.id}{" "}
                        </PoolNumberText>
                      ) : (
                        ""
                      )}
                      <RangeText>
                        {!poolsArray?.pools?.length
                          ? "Not Whitelisted"
                          : "Whitelisted"}
                      </RangeText>
                    </WhiteListedTokenDivInner>
                    {!poolsArray ? (
                      <LoadingBubble height="25px" width="80px" delay="300ms" />
                    ) : (
                      <FeeTierSpan>
                        {poolData && poolData?.pool && feeTierPercent(poolData?.pool?.feeTier)}{" "}
                        Fee tier
                      </FeeTierSpan>
                    )}
                  </WhiteListedTokenDiv>
                </PoolInfoContainer>
              </TopSectionWrapper>
              <BottomSectionWrapper>
                <PoolStatsWrapper>
                  <PoolStatsItem>
                    <ClickableText2 onClick={() => null}>
                      Pool Liquidity (TVL)
                    </ClickableText2>
                    <ThemedText.DeprecatedWhite
                      fontSize="15px"
                      fontWeight={400}
                      style={{
                        maxWidth: "95px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {!tvlUsd ? (
                        <LoadingBubble
                          height="25px"
                          width="80px"
                          delay="300ms"
                        />
                      ) : (
                        formatDollarAmountSuffix(tvlUsd) || "$0"
                      )}
                    </ThemedText.DeprecatedWhite>
                  </PoolStatsItem>
                  <PoolStatsItem>
                    <ClickableText2 onClick={() => null}>
                      Trading volume (24h)
                    </ClickableText2>
                    <ThemedText.DeprecatedWhite
                      fontSize="15px"
                      fontWeight={400}
                      style={{
                        maxWidth: "95px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {!volumeUsd ? (
                        <LoadingBubble
                          height="25px"
                          width="80px"
                          delay="300ms"
                        />
                      ) : (
                        formatDollarAmountSuffix(volumeUsd) || "$0"
                      )}
                    </ThemedText.DeprecatedWhite>
                  </PoolStatsItem>
                  <PoolStatsItem>
                    <ClickableText2 onClick={() => null}>
                      Fees Generated
                    </ClickableText2>
                    <ThemedText.DeprecatedWhite
                      fontSize="15px"
                      fontWeight={400}
                      style={{
                        maxWidth: "95px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {!poolData ? (
                        <LoadingBubble
                          height="25px"
                          width="80px"
                          delay="300ms"
                        />
                      ) : (
                        formatter.format(poolData?.pool?.feesUSD) || "$0"
                      )}
                    </ThemedText.DeprecatedWhite>
                  </PoolStatsItem>
                  <PoolStatsItemNoBorder>
                    <ClickableText2 onClick={() => null}>APR</ClickableText2>
                    <ThemedText.DeprecatedWhite
                      fontSize="15px"
                      fontWeight={400}
                      style={{
                        maxWidth: "95px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {/* {!aprRewards ? (
                        <LoadingBubble
                          height="25px"
                          width="80px"
                          delay="300ms"
                        />
                      ) : data?.newUpkeepPeriods.length === 0 ? (
                        "0%"
                      ) : (
                        `${aprRewards?.toFixed(2)}%`
                      )}
                       */}
                                             {!aprRewards ? (
                        <LoadingBubble
                          height="25px"
                          width="80px"
                          delay="300ms"
                        />
                      )  : (
                        `${aprRewards?.toFixed(2)}%`
                      )}

                    </ThemedText.DeprecatedWhite>
                  </PoolStatsItemNoBorder>
                </PoolStatsWrapper>
              </BottomSectionWrapper>
            </RightSection>
          </HeaderContentWrapper>
          <WrappingDiv style={{height:20}}>
           
          </WrappingDiv>
        </HeaderWrapperSinglePool>
        <PoolPageContentWrapperDetails>
          <LeftContentWrapper>
            <Outlet />
          </LeftContentWrapper>
        </PoolPageContentWrapperDetails>
      </AutoColumn>
    </Trace>
  );
};

export default PoolPage;
