import { useEffect, useState } from "react";
import { useOracle } from "./useOracle";
import { ethers } from "ethers";

export const useGetUsdOraclePrice = (baseAmount: any, baseTokenAddress: any, period: any) => {
  const [oracleUsdPrice, setOracleUsdPrice] = useState<any>(0);
  const [loading, setLoading] = useState<boolean>(true);
 
  const { oracle } = useOracle();

  useEffect(() => {
    const getOraclePrice = async () => {
      try {
        if (oracle && baseAmount && baseTokenAddress && baseAmount > 0) {
          setLoading(true);
          const value = ethers.utils.parseEther(parseFloat(baseAmount).toFixed(18).toString());
          const price = await oracle.getUsdPrice(value, baseTokenAddress, period);
          setOracleUsdPrice(Number(price.toString()) / 10 ** 6);
        }
      } catch (error) {
        console.log(`Error fetching Oracle price:`, error);
      } finally {
        setLoading(false);
      }
    };
    if(baseAmount){
      getOraclePrice();
    }
  }, [oracle, baseAmount, baseTokenAddress, period]);

  return {
    oracleUsdPrice,
    loading
  };
};




export const useGetUsdOraclePriceDynamicDecimals = (baseAmount: any, baseTokenAddress: any, period: any, currencyDetails:any) => {
  const [oracleUsdPrice, setOracleUsdPrice] = useState<any>(0);
  const [loading, setLoading] = useState<boolean>(true);

  const { oracle } = useOracle();

  useEffect(() => {
    const getOraclePrice = async () => {
      try {
        if (oracle && baseAmount && baseTokenAddress && baseAmount > 0) {
          setLoading(true);
          const price = await oracle.getUsdPrice(baseAmount, baseTokenAddress, period);
          setOracleUsdPrice(Number(price.toString()) / 10 ** 6);
        }
      } catch (error) {
        console.log(`Error fetching Oracle price:`, error);
      } finally {
        setLoading(false);
      }
    };
    if(baseAmount){
      getOraclePrice();
    }
  }, [oracle, baseAmount, baseTokenAddress, period]);

  return {
    oracleUsdPrice,
    loading
  };
};
