import { useEffect, useState } from "react";
import { Staking, StakerWallet, Locking,ElektrikToken } from "@eltk/staking-sdk";
import { Signer, providers } from "ethers";
import { useSignerWallet } from "hooks/useSignerWallet";

export const useElektrikToken = () => {
  const [elektrikToken, setElektrikToken] = useState<any>();
  const { wallet: signerWalletInstance } = useSignerWallet();

  useEffect(() => {
    const initializeELektrikToken = async () => {
      try {
        if (signerWalletInstance) {
          const elektrikTokenInstance = await ElektrikToken.getInstance(signerWalletInstance);
          setElektrikToken(elektrikTokenInstance);
        }
      } catch (error) {
        console.error("Error initializeELektrikToken", error);
      }
    };

    initializeELektrikToken();
  }, [signerWalletInstance]);

  return {
    elektrikToken,
  };
};