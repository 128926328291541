import { Percent } from "@uniswap/sdk-core";
import { BigNumber } from "ethers";
import JSBI from "jsbi";
import ms from "ms.macro";
import { SupportedChainId } from "./chains";
import {
  fourYearInUnix,
  oneHourInUnix,
  oneMonthInUnix,
  oneWeekInUnix,
  oneYearInUnix,
  sixMonthInUnix,
} from "@eltk/staking-sdk";

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

// TODO(WEB-3290): Convert the deadline to minutes and remove unecessary conversions from
// seconds to minutes in the codebase.
// 30 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 30;
export const L2_DEADLINE_FROM_NOW = 60 * 5;

// transaction popup dismisal amounts
export const DEFAULT_TXN_DISMISS_MS = 10000;
export const L2_TXN_DISMISS_MS = 5000;

export const BIG_INT_ZERO = JSBI.BigInt(0);

// one basis JSBI.BigInt
const BIPS_BASE = JSBI.BigInt(10000);
export const ONE_BIPS = new Percent(JSBI.BigInt(1), BIPS_BASE);

// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(
  JSBI.BigInt(100),
  BIPS_BASE
); // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(
  JSBI.BigInt(300),
  BIPS_BASE
); // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(
  JSBI.BigInt(500),
  BIPS_BASE
); // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(
  JSBI.BigInt(1000),
  BIPS_BASE
); // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(
  JSBI.BigInt(1500),
  BIPS_BASE
); // 15%

export const ZERO_PERCENT = new Percent("0");
export const ONE_HUNDRED_PERCENT = new Percent("1");

export function percentToBips(percent: Percent): string {
  return percent.multiply("100").toSignificant();
}

export const TRUE = true;
export const FALSE = false;

const THIRTY_BIPS_FEE = new Percent(JSBI.BigInt(30), JSBI.BigInt(10000));
export const INPUT_FRACTION_AFTER_FEE =
  ONE_HUNDRED_PERCENT.subtract(THIRTY_BIPS_FEE);

export const MIN_NATIVE_CURRENCY_FOR_GAS: JSBI = JSBI.exponentiate(
  JSBI.BigInt(10),
  JSBI.BigInt(16)
); // .01 ETH

export const MAX_UINT128 = BigNumber.from(2).pow(128).sub(1);
export const navSearchInputVisibleSize = 1100;
export const REFRESH_FREQUENCY = { blocksPerFetch: 2 };
export const PERMIT_EXPIRATION = ms`30d`;
export const PERMIT_SIG_EXPIRATION = ms`30m`;
export const MAX_DATA_BLOCK_AGE = 20;

export const QUOTE_GAS_OVERRIDES: { [chainId: number]: number } = {
  [SupportedChainId.ARBITRUM_ONE]: 25_000_000,
  [SupportedChainId.ARBITRUM_GOERLI]: 25_000_000,
  [SupportedChainId.CELO]: 50_000_000,
  [SupportedChainId.CELO_ALFAJORES]: 50_000_000,
  [SupportedChainId.POLYGON]: 40_000_000,
  [SupportedChainId.POLYGON_MUMBAI]: 40_000_000,
  [SupportedChainId.BNB]: 50_000_000,
  [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]: 10_000_000,
  [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]: 10_000_000,
};

export const DEFAULT_GAS_QUOTE = 2_000_000;

// Prevents excessive quote requests between keystrokes.
export const DEBOUNCE_TIME = 350;

// Temporary until we remove the feature flag.
export const DEBOUNCE_TIME_INCREASED = 650;

export const DEFAULT_AUTO_SLIPPAGE = new Percent(1, 1000); // .10%

// Base costs regardless of how many hops in the route
export const V3_SWAP_BASE_GAS_ESTIMATE = 100_000;
export const V2_SWAP_BASE_GAS_ESTIMATE = 135_000;

// Extra cost per hop in the route
export const V3_SWAP_HOP_GAS_ESTIMATE = 70_000;
export const V2_SWAP_HOP_GAS_ESTIMATE = 50_000;
export const MIN_AUTO_SLIPPAGE_TOLERANCE = DEFAULT_AUTO_SLIPPAGE;
export const MAX_AUTO_SLIPPAGE_TOLERANCE = new Percent(5, 100); // 5%

export const LOCKINGTIME: Record<string, number> = {
  // "1h": oneHourInUnix, // 1 hour in seconds
  "1W": oneWeekInUnix, // 1 week in seconds
  "1M": oneMonthInUnix, // 1 month in seconds (approximation)
  "6M": sixMonthInUnix, // 6 months in seconds (approximation)
  "1Y": oneYearInUnix, // 1 year in seconds (approximation)
  "4Y": fourYearInUnix, // 4 years in seconds (approximation)
};


export const LOCKINGTIMEEXTEND: Record<string, number> = {
  // "1h": oneHourInUnix, // 1 hour in seconds
  "1W": oneWeekInUnix, // 1 week in seconds
  "1M": oneMonthInUnix, // 1 month in seconds (approximation)
  "6M": sixMonthInUnix, // 6 months in seconds (approximation)
  "1Y": oneYearInUnix, // 1 year in seconds (approximation)
};
export const NUMBER_OF_WEEKS_PER_YEAR = 52;
export const SORT_FIELD = {
  feeTier: "feeTier",
  volumeUSD: "volumeUSD",
  tvlUSD: "tvlUSD",
  volumeUSDWeek: "volumeUSDWeek",
  feesAPR: "feesAPR",
  stakedAPR: "stakedAPR",
};



export const POOL_SORT_FIELD = {
  totalValueLockedUSD: "totalValueLockedUSD",
  volumeUSD: "volumeUSD",
};


// export const SelectedPools = [
//   "ss",
//   // "0xa2247c3098f2dc3de53234ed764c13ecf1615c90",
//   "0x63efd70d5a2d6c9b9da8b691688ff92031fbaa17",
//   "0xb80755f076a9ac2ded1dc49cb4bd2cdd7c6e265a",
//   // "0xcc31b42606358a75a3cd58750f4d5eeaabb296ead",
//   // // "0x8df45bdee83ec6c258a454b276fb02bfcc60a801",
// ];
// export const SelectedPoolsUpdate = [
//   "0xb03ad42483ef7d3960817006d57b399680ce4c87",
//   // "0xa2247c3098f2dc3de53234ed764c13ecf1615c90",
//   // "0x63efd70d5a2d6c9b9da8b691688ff92031fbaa17",
//   // "0xb80755f076a9ac2ded1dc49cb4bd2cdd7c6e265a",
//   // "0xcc31b42606358a75a3cd58750f4d5eeaabb296ead",
//   // // "0x8df45bdee83ec6c258a454b276fb02bfcc60a801",
// ];
// 0x1de164d097b01a478dc2b4fa6cbf8998e1a3c26b
// 0x63efd70d5a2d6c9b9da8b691688ff92031fbaa17

export const ELTK_UNIT = Math.pow(10, 18);
export   const maxDays = 1460;
