import { Currency } from "@uniswap/sdk-core";
import { Position } from "@uniswap/v3-sdk";
import RangeBadge from "components/Badge/RangeBadge";
import { LightCard } from "components/Card";
import { AutoColumn } from "components/Column";
import { truncateFormatPriceImpact } from "components/CurrencyInputPanel/limitFiatValue";
import DoubleCurrencyLogo from "components/DoubleLogo";
import FeeTier from "components/FeeTier/FeeTier";
import CurrencyLogo from "components/Logo/CurrencyLogo";
import { RowBetween, RowFixed } from "components/Row";
import { MouseoverTooltip } from "components/Tooltip";
import JSBI from "jsbi";
import { ReactNode, useCallback, useState } from "react";
import { useTheme } from "styled-components/macro";
import { ThemedText } from "theme";
import { formatNumberWithSuffix } from "utils/numbers";
import { unwrappedToken } from "utils/unwrappedToken";

export const PositionPreview = ({
  position,
  title,
  inRange,
  baseCurrencyDefault,
  ticksAtLimit,
  tokenId
}: {
  position: Position;
  title?: ReactNode;
  inRange: boolean;
  baseCurrencyDefault?: Currency;
  ticksAtLimit: { [bound: string]: boolean | undefined };
  tokenId:any

}) => {

  const theme = useTheme();


  const currency0 = unwrappedToken(position.pool.token0);
  const currency1 = unwrappedToken(position.pool.token1);

  // track which currency should be base
  const [baseCurrency, setBaseCurrency] = useState(
    baseCurrencyDefault
      ? baseCurrencyDefault === currency0
        ? currency0
        : baseCurrencyDefault === currency1
          ? currency1
          : currency0
      : currency0.symbol?.toLowerCase() === "eth"
        ? currency0
        : currency1.symbol?.toLowerCase() === "eth"
          ? currency1
          : currency0
  );

  const sorted = baseCurrency === currency0;
  const quoteCurrency = sorted ? currency1 : currency0;

  const price = sorted
    ? position.pool.priceOf(position.pool.token0)
    : position.pool.priceOf(position.pool.token1);

  const priceLower = sorted
    ? position.token0PriceLower
    : position.token0PriceUpper.invert();
  const priceUpper = sorted
    ? position.token0PriceUpper
    : position.token0PriceLower.invert();

  const handleRateChange = useCallback(() => {
    setBaseCurrency(quoteCurrency);
  }, [quoteCurrency]);

  const removed =
    position?.liquidity && JSBI.equal(position?.liquidity, JSBI.BigInt(0));

  return (
    <AutoColumn gap="md">
      <RowBetween 
      style={{
        alignItems: "top",
      }} >
        <RowFixed>
          <DoubleCurrencyLogo
            currency0={currency0 ?? undefined}
            currency1={currency1 ?? undefined}
            size={26}
            margin={true}
          />
          <ThemedText.DeprecatedLabel ml="10px" fontSize="18px" fontWeight={600}>
          {currency1?.symbol}/ {currency0?.symbol}
          </ThemedText.DeprecatedLabel>
        </RowFixed>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
           {tokenId ? <span style={{ marginRight: "10px", fontSize: "12px" }}>Position {tokenId}</span>: <span></span>}
           &nbsp; &nbsp; &nbsp;
           <RangeBadge removed={removed} inRange={inRange}  />
          </div>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FeeTier position={position?.pool} />
          </div>
        </div>
      </RowBetween>

      <LightCard style={{ background: "#121B1E", border: "none" }}>
        <AutoColumn gap="md">
          <RowBetween>
            <RowFixed>
              <CurrencyLogo currency={currency0} />
              <ThemedText.DeprecatedLabel ml="8px">
                {currency0?.symbol}
              </ThemedText.DeprecatedLabel>
            </RowFixed>
            <RowFixed>
                <ThemedText.DeprecatedLabel mr="8px">
                  {formatNumberWithSuffix(Number(position.amount0.toSignificant(4)))}
                </ThemedText.DeprecatedLabel>
            </RowFixed>
          </RowBetween>
          <RowBetween>
            <RowFixed>
              <CurrencyLogo currency={currency1} />
              <ThemedText.DeprecatedLabel ml="8px">
                {currency1?.symbol}
              </ThemedText.DeprecatedLabel>
            </RowFixed>
            <RowFixed>
                <ThemedText.DeprecatedLabel mr="8px">
                  { formatNumberWithSuffix(Number(position.amount1.toSignificant(4))) }
                </ThemedText.DeprecatedLabel>
            </RowFixed>
          </RowBetween>
       
        </AutoColumn>
      </LightCard>

      <AutoColumn gap="md">
        <RowBetween>
          
        </RowBetween>

     
      </AutoColumn>
    </AutoColumn>
  );
};
