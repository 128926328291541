import { getLimitOrderBaseUrl } from "config";
import { io, Socket } from "socket.io-client";

let socket: Socket | null = null;

const connectionSocket = async (accAddr: any, chainId: number | undefined) => {
  const baseUrl = getLimitOrderBaseUrl(chainId);

  socket = io(`${baseUrl}`, {
    query: { makerAddress: accAddr },
    withCredentials: false,
    transports: ["websocket"],
  });

  socket.on("connect", () => {
    console.log("ConnectedSocket", socket?.connected, socket);
  });

  socket.on("connect_error", (error) => {
    console.error("Connectionerror:", error, socket);
  });

  socket.on("error", (error) => {
    console.error("Error:", error);
  });

  socket.on("disconnect", () => {
    console.log("Disconnect");
  });
};

export { connectionSocket, socket };
