import { formatPriceImpact } from '@uniswap/conedison/format'
import { Percent } from '@uniswap/sdk-core'
import { ButtonEmphasis, ButtonSize, ThemeButton } from 'components/Button'
import { ColumnCenter } from 'components/Column'
import Row from 'components/Row'
import { AlertTriangle } from 'react-feather'
import styled from 'styled-components/macro'
import { CloseIcon, ThemedText } from 'theme'

import Modal from '../Modal'

// eslint-disable-next-line import/no-unused-modules
export const Wrapper = styled(ColumnCenter)`
 

  --border-width: 2px;
--border-radius: 17px;
background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
border: 0;
padding: 1rem 2rem;
position: relative;
border-radius: var(--border-radius);
z-index:1;
&::before {
  content: "";
  display: block;
  height: calc(
    100% - (var(--border-width) * 2)
  ); /* Corrected the syntax here */
  width: calc(
    100% - (var(--border-width) * 2)
  ); /* Corrected the syntax here */
  background:${({ theme }) => theme.backgroundSurface} ;
  position: absolute;
  top: var(--border-width);
  left: var(--border-width);
  border-radius: calc(var(--border-radius) - var(--border-width));
  z-index: -1;

`

const IconContainer = styled.div`
  padding: 32px 0px;
`

const WarningIcon = styled(AlertTriangle)`
  color: ${({ theme }) => theme.accentCritical};
`

const ButtonContainer = styled(ColumnCenter)`
  padding: 12px 0px 0px;
`

const StyledThemeButton = styled(ThemeButton)`
  width: 100%;
`

interface PriceImpactModalProps {
  priceImpact: Percent
  onDismiss: () => void
  onContinue: () => void
}

export default function PriceImpactModal({ priceImpact, onDismiss, onContinue }: PriceImpactModalProps) {
  return (
    <Modal isOpen onDismiss={onDismiss}>
      <Wrapper gap="md">
        <Row padding="8px 0px 4px">
          <CloseIcon size={24} onClick={onDismiss} />
        </Row>
        <IconContainer>
          <WarningIcon size={48} />
        </IconContainer>
        <ColumnCenter gap="sm">
          <ThemedText.HeadlineSmall fontWeight={500}>
            <div>Warning</div>
          </ThemedText.HeadlineSmall>
          <ThemedText.BodyPrimary lineHeight="24px" textAlign="center">
            <div>
              This transaction will result in a{' '}
              <ThemedText.BodyPrimary lineHeight="24px" color="accentFailure" display="inline">
                {formatPriceImpact(priceImpact)}
              </ThemedText.BodyPrimary>{' '}
              price impact on the market price of this pool. Do you wish to continue?
            </div>
          </ThemedText.BodyPrimary>
        </ColumnCenter>
        <ButtonContainer gap="md">
          <StyledThemeButton size={ButtonSize.large} emphasis={ButtonEmphasis.failure} onClick={onContinue}>
            <div>Continue</div>
          </StyledThemeButton>
          <StyledThemeButton size={ButtonSize.medium} emphasis={ButtonEmphasis.low} onClick={onDismiss}>
            <div>Cancel</div>
          </StyledThemeButton>
        </ButtonContainer>
      </Wrapper>
    </Modal>
  )
}
