import { AlertTriangle } from 'react-feather'
import { ThemedText } from 'theme'
import { ExplainerText, TitleRow, Wrapper } from './styled'


  const OwnershipWarning = ({ ownerAddress }: { ownerAddress: string }) => (
  <Wrapper>
    <TitleRow>
      <AlertTriangle style={{ marginRight: '8px' }} />
      <ThemedText.SubHeader color="accentWarning">
        <div>Warning</div>
      </ThemedText.SubHeader>
    </TitleRow>
    <ExplainerText style={{color:'#EEB317'}}>
      <div>
        You are not the owner of this LP position. You will not be able to withdraw the liquidity from this position
        unless you own the following address: {ownerAddress}
      </div>
    </ExplainerText>
  </Wrapper>
)

export default OwnershipWarning
