import { useWeb3React } from "@web3-react/core";
import { useFilterPossiblyMaliciousPositions } from "hooks/useFilterPossiblyMaliciousPositions";
import { useV3Positions } from "hooks/useV3Positions";
import { Fragment, useMemo } from "react";
import { useUserHideClosedPositions } from "state/user/hooks";
import { PositionDetails } from "types/position";
import {
  ErrorContainer,
  MainContentWrapperPositions,
  ResponsiveButtonPrimary,
  PositionsTitle,
  PositionHeaderTitle,
} from "./styleds";
import { ThemedText } from "theme";
import { ButtonPrimary, ButtonText } from "components/Button";
import { useToggleAccountDrawer } from "components/AccountDrawer";
import styled, { css, useTheme } from "styled-components/macro";
import { Inbox } from "react-feather";
import PositionList from "components/PositionList";
import { Link, useNavigate } from "react-router-dom";
import { ElektrikLoader, LoaderV2 } from "components/Icons/LoadingSpinner";
import { RowFixed } from "components/Row";
import { StyledArrowLeftChevron } from "components/NavigationTabs/styled";

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`;

const InboxIcon = styled(Inbox)`
  ${IconStyle}
`;

export default function PoolsPosition() {


  const { account, chainId } = useWeb3React();
  const navigate= useNavigate()
  const theme = useTheme();
  
  const [userHideClosedPositions, setUserHideClosedPositions] =
    useUserHideClosedPositions();
  const { positions, loading: positionsLoading } = useV3Positions(account);


  const [openPositions, closedPositions] = positions?.reduce<
    [PositionDetails[], PositionDetails[]]
  >(
    (acc, p) => {
      acc[p.liquidity?.isZero() ? 1 : 0].push(p);
      return acc;
    },
    [[], []]
  ) ?? [[], []];

  const userSelectedPositionSet = useMemo(
    () => [
      ...openPositions,
      ...(userHideClosedPositions ? [] : closedPositions),
    ],
    [closedPositions, openPositions, userHideClosedPositions]
  );

  const showConnectAWallet = Boolean(!account);

  const filteredPositions = useFilterPossiblyMaliciousPositions(
    userSelectedPositionSet
  );

  const toggleWalletDrawer = useToggleAccountDrawer();
  
  const navigateToMyPool = ()=>{
    navigate(`/pools`)
  }

  return (
    <Fragment>
      <MainContentWrapperPositions
        style={{ minWidth: "80%", margin: "20px 0px" }}
      >
        {!positionsLoading && (
          <PositionHeaderTitle>
            <div style={{ display: "flex", alignItems: "center" }}>
              <PositionsTitle>My Positions</PositionsTitle>{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <ResponsiveButtonPrimary
                data-cy="join-pool-button"
                id="join-pool-button"
                as={Link}
                to="/add"
                style={{
                  background: `linear-gradient(146deg, #a1eeff 0%, #029af0 100%)`,
                  color: "#000",
                  height: "46px",
                  width:"200px",
                  padding:"14px 14px",
                  fontSize:14
                }}  
              >
                + <div>Add New Position</div>
              </ResponsiveButtonPrimary>
            </div>

          </PositionHeaderTitle>
        )}

        {positionsLoading ? (
        
          <ElektrikLoader fill={true} />
        ) : filteredPositions &&
          closedPositions &&
          filteredPositions.length > 0 ? (
          <PositionList
            positions={userSelectedPositionSet}
            setUserHideClosedPositions={setUserHideClosedPositions}
            userHideClosedPositions={userHideClosedPositions}
          />
        ) : (
          <ErrorContainer style={{height:'60vh'}}>
            <ThemedText.DeprecatedBody
              color={theme.textTertiary}
              textAlign="center"
            >
              <InboxIcon strokeWidth={1} style={{ marginTop: "2em" }} />
              <div>
                <div>Your active liquidity positions will appear here.</div>
              </div>
            </ThemedText.DeprecatedBody>
            {!showConnectAWallet && closedPositions.length > 0 && (
              <ButtonText
                style={{
                  marginTop: ".5rem",
                  background: `linear-gradient(146deg, #a1eeff 0%, #029af0 100%)`,
                  color: "#000",
                  padding: "10px",
                  marginBottom: "10px",
                }}
                onClick={() =>
                  setUserHideClosedPositions(!userHideClosedPositions)
                }
              >
                <div>Show closed positions</div>
              </ButtonText>
            )}
            {showConnectAWallet && (
              <ButtonPrimary
                style={{
                  marginTop: "2em",
                  marginBottom: "2em",
                  padding: "8px 16px",
                  background: `linear-gradient(146deg, #a1eeff 0%, #029af0 100%)`,
                  color: "#000",
                  borderRadius: "10px",
                }}
                onClick={toggleWalletDrawer}
              >
                <div>Connect wallet</div>
              </ButtonPrimary>
            )}
          </ErrorContainer>
        )}
      { filteredPositions.length ? <div
          style={{ display: "flex", justifyContent: "flex-end", position:'relative', top:-20, marginTop:30, marginBottom:20 }}
          onClick={() => navigateToMyPool()}
        >
          <RowFixed>
            <div
              style={{
                borderRadius: "50%",
                background:
                  "linear-gradient(91deg, #A1EEFF 7.28%, #029AF0 89.82%)",
                cursor: "pointer",
              }}
            >
              <StyledArrowLeftChevron
                color="#000000"
                style={{ display: "flex", padding: "4px" }}
              />
            </div>
          </RowFixed>
        </div>: <></>}
      </MainContentWrapperPositions>
    </Fragment>
  );
}




