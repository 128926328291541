export function formatTimestamp(timestamp: number): string {
  const date = new Date(timestamp * 1000);

  const month = date.toLocaleString("en", { month: "short" });
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  const timezoneOffsetHours = Math.abs(date.getTimezoneOffset()) / 60;
  const timezoneOffsetSign = date.getTimezoneOffset() > 0 ? "-" : "+";
  const timezoneOffsetFormatted = `${timezoneOffsetSign}${timezoneOffsetHours
    .toString()
    .padStart(2, "0")}:00`;

  const formattedTimestamp = `${month} ${day
    .toString()
    .padStart(
      2,
      "0"
    )} ${year} ${hours}:${minutes}:${seconds} (${timezoneOffsetFormatted} UTC)`;

  return formattedTimestamp;
}