import { formatEther } from "@ethersproject/units";
import { useQuery } from "react-query";

export enum Currency {
  ETH = "ETH",
  LOOKS = "LOOKS",
  MATIC = "MATIC",
}

export const  fetchPrice = async (
  currency: Currency = Currency.ETH
): Promise<number | undefined> => {
  try {
    const response = await fetch(
      `https://api.coinbase.com/v2/exchange-rates?currency=${currency}`
    );
    return response.json().then((j) => j.data.rates.USD);
  } catch (e) {
    console.error(e)

    return;
  }
};

export const fetchPriceUsd = async (currencyIds: any) => {

  const idsString = currencyIds.join(",");

  try {
    const response = await fetch(
      `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${idsString}&sparkline=false`
    );
    const data = await response.json();

    console.log("SSSSS", currencyIds, data)

    return data;
  } catch (e) {
    return;
  }
};

export const getChartData = async (duration: any, tokenA: any) => {
  const tok = tokenA.toLowerCase();

  try {
    const response = await fetch(
      `https://pro-api.coingecko.com/api/v3/coins/${tok}/ohlc?vs_currency=usd&days=${duration}&interval=daily&x_cg_pro_api_key=CG-bRt8DjKAQmq5w4e9FgbQmDXy`
    );
    const data = await response.json();
    return data;
  } catch (e) {

  }
};


export function useUsdPrice(asset: any): string | undefined {
  const { data: fetchedPriceData } = useQuery(
    ["fetchPrice", {}],
    () => fetchPrice(),
    {}
  );

  return fetchedPriceData && asset?.priceInfo?.ETHPrice
    ? (
        parseFloat(formatEther(asset?.priceInfo?.ETHPrice)) * fetchedPriceData
      ).toString()
    : "";
}

export const getCurrentMarketPriceBySymbol = (
  coinUsd: any,
  symbol: string | undefined
) => {
  const coin = coinUsd.find((coin: any) => {
    return coin.symbol === symbol?.toLowerCase();
  });
  return coin;
};
