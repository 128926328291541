import {
  Currency,
  CurrencyAmount,
  Percent,
  TradeType,
} from "@uniswap/sdk-core";
import {
  currencyAmountToPreciseFloat,
  formatTransactionAmount,
} from "utils/formatNumbers";
import { useWeb3React } from "@web3-react/core";
import { SupportedChainId } from "constants/chains";
import useAutoSlippageTolerance from "hooks/useAutoSlippageTolerance";
import { useBestTrade } from "hooks/useBestTrade";
import tryParseCurrencyAmount from "lib/utils/tryParseCurrencyAmount";
import { ParsedQs } from "qs";
import { ReactNode, useCallback, useEffect, useMemo , useState} from "react";
import { AnyAction } from "redux";
import { useAppDispatch } from "state/hooks";
import { InterfaceTrade, TradeState } from "state/routing/types";
import { useUserSlippageToleranceWithDefault } from "state/user/hooks";

import { TOKEN_SHORTHANDS } from "../../constants/tokens";
import { useCurrency } from "../../hooks/Tokens";
import useENS from "../../hooks/useENS";
import useParsedQueryString from "../../hooks/useParsedQueryString";
import { isAddress } from "../../utils";
import { useCurrencyBalance, useCurrencyBalances } from "../connection/hooks";
import {
  Field,
  replaceSwapState,
  selectCurrency,
  setRecipient,
  switchCurrencies,
  typeInput,
} from "./actions";
import { SwapState } from "./reducer";
import { useLocation } from "react-router-dom";
import { formatCurrencyAmount } from "utils/formatCurrencyAmount";

export function useSwapActionHandlers(dispatch: React.Dispatch<AnyAction>): {
  onCurrencySelection: (field: Field, currency: Currency) => void;
  onSwitchTokens: () => void;
  onUserInput: (field: Field, typedValue: string) => void;
  onChangeRecipient: (recipient: string | null) => void;
} {
  const onCurrencySelection = useCallback(
    (field: Field, currency: Currency) => {
      dispatch(
        selectCurrency({
          field,
          currencyId: currency.isToken
            ? currency.address
            : currency.isNative
              ? "ETH"
              : "",
        })
      );
    },
    [dispatch]
  );

  const onSwitchTokens = useCallback(() => {
    dispatch(switchCurrencies());
  }, [dispatch]);

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      dispatch(typeInput({ field, typedValue }));
    },
    [dispatch]
  );

  const onChangeRecipient = useCallback(
    (recipient: string | null) => {
      dispatch(setRecipient({ recipient }));
    },
    [dispatch]
  );

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
  };
}

const BAD_RECIPIENT_ADDRESSES: { [address: string]: true } = {
  "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f": true, // v2 factory
  "0xf164fC0Ec4E93095b804a4795bBe1e041497b92a": true, // v2 router 01
  "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D": true, // v2 router 02
};

// from the current swap inputs, compute the best trade and return it.
export function useDerivedSwapInfo(
  state: SwapState,
  chainId: SupportedChainId | undefined,
  numberOfPartsValue? : number
): {
  currencies: { [field in Field]?: Currency | null };
  currencyBalances: { [field in Field]?: CurrencyAmount<Currency> };
  parsedAmount?: CurrencyAmount<Currency>;
  parsedAmountLimit?: CurrencyAmount<Currency>;
  inputError?: ReactNode;
  trade: {
    trade?: InterfaceTrade;
    state: TradeState;
  };
  allowedSlippage: Percent;
  autoSlippage: Percent;
} {
  const { account } = useWeb3React();
  const location = useLocation();
  const isLimitPath = location.pathname === "/limit";
  const isSwapPath = location.pathname === "/swap";

  const {
    independentField,
    typedValue,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
    recipient,
  } = state;

  const inputCurrency = useCurrency(inputCurrencyId, chainId);

  const outputCurrency = useCurrency(outputCurrencyId, chainId);

  const recipientLookup = useENS(recipient ?? undefined);
  const to: string | null =
    (recipient === null ? account : recipientLookup.address) ?? null;

  const relevantTokenBalances = useCurrencyBalances(
    account ?? undefined,
    useMemo(
      () => [inputCurrency ?? undefined, outputCurrency ?? undefined],
      [inputCurrency, outputCurrency]
    )
  );




  const isExactIn: boolean = independentField === Field.INPUT;

  const parsedAmount = useMemo(() => {
    if (isLimitPath) {
      return undefined;
    }
    return  tryParseCurrencyAmount(
      typedValue,
      (isExactIn ? inputCurrency : outputCurrency) ?? undefined
    );
  }, [inputCurrency, isExactIn, outputCurrency, typedValue]);

  const parsedAmountLimit = useMemo(() => {
    return tryParseCurrencyAmount(
      typedValue,
      (isExactIn ? inputCurrency : outputCurrency) ?? undefined
    );
  }, [inputCurrency, isExactIn, outputCurrency, typedValue]);

  const trade = useBestTrade(
    isExactIn ? TradeType.EXACT_INPUT : TradeType.EXACT_OUTPUT,
    parsedAmount,
    (isExactIn ? outputCurrency : inputCurrency) ?? undefined
  );

  const currencyBalances = useMemo(
    () => ({
      [Field.INPUT]: relevantTokenBalances[0],
      [Field.OUTPUT]: relevantTokenBalances[1],
    }),
    [relevantTokenBalances]
  );

  const currencies: { [field in Field]?: Currency | null } = useMemo(
    () => ({
      [Field.INPUT]: inputCurrency,
      [Field.OUTPUT]: outputCurrency,
    }),
    [inputCurrency, outputCurrency]
  );

  const selectedCurrencyBalanceSell = useCurrencyBalance(
    account ?? undefined,
    currencies[Field.INPUT] ?? undefined
  );

  // allowed slippage is either auto slippage, or custom user defined slippage if auto slippage disabled
  const autoSlippage = useAutoSlippageTolerance(trade.trade);
  const allowedSlippage = useUserSlippageToleranceWithDefault(autoSlippage);

  const inputError = useMemo(() => {
    let inputError: ReactNode | undefined;

    if (!account) {
      inputError = <div>Connect Wallet</div>;
    }

    if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
      inputError = inputError ?? <div>Choose token</div>;
    }

    if (isLimitPath && !parsedAmountLimit) {
      inputError = inputError ?? <div>Enter an amount</div>;
    }

    if (!isLimitPath && !parsedAmount) {
      inputError = inputError ?? <div>Enter an amount</div>;
    }

    const formattedTo = isAddress(to);
    if (!to || !formattedTo) {
      inputError = inputError ?? <div>Enter a recipient</div>;
    } else {
      if (BAD_RECIPIENT_ADDRESSES[formattedTo]) {
        inputError = inputError ?? <div>Invalid recipient</div>;
      }
    }

    // compare input balance to max input based on version
    const [balanceIn, amountIn] = [
      currencyBalances[Field.INPUT],
      trade.trade?.maximumAmountIn(allowedSlippage),
    ];
    const [balanceInLimit, amountInLimit] = [
      currencyBalances[Field.INPUT],
      selectedCurrencyBalanceSell,
    ];

    if (balanceIn && amountIn && balanceIn.lessThan(amountIn)) {
      inputError = (
        <div>Insufficient {amountIn.currency.symbol} balance</div>
      );
    }

    if (Number(formatCurrencyAmount(balanceInLimit, 9)) < Number(typedValue) && !isSwapPath) {
      inputError = <div>Insufficient balance</div>;
    }

    return inputError;
  }, [
    account,
    allowedSlippage,
    currencies,
    currencyBalances,
    parsedAmount,
    parsedAmountLimit,
    to,
    trade.trade,
    typedValue,
  ]);



  

  return useMemo(
    () => ({
      currencies,
      currencyBalances,
      parsedAmount,
      parsedAmountLimit,
      inputError,
      trade,
      autoSlippage,
      allowedSlippage,
    }),
    [
      allowedSlippage,
      autoSlippage,
      currencies,
      currencyBalances,
      inputError,
      parsedAmount,
      parsedAmountLimit,
      trade,
    ]
  );
}

function parseCurrencyFromURLParameter(urlParam: ParsedQs[string]): string {
  if (typeof urlParam === "string") {
    const valid = isAddress(urlParam);
    if (valid) return valid;
    const upper = urlParam.toUpperCase();
    if (upper === "ETH") return "ETH";
    if (upper in TOKEN_SHORTHANDS) return upper;
  }
  return "";
}

function parseTokenAmountURLParameter(urlParam: any): string {
  return typeof urlParam === "string" && !isNaN(parseFloat(urlParam))
    ? urlParam
    : "";
}

function parseIndependentFieldURLParameter(urlParam: any): Field {
  return typeof urlParam === "string" && urlParam.toLowerCase() === "output"
    ? Field.OUTPUT
    : Field.INPUT;
}

const ENS_NAME_REGEX =
  /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?$/;
const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/;
function validatedRecipient(recipient: any): string | null {
  if (typeof recipient !== "string") return null;
  const address = isAddress(recipient);
  if (address) return address;
  if (ENS_NAME_REGEX.test(recipient)) return recipient;
  if (ADDRESS_REGEX.test(recipient)) return recipient;
  return null;
}

export function queryParametersToSwapState(
  parsedQs: ParsedQs,
  chainId?: any
): SwapState {
  let inputCurrency = parseCurrencyFromURLParameter(parsedQs.inputCurrency);
  let outputCurrency = parseCurrencyFromURLParameter(parsedQs.outputCurrency);
  const typedValue = parseTokenAmountURLParameter(parsedQs.exactAmount);
  const independentField = parseIndependentFieldURLParameter(
    parsedQs.exactField
  );

  if (
    inputCurrency === "" &&
    outputCurrency === "" &&
    typedValue === "" &&
    independentField === Field.INPUT
  ) {
    // Defaults to having the native currency selected
    // inputCurrency = "0x04E4929B89fc1483B6C6839c4455AbE1C1Caa811";

    inputCurrency =
      chainId === 1890
        ? "0x04E4929B89fc1483B6C6839c4455AbE1C1Caa811"
        : "0xe0108586a915fcf787479185ae1738e6ca8b4a9f";

    // outputCurrency = "0xd9d7123552fA2bEdB2348bB562576D67f6E8e96E";
  } else if (inputCurrency === outputCurrency) {
    // clear output if identical
    outputCurrency = "";
  }

  const recipient = validatedRecipient(parsedQs.recipient);

  return {
    [Field.INPUT]: {
      currencyId: inputCurrency === "" ? null : inputCurrency ?? null,
    },
    [Field.OUTPUT]: {
      currencyId: outputCurrency === "" ? null : outputCurrency ?? null,
    },
    typedValue,
    independentField,
    recipient,
  };
}

// updates the swap state to use the defaults for a given network
export function useDefaultsFromURLSearch(): SwapState {
  const { chainId } = useWeb3React();
  const dispatch = useAppDispatch();
  const parsedQs = useParsedQueryString();

  const parsedSwapState = useMemo(() => {
    return queryParametersToSwapState(parsedQs, chainId);
  }, [parsedQs, chainId]);

  useEffect(() => {
    if (!chainId) return;
    const inputCurrencyId =
      parsedSwapState[Field.INPUT].currencyId ?? undefined;
    const outputCurrencyId =
      parsedSwapState[Field.OUTPUT].currencyId ?? undefined;

    dispatch(
      replaceSwapState({
        typedValue: parsedSwapState.typedValue,
        field: parsedSwapState.independentField,
        inputCurrencyId,
        outputCurrencyId,
        recipient: parsedSwapState.recipient,
      })
    );
  }, [dispatch, chainId, parsedSwapState]);

  return parsedSwapState;
}
