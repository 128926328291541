import { ReactComponent as ElektrikLogo } from "assets/svg/elektrik_logo.svg";
import { ReactComponent as ElektrikLogoWhite } from "assets/svg/elektrik_logo_white.svg";
import { ReactComponent as BurgerMenu } from "assets/svg/burger.svg";
import { useAccountDrawer } from "components/AccountDrawer";
import Web3Status from "components/Web3Status";
import LogoWithBackground from "assets/svg/logowithbackground.svg";

import { useIsPoolsPage } from "hooks/useIsPoolsPage";
import { Portal } from "components/Portal";
import { ChevronDown, ChevronUp } from "react-feather";

import { useCallback, useState, useEffect, useRef } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  CloseIcon,
  ExternalLinkDeprecated,
  StyledLinkDeprecatedSpan,
  ThemedText,
} from "theme";

import Blur from "./Blur";
import * as styles from "./style.css";
import "components/NavBar/divider.css"; // Import the CSS
import CustomSwitch from "./Switch";
import { LabelText, Nav } from "./styled";
import { MenuItemProps } from "types";
import Modal from "components/Modal";
import { useIsMobile, useIsNavMobile } from "utils/common";
import {
  TwitterXIcon,
  DiscordXIcon,
  GithubXIcon,
  PlaneXIcon,
  IconLink,
} from "pages/app.styled";
import { Box } from "components/Box";
import { Column, Row } from "components/Flex";
import { ChainSelector } from "./ChainSelector";
import { Backdrop, ModalContainer } from "components/IntroModal/styled";
import { useWeb3React } from "@web3-react/core";
import { NavDropdown } from "./NavDropdown";
import { useTheme } from "styled-components/macro";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import {
  ELEKTRIK_DISCORD,
  ELEKTRIK_WEBISTE_URL,
  ELEKTRIK_X,
} from "constants/links";
import { SupportedChainId } from "constants/chains";
import { DropDownWrapper } from "components/AccountDrawer/styled";
import { ButtonOutlinedDark } from "components/Button";

const MenuItem = ({
  href,
  dataTestId,
  id,
  isActive,
  children,
  isLp,
  additionalStyles,
}: MenuItemProps) => {
  return (
    <NavLink
      to={href}
      className={isActive ? styles.activeMenuItem : styles.menuItem}
      id={id}
      style={{
        textDecoration: "none",
        marginLeft: "0px",
        marginRight: "0px",
        fontSize: "12px",
        justifyContent: isLp ? "center" : "center",
        ...additionalStyles,
      }}
      data-testid={dataTestId}
    >
      {children}
    </NavLink>
  );
};

export const MenuItemSwap = ({
  href,
  dataTestId,
  id,
  isActive,
  children,
}: MenuItemProps) => {
  return (
    <NavLink
      to={href}
      className={isActive ? styles.activeMenuItemSwap : styles.menuItemSwap}
      id={id}
      style={{
        textDecoration: "none",
        marginRight: "0px",
        fontSize: "12px",
      }}
      data-testid={dataTestId}
    >
      {children}
    </NavLink>
  );
};


export const MenuItemDropDown = ({
  href,
  dataTestId,
  id,
  isActive,
  children,
}: MenuItemProps) => {
  return (
    <NavLink
      to={href}
      className={isActive ? styles.activeMenuItemSwapNavbar : styles.menuItemSwapNavbar}
      id={id}
      style={{
        textDecoration: "none",
        marginRight: "0px",
        fontSize: "12px",
      }}
      data-testid={dataTestId}
    >
      {children}
    </NavLink>
  );
};

// eslint-disable-next-line import/no-unused-modules
export const PageTabs = () => {
  const { pathname } = useLocation();
  const isPoolActive = useIsPoolsPage();

  const ref = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const [hover, setHover] = useState(false);

  const refLP = useRef<HTMLDivElement>(null);
  const modalRefLP = useRef<HTMLDivElement>(null);
  const [hoverLP, setHoverLP] = useState(false);

  const { chainId } = useWeb3React();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenLP, setIsOpenLP] = useState<boolean>(false);

  const isMobile = useIsMobile();

  const theme = useTheme();

  const chevronProps = {
    height: 20,
    width: 20,
    color: theme.textPrimary,
  };
  useOnClickOutside(ref, () => setIsOpen(false), [modalRef]);
  useOnClickOutside(refLP, () => setIsOpenLP(false), [modalRefLP]);

  useEffect(() => {
    setIsOpen(false);
    setIsOpenLP(false);
  }, [pathname]);

  const LP_DROPDOWN = [
    // {
    //   name: "Voting",
    //   href: "/locking",
    //   pathname: pathname.startsWith("/voting"),
    // },
    {
      name: "Locking",
      href: "/locking",
      pathname: pathname.startsWith("/locking"),
    },
    // {
    //   name: "Proposals",
    //   href: "/governance",
    //   pathname: pathname.startsWith("/governance"),
    // },
  ];

  const LP_DROPDOWNMAIN = [
    {
      name: "Positions",
      href: "/poolPosition",
      pathname: pathname.startsWith("/poolPosition"),
    },
    {
      name: "Pools",
      href: "/pools",
      pathname: pathname.startsWith("/pools"),
    },
  ];

  const dropdown = (
    <NavDropdown
      top="40"
      left={true ? "0" : "auto"}
      right={true ? "auto" : "0"}
      ref={modalRef}
      style={{
        background: "#091012",
        borderRadius: "12px",
        border: "1px solid #A1EEFF",
        zIndex: 999,
        paddingTop: 0,
        paddingBottom: 0,
      }}
    >
      <Column paddingX="8" textAlign="left">
        {LP_DROPDOWN.map((item: any, index: any) => (
          <MenuItem
            key={index}
            href={item?.href}
            isActive={item?.pathname}
            isLp={true}
            additionalStyles={{ padding: "5px 10px" }}
          >
            <div>{item?.name}</div>
          </MenuItem>
        ))}
      </Column>
    </NavDropdown>
  );

  const dropdownLP = (
    <NavDropdown
      top="40"
      left={"8"}
      right={"0"}
      ref={modalRefLP}
      style={{
        background: "#091012",
        borderRadius: "12px",
        zIndex: 999,
        paddingTop: 0,
        paddingBottom: 0,
      }}
    >
      <DropDownWrapper>
        {LP_DROPDOWNMAIN.map((item: any, index: any) => (
          <MenuItemDropDown
            key={index}
            href={item?.href}
            isActive={item?.pathname}
            isLp={true}
            additionalStyles={{ padding: "5px 10px", textAlign:'left' }}
          >
            <div>{item?.name}</div>
          </MenuItemDropDown>
        ))}
      </DropDownWrapper>
    </NavDropdown>
  );

  return (
    <>
      <MenuItem
        href="/swap"
        isActive={pathname.startsWith("/swap") || pathname.startsWith("/limit")}
      >
        <div>Swap</div>
      </MenuItem>

      <ExternalLinkDeprecated href="https://analytics.elektrik.network/#/">
        Analytics
      </ExternalLinkDeprecated>
      <MenuItem href="/locking" isActive={pathname.startsWith("/locking")}>
        <div>Locking</div>
      </MenuItem>
      <Box display={{ sm: "none", lg: "flex" }}>
        <Box position="relative" ref={refLP}>
          <div
            style={{
              background: "none",
              display: "flex",
              fontSize: 12,
              fontWeight: 500,
              margin: "4px 0px",
              padding: "8px 20px 8px 14px",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => setIsOpenLP(!isOpenLP)}
          >
            <ThemedText.SubHeaderSmall
              style={{
                marginLeft: "3px",
                lineHeight: "24px",
                color: hover ? `#A2EEFF` : "#fff",
              }}
              onMouseEnter={() => {
                setHoverLP(true);
              }}
              onMouseLeave={() => {
                setHoverLP(false);
              }}
            ></ThemedText.SubHeaderSmall>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "77px",
              }}
            >
              <div>LP & Staking</div>
            </div>
            &nbsp; &nbsp;
            {isOpenLP ? (
              <ChevronUp {...chevronProps} />
            ) : (
              <ChevronDown {...chevronProps} />
            )}
          </div>
          {isOpenLP &&
            (isMobile ? <Portal>{dropdownLP}</Portal> : <>{dropdownLP}</>)}
        </Box>
      </Box>
      <span>&nbsp;&nbsp;</span>
    </>
  );
};

export const BottomPageTabs = () => {
  return (
    <>
          <a 
      style={{textDecoration:"none"}}
      href="https://elektrik.network/privacy-policy.html" target="_blank" >
      <StyledLinkDeprecatedSpan
        style={{
          fontSize: 12,
          fontWeight: 500,
          textAlign: "center",
        }}
      >
        Privacy Policy
      </StyledLinkDeprecatedSpan>
      </a>
      <a 
      style={{textDecoration:"none"}}
      href="https://elektrik.network/terms.html" target="_blank" >
      <StyledLinkDeprecatedSpan
        style={{ fontSize: 12, fontWeight: 500, textAlign: "center" }}
      >
        Terms & Conditions
      </StyledLinkDeprecatedSpan>
      </a>
      <a 
      style={{textDecoration:"none"}}
      href="https://elektrik.network/cookie-policy.html" target="_blank" >
      <StyledLinkDeprecatedSpan
        style={{ fontSize: 12, fontWeight: 500, textAlign: "center" }}
      >
        Cookie Policy
      </StyledLinkDeprecatedSpan>
      </a>
      {/* <ExternalLinkDeprecated
        style={{ fontSize: 12, fontWeight: 500, textAlign: "center" }}
        href="https://elektrik.network/"
      >
        Elektrik Website
      </ExternalLinkDeprecated> */}
      <ExternalLinkDeprecated
        style={{ fontSize: 12, fontWeight: 500, textAlign: "center" }}
        href="https://docs.elektrik.network/protocol-overview/welcome-to-elektrik"
      >
        Docs
      </ExternalLinkDeprecated>
    </>
  );
};

export const SwapPageTabs = () => {
  const { pathname } = useLocation();

  return (
    <>
      <MenuItemSwap href="/swap" isActive={pathname.startsWith("/swap")}>
        <div>Swap</div>
      </MenuItemSwap>
      <MenuItemSwap
        href="/limit"
        dataTestId="pool-nav-link"
        isActive={pathname.startsWith("/limit")}
      >
        <div>Limit</div>
      </MenuItemSwap>
      <MenuItemSwap
        href="/twap"
        dataTestId="pool-nav-link"
        isActive={pathname.startsWith("/twap")}
      >
        <div>TWAP</div>
      </MenuItemSwap>
    </>
  );
};

const Navbar = ({ blur }: { blur: boolean }) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const IsNavMobile = useIsNavMobile();

  const location = useLocation();
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
  const toggleBurgerMenu = () => setIsBurgerMenuOpen((prevState) => !prevState);
  useEffect(() => {
    setIsBurgerMenuOpen(false);
  }, [location]);

  const [accountDrawerOpen, toggleAccountDrawer] = useAccountDrawer();

  const handleUniIconClick = useCallback(() => {
    if (accountDrawerOpen) {
      toggleAccountDrawer();
    }
    window.open(ELEKTRIK_WEBISTE_URL);
  }, [accountDrawerOpen, navigate, toggleAccountDrawer]);

  const [isChecked, setIsChecked] = useState(false);

  const toggleSwitch = () => {
    setIsChecked(!isChecked);
  };

  const { chainId } = useWeb3React();

  const modalRef = useRef(null);
  const handleCloseModal = () => {
    setIsChecked(false);
  };

  useOnClickOutside(modalRef, handleCloseModal);

  return (
    <>
      {blur && <Blur />}
      <Nav
        isMobile={IsNavMobile}
        style={{
          marginTop:
            chainId === SupportedChainId.LIGHTLINK_PHOENIX_MAINNET ? 0 : 0,
          background: "#000000",
        }}
      >
        <Box display="flex" height="full" flexWrap="nowrap">
          <Box className={styles.leftSideContainer}>
            <Box className={styles.logoContainer} onClick={handleUniIconClick}>
              {IsNavMobile ? (
                <ElektrikLogo
                  width={40}
                  height={40}
                  style={{ marginRight: "0px" }}
                />
              ) : (
                <ElektrikLogoWhite width={160} height={38} />
              )}
            </Box>
            <span>&nbsp;&nbsp;&nbsp;</span>

            <Row display={{ sm: "none", md: "none", lg: "flex" }}>
              <PageTabs />
            </Row>
          </Box>
          <Box className={styles.rightSideContainer}>
            <Box
              display={{ sm: "flex", lg: "none" }}
              marginRight={{ sm: "10", lg: "0" }}
              marginLeft={{ lg: "10", sm: "0" }}
            >
              <ChainSelector leftAlign={true} />
            </Box>
            {!IsNavMobile && (
              <Row display={{ sm: "none", md: "none", lg: "flex" }} gap="12">
                <LabelText>Lite</LabelText>
                <CustomSwitch
                  isChecked={isChecked}
                  toggleSwitch={toggleSwitch}
                />
                <LabelText>Pro</LabelText>
              </Row>
            )}

            <span>&nbsp;&nbsp;&nbsp;</span>

            <Row gap="12">
              <Box display={{ sm: "none", lg: "flex" }}>
                <ChainSelector />
              </Box>
              <Web3Status />

              {isMobile && (
                <button
                  className={styles.iconButton}
                  onClick={toggleBurgerMenu}
                  aria-label="Toggle menu"
                >
                  <BurgerMenu />
                </button>
              )}
            </Row>
          </Box>
        </Box>
      </Nav>
      {isBurgerMenuOpen && (
        <div
          style={{
            position: "absolute",
            top: "58px",
            left: 0,
            width: "100%",
            height: "100vh",
            background: "#000",
            zIndex: 10,
          }}
        >
          <PageTabs />
        </div>
      )}
      {isChecked && <Backdrop></Backdrop>}

      <Modal isOpen={isChecked}>
        <ModalContainer ref={modalRef}>
          <div style={{ textAlign: "right" }}>
            <CloseIcon
              onClick={() => {
                setIsChecked(false);
              }}
              style={{
                zIndex: 99,
              }}
              stroke="#647383"
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={LogoWithBackground} height={100} width={293} />

            <div style={{ fontSize: 24, fontWeight: "bold", marginBottom: 5 }}>
              Pro Mode
            </div>

            <div style={{ color: "#9BADB2", fontSize: 16, marginBottom: 20 }}>
              Pro Feature is Coming Soon!
            </div>
            <div style={{ display: "flex", marginBottom: 40 }}>
              <IconLink
                href="https://x.com/elektriknetwork?s=21&t=8UGAzDLIjTPhad9esn-tHw"
                target="_blank"
              >
                <TwitterXIcon size={18} cursor={"pointer"} />
              </IconLink>
            
              &nbsp; &nbsp; &nbsp;
              <IconLink href="https://t.me/elektrikzapchat" target="_blank">
                <PlaneXIcon size={18} cursor={"pointer"} />
              </IconLink>
              &nbsp; &nbsp; &nbsp;
              <IconLink
                href="https://discord.com/invite/kF3n9FWCZ4"
                target="_blank"
              >
                <DiscordXIcon size={18} cursor={"pointer"} />
              </IconLink>
            </div>
            <ButtonOutlinedDark
              style={{
                boxShadow: "none",
                border: "1px solid #5D7279",
              }}
              onClick={() => {
                setIsChecked(false);
              }}
            >
              Okay
            </ButtonOutlinedDark>
          </div>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default Navbar;
