import { t, } from "@lingui/macro";
import { Currency } from "@uniswap/sdk-core";
import { useWeb3React } from "@web3-react/core";
import { ColumnCenter } from "components/Column";
import Column from "components/Column";
import Row, { RowBetween } from "components/Row";
import { SupportedChainId } from "constants/chains";
import { useUnmountingAnimation } from "hooks/useUnmountingAnimation";
import { ReactNode, useEffect, useRef, useState } from "react";
import { InterfaceTrade } from "state/routing/types";
import { useIsTransactionConfirmed } from "state/transactions/hooks";
import styled, { css, keyframes } from "styled-components/macro";
import { ExternalLink } from "theme";
import { ThemedText } from "theme/components/text";
import { getExplorerLink } from "utils/getExplorerLink";
import { ExplorerDataType } from "utils/getExplorerLink";

import { ConfirmModalState } from "../ConfirmSwapModal";
import {
  AnimatedEntranceConfirmationIcon,
  AnimationType,
  CurrencyLoader,
  LoadingIndicatorOverlay,
  LogoContainer,
  PaperIcon,
} from "./Logos";
import { TradeSummary } from "./TradeSummary";
import { useLocation } from "react-router-dom";
import { useAddPopup } from "state/application/hooks";
import { GradientGreen, GradientText } from "pages/Pool/styleds";
import BarLoader from "components/Icons/BarLoader";
import { AllowanceState } from "hooks/usePermit2Allowance";
import { ButtonLight } from "components/Button";

export const PendingModalContainer = styled(ColumnCenter)`
  margin: 48px 0 8px;
  max-width: 355px;
  overflow: hidden;
`;

const HeaderContainer = styled(ColumnCenter)<{ $disabled?: boolean }>`
  ${({ $disabled }) => $disabled && `opacity: 0.5;`}
  padding: 0 0px;
  overflow: visible;
`;

const StepCircle = styled.div<{ active: boolean }>`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${({ theme, active }) =>
    active ? theme.accentAction : theme.textTertiary};
  outline: 3px solid
    ${({ theme, active }) =>
      active ? theme.accentActionSoft : theme.accentTextLightTertiary};
  transition: background-color
    ${({ theme }) =>
      `${theme.transition.duration.medium} ${theme.transition.timing.inOut}`};
`;

const slideIn = keyframes`
  from { opacity: 0; transform: translateX(40px) }
  to { opacity: 1; transform: translateX(0px) }
`;
const slideInAnimation = css`
  animation: ${slideIn}
    ${({ theme }) =>
      `${theme.transition.duration.medium} ${theme.transition.timing.inOut}`};
`;
const slideOut = keyframes`
  from { opacity: 1; transform: translateX(0px) }
  to { opacity: 0; transform: translateX(-40px) }
`;
const slideOutAnimation = css`
  animation: ${slideOut}
    ${({ theme }) =>
      `${theme.transition.duration.medium} ${theme.transition.timing.inOut}`};
`;

const AnimationWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 72px;
  display: flex;
  flex-grow: 1;
`;

const StepTitleAnimationContainer = styled(Column)<{
  disableEntranceAnimation?: boolean;
}>`
  position: absolute;
  width: 100%;
  align-items: center;
  transition: display
    ${({ theme }) =>
      `${theme.transition.duration.medium} ${theme.transition.timing.inOut}`};
  ${({ disableEntranceAnimation }) =>
    !disableEntranceAnimation &&
    css`
      ${slideInAnimation}
    `}

  &.${AnimationType.EXITING} {
    ${slideOutAnimation}
  }
`;

// This component is used for all steps after ConfirmModalState.REVIEWING
export type PendingConfirmModalState = Extract<
  ConfirmModalState,
  | ConfirmModalState.APPROVING_TOKEN
  | ConfirmModalState.PERMITTING
  | ConfirmModalState.PENDING_CONFIRMATION
>;

interface PendingModalStep {
  title: ReactNode;
  subtitle?: ReactNode;
  label?: ReactNode;
  logo?: ReactNode;
  button?: ReactNode;
}

interface PendingModalContentProps {
  steps: PendingConfirmModalState[];
  currentStep: PendingConfirmModalState;
  trade?: InterfaceTrade;
  swapTxHash?: string;
  hideStepIndicators?: boolean;
  tokenApprovalPending?: boolean;
  formattedAmountsLimit?: any;
}

interface ContentArgs {
  step: PendingConfirmModalState;
  approvalCurrency?: Currency;
  trade?: InterfaceTrade;
  swapConfirmed: boolean;
  swapPending: boolean;
  tokenApprovalPending: boolean;
  swapTxHash?: string;
  chainId?: number;
  pathname?: any;
  formattedAmountsLimit?: any;
}

function getContent(args: ContentArgs): PendingModalStep {
  const {
    step,
    approvalCurrency,
    swapConfirmed,
    swapPending,
    tokenApprovalPending,
    trade,
    swapTxHash,
    chainId,
    pathname,
    formattedAmountsLimit,
  } = args;
  switch (step) {
    case ConfirmModalState.APPROVING_TOKEN:
      return {
        title: t`Enable spending ${
          approvalCurrency?.symbol ?? "this token"
        } on Elektrik`,
        subtitle: (
          <ExternalLink href="https://support.uniswap.org/hc/en-us/articles/8120520483085">
            {/* <div>Why is this required?</div> */}
          </ExternalLink>
        ),
        label: tokenApprovalPending ? t`Pending...` : t`Confirmation needed`,
        button: AllowanceState.REQUIRED && <div>Need to approve more</div>,
      };
    case ConfirmModalState.PERMITTING:
      return {
        title: t`Allow ${
          approvalCurrency?.symbol ?? "this token"
        } to be used for swapping`,
        subtitle: (
          <ExternalLink href="https://support.uniswap.org/hc/en-us/articles/8120520483085">
            {/* <div>Why is this required?</div> */}
          </ExternalLink>
        ),
        label: t`Confirmation needed`,
      };
    case ConfirmModalState.PENDING_CONFIRMATION:
      const isLimitOrder = pathname === "/limit";
      return {
        title: isLimitOrder
          ? t`Confirm Limit Order`
          : swapPending
            ? t`Transaction submitted`
            : swapConfirmed
              ? t`Success`
              : t`Confirm Swap`,
        subtitle: trade ? (
          <TradeSummary
            trade={trade}
            formattedAmountsLimit={formattedAmountsLimit}
          />
        ) : null,
        label:
          swapConfirmed && swapTxHash && chainId ? (
            <></>
          ) : !swapPending ? (
            t`Confirmation needed`
          ) : null,
      };
  }
}

export function PendingModalContent({
  steps,
  currentStep,
  trade,
  swapTxHash,
  hideStepIndicators,
  tokenApprovalPending = false,
  formattedAmountsLimit,
  allowance,
  onReallowance,
  onDismiss
}: any) {
  const { pathname } = useLocation();
  const { chainId } = useWeb3React();
  const swapConfirmed = useIsTransactionConfirmed(swapTxHash);
  const swapPending = swapTxHash !== undefined && !swapConfirmed;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let timer: any;

    if (swapConfirmed) {
      setIsLoading(true); // Start showing loader when swap is confirmed

      // Simulate loading completion after 3 seconds
      timer = setTimeout(() => {
        // setIsLoading(false);
      }, 3000);
    }

    // Clean up timer
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [swapConfirmed]);

  const { label, button } = getContent({
    step: currentStep,
    approvalCurrency: trade?.inputAmount.currency,
    swapConfirmed,
    swapPending,
    tokenApprovalPending,
    swapTxHash,
    trade,
    chainId,
    pathname,
    formattedAmountsLimit,
  });
  const currentStepContainerRef = useRef<HTMLDivElement>(null);
  useUnmountingAnimation(currentStepContainerRef, () => AnimationType.EXITING);
  const useActivePopups = useAddPopup();

  if (steps.length === 0) {
    return null;
  }
  // On mainnet, we show the success icon once the tx is sent, since it takes longer to confirm than on L2s.
  const showSuccess =
    swapConfirmed ||
    (swapPending && chainId === SupportedChainId.LIGHTLINK_PHOENIX_MAINNET) ||
    SupportedChainId.LIGHTLINK_PEGASUS_TESTNET;

  return (
    <PendingModalContainer gap="lg" style={{ margin: "0px 0px 0px 0px" }}>
      <Row>
        <ThemedText.DeprecatedLargeHeader>
          Transaction in Progress
        </ThemedText.DeprecatedLargeHeader>
      </Row>
      <Row>
        <div style={{ color: "#5D7279", fontWeight: 300, fontSize: 12 }}>
          
          Your transaction is currently in progress. Please review the details below and confirm the transaction in your wallet.
           The transaction will be completed once all necessary approvals are received.
        </div>
      </Row>
      <HeaderContainer gap="md" $disabled={tokenApprovalPending || swapPending}>
        <AnimationWrapper>
          {steps.map((step: any) => {
            const { title, subtitle } = getContent({
              step,
              approvalCurrency: trade?.inputAmount.currency,
              swapConfirmed,
              swapPending,
              tokenApprovalPending,
              swapTxHash,
              trade,
              pathname,
              formattedAmountsLimit,
            });
            return (
              Boolean(step === currentStep) && (
                <StepTitleAnimationContainer
                  disableEntranceAnimation={steps[0] === currentStep}
                  gap="md"
                  key={step}
                  ref={
                    step === currentStep ? currentStepContainerRef : undefined
                  }
                >
                  {title === "Success" ? (
                    <ThemedText.SubHeaderLarge
                      textAlign="center"
                      data-testid="pending-modal-content-title"
                    >
                      <GradientGreen style={{ fontSize: 20 }}>
                        Success! Transaction Completed
                      </GradientGreen>
                    </ThemedText.SubHeaderLarge>
                  ) : (
                    <ThemedText.SubHeaderLarge
                      textAlign="center"
                      data-testid="pending-modal-content-title"
                    >
                      {title === "Confirm Swap" ? "" : title}
                    </ThemedText.SubHeaderLarge>
                  )}
                  <ThemedText.LabelSmall textAlign="center" style={{width:'75%'}}>
                    {subtitle}
                  </ThemedText.LabelSmall>
                </StepTitleAnimationContainer>
              )
            );
          })}
        </AnimationWrapper>

        < Row justify="center" marginTop="0px" minHeight="24px">
          <ThemedText.Caption color="textPrimary" >{label}</ThemedText.Caption>
        </Row>
       {!swapConfirmed && <div
          style={{
            background:
              "linear-gradient(191deg, rgba(161, 238, 255, 0.20) 5.4%, rgba(2, 154, 240, 0.20) 92.07%)",
            padding: "5px 10px",
            borderRadius: "12px",
            height: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width:'100%' ,
            textAlign:'center'
          }}
        >
          <GradientText style={{ fontWeight: 500, fontSize: 14, }}>
            Sign Transaction in Your Wallet
          </GradientText>
        </div>}

        {/* {isLoading && (
          <RowBetween style={{marginBottom:0}} >
            <div style={{ fontSize: 14, fontWeight: 500 }}>
              Estimation Time:
            </div>
            <GradientGreen>
              {isLoading ? "3 sec" : "Success! Transaction Completed"}
            </GradientGreen>
          </RowBetween>
        )} */}
        {/* {<div>{isLoading && <BarLoader />}</div>} */}
        {!swapPending && swapConfirmed && ( 
          <div style={{width:'100%'}}>
            {" "}
            <ButtonLight
              onClick={()=>onDismiss()}
              fontWeight={600}
              fontSize={15}
              style={{
                background: `linear-gradient(270deg, #23ff95 12.87%, #03cd54 91.25%)`,
                color: "#000",
                border: "none",
                marginTop:0
              }}
            >
              <div>Back to Swap</div>
            </ButtonLight>
          </div>
        )}
      </HeaderContainer>
      {button && (
        <Row
          justify="center"
          onClick={onReallowance}
          color={"#5d7279"}
          fontWeight={600}
          style={{ cursor: "pointer" }}
        >
          {button}
        </Row>
      )}
      {!hideStepIndicators && (
        <Row gap="14px" justify="center" style={{ padding: 5 }}>
          {steps.map((_: any, i: any) => {
            return (
              <StepCircle key={i} active={steps.indexOf(currentStep) === i} />
            );
          })}
        </Row>
      )}
    </PendingModalContainer>
  );
}
