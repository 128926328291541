import { Percent } from '@uniswap/sdk-core'
import { InterfaceTrade } from 'state/routing/types'

export function tradeMeaningfullyDiffers(tradeA: InterfaceTrade, tradeB: InterfaceTrade, slippage: Percent): boolean {
  return (
    tradeA.tradeType !== tradeB.tradeType ||
    !tradeA.inputAmount.currency.equals(tradeB.inputAmount.currency) ||
    !tradeA.outputAmount.currency.equals(tradeB.outputAmount.currency) ||
    tradeB.executionPrice.lessThan(tradeA.worstExecutionPrice(slippage))
  )
}
