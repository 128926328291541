import Row from "components/Row"
import styled from "styled-components"

export const Option = styled(Row)<{ isActive: boolean }>`
  width: auto;
  cursor: pointer;
  padding: 6px 12px;
  text-align: center;
  gap: 4px;
  font-size: 15px;
  border-radius: 6px;
  margin-right: 5px; 
  background: ${({ isActive, theme }) => (isActive ? 'linear-gradient(191deg, #39484B 5.4%, #1A3848 92.07%)' : '#1F2831')};
  color: ${({ isActive, theme }) => (isActive ? '#53C5F8' : '#FFF')};
  pointer-events: ${({ isActive }) => isActive ? 'none' : 'auto'};
`;

export const Switch = styled(Row)`
  width: auto;
  padding: 4px;  
`
 