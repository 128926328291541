import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import {
  StakingClient,
  voteEscrowClient,
} from "graphql/thegraph/apollo";

const ISPOOLWHITELISTED = gql`
  query MyQuery($v3Pool: String!) {
    pools(where: { v3Pool: $v3Pool }) {
      id
    }
  }
`;

export const useIsPoolWhitelisted = (poolAddress: any) => {
  const { data } = useQuery(ISPOOLWHITELISTED, {
    variables: { v3Pool: poolAddress },
    client: StakingClient,
  });

  return {
    data,
  };
};
