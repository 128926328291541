import { ButtonRadioChecked } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { ThemedText } from 'theme'
import { FEE_AMOUNT_DETAIL } from './shared'
import { ResponsiveText } from './styled'
import { FeeOptionProps } from 'types'





export function FeeOption({ feeAmount, active, poolState, distributions, onClick }: FeeOptionProps) {
  return (
    <ButtonRadioChecked active={active} onClick={onClick}>
      <AutoColumn gap="sm" justify="flex-start">
        <AutoColumn justify="flex-start" gap="6px">
          <ResponsiveText>
            <div>{FEE_AMOUNT_DETAIL[feeAmount].label}%</div>
          </ResponsiveText>
          <ThemedText.DeprecatedMain fontWeight={400} fontSize="12px" textAlign="left">
            {FEE_AMOUNT_DETAIL[feeAmount].description}
          </ThemedText.DeprecatedMain>
        </AutoColumn>
      </AutoColumn>
    </ButtonRadioChecked>
  )
}
