import * as Sentry from '@sentry/react'
import { useWeb3React } from '@web3-react/core'
import {  SmallButtonPrimary } from 'components/Button'
import { PropsWithChildren, useState } from 'react'
import { isSentryEnabled } from 'utils/env'
import { CopyToClipboard, ExternalLink, ThemedText } from '../../theme'
import { Column } from '../Column'
import { BodyWrapper, Code, CodeBlockWrapper, CodeTitle, CopyIcon, FallbackWrapper, Separator, ShowMoreButton, ShowMoreIcon, SmallButtonLight, StretchedRow } from './styled'
import { useIsMobile } from 'utils/common'



const Fallback = ({ error, eventId }: { error: Error; eventId: string | null }) => {
  const [isExpanded, setExpanded] = useState(false)
  const isMobile = useIsMobile()

  // @todo: ThemedText components should be responsive by default
  const [Title, Description] = isMobile
    ? [ThemedText.HeadlineSmall, ThemedText.BodySmall]
    : [ThemedText.HeadlineLarge, ThemedText.BodySecondary]

  const showErrorId = isSentryEnabled() && eventId

  const showMoreButton = (
    <ShowMoreButton onClick={() => setExpanded((s) => !s)}>
      <ThemedText.Link color="textSecondary">
        <div>{isExpanded ? 'Show less' : 'Show more'}</div>
      </ThemedText.Link>
      <ShowMoreIcon $isExpanded={isExpanded} secondaryWidth="20" secondaryHeight="20" />
    </ShowMoreButton>
  )

  const errorDetails = error.stack || error.message

  return (
    <FallbackWrapper>
      <BodyWrapper>
        <Column gap="xl">
          {showErrorId ? (
            <>
              <Column gap="sm">
                <Title textAlign="center">
                  <div>Something went wrong</div>
                </Title>
                <Description textAlign="center" color="textSecondary">
                  <div>
                    Sorry, an error occured while processing your request. If you request support, be sure to provide
                    your error ID.
                  </div>
                </Description>
              </Column>
              <CodeBlockWrapper>
                <CodeTitle>
                  <ThemedText.SubHeader>
                    <div>Error ID: {eventId}</div>
                  </ThemedText.SubHeader>
                  <CopyToClipboard toCopy={eventId}>
                    <CopyIcon />
                  </CopyToClipboard>
                </CodeTitle>
                <Separator />
                {isExpanded && (
                  <>
                    <Code>{errorDetails}</Code>
                    <Separator />
                  </>
                )}
                {showMoreButton}
              </CodeBlockWrapper>
            </>
          ) : (
            <>
              <Column gap="sm">
                <Title textAlign="center">
                  <div>Something went wrong</div>
                </Title>
                <Description textAlign="center" color="textSecondary">
                  <div>
                    Sorry, an error occured while processing your request. If you request support, be sure to copy the
                    details of this error.
                  </div>
                </Description>
              </Column>
              <CodeBlockWrapper>
                <CodeTitle>
                  <ThemedText.SubHeader>Error details</ThemedText.SubHeader>
                  <CopyToClipboard toCopy={errorDetails}>
                    <CopyIcon />
                  </CopyToClipboard>
                </CodeTitle>
                <Separator />
                <Code>{errorDetails.split('\n').slice(0, isExpanded ? undefined : 4)}</Code>
                <Separator />
                {showMoreButton}
              </CodeBlockWrapper>
            </>
          )}
          <StretchedRow>
            <SmallButtonPrimary onClick={() => window.location.reload()}>
              <div>Reload the app</div>
            </SmallButtonPrimary>
            <ExternalLink id="get-support-of-docs" href="https://docs.elektrik.network/protocol-overview/welcome-to-elektrik" target="_blank">
              <SmallButtonLight>
                <div>Get support</div>
              </SmallButtonLight>
            </ExternalLink>
          </StretchedRow>
        </Column>
      </BodyWrapper>
    </FallbackWrapper>
  )
}

async function updateServiceWorker(): Promise<ServiceWorkerRegistration> {
  const ready = await navigator.serviceWorker.ready
  // the return type of update is incorrectly typed as Promise<void>. See
  // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration/update
  return ready.update() as unknown as Promise<ServiceWorkerRegistration>
}

const updateServiceWorkerInBackground = async () => {
  try {
    const registration = await updateServiceWorker()

    // We want to refresh only if we detect a new service worker is waiting to be activated.
    // See details about it: https://web.dev/service-worker-lifecycle/
    if (registration?.waiting) {
      await registration.unregister()

      // Makes Workbox call skipWaiting().
      // For more info on skipWaiting see: https://web.dev/service-worker-lifecycle/#skip-the-waiting-phase
      registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    }
  } catch (error) {
    // console.error('Failed to update service worker', error)
  }
}

export default function ErrorBoundary({ children }: PropsWithChildren): JSX.Element {
  const { chainId } = useWeb3React()
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, eventId }) => <Fallback error={error} eventId={eventId} />}
      beforeCapture={(scope) => {
        scope.setLevel('fatal')
        scope.setTag('chain_id', chainId)
      }}
      onError={() => {
        updateServiceWorkerInBackground()
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}
