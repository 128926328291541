import { sendAnalyticsEvent } from "@uniswap/analytics";
import { InterfaceElementName, SwapEventName } from "@uniswap/analytics-events";
import { LoadingOpacityContainer } from "components/Loader/styled";
import { RowFixed } from "components/Row";
import { MouseoverTooltip } from "components/Tooltip";
import { InterfaceTrade } from "state/routing/types";
import styled from "styled-components/macro";
import { ThemedText } from "theme";

import { ReactComponent as GasIcon } from "../../assets/images/gas-icon.svg";
import SwapRoute from "./SwapRoute";

import { SupportedChainId, isPhoenixChain } from "constants/chains";
import { TooltipSize } from "components/Tooltip/styled";
import { StyledGasIconProps } from "types";
import { truncateFormatPriceImpact } from "components/CurrencyInputPanel/FiatValue";

const StyledGasIcon = styled(GasIcon)<StyledGasIconProps>`
  margin-right: 4px;
  height: 18px;
  & > * {
    stroke: ${({ theme, isFreeTransactions }) =>
      isFreeTransactions ? theme.textFreeTransaction : theme.textTertiary};
  }
`;

export default function GasEstimateTooltip({
  trade,
  loading,
  disabled,
  chainId,
}: {
  trade: InterfaceTrade; // dollar amount in active chain's stablecoin
  loading: boolean;
  disabled?: boolean;
  chainId?: number;
}) {
  const formattedGasPriceString = trade?.gasUseEstimateUSD
    ? trade.gasUseEstimateUSD === "0.00"
      ? "<$0.01"
      : "$" + trade.gasUseEstimateUSD
    : undefined;

  const isPhoenix = isPhoenixChain(chainId);
  let cleanedGasPriceString: any =
    formattedGasPriceString &&
    formattedGasPriceString.replace(/[^0-9.-]+/g, "");
  let parsedGasPrice: any = parseFloat(cleanedGasPriceString);

  let formattedGasPrice = !isNaN(parsedGasPrice)
    ? parsedGasPrice.toFixed(2)
    : "Invalid input";
  return (
    <MouseoverTooltip text={"Gas Fee"}>
      <LoadingOpacityContainer
        $loading={loading}
        style={{ padding: "8px 15px", borderRadius: 10, background: "#121B1E" }}
      >
        <RowFixed>
          <StyledGasIcon isFreeTransactions={isPhoenix} />
          {isPhoenix ? (
            <ThemedText.FreeTransaction color="textFreeTransaction">
              $0
            </ThemedText.FreeTransaction>
          ) : (
            <ThemedText.BodySmall
              style={{ fontSize: 14, fontWeight: 500, color: "#00EA96" }}
            >
              ${formattedGasPrice}
            </ThemedText.BodySmall>
          )}
        </RowFixed>
      </LoadingOpacityContainer>
    </MouseoverTooltip>
  );
}
