import JSBI from 'jsbi'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { useMemo } from 'react'

import { useContract } from './useContract'
import useENSAddress from './useENSAddress'

import CHAIN_DATA_ABI from '../abis/chain-data.json'

export default function useGasPrice(): JSBI | undefined {
  const { address } = useENSAddress('fast-gas-gwei.data.eth')
  const contract = useContract(address ?? undefined, CHAIN_DATA_ABI, false)

  const resultStr = useSingleCallResult(contract, 'latestAnswer').result?.[0]?.toString()
  return useMemo(() => (typeof resultStr === 'string' ? JSBI.BigInt(resultStr) : undefined), [resultStr])
}
