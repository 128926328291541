import { Token } from "@uniswap/sdk-core";
import { SupportedChainId } from "constants/chains";
import { DEFAULT_LIST_OF_LISTS } from "constants/lists";
import uriToHttp from "lib/utils/uriToHttp";
import Vibrant from "node-vibrant/lib/bundle.js";
import { shade } from "polished";
import { useEffect, useState } from "react";
import { useAllLists, useCombinedTokenMapFromUrls } from "state/lists/hooks";
import { WrappedTokenInfo } from "state/lists/wrappedTokenInfo";
import { hex } from "wcag-contrast";

async function getColorFromToken(
  token: Token,
  uri: string
): Promise<string | null> {
  if (!(token instanceof WrappedTokenInfo)) {
    return null;
  }

  const wrappedToken = token as WrappedTokenInfo;
  const { address } = wrappedToken;
  let { logoURI } = wrappedToken;
  if (!logoURI) {
    if (token.chainId !== SupportedChainId.MAINNET) {
      return null;
    } else {
      logoURI = uri;
    }
  }

  try {
    return await getColorFromUriPath(uri);
  } catch (e) {
    if (logoURI === uri) {
      return null;
    }

    try {
      logoURI = uri;
      return await getColorFromUriPath(logoURI);
    } catch (error) {
      console.warn(`Unable to load logoURI (${token.symbol}): ${logoURI}`);
      return null;
    }
  }
}

async function getColorFromUriPath(uri: string): Promise<string | null> {
  const formattedPath = uriToHttp(uri)[0];

  let palette;

  try {
    palette = await Vibrant.from(formattedPath).getPalette();
  } catch (err) {
    return null;
  }
  if (!palette?.Vibrant) {
    return null;
  }

  let detectedHex = palette.Vibrant.hex;
  let AAscore = hex(detectedHex, "#FFF");
  while (AAscore < 3) {
    detectedHex = shade(0.005, detectedHex);
    AAscore = hex(detectedHex, "#FFF");
  }

  return detectedHex;
}

export function useColor(token?: Token) {
  const [color, setColor] = useState("#2172E5");

  const lists = useAllLists();

  let logoUri = "";

  for (const list in lists) {
    const selectedToken = lists[list].current?.tokens.find((t) => {
      return t.address.toLowerCase() === token?.address.toLowerCase();
    });
    logoUri = selectedToken?.logoURI ? selectedToken?.logoURI : "";
  }

  useEffect(() => {
    let stale = false;

    if (token) {
      getColorFromToken(token, logoUri).then((tokenColor) => {
        if (!stale && tokenColor !== null) {
          setColor(tokenColor);
        }
      });
    }

    return () => {
      stale = true;
      setColor("#2172E5");
    };
  }, [token]);

  return color;
}
