import { Currency } from "@uniswap/sdk-core";
import { useWeb3React } from "@web3-react/core";
import { getChainInfo } from "constants/chainInfo";
import { SupportedChainId, SupportedL2ChainId } from "constants/chains";
import useCurrencyLogoURIs from "lib/hooks/useCurrencyLogoURIs";
import { ReactNode, useCallback, useState } from "react";
import { AlertCircle, ArrowUpCircle, CheckCircle } from "react-feather";
import {
  useIsTransactionConfirmed,
  useTransaction,
} from "state/transactions/hooks";
import { useTheme } from "styled-components/macro";
import { isL2ChainId } from "utils/chains";
import { ExplorerDataType, getExplorerLink } from "utils/getExplorerLink";

import Circle from "../../assets/images/blue-loader.svg";
import { ExternalLink, ThemedText } from "../../theme";
import { CloseIcon, CustomLightSpinner } from "../../theme";
import { TransactionSummary } from "../AccountDetails/TransactionSummary";
import { ButtonLight, ButtonPrimary } from "../Button";
import { AutoColumn } from "../Column";
import Modal from "../Modal";
import Row, { RowBetween, RowFixed } from "../Row";
import AnimatedConfirmation from "./AnimatedConfirmation";
import {
  BottomSection,
  ConfirmationModalContentWrapper,
  ConfirmedIcon,
  StyledLogo,
  Wrapper,
} from "./styled";
import { DEFAULT_ADD_IN_RANGE_SLIPPAGE_TOLERANCE } from "constants/common";
import SettingsTab from "components/Settings";

function ConfirmationPendingContent({
  onDismiss,
  pendingText,
  inline,
}: {
  onDismiss: () => void;
  pendingText: ReactNode;
  inline?: boolean; // not in modal
}) {
  return (
    <Wrapper>
      <AutoColumn gap="md">
        {!inline && (
          <RowBetween>
            <div />
            <CloseIcon onClick={onDismiss} />
          </RowBetween>
        )}
        <ConfirmedIcon inline={inline}>
          <CustomLightSpinner
            src={Circle}
            alt="loader"
            size={inline ? "40px" : "90px"}
          />
        </ConfirmedIcon>
        <AutoColumn gap="md" justify="center">
          <ThemedText.SubHeaderLarge color="textPrimary" textAlign="center">
            <div>Waiting for confirmation</div>
          </ThemedText.SubHeaderLarge>
          <ThemedText.SubHeader color="textPrimary" textAlign="center">
            {pendingText}
          </ThemedText.SubHeader>
          <ThemedText.SubHeaderSmall
            color="textSecondary"
            textAlign="center"
            marginBottom="12px"
          >
            <div>Confirm this transaction in your wallet</div>
          </ThemedText.SubHeaderSmall>
        </AutoColumn>
      </AutoColumn>
    </Wrapper>
  );
}
function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
  currencyToAdd,
  inline,
}: {
  onDismiss: () => void;
  hash?: string;
  chainId: number;
  currencyToAdd?: Currency;
  inline?: boolean; // not in modal
}) {
  const theme = useTheme();

  const { connector } = useWeb3React();

  const token = currencyToAdd?.wrapped;
  const logoURL = useCurrencyLogoURIs(token)[0];

  const [success, setSuccess] = useState<boolean | undefined>();

  const addToken = useCallback(() => {
    if (!token?.symbol || !connector.watchAsset) return;
    connector
      .watchAsset({
        address: token.address,
        symbol: token.symbol,
        decimals: token.decimals,
        image: logoURL,
      })
      .then(() => setSuccess(true))
      .catch(() => setSuccess(false));
  }, [connector, logoURL, token]);

  return (
    <Wrapper>
      <AutoColumn>
        {!inline && (
          <RowBetween>
            <div />
            <CloseIcon onClick={onDismiss} />
          </RowBetween>
        )}
        <ConfirmedIcon inline={inline}>
          <ArrowUpCircle
            strokeWidth={1}
            size={inline ? "40px" : "75px"}
            color={theme.accentActive}
          />
        </ConfirmedIcon>
        <ConfirmationModalContentWrapper gap="md" justify="center">
          <ThemedText.MediumHeader textAlign="center">
            <div>Transaction submitted</div>
          </ThemedText.MediumHeader>
          {currencyToAdd && connector.watchAsset && (
            <ButtonLight
              mt="12px"
              padding="6px 12px"
              width="fit-content"
              onClick={addToken}
            >
              {!success ? (
                <RowFixed>
                  <div>Add {currencyToAdd.symbol}</div>
                </RowFixed>
              ) : (
                <RowFixed>
                  <div>Added {currencyToAdd.symbol} </div>
                  <CheckCircle
                    size="16px"
                    stroke={theme.accentSuccess}
                    style={{ marginLeft: "6px" }}
                  />
                </RowFixed>
              )}
            </ButtonLight>
          )}
          <ButtonPrimary
            onClick={onDismiss}
            style={{ margin: "20px 0 0 0" }}
            data-testid="dismiss-tx-confirmation"
          >
            <ThemedText.HeadlineSmall color={theme.accentTextLightPrimary}>
              {inline ? <div>Return</div> : <div>Close</div>}
            </ThemedText.HeadlineSmall>
          </ButtonPrimary>
          {chainId && hash && (
            <ExternalLink
              href={getExplorerLink(
                chainId,
                hash,
                ExplorerDataType.TRANSACTION
              )}
            >
              <ThemedText.Link color={theme.accentAction}>
                <div>
                  View on{" "}
                  {chainId === SupportedChainId.MAINNET
                    ? "Etherscan"
                    : "Block Explorer"}
                </div>
              </ThemedText.Link>
            </ExternalLink>
          )}
        </ConfirmationModalContentWrapper>
      </AutoColumn>
    </Wrapper>
  );
}

export function ConfirmationModalContent({
  title,
  bottomContent,
  onDismiss,
  topContent,
  headerContent,
  isShowSetting = false,
}: {
  title: ReactNode;
  onDismiss: () => void;
  topContent: () => ReactNode;
  bottomContent?: () => ReactNode;
  headerContent?: () => ReactNode;
  isShowSetting?: any;
}) {
  const { chainId } = useWeb3React();
  console.log("OOOQQ", title);
  return (
    <Wrapper>
      <AutoColumn gap="sm">
        <Row>
          <Row justify="center" marginLeft="24px">
         
            <ThemedText.DeprecatedMediumHeader>
              {title}
            </ThemedText.DeprecatedMediumHeader>
            {isShowSetting && (
              <div style={{position:'relative', left:95, top:2}}>
                <SettingsTab
                  autoSlippage={DEFAULT_ADD_IN_RANGE_SLIPPAGE_TOLERANCE}
                  chainId={chainId}
                  isButton={false}
                />
              </div>
            )}
          </Row>
        </Row>
        {topContent()}
      </AutoColumn>
      {bottomContent && (
        <BottomSection gap="12px">{bottomContent()}</BottomSection>
      )}
    </Wrapper>
  );
}

function L2Content({
  onDismiss,
  chainId,
  hash,
  pendingText,
  inline,
}: {
  onDismiss: () => void;
  hash?: string;
  chainId: SupportedL2ChainId;
  currencyToAdd?: Currency;
  pendingText: ReactNode;
  inline?: boolean; // not in modal
}) {
  const theme = useTheme();
  const transaction = useTransaction(hash);
  const confirmed = useIsTransactionConfirmed(hash);
  const transactionSuccess = transaction?.receipt?.status === 1;

  // convert unix time difference to seconds
  const secondsToConfirm = transaction?.confirmedTime
    ? (transaction.confirmedTime - transaction.addedTime) / 1000
    : undefined;

  const info = getChainInfo(chainId);

  return (
    <Wrapper>
      <AutoColumn>
        {!inline && (
          <RowBetween mb="16px">
            {/* <Badge>
              <RowFixed>
                <StyledLogo src={info.logoUrl} style={{ margin: '0 8px 0 0' }} />
                {info.label}
              </RowFixed>
            </Badge> */}
            <div></div>
            <CloseIcon onClick={onDismiss} />
          </RowBetween>
        )}
        <ConfirmedIcon inline={inline}>
          {confirmed ? (
            transactionSuccess ? (
              // <CheckCircle strokeWidth={1} size={inline ? '40px' : '90px'} color={theme.accentSuccess} />
              <AnimatedConfirmation />
            ) : (
              <AlertCircle
                strokeWidth={1}
                size={inline ? "40px" : "90px"}
                color={theme.accentFailure}
              />
            )
          ) : (
            <CustomLightSpinner
              src={Circle}
              alt="loader"
              size={inline ? "40px" : "90px"}
            />
          )}
        </ConfirmedIcon>
        <AutoColumn gap="md" justify="center">
          <ThemedText.SubHeaderLarge textAlign="center">
            {!hash ? (
              <div>Confirm transaction in wallet</div>
            ) : !confirmed ? (
              <div>Transaction Submitted</div>
            ) : transactionSuccess ? (
              <div>Success</div>
            ) : (
              <div>Error</div>
            )}
          </ThemedText.SubHeaderLarge>
          <ThemedText.BodySecondary textAlign="center">
            {transaction ? (
              <TransactionSummary info={transaction.info} />
            ) : (
              pendingText
            )}
          </ThemedText.BodySecondary>
          {chainId && hash ? (
            <ExternalLink
              href={getExplorerLink(
                chainId,
                hash,
                ExplorerDataType.TRANSACTION
              )}
            >
              <ThemedText.SubHeaderSmall color={theme.accentAction}>
                <div>View on Explorer</div>
              </ThemedText.SubHeaderSmall>
            </ExternalLink>
          ) : (
            <div style={{ height: "17px" }} />
          )}
          <ThemedText.SubHeaderSmall
            color={theme.textTertiary}
            marginTop="20px"
          >
            {!secondsToConfirm ? (
              <div style={{ height: "24px" }} />
            ) : transactionSuccess && (
              <div>
                <div>Transaction completed in </div>
                <span
                  style={{
                    fontWeight: 500,
                    marginLeft: "4px",
                    color: theme.textPrimary,
                  }}
                >
                  {secondsToConfirm} seconds 🎉
                </span>
              </div>
            )}
          </ThemedText.SubHeaderSmall>
          <ButtonPrimary onClick={onDismiss} style={{ margin: "4px 0 0 0" }}>
            <ThemedText.SubHeaderLargeDeprecated style={{ color: "#000000" }}>
              {inline ? <div>Return</div> : <div>Close</div>}
            </ThemedText.SubHeaderLargeDeprecated>
          </ButtonPrimary>
        </AutoColumn>
      </AutoColumn>
    </Wrapper>
  );
}

interface ConfirmationModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  hash?: string;
  reviewContent: () => ReactNode;
  attemptingTxn: boolean;
  pendingText: ReactNode;
  currencyToAdd?: Currency;
}

export default function TransactionConfirmationModal({
  isOpen,
  onDismiss,
  attemptingTxn,
  hash,
  pendingText,
  reviewContent,
  currencyToAdd,
}: ConfirmationModalProps) {
  const { chainId } = useWeb3React();

  if (!chainId) return null;

  // confirmation screen
  return (
    <Modal
      isOpen={isOpen}
      $scrollOverlay={true}
      onDismiss={onDismiss}
      maxHeight={90}
    >
      {isL2ChainId(chainId) && (hash || attemptingTxn) ? (
        <L2Content
          chainId={chainId}
          hash={hash}
          onDismiss={onDismiss}
          pendingText={pendingText}
        />
      ) : attemptingTxn ? (
        <ConfirmationPendingContent
          onDismiss={onDismiss}
          pendingText={pendingText}
        />
      ) : hash ? (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={onDismiss}
          currencyToAdd={currencyToAdd}
        />
      ) : (
        reviewContent()
      )}
    </Modal>
  );
}
