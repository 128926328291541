import styled from "styled-components/macro";
import { BREAKPOINTS } from "theme";
import { flexRowNoWrap } from "theme/styles";
import { Z_INDEX } from "theme/zIndex";
import { BodyWrapperProps } from "types";

import { ReactComponent as TwitterX } from "../assets/svg/twitterx.svg";
import { ReactComponent as GithubX } from "../assets/svg/githubx.svg";
import { ReactComponent as DiscordX } from "../assets/svg/discordx.svg";

import bgImage from '../assets/images/bg1.png';

import { ReactComponent as PlaneX } from "../assets/svg/Planex.svg";

export const MobileBottomBar = styled.div`
  position: fixed; /* Set the position to fixed */
  bottom: 0; /* Position the footer at the bottom of the viewport */
  left: 0; /* Align it to the left */
  width: 100%; /* Make it full width */
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap; 
  padding: 4px 8px;
  height: 60px;
  background: ${({ theme }) => theme.black};
  padding-left: 100px;  
  padding-right: 100px;
  z-index: 1;

  @media (max-width: 870px) {
    padding-left: 8px;  /* Adjust padding for smaller screens */
    padding-right: 8px; /* Adjust padding for smaller screens */
  }

  @media (max-width: 768px) {
    height: 365px;
    flex-direction: column; /* Stack elements vertically on small screens */
    justify-content: center; /* Adjust this as needed for your design */
    padding-left: 8px;  /* Adjust padding for smaller screens */
    padding-right: 8px; /* Adjust padding for smaller screens */
    align-items: center; /* Centers items horizontally */
  }
`;

export const HeaderWrapper = styled.div<{ transparent?: boolean }>`
  ${flexRowNoWrap};
  border-bottom: ${({ theme, transparent }) =>
    !transparent ? `1px solid ${theme.backgroundOutline}` : "none"};
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: ${Z_INDEX.hover};
`;

export const LeftSideFooter = styled.div`
display:flex;
alignItems: 'center';
justifyContent: 'flex-start';
@media (max-width: 768px) {
  justify-content: center; // Center content on mobile
  margin: 20px 0 20px 0;
}
`


export const RightSideFooter = styled.div`
display:flex;
alignItems: 'center';
justifyContent: 'flex-end';
@media (max-width: 768px) {
  flex-direction: column;
  justify-content: center; // Center content on mobile
}
`

export const SocialIconsContainer = styled.div` 
display:flex;
flex-direction:row;
align-items:center;


@media (max-width: 768px) {
  
}
`


export const Banner = styled.div`
  z-index: ${Z_INDEX.banner};
  height: 15px;
  background: linear-gradient(90.8deg, #a2eeff 7.28%, #029af0 89.82%);
  width: 100%;

  display: flex;
  align-items: center;
  padding: 3;
  font-size: 12px @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    display: flex;
  }
  @media screen and (max-width: 414px) {
    display: flex;
  }
  @media screen and (max-width: 414px) {
    display: flex;
  }
`;

export const BannerText = styled.div`
  margin-right:30px
    font-size: 12px;
    color: ${({ theme }) => theme.black};
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
     
      font-size: 5px;
    }
    @media screen and (max-width: 400px) {

      font-size: 5px;
    }
    @media screen and (max-width: 820px) {
      font-size: 5px;
    }

    
  `;
export const TimerWrapper = styled.div`
  height: 28px;
  z-index: ${Z_INDEX.banner};
  width: 100%;
  background: linear-gradient(90.8deg, #a2eeff 7.28%, #029af0 89.82%);
  align-items: center;
  display: flex;
  overflow-y: hidden;
`;

export const Shadow = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  width: 432.198px;
  height: 432.198px;
  flex-shrink: 0;

  border-radius: 432.198px;
  opacity: 0.3;
  background: var(
    --Gradient-1,
    linear-gradient(191deg, #a1eeff 5.4%, #029af0 92.07%)
  );
  filter: blur(250px);
`;

export const SpanText = styled.span``;

export const BodyWrapper = styled.main<BodyWrapperProps>`
  position: relative;
  margin-top: ${({ $margin }) => $margin ?? "0px"};
  max-width: ${({ $maxWidth }) => $maxWidth ?? "420px"};
  width: 100%; 
  border-radius: 10px;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  z-index: ${Z_INDEX.default};
  font-feature-settings:
    "ss01" on,
    "ss02" on,
    "cv01" on,
    "cv03" on;
`;

export const BodyWrapperDiv = styled.div`
  display: flex;
  background-image: url(${bgImage});
  background-size: cover;
  background-position: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: ${({ theme }) => theme.navHeight}px 0px 20px 0px;
  align-items: center;
  flex: 1; 
`;

export const TwitterXIcon = styled(TwitterX)<{ size?: number; fill?: string }>`
  height: ${({ size }) => (size ? size + "px" : "32px")};
  width: ${({ size }) => (size ? size + "px" : "32px")};
  fill: ${({ fill }) => fill ?? "#98A1C0"};
  opacity: 1;
`;

export const GithubXIcon = styled(GithubX)<{ size?: number; fill?: string }>`
  height: ${({ size }) => (size ? size + "px" : "32px")};
  width: ${({ size }) => (size ? size + "px" : "32px")};
  fill: ${({ fill }) => fill ?? "#98A1C0"};
  opacity: 1;
`;


export const DiscordXIcon = styled(DiscordX)<{ size?: number; fill?: string }>`
  height: ${({ size }) => (size ? size + "px" : "32px")};
  width: ${({ size }) => (size ? size + "px" : "32px")};
  fill: ${({ fill }) => fill ?? "#98A1C0"};
  opacity: 1;
`;

export const PlaneXIcon = styled(PlaneX)<{ size?: number; fill?: string }>`
  height: ${({ size }) => (size ? size + "px" : "32px")};
  width: ${({ size }) => (size ? size + "px" : "32px")};
  fill: ${({ fill }) => fill ?? "#98A1C0"};
  opacity: 1;
`;
export const IconLink = styled.a`
`;


export const CopyRight = styled.div`
color: #797979;
font-size: 9px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;
