import { useEffect, useState } from "react";
import { StakerWallet } from "@eltk/staking-sdk";
import { useWeb3React } from "@web3-react/core";

export const useSignerWallet = () => {
  const { account, chainId } = useWeb3React();
  const [wallet, setWallet] = useState<any>();

  useEffect(() => {
    const initializeSignerWallet = async () => {
      try {
        if (account && chainId) {
          const wallet = await StakerWallet.getSignerFromMetaMask();
          setWallet(wallet);
        }
      } catch (error) {
        console.error("Error initializeSignerWallet", error);
      }
    };

    initializeSignerWallet();
  }, [account, chainId]);

  return {
    wallet,
  };
};
