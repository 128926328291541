import { useEffect, useState } from "react";
import { Pool, StakerWallet } from "@eltk/staking-sdk";
import { Signer, providers } from "ethers";
import { useSignerWallet } from "hooks/useSignerWallet";
import { usePool } from "./usePool";

export const usePoolId = (address: any) => {
  const { pool: poolInstance } = usePool();
  const [poolId, setPoolId] = useState<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getPoolId = async () => {
      try {
        if (poolInstance && address) {
          setLoading(true);

          const response = await poolInstance.getPoolPidByPoolAddress(address);
          setLoading(false);
          setPoolId(response);
        }
      } catch (error) {
        console.error("Error Getting Pool Id:", error);
      } finally {
        setLoading(false);
      }
    };

    getPoolId();
  }, [poolInstance,address]);

  return {
    poolId,
    loading,
  };
};
