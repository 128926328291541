import { ButtonGray } from "components/Button";
import styled from "styled-components/macro";

export const Path = styled.path<{ fill?: string }>`
  opacity: 1;
  stroke: #868e9b;
  fill: ${({ fill }) => (fill ? `url(${fill})` : "none")};
`;
export const StyledGroup = styled.g`
  line {
    display: none;
  }

  text {
    color: ${({ theme }) => theme.textSecondary};
    transform: translateY(5px);
  }
`;
export const Handle = styled.path<{ color: string }>`
  cursor: ew-resize;
  pointer-events: none;
  opacity: 0.5;
  stroke-width: 1;
  stroke: #868e9b;
  fill: #868e9b;
`;

export const HandleAccent = styled.path`
  cursor: ew-resize;
  pointer-events: none;

  stroke-width: 1.5;
  stroke: #091012;
  opacity: ${({ theme }) => theme.opacity.hover};
`;

export const LabelGroup = styled.g<{ visible: boolean }>`
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  transition: opacity 300ms;
`;

export const TooltipBackground = styled.rect`
  fill: ${({ theme }) => theme.backgroundInteractive};
`;

export const Tooltip = styled.text`
  text-anchor: middle;
  font-size: 13px;
  fill: ${({ theme }) => theme.textPrimary};
`;

export const StyledLine = styled.line`
  stroke-width: 1px;
  stroke: #fff;
  opacity: 0.5;
  fill: none;
  stroke-dasharray: 5;
`;

export const Wrapper = styled.div<{ count: number }>`
  display: flex;
  gap: 10px;
  flex-direction: row-reverse;
`;

export const Button = styled(ButtonGray)`
  &:hover {
    background-color: ${({ theme }) => theme.backgroundInteractive};
    color: ${({ theme }) => theme.textPrimary};
  }

  width: 32px;
  height: 32px;
  padding: 4px;
`;

export const ZoomOverlay = styled.rect`
  fill: transparent;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`;
