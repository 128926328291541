import { getLimitOrderBaseUrl } from "config";

export const fetchLimitPriceData = async (
  chainId: any,
  currencyAddresses: any
) => {
  const baseUrl = getLimitOrderBaseUrl(chainId);
  const url = `${baseUrl}1inch/${chainId}/${currencyAddresses.join(",")}`;

  try {
    const response = await fetch(url);
    const data = await response.json();
    return data;
  } catch (e) {
    return;
  }
};

// TK9
export const getBidAskData = async (
  tokenA: any,
  tokenB: any,
  chainId: number | undefined
) => {
  const baseUrl = getLimitOrderBaseUrl(chainId);
  const tokA = tokenA.toLowerCase();
  const tokB = tokenB.toLowerCase();

  try {
    const response = await fetch(
      `${baseUrl}matching-engine/market-depth?tokenA=${tokA}&tokenB=${tokB}`
    );
    const data = await response.json();


    if (data.asks && data.bids) {
      const formattedData = {
        asks: data.asks.map((ask: any) => ({ price: ask[0], volume: ask[1] })),
        bids: data.bids.map((bid: any) => ({ price: bid[0], volume: bid[1] })),
      };

      return formattedData;
    } else {
      // Return a default value in case data.asks or data.bids is falsy
      return {
        asks: [],
        bids: [],
      };
    }
  } catch (e) {
    console.error("Error fetching market depth data:", e);
    // Return an appropriate default value when there's an error
    return null;
  }
};
