import { TraceEvent } from "@uniswap/analytics";
import {
  BrowserEvent,
  InterfaceElementName,
  SwapEventName,
} from "@uniswap/analytics-events";
import { Currency, CurrencyAmount, Percent } from "@uniswap/sdk-core";
import { Pair } from "@uniswap/v2-sdk";
import { useWeb3React } from "@web3-react/core";
import { AutoColumn } from "components/Column";
import { loadingOpacityMixin } from "components/Loader/styled";
import CurrencyLogo from "components/Logo/CurrencyLogo";
import { isSupportedChain } from "constants/chains";
import { Fragment, ReactNode, useCallback, useState } from "react";
import { Lock } from "react-feather";
import styled, { useTheme } from "styled-components/macro";
import { flexColumnNoWrap, flexRowNoWrap } from "theme/styles";
import { formatCurrencyAmount } from "utils/formatCurrencyAmount";

import { ReactComponent as DropDown } from "../../assets/images/dropdown.svg";
import { useCurrencyBalance } from "../../state/connection/hooks";
import { BREAKPOINTS, ThemedText } from "../../theme";
import { ButtonGray } from "../Button";
import DoubleCurrencyLogo from "../DoubleLogo";
import { Input as NumericalInput } from "../NumericalInput";
import { RowFixed } from "../Row";
import CurrencySearchModal from "../SearchModal/CurrencySearchModal";
import { truncateFormatPriceImpact } from "./FiatValue";
import { MouseoverTooltip } from "components/Tooltip";
import { Z_INDEX } from "theme/zIndex";
import { useLocation } from "react-router";
// import { StyledTextButton } from "components/swap/SwapInputHeader";
import { formatNumberWithSuffix } from "utils/numbers";
import { GradientTextDuration, StyledTextButton } from "pages/Locking/styled";

const InputPanel = styled.div<{ hideInput?: boolean }>`
  ${flexColumnNoWrap};

  display: flex;
  position: relative;
  border-radius: 10px;
  z-index: ${Z_INDEX.default};
  width: ${({ hideInput }) => (hideInput ? "100%" : "initial")};
  transition: height 1s ease;
  will-change: height;
`;

const FixedContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  z-index: ${Z_INDEX.hover};
`;

const Container = styled.div<{ hideInput: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 44px;
  border-radius: ${({ hideInput }) => (hideInput ? "16px" : "20px")};
  width: ${({ hideInput }) => (hideInput ? "100%" : "initial")};
  gap: 5px;
`;

const CurrencySelect = styled(ButtonGray)<{
  visible: boolean;
  selected: boolean;
  hideInput?: boolean;
  disabled?: boolean;
}>`
  align-items: center;

  background: ${({ selected, theme }) =>
    selected ? `${theme.inputGray}` : `${theme.inputGray}`};
  opacity: ${({ disabled }) => (!disabled ? 1 : 0.4)};
  box-shadow: ${({ selected }) =>
    selected ? "none" : "0px 6px 10px rgba(0, 0, 0, 0.075)"};

  flex-grow: 1;

  color: ${({ selected, theme }) =>
    selected ? theme.textPrimary : theme.textPrimary};
  cursor: pointer;
  height: 46px;
  border-radius: 10px;
  outline: none;
  margin-bottom: 10px;
  user-select: none;
  border: none;
  order: 3;
  font-size: 2px;
  font-weight: 600;
  width: ${({ hideInput }) => (hideInput ? "100%" : "initial")};

  padding: 16px 14px 16px 21px;
  gap: 8px;
  justify-content: space-between;
  width: 170px;

  margin-left: ${({ hideInput }) => (hideInput ? "0" : "0px")};
  :focus,
  :hover {
    background: ${({ selected }) =>
      selected
        ? "linear-gradient(191deg, #122934 5.4%, #203035 92.07%)"
        : "linear-gradient(191deg, #203035 5.4%, #122934 92.07%)"};
  }
  &:before {
    background-size: 100%;
    border-radius: inherit;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    content: "";
  }

  &:hover:before {
    // background-color: ${({ theme }) => theme.stateOverlayHover};
  }

  &:active:before {
    // background-color: ${({ theme }) => theme.stateOverlayPressed};
  }

  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};

  @media (max-width: 1850px) {
    margin-right: 0px;
  }
`;

const InputRow = styled.div`
  ${flexRowNoWrap};
  order: 4;
  align-items: center;
  border-radius: 10px;
  background: ${({ theme }) => theme.inputGray};
  width: 400px;
  height: 46px;
  justify-content: space-between;
  flex-grow: 1; /* Allow the component to grow and take remaining space */
`;

const Aligner = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
  margin: 0 0.25rem 0 0.35rem;
  height: 35%;
  margin-left: 8px;

  path {
    stroke: ${({ selected, theme }) =>
      selected ? theme.textPrimary : theme.white};
    stroke-width: 2px;
  }
`;

const StyledTokenName = styled.span<{ active?: boolean }>`
  ${({ active }) =>
    active
      ? "  margin: 0 0.25rem 0 0.25rem;"
      : "  margin: 0 0.25rem 0 0.25rem;"}
  font-size: 15px;
  font-weight: 600;
`;

const StyledBalanceMax = styled.button<{ disabled?: boolean }>`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.accentAction};
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  opacity: ${({ disabled }) => (!disabled ? 1 : 0.4)};
  padding: 4px 6px;
  pointer-events: ${({ disabled }) => (!disabled ? "initial" : "none")};

  :hover {
    opacity: ${({ disabled }) => (!disabled ? 0.8 : 0.4)};
  }

  :focus {
    outline: none;
  }
`;

const StyledNumericalInput = styled(NumericalInput)<{ $loading: boolean }>`
  ${loadingOpacityMixin};

  text-align: right;
  font-size: 15px;
  line-height: 100%;
  padding: 13px 22px 13px 22px;
  padding-left: 10px;
`;
const BalanceDiv = styled.div`
  display: "flex";
  justify-content: space-between;
  color: white;
  order: 2;
  width: 100%;
  /* max-width: 414px; */
  /* height: 20px; */
  /* text-align: right; */
`;
const TitleDiv = styled.div`
  color: white;
  width: 160px;
  height: 20px;
  text-align: left;
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

const GradientText = styled.span`
  background: linear-gradient(191deg, #a1eeff 5.4%, #029af0 92.07%);
  font-family: Inter;
  font-size: 10px;
  font-weight: 600;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  cursor: pointer;
`;

interface SwapCurrencyInputPanelProps {
  value: string;
  onUserInput: (value: string) => void;
  onMax?: () => void;
  showMaxButton: boolean;
  label?: ReactNode;
  onCurrencySelect?: (currency: Currency) => void;
  currency?: Currency | null;
  hideBalance?: boolean;
  pair?: Pair | null;
  hideInput?: boolean;
  otherCurrency?: Currency | null;
  fiatValue?: { data?: number; isLoading: boolean };
  priceImpact?: Percent;
  id?: string;
  showCommonBases?: boolean;
  titleType?: string;
  showCurrencyAmount?: boolean;
  disableNonToken?: boolean;
  renderBalance?: (amount: CurrencyAmount<Currency>) => ReactNode;
  locked?: boolean;
  loading?: boolean;
  disabled?: boolean;
  handlePercentageInput?: any;
  percentage?: any;
  setPercentage?: any;
}

export default function SwapCurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  onCurrencySelect,
  currency,
  otherCurrency,
  id,
  titleType,
  showCommonBases,
  showCurrencyAmount,
  disableNonToken,
  renderBalance,
  fiatValue,
  priceImpact,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  locked = false,
  loading = false,
  disabled = false,
  handlePercentageInput,
  percentage,
  setPercentage,
  ...rest
}: SwapCurrencyInputPanelProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const { account, chainId } = useWeb3React();
  const selectedCurrencyBalance = useCurrencyBalance(
    account ?? undefined,
    currency ?? undefined
  );
  const theme = useTheme();
  const handleDismissSearch = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const chainAllowed = isSupportedChain(chainId);

  const { pathname } = useLocation();

  const handlePercentageClick = (percent: any) => {
    handlePercentageInput(percent);
    setPercentage(percent);
  };
  const onMaxInput = (percent: any) => {
    onMax && onMax();
    setPercentage(percent);
  };

console.log("KSKSK", currency)
  return (
    <InputPanel id={id} hideInput={hideInput} {...rest}>
      {locked && (
        <FixedContainer>
          <AutoColumn gap="sm">
            <Lock />
            <ThemedText.DeprecatedLabel
              fontSize="12px"
              textAlign="center"
              padding="0 12px"
            >
              <div>
                The market price is outside your specified price range.
                Single-asset deposit only.
              </div>
            </ThemedText.DeprecatedLabel>
          </AutoColumn>
        </FixedContainer>
      )}
      <Container hideInput={hideInput}>
        <CurrencySelect
          disabled={!chainAllowed || disabled}
          visible={currency !== undefined}
          selected={!!currency}
          hideInput={hideInput}
          className="open-currency-select-button"
          onClick={() => {
            if (onCurrencySelect) {
              setModalOpen(true);
            }
          }}
        >
          {disabled ? (
            <>
              {" "}
              <StyledTokenName>
                <div>Select Token </div>
              </StyledTokenName>
            </>
          ) : (
            <Aligner>
              <RowFixed>
                {pair ? (
                  <span style={{ marginRight: "0.5rem" }}>
                    <DoubleCurrencyLogo
                      currency0={pair.token0}
                      currency1={pair.token1}
                      size={22}
                      margin={true}
                    />
                  </span>
                ) : currency ? (
                  <CurrencyLogo
                    style={{ marginRight: "11px" }}
                    currency={currency}
                    size="22px"
                  />
                ) : null}
                {pair ? (
                  <StyledTokenName className="pair-name-container">
                    {pair?.token0.symbol}:{pair?.token1.symbol}
                  </StyledTokenName>
                ) : (
                  <StyledTokenName
                    className="token-symbol-container"
                    active={Boolean(currency && currency.symbol)}
                  >
                    {(currency && currency.symbol && currency.symbol.length > 20
                      ? currency.symbol.slice(0, 4) +
                        "..." +
                        currency.symbol.slice(
                          currency.symbol.length - 5,
                          currency.symbol.length
                        )
                      : currency?.symbol) || <div>Select Token </div>}
                  </StyledTokenName>
                )}
              </RowFixed>
              {onCurrencySelect && <StyledDropDown selected={!!currency} />}
            </Aligner>
          )}
        </CurrencySelect>

        <InputRow
          style={hideInput ? { padding: "0", borderRadius: "8px" } : {}}
        >
          {!hideInput && (
            <StyledNumericalInput
              className="token-amount-input"
              value={value}
              onUserInput={onUserInput}
              disabled={!chainAllowed || disabled}
              $loading={loading}
            />
          )}
        </InputRow>

        <BalanceDiv style={{ height: selectedCurrencyBalance ? 20 : 0 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              {selectedCurrencyBalance && titleType !== "output" ? (
                <RowFixed>
                  {pathname !== "/limit" && (
                    <Fragment>
                      <StyledTextButton
                        fontSize={14}
                        color="#445156"
                        fontWeight={500}
                        onClick={() => handlePercentageClick(10)}
                      >
                        {percentage == 10 ? (
                          <GradientTextDuration>10%</GradientTextDuration>
                        ) : (
                          <>10%</>
                        )}{" "}
                      </StyledTextButton>
                      <StyledTextButton
                        fontSize={14}
                        color="#445156"
                        fontWeight={500}
                        onClick={() => handlePercentageClick(25)}
                      >
                        {percentage == 25 ? (
                          <GradientTextDuration>25%</GradientTextDuration>
                        ) : (
                          <>25%</>
                        )}{" "}
                      </StyledTextButton>
                      <StyledTextButton
                        fontSize={14}
                        color="#445156"
                        fontWeight={500}
                        onClick={() => handlePercentageClick(50)}
                      >
                        {percentage == 50 ? (
                          <GradientTextDuration>50%</GradientTextDuration>
                        ) : (
                          <>50%</>
                        )}
                      </StyledTextButton>
                      <StyledTextButton
                        fontSize={14}
                        color="#445156"
                        fontWeight={500}
                        onClick={() => handlePercentageClick(80)}
                      >
                        {percentage == 80 ? (
                          <GradientTextDuration>80%</GradientTextDuration>
                        ) : (
                          <>80%</>
                        )}
                      </StyledTextButton>
                    </Fragment>
                  )}
                  &nbsp;
                  {currency?.symbol !== 'ETH' &&<StyledTextButton onClick={() => onMaxInput(100)}>
                    {percentage == 100 ? (
                      <GradientTextDuration>Max</GradientTextDuration>
                    ) : (
                      <>Max</>
                    )}{" "}
                  </StyledTextButton>}
                </RowFixed>
              ) : (
                currency &&
                !disabled && account && (
                  <div
                    style={{ color: "#445156", fontSize: 10, fontWeight: 400 }}
                  >
                    This is the asset you will receive
                  </div>
                )
              )}{" "}
            </div>
            <div>
              {account ? (
                <Fragment>
                  <ThemedText.DeprecatedBody
                    data-testid="balance-text"
                    color={theme.textSecondary}
                    fontWeight={400}
                    fontSize={10}
                    style={{ display: "inline", textAlign: "right" }}
                  >
                    {!hideBalance && currency && selectedCurrencyBalance ? (
                      renderBalance ? (
                        renderBalance(selectedCurrencyBalance)
                      ) : (
                        <div>
                          <span style={{ color: "#445156", fontSize:'12px' }}>Balance: </span>{" "}
                          &nbsp;&nbsp;
                          <GradientText
                            // onClick={onMax}
                            style={{ cursor: "unset", fontSize:'12px' }}
                          >
                            {formatNumberWithSuffix(
                              Number(selectedCurrencyBalance.toExact())
                            )}
                          </GradientText>
                        </div>
                      )
                    ) : null}
                  </ThemedText.DeprecatedBody>
                  {showMaxButton && selectedCurrencyBalance ? (
                    <TraceEvent
                      events={[BrowserEvent.onClick]}
                      name={SwapEventName.SWAP_MAX_TOKEN_AMOUNT_SELECTED}
                      element={InterfaceElementName.MAX_TOKEN_AMOUNT_BUTTON}
                    >
                      <StyledBalanceMax onClick={() => onMaxInput(100)}>
                        {percentage == 100 ? (
                          <GradientTextDuration>Max</GradientTextDuration>
                        ) : (
                          <>Max</>
                        )}{" "}
                      </StyledBalanceMax>
                    </TraceEvent>
                  ) : null}
                </Fragment>
              ) : (
                <span />
              )}
            </div>
          </div>
        </BalanceDiv>

        <TitleDiv>
          <div>{titleType === "output" ? "You Buy" : "You Sell"}</div>
        </TitleDiv>
      </Container>
      {onCurrencySelect && (
        <CurrencySearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
          showCurrencyAmount={showCurrencyAmount}
          disableNonToken={disableNonToken}
        />
      )}
    </InputPanel>
  );
}
