import { Percent, TradeType } from "@uniswap/sdk-core";
import Column, { AutoColumn } from "components/Column";
import { useUSDPrice } from "hooks/useUSDPrice";
import { InterfaceTrade } from "state/routing/types";
import { Field } from "state/swap/actions";
import styled from "styled-components/macro";
import { BREAKPOINTS, Divider, ThemedText } from "theme";
import { useLocation } from "react-router-dom";

import { SwapModalHeaderAmount } from "./SwapModalHeaderAmount";
import { useWindowSize } from "hooks/useWindowSize";
import { PropsWithChildren } from "react";
import { TextProps } from "rebass";
import moment from 'moment';

export const Label = styled(ThemedText.BodySmall)<{ cursor?: string }>`
  cursor: ${({ cursor }) => cursor};
  color: ${({ theme }) => theme.textSecondary};
  margin-right: 8px;
`

const Rule = styled(Divider)`
  margin: 16px 2px 24px 2px;
`;

export const HeaderContainer = styled(AutoColumn)`
  margin-top: 16px;
`;

const ResponsiveHeadline = ({ children, ...textProps }: PropsWithChildren<TextProps>) => {
  const { width } = useWindowSize()

  if (width && width < BREAKPOINTS.xs) {
    return <ThemedText.HeadlineMedium {...textProps}>{children}</ThemedText.HeadlineMedium>
  }

  return (
    <ThemedText.HeadlineLarge fontWeight={500} {...textProps}>
      {children}
    </ThemedText.HeadlineLarge>
  )
}
export default function SwapModalHeader({
  trade,
  allowedSlippage,
  limitOrderPayload,
  formattedAmountsLimit
}: {
  trade: InterfaceTrade;
  allowedSlippage: Percent;
  limitOrderPayload?: any;
  formattedAmountsLimit?:any
}) {
  const fiatValueInput = useUSDPrice(trade.inputAmount);
  const fiatValueOutput = useUSDPrice(trade.outputAmount);
  // const {partialFill , expiry} = limitOrderPayload
  
  const { pathname, search } = useLocation();


  return (
    <HeaderContainer gap="sm">
      <Column gap="lg">
        <SwapModalHeaderAmount
          field={Field.INPUT}
          label={<div>You sell</div>}
          amount={trade.inputAmount}
          usdAmount={fiatValueInput.data}
          formattedAmountsLimit={formattedAmountsLimit}
        />
        <SwapModalHeaderAmount
         formattedAmountsLimit={formattedAmountsLimit}
          field={Field.OUTPUT}
          label={<div>You buy</div>}
          amount={trade.outputAmount}
          usdAmount={fiatValueOutput.data}
          tooltipText={
            trade.tradeType === TradeType.EXACT_INPUT ? (
              <ThemedText.Caption>
                <div>
                  Output is estimated. You will receive at least{" "}
                  <b>
                    {trade.minimumAmountOut(allowedSlippage).toSignificant(6)}{" "}
                    {trade.outputAmount.currency.symbol}
                  </b>{" "}
                  or the transaction will revert.
                </div>
              </ThemedText.Caption>
            ) : (
              <ThemedText.Caption>
                <div>
                  Input is estimated. You will sell at most{" "}
                  <b>
                    {trade.maximumAmountIn(allowedSlippage).toSignificant(6)}{" "}
                    {trade.inputAmount.currency.symbol}
                  </b>{" "}
                  or the transaction will revert.
                </div>
              </ThemedText.Caption>
            )
          }
        />

      { pathname === '/limit' &&  <Column gap="xs">
          <ThemedText.BodySecondary>
              <Label cursor="help">Order Expiry</Label>
          </ThemedText.BodySecondary>
          <Column gap="xs">
            <ResponsiveHeadline >
              {limitOrderPayload && moment(limitOrderPayload?.expiry).format("MMM Do YY")  }
            </ResponsiveHeadline>
          </Column>
        </Column>}

        { pathname === '/limit' &&  <Column gap="xs">
          <ThemedText.BodySecondary>
              <Label cursor="help">Partiall fill order</Label>
          </ThemedText.BodySecondary>
          <Column gap="xs">
            <ResponsiveHeadline >
               {limitOrderPayload && limitOrderPayload.partialFill ? 'true' : 'false'}
            </ResponsiveHeadline>
          </Column>
        </Column>}

       
      </Column>
      <Rule />
    </HeaderContainer>
  );
}
