import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orders: [],
  ordersLoading: false,
  metaData:{}
};

export const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    addOrders: (state, { payload }: { payload: any }) => {
      return {
        orders: [...payload?.data?.orders],
        metaData: { ...payload?.data?.metaData }, 
        ordersLoading: payload?.loading, 
      } as any;
    },

    updateOrderStatus: (state, { payload }) => {
      const { orderUID, status } = payload;
      const updatedOrders = state.orders.map((order: any) =>
        order.orderUID === orderUID ? { ...order, status } : order
      );
      return {
        ...state,
        orders: updatedOrders,
      } as any;
    },

    deleteOrderStatus: (state, { payload }) => {
      const { orderUID } = payload;
      const updatedOrders = state.orders.filter((order: any) =>
        order.orderUID !== orderUID ? { ...order } : order
      );
      return {
        ...state,
        orders: updatedOrders,
      } as any;
    },
  },
});

export const { addOrders, updateOrderStatus, deleteOrderStatus } =
  orderSlice.actions;
export default orderSlice.reducer;
