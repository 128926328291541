import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import {
  StakingClient,
  voteEscrowClient,
} from "graphql/thegraph/apollo";

const STAKING_STATUS = gql`
  query MyQuery($tokenId: String!) {
    deposits(first: 1, where: { tokenId: $tokenId }) {
      id
    }
  }
`;



export const useIsStakingStatus = (id: any) => {
  const { loading, error, data } = useQuery(STAKING_STATUS, {
    variables: { tokenId: id },
    client: StakingClient,
  });

  return {
    data,
  };
};


const GET_LPS_QUERY = gql`
  query MyQuery($id: String!) {
    lps(where: {id: $id}) {
      id
      withdrawn
    }
  }
`;



export const useIsStakingStatusDetail = (id: any) => {
  const { loading, error, data } = useQuery(GET_LPS_QUERY, {
    variables: { id },
    client: StakingClient,
    fetchPolicy:'network-only'
  });

  return {
    data,
  };
};
