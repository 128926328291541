import { ButtonGray } from "components/Button";
import { CustomDarkCard } from "components/Card";
import styled, { keyframes } from "styled-components";
import { ThemedText } from "theme";
import { Input as NumericalInput } from '../NumericalInput'

export const pulse = (color: string) => keyframes`
  0% {
    box-shadow: 0 0 0 0 ${color};
  }

  70% {
    box-shadow: 0 0 0 2px ${color};
  }

  100% {
    box-shadow: 0 0 0 0 ${color};
  }
`

export const InputRow = styled.div`
  display: grid;

  grid-template-columns: 22px 1fr 22px;
`

export const SmallButton = styled(ButtonGray)`
height: 22px;
width: 22px;
  border-radius: 5px;
  background-color: #2A393E;
  padding: 4px;
`

export const FocusedOutlineCard = styled(CustomDarkCard)<{ active?: boolean; pulsing?: boolean }>`
  // border-color: ${({ active, theme }) => active && theme.accentAction};
  padding: 12px;
  animation: ${({ pulsing, theme }) => pulsing && pulse(theme.accentAction)} 0.8s linear;
`


export const InputTitleDark = styled(ThemedText.DeprecatedSmall)`
  color: #fff
  font-size: 12px;
  font-weight: 500;
`
export const InputTitle = styled(ThemedText.DeprecatedSmall)`
  color: #868E9B;
  font-size: 12px;
  font-weight: 500;
`

export const ButtonLabel = styled(ThemedText.DeprecatedWhite)<{ disabled: boolean }>`
  color: #121B1E !important;
  display: flex;
  justify-content: center;
  align-items: center; 
`

export const StyledInput = styled(NumericalInput)<{ usePercent?: boolean }>`
  background-color: transparent;
  text-align: center;
  color: #4C6066;
  width: 100%;
  font-weight: 500;
  padding: 0 10px;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    font-size: 16px;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToExtraSmall`
    font-size: 12px;
  `};
`
