import { flexRowNoWrap } from "theme/styles";
import { Link as HistoryLink } from "react-router-dom";
import styled from "styled-components";
import { ArrowLeft, ChevronLeft,ChevronsLeft } from "react-feather";

export const Tabs = styled.div`
  ${flexRowNoWrap};
  align-items: center;
  border-radius: 3rem;
  justify-content: space-evenly;
`;

export const StyledHistoryLink = styled(HistoryLink)<{ flex?: string }>`
  display: flex;
  align-items: center;
  text-decoration: none;
  
  flex: ${({ flex }) => flex ?? "none"};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    flex: none;
    margin-right: 10px;
  `};
`;

export const ActiveText = styled.div`
  font-weight: 500;
  font-size: 20px;
`;

export const StyledArrowLeft = styled(ArrowLeft)`
  color: ${({ theme }) => theme.textPrimary};
`;

export const StyledArrowLeftChevron = styled(ChevronsLeft)`
  color: '#000000';
`;


export const StyledChevronArrowLeft = styled(ChevronLeft)`
  color: ${({ theme }) => theme.textPrimary};
`;
