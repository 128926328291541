
export enum Z_INDEX {
  negative= -1,
  deprecated_zero = 0,
  default = 1,
  hover = 2,
  active = 3,
  banner = 5,
  century = 100,
  under_dropdown = 990,
  dropdown = 1000,
  sticky = 1020,
  fixed = 1030,
  modalBackdrop = 1040,
  offcanvas = 1050,
  modal = 1060,
  popover = 1070,
  tooltip = 1080,
  modalOverTooltip = 1090,
}
