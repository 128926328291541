import React from "react";
import { ChartEntry } from "./index";
import styled from "styled-components";
import { AutoColumn } from "components/Column";
import { RowFixed } from "components/Row";
import { formatAmountChart } from "utils/numbers";

export interface PoolData {
  // basic token info
  address: string;
  feeTier: number;

  token0: {
    name: string;
    symbol: string;
    address: string;
    decimals: number;
    derivedETH: number;
  };

  token1: {
    name: string;
    symbol: string;
    address: string;
    decimals: number;
    derivedETH: number;
  };

  // for tick math
  liquidity: number;
  sqrtPrice: number;
  tick: number;

  // volume
  volumeUSD: number;
  volumeUSDChange: number;
  volumeUSDWeek: number;

  // liquidity
  tvlUSD: number;
  tvlUSDChange: number;

  // prices
  token0Price: number;
  token1Price: number;

  // token amounts
  tvlToken0: number;
  tvlToken1: number;
}
const WrapperRight = styled.div`
  padding: 6px 0px 6px 0px;
  color: white;
  margin-left: 10px;
  width: fit-content;
  font-size: 14px;
  background-color: ${({ theme }) => "transparent"};
  &::before {
    content: ""; /* Required for display */
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 1px; /* Line thickness */
    background-image: linear-gradient(
      #14e877 50%,
      transparent 50%
    ); /* Creates the dashed effect */
    background-size: 2px 6px; /* Dash length and spacing */
  }
`;

const WrapperLeft = styled.div`
  padding: 6px 0px 10px 0px;
  margin-left: 20px;
  color: white;
  width: fit-content;
  font-size: 14px;
  background-color: ${({ theme }) => "transparent"};
  &::before {
    content: ""; /* Required for display */
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 1px; /* Line thickness */
    background-image: linear-gradient(
      #f73838 50%,
      transparent 50%
    ); /* Creates the dashed effect */
    background-size: 2px 6px; /* Dash length and spacing */
  }
`;

const WrapperWhite = styled.div`
  padding: 6px 0px 6px 0px;
  color: white;
  margin-left: 20px;
  width: fit-content;
  font-size: 14px;
  background-color: ${({ theme }) => "transparent"};
  &::before {
    content: ""; /* Required for display */
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 1px; /* Line thickness */
    background-image: linear-gradient(
      #fff 50%,
      transparent 50%
    ); /* Creates the dashed effect */
    background-size: 2px 6px; /* Dash length and spacing */
  }
`;

interface LabelProps {
  x: number;
  y: number;
  index: number;
}

interface CurrentPriceLabelProps {
  data?: ChartEntry[];
  chartProps: any;
  poolData: PoolData;
}

export function CurrentPriceLabelArea({
  data,
  chartProps,
  poolData,
}: CurrentPriceLabelProps) {
  const labelData = chartProps as LabelProps;
  const entryData = data?.[labelData.index];

  if (entryData?.isCurrent) {
    const price0 = entryData.price0;
    const price1 = entryData.price1;

    // Get the first and last entries
    const firstEntry = data?.[0];
    const lastEntry = data?.[data.length - 1];

    return (
      <>
        <g>
          <foreignObject x="50%" y={0} width="100%" height={230}>
            <WrapperWhite>
              <AutoColumn>
                <RowFixed align="center">
                  <p style={{ color: "#029AF0", fontWeight: 500 }}>
                    {" "}
                    Current Price
                  </p>
                </RowFixed>
                <div style={{ display: "block" }}>
                  <div style={{ fontSize: 12, color: "#868E9B" }}>{`1 ${
                    poolData.token0.symbol
                  } = ${formatAmountChart(price0)}
                ${poolData.token1.symbol}`}</div>
                </div>
              </AutoColumn>
            </WrapperWhite>
          </foreignObject>
        </g>
        <g>
          <foreignObject x="10%" y="0" width="175px" height={230}>
            <WrapperLeft>
              <AutoColumn>
                <RowFixed align="center">
                  <p style={{ color: "#F73838", fontWeight: 500 }}>
                    Minimum Price
                  </p>
                </RowFixed>
                <div style={{ display: "block" }}>
                  <div style={{ fontSize: 12, color: "#868E9B" }}>{`1 ${
                    poolData.token0.symbol
                  } = ${formatAmountChart(firstEntry?.price0)}
                ${poolData.token1.symbol}`}</div>
                </div>
              </AutoColumn>
            </WrapperLeft>
          </foreignObject>
        </g>
        <g>
          <foreignObject x="76%" y="0" width="175px" height={230}>
            <WrapperRight>
              <AutoColumn>
                <RowFixed  align="center" paddingLeft="0px">
                  <p
                    style={{
                      color: "#14E877",
                      fontWeight: "500",
                      paddingLeft: "12px",
                    }}
                  >
                    Maximum Price
                  </p>
                </RowFixed>
                <div style={{ display: "block" }}>
                  <div
                    style={{
                      fontSize: 12,
                      color: "#868E9B",
                      paddingLeft: "12px",
                    }}
                  >{`1 ${poolData.token0.symbol} = ${formatAmountChart(lastEntry?.price0)}
                ${poolData.token1.symbol}`}</div>
                </div>
              </AutoColumn>
            </WrapperRight>
          </foreignObject>
        </g>
      </>
    );
  }
  return null;
}
