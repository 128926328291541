import { Trace, TraceEvent } from '@uniswap/analytics'
import { BrowserEvent, InterfaceElementName, InterfaceEventName, InterfacePageName } from '@uniswap/analytics-events'
import { useWeb3React } from '@web3-react/core'
import { useToggleAccountDrawer } from 'components/AccountDrawer'
import {  ButtonPrimary, ButtonText } from 'components/Button'
import { AutoColumn } from 'components/Column'
import PositionList from 'components/PositionList'
import { SwitchLocaleLink } from 'components/SwitchLocaleLink'
import { isSupportedChainLightLink } from 'constants/chains'
import { useFilterPossiblyMaliciousPositions } from 'hooks/useFilterPossiblyMaliciousPositions'
import { useV3Positions } from 'hooks/useV3Positions'
import { useMemo } from 'react'
import { BookOpen, ChevronsRight, Layers } from 'react-feather'
import { Link } from 'react-router-dom'
import { useUserHideClosedPositions } from 'state/user/hooks'
import { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { PositionDetails } from 'types/position'

import { ButtonRow, ErrorContainer, InboxIcon, LoadingRows, MainContentWrapper, NetworkIcon, PoolMenuItem, PoolPageWrapper, ResponsiveButtonPrimary, TitleRow } from './styleds'
import { WrappingDiv } from './poolStyle'

function PositionsLoadingPlaceholder() {
  return (
    <LoadingRows>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </LoadingRows>
  )
}

function WrongNetworkCard() {
  const theme = useTheme()

  return (
    <>
      <PoolPageWrapper>
        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="lg" style={{ width: '100%' }}>
            <TitleRow padding="0">
              <ThemedText.LargeHeader>
                <div>Pools</div>
              </ThemedText.LargeHeader>
            </TitleRow>

            <MainContentWrapper>
              <ErrorContainer>
                <ThemedText.DeprecatedBody color={theme.textTertiary} textAlign="center">
                  <NetworkIcon strokeWidth={1.2} />
                  <div data-testid="pools-unsupported-err">
                    <div>Your connected network is unsupported.</div>
                  </div>
                </ThemedText.DeprecatedBody>
              </ErrorContainer>
            </MainContentWrapper>
          </AutoColumn>
        </AutoColumn>
      </PoolPageWrapper>
      {/* <SwitchLocaleLink /> */}
    </>
  )
}

export default function Positions() {
  const { account, chainId } = useWeb3React()
  const toggleWalletDrawer = useToggleAccountDrawer()

  const theme = useTheme()
  const [userHideClosedPositions, setUserHideClosedPositions] = useUserHideClosedPositions()

  const { positions, loading: positionsLoading } = useV3Positions(account)

  const [openPositions, closedPositions] = positions?.reduce<[PositionDetails[], PositionDetails[]]>(
    (acc, p) => {
      acc[p.liquidity?.isZero() ? 1 : 0].push(p)
      return acc
    },
    [[], []]
  ) ?? [[], []]

  const userSelectedPositionSet = useMemo(
    () => [...openPositions, ...(userHideClosedPositions ? [] : closedPositions)],
    [closedPositions, openPositions, userHideClosedPositions]
  )

  const filteredPositions = useFilterPossiblyMaliciousPositions(userSelectedPositionSet)


  if (!isSupportedChainLightLink(chainId)) {
    return <WrongNetworkCard />
  }

  const showConnectAWallet = Boolean(!account)



  return (
    <Trace page={InterfacePageName.POOL_PAGE} shouldLogImpression>
    <>
      <PoolPageWrapper>
        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="lg" style={{ width: '100%' }}>
            <TitleRow padding="0">
              <ThemedText.LargeHeader>
                <div>Pools</div>
              </ThemedText.LargeHeader>
              <ButtonRow>
                
                <ResponsiveButtonPrimary
                  data-cy="join-pool-button"
                  id="join-pool-button"
                  as={Link}
                  to="/add"
                  style={{
                    background: `linear-gradient(146deg, #a1eeff 0%, #029af0 100%)`,
                    color: '#000',
                  }}
                >
                  + <div>New Position</div>
                </ResponsiveButtonPrimary>
              </ButtonRow>
            </TitleRow>

            <MainContentWrapper>
              {positionsLoading ? (
                <PositionsLoadingPlaceholder />
              ) : filteredPositions && closedPositions && filteredPositions.length > 0 ? (
                <PositionList
                  positions={filteredPositions}
                  setUserHideClosedPositions={setUserHideClosedPositions}
                  userHideClosedPositions={userHideClosedPositions}
                />
              ) : (
                <ErrorContainer>
                  <ThemedText.DeprecatedBody color={theme.textTertiary} textAlign="center">
                    <InboxIcon strokeWidth={1} style={{ marginTop: '2em' }} />
                    <WrappingDiv>
                      <div>Your active liquidity positions will appear here.</div>
                    </WrappingDiv>
                  </ThemedText.DeprecatedBody>
                  {!showConnectAWallet && closedPositions.length > 0 && (
                    <ButtonText
                      style={{
                        marginTop: '.5rem',
                        background: `linear-gradient(146deg, #a1eeff 0%, #029af0 100%)`,
                        color: '#000',
                        padding: '10px',
                        marginBottom: '10px',
                      }}
                      onClick={() => setUserHideClosedPositions(!userHideClosedPositions)}
                    >
                      <div>Show closed positions</div>
                    </ButtonText>
                  )}
                  {showConnectAWallet && (
                    <TraceEvent
                      events={[BrowserEvent.onClick]}
                      name={InterfaceEventName.CONNECT_WALLET_BUTTON_CLICKED}
                      properties={{ received_swap_quote: false }}
                      element={InterfaceElementName.CONNECT_WALLET_BUTTON}
                    >
                      <ButtonPrimary
                        style={{
                          marginTop: '2em',
                          marginBottom: '2em',
                          padding: '8px 16px',
                          background: `linear-gradient(146deg, #a1eeff 0%, #029af0 100%)`,
                          color: '#000',
                          borderRadius: '10px',
                        }}
                        onClick={toggleWalletDrawer}
                      >
                        <div>Connect wallet</div>
                      </ButtonPrimary>
                 </TraceEvent>
                  )}
                </ErrorContainer>
              )}
            </MainContentWrapper>
        
          </AutoColumn>
        </AutoColumn>
      </PoolPageWrapper>
      {/* <SwitchLocaleLink /> */}
      </>

   </Trace>
  )
}
