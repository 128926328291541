import styled, { css, keyframes } from "styled-components"
import { X } from 'react-feather'
import { MEDIA_WIDTHS } from "theme"
import { AutoColumn } from "components/Column"
import { AutoRow } from "components/Row"
import AlertTriangleFilled from "components/Icons/AlertTriangleFilled"

export const StyledClose = styled(X)<{ $padding: number }>`
  position: absolute;
  right: ${({ $padding }) => `${$padding}px`};
  top: ${({ $padding }) => `${$padding}px`};

  :hover {
    cursor: pointer;
  }
`
export const PopupCss = css<{ show: boolean }>`
  display: inline-block;
  width: 100%;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  background-color: ${({ theme }) => theme.backgroundSurface};
  position: relative;
  border: 1px solid ${({ theme }) => theme.backgroundOutline};
  border-radius: 10px;
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.deepShadow};
  transition: ${({ theme }) => `visibility ${theme.transition.duration.fast} ease-in-out`};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
  min-width: 290px;
  &:not(:last-of-type) {
    margin-right: 20px;
  }
`}
`

export const TransactionPopupContainer = styled.div`
  ${PopupCss}
  padding: 2px 0px;
  background:green;
  color:'#fff';
`

export const FailedSwitchNetworkPopupContainer = styled.div<{ show: boolean }>`
  ${PopupCss}
  padding: 20px 35px 20px 20px;
`


export const LimitOrderSuccessPopUpContainer = styled.div<{ show: boolean }>`
  ${PopupCss}
  padding: 20px 35px 20px 20px;
  z-index:999999;
    background: green;

`

export const LimitOrderErrorPopUpContainer = styled.div<{ show: boolean }>`
  ${PopupCss}
  padding: 20px 35px 20px 20px;
  z-index:999999;
    background: red;

`
export const MobilePopupWrapper = styled.div`
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  display: none;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    display: block;
    padding-top: 20px;
  `};
`

export const MobilePopupInner = styled.div`
  height: 99%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const StopOverflowQuery = `@media screen and (min-width: ${MEDIA_WIDTHS.deprecated_upToMedium + 1}px) and (max-width: ${
  MEDIA_WIDTHS.deprecated_upToMedium + 500
}px)`

export const FixedPopupColumn = styled(AutoColumn)<{ extraPadding: boolean; xlPadding: boolean }>`
  position: fixed;
  top: ${({ extraPadding }) => (extraPadding ? '72px' : '64px')};
  right: 1rem;
  max-width: 348px !important;
  width: 100%;
  z-index: 3;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    display: none;
  `};

  ${StopOverflowQuery} {
    top: ${({ extraPadding, xlPadding }) => (xlPadding ? '72px' : extraPadding ? '72px' : '64px')};
  }
`

export const RowNoFlex = styled(AutoRow)`
  flex-wrap: nowrap;
`

export const ColumnContainer = styled(AutoColumn)`
  margin: 0 12px;
`

export const PopupAlertTriangle = styled(AlertTriangleFilled)`
  flex-shrink: 0;
  width: 32px;
  height: 32px;
`

export const StyledClaimPopup = styled(AutoColumn)`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #ff007a 0%, #021d43 100%);
  border-radius: 20px;
  padding: 1.5rem;
  overflow: hidden;
  position: relative;
  max-width: 360px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`

export const PopUpStyledClose = styled(X)`
  position: absolute;
  right: 10px;
  top: 10px;

  :hover {
    cursor: pointer;
  }
`

export const rotate = keyframes`
  0% {
    transform: perspective(1000px) rotateY(0deg);
  }

  100% {
    transform: perspective(1000px) rotateY(360deg);
  }
`

export const UniToken = styled.img`
  animation: ${rotate} 5s cubic-bezier(0.83, 0, 0.17, 1) infinite;
`
