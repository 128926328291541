import {
  ButtonOutlined,
  ButtonOutlinedDark,
  ButtonPrimary,
} from "components/Button";
import {
  Backdrop,
  CheckboxInput,
  Checkmark,
  CheckmarkIntro,
  Container,
  ModalContainer,
  StyledList,
  StyledListItem,
  SwapWrapWrapper,
  TitleHeading,
  TitleHeadingSub,
  TitleSection,
  TitleSectionBottom,
  TitleStrong,
  TitleStrongCheck,
} from "./styled";
import { SupportedChainId } from "constants/chains";
import Modal from "components/Modal";
import { ReactComponent as ElektrikLogo } from "assets/svg/elektrik_logo.svg";
import { ReactComponent as ElektrikLogoWhite } from "assets/svg/elektrik_logo_white.svg";
import { useRef, useState } from "react";
import { CloseIcon, ExternalLinkDeprecated } from "theme";
import {
  GithubXIcon,
  TwitterXIcon,
  DiscordXIcon,
  PlaneXIcon,
  IconLink,
} from "pages/app.styled";
import styled from "styled-components";
import LogoWithBackground from "assets/svg/logowithbackground.svg";
import LogoWithBackgroundPNG from "assets/images/logowithbackgroundpng.png";
import { useOnClickOutside } from "hooks/useOnClickOutside";

interface IntroModalProps {
  setShowTrade: React.Dispatch<React.SetStateAction<boolean>>;
  chainId: SupportedChainId | undefined;
  showTrade: any;
}

const CustomButton = styled(ButtonOutlined)`
  border: 1px solid #5d7279;
  border-radius: 10px;
  background: #091012 !important;

  &:hover {
    box-shadow: 0 0 0 1px #5d7279;
  }
`;

export const IntroModal = ({
  setShowTrade,
  chainId,
  showTrade,
}: IntroModalProps) => {
  const [isChecked, setIsChecked] = useState(false);
  const [checboxCheck, setCheckboxCheck] = useState(true);

  const modalRef = useRef(null);
  const handleCloseModal = () => {
    setIsChecked(false);
  };

  useOnClickOutside(modalRef, handleCloseModal);
  return (
    <>
      {!showTrade && <Backdrop />}
      <Modal maxWidth={506} isOpen={!showTrade}>
        <SwapWrapWrapper id="swap-page" chainId={chainId}>
          <TitleSection>
            <TitleHeading>Choose your Trading mode</TitleHeading>
            <TitleStrong>
              Currently, we are only supporting Lite Trading mode. Pro Trading
              mode is under development. Feel free to use limit orders, staking,
              locking and other features which are available in the app.
            </TitleStrong>
          </TitleSection>

          <TitleSection style={{ marginTop: 20 }}>
            <TitleHeadingSub>Legal Terms of Use</TitleHeadingSub>
            <TitleStrong style={{ marginTop: 12 }}>
              Please find the{" "}
              <a
                href="https://elektrik.network/legal-notice.html"
                target="_blank"
              >
                legal
              </a>{" "}
              terms and conditions associated with the app and the ecosystem of
              Elektrik attached.
            </TitleStrong>
          </TitleSection>
          <TitleSectionBottom>
            <TitleHeadingSub style={{ marginTop: 20, marginBottom: 14 }}>
              I agree with Elektrik Service Rules
            </TitleHeadingSub>
            <TitleStrongCheck>
              <Container>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    top: 2,
                  }}
                >
                  I agree that i am using this product and accept responsibility
                  for any loss of funds resulting from potential bugs.
                </div>

                <CheckboxInput
                  type="checkbox"
                  onChange={() => setCheckboxCheck(!checboxCheck)}
                />
                <CheckmarkIntro style={{ top: 2 }} />
              </Container>
            </TitleStrongCheck>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: 26,

                marginTop: 34,
              }}
            >
              <div style={{ width: "100%" }}>
                <CustomButton
                  disabled={checboxCheck}
                  onClick={() => {
                    setShowTrade(true);
                  }}
                >
                  Lite Mode
                </CustomButton>
              </div>
              <div style={{ width: "100%" }}>
                <ButtonPrimary
                  style={{ border: "none" }}
                  disabled={checboxCheck}
                  onClick={() => {
                    setIsChecked(true);
                  }}
                >
                  Pro Trader
                </ButtonPrimary>
              </div>
            </div>
          </TitleSectionBottom>
        </SwapWrapWrapper>
      </Modal>

      {isChecked && (
        <Backdrop>
          <Modal isOpen={isChecked}>
            <ModalContainer ref={modalRef}>
              <div style={{ textAlign: "right" }}>
                <CloseIcon
                  onClick={() => {
                    setIsChecked(false);
                  }}
                  style={{
                    zIndex: 99,
                  }}
                  stroke="#647383"
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={LogoWithBackground} height={100} width={293} />
                {/* <ElektrikLogoWhite width={160} height={38} /> */}

                <div
                  style={{ fontSize: 24, fontWeight: "bold", marginBottom: 5 }}
                >
                  Pro Mode
                </div>

                <div
                  style={{ color: "#9BADB2", fontSize: 16, marginBottom: 20 }}
                >
                  Pro Feature is Coming Soon!
                </div>
                <div style={{ display: "flex", marginBottom: 40 }}>
                  <IconLink
                    href="https://x.com/elektriknetwork?s=21&t=8UGAzDLIjTPhad9esn-tHw"
                    target="_blank"
                  >
                    <TwitterXIcon size={18} cursor={"pointer"} />
                  </IconLink>
                  &nbsp; &nbsp; &nbsp;
                  <IconLink href="https://t.me/elektrikzapchat" target="_blank">
                    <PlaneXIcon size={18} cursor={"pointer"} />
                  </IconLink>
                  &nbsp; &nbsp; &nbsp;
                  <IconLink
                    href="https://discord.com/invite/kF3n9FWCZ4"
                    target="_blank"
                  >
                    <DiscordXIcon size={18} cursor={"pointer"} />
                  </IconLink>
                </div>
                <ButtonOutlinedDark
                  style={{
                    boxShadow: "none",
                    border: "1px solid #5D7279",
                  }}
                  onClick={() => {
                    setIsChecked(false);
                  }}
                >
                  Okay
                </ButtonOutlinedDark>
              </div>
            </ModalContainer>
          </Modal>
        </Backdrop>
      )}
    </>
  );
};
