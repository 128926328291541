import { useWeb3React } from "@web3-react/core";
import { AutoColumn } from "components/Column";
import { AutoRow } from "components/Row";
import { getConnections, networkConnection } from "connection";
import { ActivationStatus, useActivationState } from "connection/activate";
import { isSupportedChain } from "constants/chains";
import { useEffect, useRef } from "react";
import { CloseIcon, ThemedText } from "theme";

import ConnectionErrorView from "./ConnectionErrorView";
import Option from "./Option";
import PrivacyPolicyNotice from "./PrivacyPolicyNotice";
import { useAccountDrawer } from "components/AccountDrawer";
import { OptionGrid, PrivacyPolicyWrapper, WalletModalWrapper } from "./styled";
import useToggle from "hooks/useToggle";
import { useOnClickOutside } from "hooks/useOnClickOutside";

export default function WalletModal({
  openSettings,
}: {
  openSettings: () => void;
}) {
  const { connector, chainId } = useWeb3React();

  const connections = getConnections();

  const { activationState } = useActivationState();

  // Keep the network connector in sync with any active user connector to prevent chain-switching on wallet disconnection.
  useEffect(() => {
    if (
      chainId &&
      isSupportedChain(chainId) &&
      connector !== networkConnection.connector
    ) {
      networkConnection.connector.activate(chainId);
    }
  }, [chainId, connector]);
  const [walletDrawerOpen, toggleWalletDrawer] = useAccountDrawer();
  
  const modalRef = useRef(null);
  const handleCloseModal = () => {
    toggleWalletDrawer();
  };

  useOnClickOutside(modalRef, handleCloseModal);

  return (
    <WalletModalWrapper ref={modalRef} data-testid="wallet-modal">
      <AutoRow justify="space-between" width="100%" marginBottom="16px">
        <ThemedText.SubHeader>Connect a wallet</ThemedText.SubHeader>
        <CloseIcon
          onClick={toggleWalletDrawer}
          style={{ zIndex: 99 }}
          stroke="#647383"
        />
      </AutoRow>

      {activationState.status === ActivationStatus.ERROR ? (
        <ConnectionErrorView />
      ) : (
        <AutoColumn gap="16px">
          <OptionGrid data-testid="option-grid">
            {connections
              .filter((connection) => connection.shouldDisplay())
              .map((connection) => (
                <Option key={connection.getName()} connection={connection} />
              ))}
          </OptionGrid>
          <PrivacyPolicyWrapper>
            <PrivacyPolicyNotice />
          </PrivacyPolicyWrapper>
        </AutoColumn>
      )}
    </WalletModalWrapper>
  );
}
