import { useState, useEffect } from 'react';
import {useNavigate, useSearchParams} from  'react-router-dom'

interface PaginationProps<T> {
  data?: T[];
  itemsPerPage: number;
}

interface PaginationResult<T> {
  nextPage: () => void;
  prevPage: () => void;
  goToPage: (pageNumber: number) => void;
  currentItems: {start:number, end:number};
  currentPage: number | null;
  totalPages: number;
}

export const usePagination = <T,>({ data, itemsPerPage }: PaginationProps<T>): PaginationResult<T> => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const currentPage:string | null = searchParams.get('page')

  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (data) {
      setTotalPages(Math.ceil(data.length / itemsPerPage));
      // navigate(`/pools?page=${1}`)
    }
    if(!currentPage){
        navigate(`/pools?page=${1}`)
    }
  }, [data]);

  const currentItems = (): {start:number, end:number} => {
    if (!data) return {start:0 , end:10};
    const indexOfLastItem:any = Number(currentPage) * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return {start:indexOfFirstItem , end:indexOfLastItem};
  };

  const nextPage = (): void => {
    navigate(`/pools?page=${Math.min(Number(currentPage) + 1, totalPages)}`)
  };

  const prevPage = (): void => {
    navigate(`/pools?page=${Math.max(Number(currentPage) - 1, 1)}`)
  };

  const goToPage = (pageNumber: number): void => {
    const validPageNumber = Math.min(Math.max(1, pageNumber), totalPages);
    navigate(`/pools?page=${validPageNumber}`)
  };

  return {
    nextPage,
    prevPage,
    goToPage,
    currentItems: currentItems(),
    currentPage:Number(currentPage),
    totalPages,
  };
};

