import { useCloseAccountDrawer } from 'components/AccountDrawer'
import { ButtonEmpty, ButtonPrimary } from 'components/Button'
import { ActivationStatus, useActivationState } from 'connection/activate'

import { ThemedText } from 'theme'
import { AlertTriangleIcon, ConnectionErrorWrapper } from './styled'


// TODO(cartcrom): move this to a top level modal, rather than inline in the drawer
export default function ConnectionErrorView() {
  const { activationState, tryActivation, cancelActivation } = useActivationState()
  const closeDrawer = useCloseAccountDrawer()

  if (activationState.status !== ActivationStatus.ERROR) return null

  const retry = () => tryActivation(activationState.connection, closeDrawer)

  return (
    <ConnectionErrorWrapper>
      <AlertTriangleIcon />
      <ThemedText.HeadlineSmall marginBottom="8px">
        <div>Error connecting</div>
      </ThemedText.HeadlineSmall>
      <ThemedText.BodyPrimary fontSize={16} marginBottom={24} lineHeight="24px" textAlign="center">
        <div>
          The connection attempt failed. Please click try again and follow the steps to connect in your wallet.
        </div>
      </ThemedText.BodyPrimary>
      <ButtonPrimary $borderRadius="16px" onClick={retry}>
        <div>Try Again</div>
      </ButtonPrimary>
      <ButtonEmpty width="fit-content" padding="0" marginTop={20}>
        <ThemedText.Link onClick={cancelActivation} marginBottom={12}>
          <div>Back to wallet selection</div>
        </ThemedText.Link>
      </ButtonEmpty>
    </ConnectionErrorWrapper>
  )
}
