import { ButtonGray } from "components/Button";
import styled from "styled-components";

export const Button = styled(ButtonGray).attrs(() => ({
  padding: '8px',
  $borderRadius: '10px',
}))`
  color: ${({ theme }) => theme.textPrimary};
  background-color: #2A393E  ;
  flex: 1;
`
