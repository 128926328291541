import { useEffect, useState } from "react";
import { Staking, StakerWallet } from "@eltk/staking-sdk";
import { Signer, providers } from "ethers";
import { useSignerWallet } from "hooks/useSignerWallet";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import {
  StakingClient,
  apolloClient,
  voteEscrowClient,
} from "graphql/thegraph/apollo";
//TODO
const GET_LP_TOKEN_IDS = gql`
  query MyQuery($account: String!) {
    deposits(where: { from: $account }) {
      tokenId
    }
  }
`;

export const useGetLPTokenIds = (account: any) => {
  const { loading, error, data } = useQuery(GET_LP_TOKEN_IDS, {
    variables: { account },
    client: StakingClient,
  });
  return {
    data,
  };
};
