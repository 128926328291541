import { feeTierPercent } from "utils";
import { FeeTierSpan } from "./styled";

export default function FeeTier({ position }: any) {
  return (
    <FeeTierSpan >
      {feeTierPercent(position?.fee)} &nbsp;
      Fee tier
    </FeeTierSpan>
  );
}
