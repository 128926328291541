import { sendAnalyticsEvent, TraceEvent } from "@uniswap/analytics";
import {
  BrowserEvent,
  InterfaceElementName,
  InterfaceEventName,
} from "@uniswap/analytics-events";
import { useWeb3React } from "@web3-react/core";
import PortfolioDrawer, { useAccountDrawer } from "components/AccountDrawer";
import PrefetchBalancesWrapper from "components/AccountDrawer/PrefetchBalancesWrapper";
import { useGetConnection } from "connection";
import { useCallback, useMemo } from "react";
import { useAppSelector } from "state/hooks";
import {
  isTransactionRecent,
  useAllTransactions,
} from "../../state/transactions/hooks";
import { shortenAddress } from "../../utils";
import { RowBetween } from "../Row";
import { newTransactionsFirst } from "utils/newTransactionFirst";
import {
  AddressAndChevronContainer,
  NetworkIcon,
  StyledConnectButton,
  Text,
  TextMobile,
  Web3StatusConnected,
  Web3StatusConnectWrapper,
  Web3StatusError,
} from "./styled";
import { useIsMobile } from "utils/common";
import { Portal } from "components/Portal";
import { truncateFormatPriceImpact } from "components/CurrencyInputPanel/limitFiatValue";

function Web3StatusInner() {
  const { account, connector, chainId, ENSName } = useWeb3React();
  const getConnection = useGetConnection();
  const isMobile = useIsMobile();
  const connection = getConnection(connector);
  const [, toggleAccountDrawer] = useAccountDrawer();
  const handleWalletDropdownClick = useCallback(() => {
    sendAnalyticsEvent(InterfaceEventName.ACCOUNT_DROPDOWN_BUTTON_CLICKED);
    toggleAccountDrawer();
  }, [toggleAccountDrawer]);

  const error = useAppSelector(
    (state) =>
      state.connection.errorByConnectionType[getConnection(connector).type]
  );

  const allTransactions = useAllTransactions();

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions);
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst);
  }, [allTransactions]);

  const pending = sortedRecentTransactions
    .filter((tx) => !tx.receipt)
    .map((tx) => tx.hash);

  const hasPendingTransactions = !!pending.length;

  if (!chainId) {
    return null;
  } else if (account) {
    return (
      <TraceEvent
        events={[BrowserEvent.onClick]}
        name={InterfaceEventName.MINI_PORTFOLIO_TOGGLED}
        properties={{ type: "open" }}
      >
        <Web3StatusConnected
          data-testid="web3-status-connected"
          onClick={handleWalletDropdownClick}
          pending={hasPendingTransactions}
        >
          <AddressAndChevronContainer>
            {isMobile ? (
              <TextMobile>{ENSName || shortenAddress(account)}</TextMobile>
            ) : (
              <Text>{ENSName || shortenAddress(account)}</Text>
            )}
          </AddressAndChevronContainer>
        </Web3StatusConnected>
      </TraceEvent>
    );
  } else {
    return (
      <TraceEvent
        events={[BrowserEvent.onClick]}
        name={InterfaceEventName.CONNECT_WALLET_BUTTON_CLICKED}
        element={InterfaceElementName.CONNECT_WALLET_BUTTON}
      >
        <Web3StatusConnectWrapper
          tabIndex={0}
          faded={!account}
          onKeyPress={(e) => e.key === "Enter" && handleWalletDropdownClick()}
          onClick={handleWalletDropdownClick}
        >
          <StyledConnectButton
            tabIndex={-1}
            data-testid="navbar-connect-wallet"
          >
            <div>{isMobile ? "Connect" : "Connect Wallet"}</div>
          </StyledConnectButton>
        </Web3StatusConnectWrapper>
      </TraceEvent>
    );
  }
}

export default function Web3Status() {
  return (
    <PrefetchBalancesWrapper>
      <Web3StatusInner />
      <Portal>
        <PortfolioDrawer />
      </Portal>
    </PrefetchBalancesWrapper>
  );
}
