import { sendAnalyticsEvent, Trace, user } from "@uniswap/analytics";
import {
  CustomUserProperties,
  getBrowser,
  SharedEventName,
} from "@uniswap/analytics-events";
import { useWeb3React } from "@web3-react/core";
import { ElektrikLoader } from "components/Icons/LoadingSpinner";
import { useFeatureFlagsIsLoaded } from "featureFlags";
import { Suspense, useEffect, lazy } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useIsDarkMode } from "theme/components/ThemeToggle";
import { getCurrentPageFromLocation } from "utils/urlRoutes";
import { getCLS, getFCP, getFID, getLCP, Metric } from "web-vitals";

import { useAnalyticsReporter } from "../components/analytics";
import ErrorBoundary from "../components/ErrorBoundary";
import { BottomPageTabs } from "../components/NavBar";
import NavBar from "../components/NavBar";
import Polling from "../components/Polling";
import Popups from "../components/Popups";
import DarkModeQueryParamReader from "../theme/components/DarkModeQueryParamReader";
import IncreaseLiquidity from "./IncreaseLiquidity";

import { RedirectDuplicateTokenIds } from "./AddLiquidity/redirects";
import BodyWrapper from "./BodyWrapper";
import NotFound from "./NotFound";
import Pool from "./Pool";
import PositionPage from "./Pool/PositionPage";
import RemoveLiquidityV3 from "./RemoveLiquidity/V3";
import Swap from "./Swap";
import { RedirectPathToSwapOnly } from "./Swap/redirects";
import Version from "components/Version";
import { connectionSocket, socket } from "sockets";
import { useIsNavMobile } from "utils/common";
import {
  HeaderWrapper,
  MobileBottomBar,
  TwitterXIcon,
  CopyRight,
  LeftSideFooter,
  RightSideFooter,
  DiscordXIcon,
} from "./app.styled";
import * as styles from "../components/NavBar/style.css";
import { ReactComponent as ElektrikLogoWhite } from "assets/svg/elektrik_logo_white.svg";
import { ReactComponent as ElektrikLogo } from "assets/svg/elektrik_logo.svg";
import { Row } from "components/Flex";
import { Box } from "components/Box";
import PoolPage from "./Pool/PoolPage";
import PoolDetail from "./Pool/PoolDetail";
import Positions from "./Pool/Positions";
import PoolsPosition from "./Pool/PoolsPosition";
import ScrollToTopOnNavigate from "./ScrollToTop";
import { ELEKTRIK_DISCORD,  ELEKTRIK_X } from "constants/links";
import MobileMessage from "components/MobileMessage";

const LimitOrders = lazy(() => import("pages/LimitOrders"));
const Governance = lazy(() => import("pages/Governance"));
const Locking = lazy(() => import("pages/Locking"));
const LockingDetailsPage = lazy(() => import("pages/Locking/LockingDetails"));
const TWAP = lazy(() => import("pages/TwapOrders"))

export default function App() {
  const isLoaded = useFeatureFlagsIsLoaded();
  const { account, chainId } = useWeb3React();

  const { pathname } = useLocation();
  const currentPage = getCurrentPageFromLocation(pathname);
  const isDarkMode = useIsDarkMode();
  const IsNavMobile = useIsNavMobile();
  useAnalyticsReporter();



  useEffect(() => {

    user.set(CustomUserProperties.USER_AGENT, navigator.userAgent);
    user.set(CustomUserProperties.BROWSER, getBrowser());
    user.set(
      CustomUserProperties.SCREEN_RESOLUTION_HEIGHT,
      window.screen.height
    );
    user.set(CustomUserProperties.SCREEN_RESOLUTION_WIDTH, window.screen.width);

    const isServiceWorkerInstalled = Boolean(
      window.navigator.serviceWorker?.controller
    );
    const isServiceWorkerHit = Boolean((window as any).__isDocumentCached);
    const serviceWorkerProperty = isServiceWorkerInstalled
      ? isServiceWorkerHit
        ? "hit"
        : "miss"
      : "uninstalled";

    const pageLoadProperties = { service_worker: serviceWorkerProperty };
    sendAnalyticsEvent(SharedEventName.APP_LOADED, pageLoadProperties);
    const sendWebVital =
      (metric: string) =>
        ({ delta }: Metric) =>
          sendAnalyticsEvent(SharedEventName.WEB_VITALS, {
            ...pageLoadProperties,
            [metric]: delta,
          });
    getCLS(sendWebVital("cumulative_layout_shift"));
    getFCP(sendWebVital("first_contentful_paint_ms"));
    getFID(sendWebVital("first_input_delay_ms"));
    getLCP(sendWebVital("largest_contentful_paint_ms"));
  }, []);

  useEffect(() => {
    user.set(CustomUserProperties.DARK_MODE, isDarkMode);
  }, [isDarkMode]);

  useEffect(() => {
    if (account) {
      connectionSocket(account, chainId);
    }
  }, [account, socket?.connected]);
//INDEX
  return (
    <ErrorBoundary>
      <DarkModeQueryParamReader />
      <Trace page={currentPage}>
        <MobileMessage />
        <HeaderWrapper transparent={true}>
          <NavBar blur={true} />
        </HeaderWrapper>
        <BodyWrapper>
          <Popups />
          <Polling />
          {/* <Version /> */}

          <Suspense fallback={<ElektrikLoader fill={true} />}>
            <ScrollToTopOnNavigate />
            {isLoaded ? (
              <Routes>

                <Route path="/" element={<RedirectPathToSwapOnly />} />

                <Route path="send" element={<RedirectPathToSwapOnly />} />
                <Route path="swap" element={<Swap />} />
                <Route path="twap" element={<TWAP />} />

                <Route path="limit" element={<LimitOrders />} />
                <Route path="governance/:lockId" element={<Governance />} />
                <Route path="locking" element={<Locking />} />
                <Route path="locking/details/:tokenId" element={<LockingDetailsPage />} />

                <Route path="positions" element={<Positions />} />

                <Route path="positions/:tokenId" element={<PositionPage />} />
                <Route path="pool" element={<Pool />} />
                <Route path="pool/:tokenId" element={<PositionPage />} />

                <Route path="pools" element={<Pool />} />
                <Route path="poolPosition" element={<PoolsPosition />} />

                <Route path="pools/single/:poolId" element={<PoolPage />}>
                  <Route path="detail" element={<PoolDetail />} />
                </Route>
                <Route path="add" element={<RedirectDuplicateTokenIds />}>
                  <Route path=":currencyIdA" />
                  <Route path=":currencyIdA/:currencyIdB" />
                  <Route path=":currencyIdA/:currencyIdB/:feeAmount" />
                </Route>

                <Route path="increase" element={<IncreaseLiquidity />}>
                  <Route path=":currencyIdA" />
                  <Route path=":currencyIdA/:currencyIdB" />
                  <Route path=":currencyIdA/:currencyIdB/:feeAmount" />
                  <Route path=":currencyIdA/:currencyIdB/:feeAmount/:tokenId" />
                </Route>

                <Route path="remove/:tokenId" element={<RemoveLiquidityV3 />} />

                <Route
                  path="*"
                  element={<Navigate to="/not-found" replace />}
                />
                <Route path="/not-found" element={<NotFound />} />
              </Routes>
            ) : (
              <ElektrikLoader fill={true} />
            )}
          </Suspense>
        </BodyWrapper>
        <>
          <MobileBottomBar>
            <LeftSideFooter>
              <Box className={styles.logoContainer}>
                {IsNavMobile ? (
                  <ElektrikLogo
                    width={40}
                    height={40}
                    style={{ marginRight: "0px" }}
                  />
                ) : (
                  <ElektrikLogoWhite width={160} height={38} />
                )}
              </Box>
              <span>&nbsp;&nbsp;&nbsp;</span>

              <Row display={{ sm: "none", lg: "flex" }}>
                <CopyRight>2024 Copyright. All rights protected</CopyRight>
              </Row>
            </LeftSideFooter>

            <RightSideFooter>
              <Row display={{ sm: "grid", lg: "flex" }}>
                <BottomPageTabs /> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
              </Row>

              <Row gap="20" style={{ justifyContent: "center" }}>
                <a href={ELEKTRIK_X} target="_blank">
                  <TwitterXIcon size={18} />
                </a>
                <a href={ELEKTRIK_DISCORD} target="_blank">
                  <DiscordXIcon size={18} />
                </a>
              </Row>
            </RightSideFooter>
          </MobileBottomBar>
        </>
      </Trace>
    </ErrorBoundary>
  );
}
