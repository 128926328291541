import { useWeb3React } from "@web3-react/core";
import { parseLocalActivity } from "components/AccountDrawer/MiniPortfolio/Activity/parseLocal";
import { PortfolioLogo } from "components/AccountDrawer/MiniPortfolio/PortfolioLogo";
import PortfolioRow from "components/AccountDrawer/MiniPortfolio/PortfolioRow";
import Column from "components/Column";
import { useAllTokensMultichain, useCurrency } from "hooks/Tokens";
import useENSName from "hooks/useENSName";
import { useTransaction } from "state/transactions/hooks";
import { TransactionDetails } from "state/transactions/types";
import styled, { css } from "styled-components/macro";
import { EllipsisStyle, ExternalLinkDeprecated, ThemedText } from "theme";
import { ExplorerDataType, getExplorerLink } from "utils/getExplorerLink";

import tokenData from "../../constants/tokenLists/info.tokelist.json";
import { PopupAlertTriangle } from "./styled";
import { InfoIcon } from "components/Icons/shared";
import CurrencyLogo from "components/Logo/CurrencyLogo";

const Descriptor = styled(ThemedText.BodySmall)`
  ${EllipsisStyle}
`;

const LogoImage = styled.img<{ useBG?: boolean }>`
  width: 35px;
  height: 35px;
  border-radius: 50%;

  ${({ useBG }) =>
    useBG &&
    css`
      background: radial-gradient(white 60%, #ffffff00 calc(70% + 1px));
      box-shadow: 0 0 1px white;
    `}
`;
function TransactionPopupContent({
  tx,
  chainId,
}: {
  tx: TransactionDetails;
  chainId: number;
}) {
  const success = tx.receipt?.status === 1;
  const tokens = useAllTokensMultichain();
  const activity = parseLocalActivity(tx, chainId, tokens);
  const { ENSName } = useENSName(activity?.otherAccount);
  if (!activity) return null;

  const explorerUrl = getExplorerLink(
    chainId,
    tx.hash,
    ExplorerDataType.TRANSACTION
  );

  return (
    <PortfolioRow
      left={
        success ? (
          <Column>
            <PortfolioLogo
              chainId={chainId}
              currencies={activity.currencies}
              images={activity.logos}
              accountAddress={activity.otherAccount}
            />
          </Column>
        ) : (
          <PopupAlertTriangle />
        )
      }
      title={
        <ThemedText.SubHeader>
          {activity.title === "Swapped"
            ? "Swapped Successfully"
            : activity.title}
        </ThemedText.SubHeader>
      }
      descriptor={
        <Descriptor color="textPrimary">
          {activity.descriptor}
          {ENSName ?? activity.otherAccount}
        </Descriptor>
      }
      onClick={() => window.open(explorerUrl, "_blank")}
    />
  );
}

export default function TransactionPopup({ hash }: { hash: string }) {
  const { chainId } = useWeb3React();

  const tx = useTransaction(hash);

  if (!chainId || !tx || tx?.info?.type === 12) return null;

  return <TransactionPopupContent tx={tx} chainId={chainId} />;
}

export function LimitOrderSuccessTransactionPopUp({ hash }: { hash: string }) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <svg
        data-testid="confirmed-icon"
        width="54"
        height="54"
        viewBox="0 0 54 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="Logos__AnimatedEntranceConfirmationIcon-sc-5dvjo4-6 ekwhED"
      >
        <path
          d="M27 0.333008C12.28 0.333008 0.333313 12.2797 0.333313 26.9997C0.333313 41.7197 12.28 53.6663 27 53.6663C41.72 53.6663 53.6666 41.7197 53.6666 26.9997C53.6666 12.2797 41.72 0.333008 27 0.333008ZM37.7466 22.1997L25.2933 34.6263C24.9199 35.0263 24.4133 35.2131 23.8799 35.2131C23.3733 35.2131 22.8666 35.0263 22.4666 34.6263L16.2533 28.4131C15.48 27.6398 15.48 26.3596 16.2533 25.5863C17.0266 24.8129 18.3066 24.8129 19.08 25.5863L23.8799 30.3864L34.92 19.373C35.6933 18.573 36.9733 18.573 37.7466 19.373C38.52 20.1464 38.52 21.3997 37.7466 22.1997Z"
          fill="#76D191"
        ></path>
      </svg>
      &nbsp; Successfully Order Placed
    </div>
  );
}

export function LimitOrderFailedTransactionPopUp({ hash }: { hash: string }) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <svg
        data-testid="failure-icon"
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="Logos__AnimatedEntranceFailureIcon-sc-5dvjo4-6 ekwhED"
      >
        <path
          d="M27 0.333008C12.28 0.333008 0.333313 12.2797 0.333313 26.9997C0.333313 41.7197 12.28 53.6663 27 53.6663C41.72 53.6663 53.6666 41.7197 53.6666 26.9997C53.6666 12.2797 41.72 0.333008 27 0.333008ZM27 49.6663C13.1866 49.6663 2.33331 38.813 2.33331 26.9997C2.33331 15.1863 13.1866 4.33301 27 4.33301C40.8133 4.33301 51.6666 15.1863 51.6666 26.9997C51.6666 38.813 40.8133 49.6663 27 49.6663Z"
          fill="#FF6F61"
        />
        <path
          d="M36.0006 16.7502L34.2499 15L27.0006 22.2493L19.7506 15L19.0006 16.7502L26.2506 24L19.0006 31.25L19.7506 33.0002L27.0006 25.7499L34.2499 33.0002L35.9999 31.25L28.7506 24L35.9999 16.7502L36.0006 16.7502Z"
          fill="#FF6F61"
        />
      </svg>{" "}
      &nbsp; Transaction Failed
    </div>
  );
}

export function EthSignEnabled({ hash }: { hash: string }) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <svg
        data-testid="failure-icon"
        width="24"
        height="24"
        viewBox="0 0 54 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="Logos__AnimatedEntranceFailureIcon-sc-5dvjo4-6 ekwhED"
      >
        <path
          d="M27 0.333008C12.28 0.333008 0.333313 12.2797 0.333313 26.9997C0.333313 41.7197 12.28 53.6663 27 53.6663C41.72 53.6663 53.6666 41.7197 53.6666 26.9997C53.6666 12.2797 41.72 0.333008 27 0.333008ZM27 49.6663C13.1866 49.6663 2.33331 38.813 2.33331 26.9997C2.33331 15.1863 13.1866 4.33301 27 4.33301C40.8133 4.33301 51.6666 15.1863 51.6666 26.9997C51.6666 38.813 40.8133 49.6663 27 49.6663Z"
          fill="#FF6F61"
        />
        <path
          d="M36.0006 16.7502L34.2499 15L27.0006 22.2493L19.7506 15L19.0006 16.7502L26.2506 24L19.0006 31.25L19.7506 33.0002L27.0006 25.7499L34.2499 33.0002L35.9999 31.25L28.7506 24L35.9999 16.7502L36.0006 16.7502Z"
          fill="#FF6F61"
        />
      </svg>{" "}
      &nbsp;{" "}
      <ExternalLinkDeprecated
        style={{ fontSize: 14, fontWeight: 500, textAlign: "center" }}
        href="https://guides.instadapp.io/features/private-transactions/how-to-enable-eth_sign-on-metamask"
      >
        Enable Your Eth Sign Request{" "}
        <InfoIcon
          content={"Enable Your ETh Sign Request by clicking the text"}
        />
      </ExternalLinkDeprecated>
    </div>
  );
}

export function CancelOrderSuccess({ hash }: { hash: string }) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <svg
        data-testid="confirmed-icon"
        width="54"
        height="54"
        viewBox="0 0 54 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="Logos__AnimatedEntranceConfirmationIcon-sc-5dvjo4-6 ekwhED"
      >
        <path
          d="M27 0.333008C12.28 0.333008 0.333313 12.2797 0.333313 26.9997C0.333313 41.7197 12.28 53.6663 27 53.6663C41.72 53.6663 53.6666 41.7197 53.6666 26.9997C53.6666 12.2797 41.72 0.333008 27 0.333008ZM37.7466 22.1997L25.2933 34.6263C24.9199 35.0263 24.4133 35.2131 23.8799 35.2131C23.3733 35.2131 22.8666 35.0263 22.4666 34.6263L16.2533 28.4131C15.48 27.6398 15.48 26.3596 16.2533 25.5863C17.0266 24.8129 18.3066 24.8129 19.08 25.5863L23.8799 30.3864L34.92 19.373C35.6933 18.573 36.9733 18.573 37.7466 19.373C38.52 20.1464 38.52 21.3997 37.7466 22.1997Z"
          fill="#76D191"
        ></path>
      </svg>
      &nbsp; Order Cancelled
    </div>
  );
}
export function CancelOrderFailure({ hash }: { hash: string }) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <svg
        data-testid="failure-icon"
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="Logos__AnimatedEntranceFailureIcon-sc-5dvjo4-6 ekwhED"
      >
        <path
          d="M27 0.333008C12.28 0.333008 0.333313 12.2797 0.333313 26.9997C0.333313 41.7197 12.28 53.6663 27 53.6663C41.72 53.6663 53.6666 41.7197 53.6666 26.9997C53.6666 12.2797 41.72 0.333008 27 0.333008ZM27 49.6663C13.1866 49.6663 2.33331 38.813 2.33331 26.9997C2.33331 15.1863 13.1866 4.33301 27 4.33301C40.8133 4.33301 51.6666 15.1863 51.6666 26.9997C51.6666 38.813 40.8133 49.6663 27 49.6663Z"
          fill="#FF6F61"
        />
        <path
          d="M36.0006 16.7502L34.2499 15L27.0006 22.2493L19.7506 15L19.0006 16.7502L26.2506 24L19.0006 31.25L19.7506 33.0002L27.0006 25.7499L34.2499 33.0002L35.9999 31.25L28.7506 24L35.9999 16.7502L36.0006 16.7502Z"
          fill="#FF6F61"
        />
      </svg>{" "}
      &nbsp; Order Cancellation Failed
    </div>
  );
}

export function EditOrderSuccess({ hash }: { hash: string }) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <svg
        data-testid="confirmed-icon"
        width="54"
        height="54"
        viewBox="0 0 54 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="Logos__AnimatedEntranceConfirmationIcon-sc-5dvjo4-6 ekwhED"
      >
        <path
          d="M27 0.333008C12.28 0.333008 0.333313 12.2797 0.333313 26.9997C0.333313 41.7197 12.28 53.6663 27 53.6663C41.72 53.6663 53.6666 41.7197 53.6666 26.9997C53.6666 12.2797 41.72 0.333008 27 0.333008ZM37.7466 22.1997L25.2933 34.6263C24.9199 35.0263 24.4133 35.2131 23.8799 35.2131C23.3733 35.2131 22.8666 35.0263 22.4666 34.6263L16.2533 28.4131C15.48 27.6398 15.48 26.3596 16.2533 25.5863C17.0266 24.8129 18.3066 24.8129 19.08 25.5863L23.8799 30.3864L34.92 19.373C35.6933 18.573 36.9733 18.573 37.7466 19.373C38.52 20.1464 38.52 21.3997 37.7466 22.1997Z"
          fill="#76D191"
        ></path>
      </svg>
      &nbsp; Order Succesfully Edited
    </div>
  );
}

export function EditOrderFailure({ hash }: { hash: string }) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <svg
        data-testid="failure-icon"
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="Logos__AnimatedEntranceFailureIcon-sc-5dvjo4-6 ekwhED"
      >
        <path
          d="M27 0.333008C12.28 0.333008 0.333313 12.2797 0.333313 26.9997C0.333313 41.7197 12.28 53.6663 27 53.6663C41.72 53.6663 53.6666 41.7197 53.6666 26.9997C53.6666 12.2797 41.72 0.333008 27 0.333008ZM27 49.6663C13.1866 49.6663 2.33331 38.813 2.33331 26.9997C2.33331 15.1863 13.1866 4.33301 27 4.33301C40.8133 4.33301 51.6666 15.1863 51.6666 26.9997C51.6666 38.813 40.8133 49.6663 27 49.6663Z"
          fill="#FF6F61"
        />
        <path
          d="M36.0006 16.7502L34.2499 15L27.0006 22.2493L19.7506 15L19.0006 16.7502L26.2506 24L19.0006 31.25L19.7506 33.0002L27.0006 25.7499L34.2499 33.0002L35.9999 31.25L28.7506 24L35.9999 16.7502L36.0006 16.7502Z"
          fill="#FF6F61"
        />
      </svg>{" "}
      &nbsp; Order Failed to Edit
    </div>
  );
}
export function OrderStatusChange({ hash }: any) {
  // const sellTokenInfo = (tokenData as any)?.tokens[hash.sellTokenAddress];
  // const buyTokenInfo = (tokenData as any)?.tokens[hash.buyTokenAddress];
  const sellTokenInfo = useCurrency(hash?.sellTokenAddress)?.wrapped;
  const buyTokenInfo = useCurrency(hash?.buyTokenAddress)?.wrapped;
  const sellImage = (sellTokenInfo as any)?.logoURI;

  const buyImage = (buyTokenInfo as any)?.logoURI;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      &nbsp;
      {sellTokenInfo && <CurrencyLogo currency={sellTokenInfo} size="35px" />}
      <div style={{ position: "relative", left: "-10px" }}>
        {buyTokenInfo && <CurrencyLogo currency={buyTokenInfo} size="35px" />}{" "}
      </div>
      Order Status Changed
    </div>
  );
}

export function OrderProcessed({ hash }: any) {
  
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      Claim in Process
    </div>
  );
}

export function OrderAlreadyProcessed({ hash }: any) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      Claim Already Processed
    </div>
  );
}

export function Sucess({ hash }: any) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>{hash.success}</div>
  );
}

export function Error({ hash }: any) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>{hash?.error}</div>
  );
}
