import { useEffect, useState } from "react";
import { Staking, StakerWallet } from "@eltk/staking-sdk";
import { Signer, providers } from "ethers";
import { useSignerWallet } from "hooks/useSignerWallet";
import { useStaking } from "./useStaking";

export const useGetPoolAllocPoints = (poolId: any) => {
  const [poolRewards, setPoolRewards] = useState<any>(null);
  const { staking } = useStaking();

  useEffect(() => {
    const getpoolAllocPoints = async () => {
      try {

        if (staking && poolId) {
          const allocPoint = await staking.poolAllocPoints(poolId.toString());
          setPoolRewards(allocPoint);
        }
      } catch (error) {
        console.error(`ErrorfetchingPoolsRewards:`, error);
      }
    };

    getpoolAllocPoints();
  }, [staking, poolId]);

  return {
    poolRewards,
  };
};
