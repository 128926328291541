import { AlertTriangle, Slash } from "react-feather";
import { useTheme } from "styled-components/macro";

import { MouseoverTooltip } from "../../components/Tooltip";
import { ActiveDot, BadgeText, BadgeWrapper, LabelText } from "./styled";

export default function RangeBadge({
  removed,
  inRange,
}: {
  removed?: boolean;
  inRange?: boolean;
}) {
  const theme = useTheme();
  return (
    <BadgeWrapper>
      {removed ? (
        <MouseoverTooltip
          text={
            <div>
              Your position has 0 liquidity, and is not earning fees.
            </div>
          }
        >
          <LabelText color={"red"}>
            <Slash width={12} height={12} />

            <BadgeText>
              <div>Closed</div>
            </BadgeText>
          </LabelText>
        </MouseoverTooltip>
      ) : inRange ? (
        <MouseoverTooltip
          text={
            <div>
              The price of this pool is within your selected range. Your
              position is currently earning fees.
            </div>
          }
        >
          <LabelText color={theme.accentSuccess}>
            <ActiveDot />
            <BadgeText>
              <div>In Range</div>
            </BadgeText>
          </LabelText>
        </MouseoverTooltip>
      ) : (
        <MouseoverTooltip
          text={
            <div>
              The price of this pool is outside of your selected range. Your
              position is not currently earning fees.
            </div>
          }
        >
          <LabelText color={theme.accentWarning}>
            <AlertTriangle width={12} height={12} />
            <BadgeText>
              <div>Out of range</div>
            </BadgeText>
          </LabelText>
        </MouseoverTooltip>
      )}
    </BadgeWrapper>
  );
}
