import { AutoColumn, ColumnCenter } from "components/Column";
import styled, { keyframes } from "styled-components";

export const AnimatedConfirmationWrapper = styled.div<{ size?: string }>`
  height: 90px;
  width: 90px;
`;

export const dash = keyframes`
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

export const dashCheck = keyframes`
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
`;

export const Circle = styled.circle`
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  -webkit-animation: ${dash} 0.9s ease-in-out;
  animation: ${dash} 0.9s ease-in-out;
`;

export const PolyLine = styled.polyline`
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  stroke-dashoffset: -100;
  -webkit-animation: ${dashCheck} 0.9s 0.35s ease-in-out forwards;
  animation: ${dashCheck} 0.9s 0.35s ease-in-out forwards;
`;
export const Wrapper = styled.div`

--border-width: 2px;
--border-radius: 12px;
background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
border: 0;
border-radius: 12px;
outline: 1px solid ${({ theme }) => theme.backgroundOutline};
width: 100%;
padding: 30px;
position: relative;
border-radius: var(--border-radius);
z-index: 1;
&::before {
  content: "";
  display: block;
  height: calc(
    100% - (var(--border-width) * 2)
  ); /* Corrected the syntax here */
  width: calc(
    100% - (var(--border-width) * 2)
  ); /* Corrected the syntax here */

  background:${({ theme }) => theme.backgroundSurface} ;

  position: absolute;

  top: var(--border-width);
  left: var(--border-width);

  border-radius: calc(var(--border-radius) - var(--border-width));

  z-index: -1;
`;

export const BottomSection = styled(AutoColumn)`
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

export const ConfirmedIcon = styled(ColumnCenter)<{ inline?: boolean }>`
  padding: ${({ inline }) => (inline ? "20px 0" : "32px 0;")};
`;

export const StyledLogo = styled.img`
  height: 16px;
  width: 16px;
  margin-left: 6px;
`;

export const ConfirmationModalContentWrapper = styled(AutoColumn)`
  padding-bottom: 12px;
`;
