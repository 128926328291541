import styled from "styled-components";
import { Z_INDEX } from "theme/zIndex";

export const SwapWrapWrapper = styled.main<{ chainId?: number }>`
  width: 100%;
  flex-shrink: 0;

  position: relative;
  --border-width: 2px;
  --border-radius: 12px;

  background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);

  border: 0;

  padding: 34px 43px;

  position: relative;

  border-radius: var(--border-radius);
  z-index: ${Z_INDEX.default};

  &::before {
    content: "";
    display: block;
    height: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    width: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    border-radius: 16px;

    background: #091012;

    position: absolute;

    top: var(--border-width);
    left: var(--border-width);
    z-index: ${Z_INDEX.negative};
  }
  @media (max-width: 768px) {
    max-width: 100%;
    padding: 1rem;
  }
`;
export const TitleSection = styled.div`
`;

export const TitleSectionBottom = styled.div`
  margin: 0px 0px;
`;
export const TitleHeading = styled.div`
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 150%; /* 36px */
  margin: 0px 0px 20px 0px;
`;
export const TitleHeadingSub = styled.h3`
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
`;

// Styled component for the checkbox
// export const StyledCheckbox = styled.input`
//   width: 18px;
//   height: 18px;
//   flex-shrink: 0;
//   border-radius: 4px;
//   background: #1F2831;
//   margin-right: 8px; /* Adjust as needed for spacing between checkbox and text */
// `;

export const TitleStrong = styled.p`
  color: #5d7279;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 16.8px */
  display: "flex";
  justify-content: "center";
  align-items: "center";
`;

export const TitleStrongCheck = styled.div`
  color: #5d7279;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 16.8px */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: "left";

  & > label {
    display: flex;
    align-items: center;
  }
`;

export const StyledList = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  liststyle: none;
  margin: 0px 0px 28px;
  padding: 0px;
  font-size: 14px;
  gap: 10px 12px;
`;

export const StyledListItem = styled.ul`
  background: transparent;
  display: grid;
  grid-template-columns: 20px auto;
  alignitems: flex-start;
  justify-content: center;
  gap: 6px;
  margin: 0px;
  padding: 10px;
  border-radius: 10px;
  position: relative;
`;

// export const StyledCheckbox = styled.input`
//   /* Reset default styles */
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
//   outline: none; /* Remove default outline on focus */

//   /* Your custom styles */
//   border-radius: 4px;
//   width: 18px;
//   height: 18px;
//   background: #1F2831;

//   /* Additional styling if needed */
//   /* border: 0.25rem solid green; */
//   font-size: 2rem;

//   /* Custom styles for checked state */
//   &:checked {
//     background: #YourCustomColorForChecked;
//   }
// `;

export const Container = styled.label`
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  user-select: none;
  color: #5d7279;

  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: #ccc;
  }
`;

export const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  border: #fff;
  color: "#000000";
`;


export const CheckmarkIntro = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #1F2831;
  border-radius: 4px;

  ${CheckboxInput}:checked ~ & {
    background: linear-gradient(91deg, #a1eeff 7.28%, #029af0 89.82%);
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  &:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  ${CheckboxInput}:checked ~ &:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  &:after {
    content: "";
    position: absolute;
    left: 6px;
    top: 4px;
    width: 3px;
    height: 7px;
    border: solid black; /* Set border color to black */
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

export const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #091012;
  border-radius: 4px;
  border: 1px solid #a1eeff;

  ${CheckboxInput}:checked ~ & {
    background: linear-gradient(91deg, #a1eeff 7.28%, #029af0 89.82%);
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  &:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  ${CheckboxInput}:checked ~ &:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  &:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 2px;
    width: 3px;
    height: 7px;
    border: solid black; /* Set border color to black */
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(3, 4, 5, 0.2) 0%,
    rgba(1, 14, 18, 0.2) 100%
  );
  backdrop-filter: blur(5px);
  z-index: 9; /* Adjust the z-index to be behind the modal */
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContainer = styled.div`
  position: relative;
  width: 420px;
  height: 370px;
  padding: 10px;
  box-sizing: border-box;
  z-index: 10;

  position: relative;
  --border-width: 2px;
  --border-radius: 20px;
  background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
  border: 0;
  padding: 25px 25px;
  position: relative;
  border-radius: var(--border-radius);
  z-index: ${Z_INDEX.default};
  &::before {
    content: "";
    display: block;
    height: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    width: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    background: ${({ theme }) => theme.backgroundSurface};
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: calc(var(--border-radius) - var(--border-width));
    z-index: ${Z_INDEX.negative};
  }
`;
