import { useWeb3React } from '@web3-react/core'
import WalletModal from 'components/WalletModal'
import { useCallback, useRef, useState } from 'react'

import AuthenticatedHeader from './AuthenticatedHeader'
import SettingsMenu from './SettingsMenu'
import { DefaultMenuWrap } from './styled'
import { MenuState } from 'types'
import useToggle from 'hooks/useToggle'
import { useOnClickOutside } from 'hooks/useOnClickOutside'


function DefaultMenu() {
  
  const { account } = useWeb3React()
  const isAuthenticated = !!account

  const [menu, setMenu] = useState<MenuState>(MenuState.DEFAULT)
  const openSettings = useCallback(() => setMenu(MenuState.SETTINGS), [])
  const closeSettings = useCallback(() => setMenu(MenuState.DEFAULT), [])

  return (
    <DefaultMenuWrap>
      {menu === MenuState.DEFAULT &&
        (isAuthenticated ? (
          <AuthenticatedHeader account={account} openSettings={openSettings} />
        ) : (
          <WalletModal openSettings={openSettings} />
        ))}
      {menu === MenuState.SETTINGS && <SettingsMenu onClose={closeSettings} />}
    </DefaultMenuWrap>
  )
}

export default DefaultMenu
