import { useEffect, useState } from "react";

const useGetTotalValueLocked = (poolData:any) => {
    const [totalValueLocked, setTotalValueLocked] = useState(0);
    const [loading, setLoading] = useState(true); 

    useEffect(() => {
        if (Array.isArray(poolData)) {
            let sum = 0;
            poolData.forEach(pool => {
                sum += parseFloat(pool.totalValueLockedUSD);
            });
            setTotalValueLocked(sum);
            setLoading(false); 
        }
    }, [poolData]);

    return { totalValueLocked, loading }; 
};

export default useGetTotalValueLocked;
