import * as styles from "../../components/NavBar/style.css";
import React from "react";
import { DiscoverPoolGradient } from "components/swap/styleds";

interface MenuItemProps {
  isActive?: boolean;
  children: React.ReactNode;
  onClick: () => void;
}

const MenuItemPool = ({ onClick, isActive, children }: MenuItemProps) => {
  return (
    <p
      className={isActive ? styles.activeMenuItem : styles.menuItem}
      style={{
        textDecoration: "none",
        fontSize: "14px",
        marginLeft: "0px",
        marginRight: "0px",
        width: "auto",
      }}
      onClick={onClick}
    >
      {children}
    </p>
  );
};

export const PoolTableTabs = ({ active, changeTab }: any) => {
  return (
    <>
      <MenuItemPool onClick={() => changeTab(1)} isActive={active === 1}>
        <div>
          <span style={{ cursor: "pointer" }}>
            {active === 1 ? (
              <DiscoverPoolGradient>Discover LP Pools</DiscoverPoolGradient>
            ) : (
              <span>Discover LP Pools</span>
            )}
          </span>
        </div>
      </MenuItemPool>
      <MenuItemPool onClick={() => changeTab(2)} isActive={active === 2}>
        <div>
          {active === 2 ? (
            <DiscoverPoolGradient>Create New Pool</DiscoverPoolGradient>
          ) : (
            <span style={{cursor: 'pointer'}}>Create New Pool</span>
          )}
        </div>
      </MenuItemPool>
    </>
  );
};
