import { Fraction, Percent } from '@uniswap/sdk-core'
import { SupportedChainId } from './chains'
import { STAKING_GENESIS } from 'state/stake/hooks'

export const MINIMUM_RECOMMENDED_SLIPPAGE = new Percent(5, 10_000)
export const MAXIMUM_RECOMMENDED_SLIPPAGE = new Percent(1, 100)

export const V2_DEFAULT_FEE_TIER = 3000
export const PRICE_FIXED_DIGITS = 8
export const PRECISION = 10000
export const ONE = new Fraction(1, 1)

//ComuteUnicirculations

export const STAKING_END = STAKING_GENESIS + 60 * 60 * 24 * 60

export const TREASURY_VESTING_GENESIS = 1600387200

// 30 days
export const TREASURY_VESTING_CLIFF: number = 60 * 60 * 24 * 30

export const ONE_YEAR: number = 60 * 60 * 24 * 365
export const TREASURY_BEGIN_YEAR_1 = TREASURY_VESTING_GENESIS
export const TREASURY_CLIFF_YEAR_1 = TREASURY_BEGIN_YEAR_1 + TREASURY_VESTING_CLIFF
export const TREASURY_END_YEAR_1 = TREASURY_BEGIN_YEAR_1 + ONE_YEAR

export const TREASURY_BEGIN_YEAR_2 = TREASURY_END_YEAR_1
export const TREASURY_END_YEAR_2 = TREASURY_BEGIN_YEAR_2 + ONE_YEAR

export const TREASURY_BEGIN_YEAR_3 = TREASURY_END_YEAR_2
export const TREASURY_END_YEAR_3 = TREASURY_BEGIN_YEAR_3 + ONE_YEAR

export const TREASURY_BEGIN_YEAR_4 = TREASURY_END_YEAR_3
export const TREASURY_END_YEAR_4 = TREASURY_BEGIN_YEAR_4 + ONE_YEAR

export const USERS_AMOUNT = 150_000_000
export const STAKING_REWARDS_AMOUNT = 20_000_000
export const TREASURY_YEAR_1_AMOUNT = 172_000_000
export const TREASURY_YEAR_2_AMOUNT = 129_000_00
export const TREASURY_YEAR_3_AMOUNT = 86_000_00
export const TREASURY_YEAR_4_AMOUNT = 43_000_00
export const TEAM_YEAR_1_AMOUNT = 160_000_000
export const TEAM_YEAR_2_AMOUNT = 120_000_00
export const TEAM_YEAR_3_AMOUNT = 80_000_00
export const TEAM_YEAR_4_AMOUNT = 40_000_00
// pegasus.lightlink

export const EXPLORER_PREFIXES: { [chainId: number]: string } = {

  //Changes
  [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]: 'pegasus.lightlink',
  [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]: 'phoenix.lightlink',
}

export function getExplorerPrefix(chainId: number ): string {
  return EXPLORER_PREFIXES[chainId] ;
}