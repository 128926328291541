import { ThemedText } from 'theme'
import {
	ButtonLight,
} from "components/Button";
import { PoolCardWrapper } from './styleds'
import PoolBanner from 'assets/images/create-pool-banner.png'
import { useNavigate } from "react-router-dom";


const CreatePoolCard = () => {
	const navigate = useNavigate();
	return (
		<PoolCardWrapper url={PoolBanner} >
			<ThemedText.HeadlineMedium >
					Creating a new pool is simple.
			</ThemedText.HeadlineMedium>
			<ThemedText.DeprecatedWhite fontWeight={300} style={{ marginTop: '1rem' }}>
				1. Choose your asset pair
			</ThemedText.DeprecatedWhite>
			<ThemedText.DeprecatedWhite fontWeight={300} style={{ marginTop: '1rem' }}>
				2. Set the pool fee tier
			</ThemedText.DeprecatedWhite>
			<ThemedText.DeprecatedWhite fontWeight={300} style={{ marginTop: '1rem' }}>
				3. Choose an asset weighting
			</ThemedText.DeprecatedWhite>
			<ThemedText.DeprecatedWhite fontWeight={300} style={{ marginTop: '1rem' }}>
				4. Deposit liquidity
			</ThemedText.DeprecatedWhite>
			<ButtonLight
				onClick={() =>navigate('/add') }
				fontWeight={600}
				style={{
					background: `linear-gradient(95.72deg, #a2eeff 13.1%, #029bf0 98.51%)`,
					color: "#000",
					width:160,
					height:40,
					fontSize:12,
					marginTop:'1rem',
					border:'none'
				}}
			>
				Create Pool Now
			</ButtonLight>
		</PoolCardWrapper>
	)
}

export default CreatePoolCard