import { useEffect, useState } from "react";
import { ThemedText } from "theme";
import {
  PoolDetailPageWrapper,
  YieldOverview,
  YieldBox,
  YeldGreen,
  YieldContainer,
  CompositionTableWrapper,
  TableContainer,
  TableHead,
  TableBody,
  PoolActivityWrapper,
  ResponsiveGridPosition,
  ResponsiveGridThriceShorten,
  YieldOverviewContainer,
  DepthChartContainer,
  OverviewAndDepth,
  TradesAndLiquidity,
  TradesContainer,
  CompositionTableWrapperPosition,
  ResponsiveGridThriceTrade,
} from "./poolStyle";
import {
  ButtonRow,
  ClickableText,
  ClickableTextPools,
  GradientText,
} from "./styleds";

import {
  getUserPositionOfPool,
  useSinglePoolDataUpdated,
} from "graphql/thegraph/PoolQuery";
import { useWeb3React } from "@web3-react/core";
import { useNavigate, useParams } from "react-router-dom";
import RowMapping from "components/RowMapping";
import { useGetPoolRewardApr } from "hooks/staking/useGetPoolRewardApr";
import { usePoolId } from "hooks/pool/usePoolId";
import { useGetPoolAllocPoints } from "hooks/staking/useGetPoolAllocPoints";
import { LoaderV2 } from "components/Icons/LoadingSpinner";
import { usegetEltkAmountInEpoch } from "hooks/staking/useCheckHarvestTimeDuration";
import { useGetUsdOraclePrice } from "hooks/oracle/useGetUsdOraclePrice";
import { getChainAddresses } from "@eltk/staking-sdk";
import { EmptyComponent } from "components/EmptyComponent";
import { RowFixed } from "components/Row";
import { StyledArrowLeftChevron } from "components/NavigationTabs/styled";
import { useGetStakedIdsForPool } from "hooks/pool/useGetStakedIdsForPool";
import { useGetStakedPositionsData } from "hooks/pool/useGetStakedPositionData";
import { LiquidityRow } from "./LiquidityRow";
import { PositionsData } from "types/pools";
import { LoadingBubble } from "components/Tokens/loading";
import { TradeRowPool } from "./TradeRowPool";
import LPDCHART from "components/DensityChart/LPDchart";
import { formatNumberWithSuffix } from "utils/numbers";

export function calculatePoolShareInPercentage(
  token0TVL: number,
  token1TVL: number
): { token0Share: number; token1Share: number } {
  const totalTVL = token0TVL + token1TVL;

  const token0Share = (token0TVL / totalTVL) * 100;
  const token1Share = (token1TVL / totalTVL) * 100;

  return { token0Share, token1Share };
}

export const fetchUserPositions = async (
  pool: string,
  account: string | undefined
) => {
  try {
    const response = await getUserPositionOfPool(pool, account!);
    return response;
  } catch (error) {
    console.error("Error fetching liquidity data for pool", ":", error);
  }
};

const PoolDetail = () => {
  const [activityTab, setActivityTab] = useState(1);
  const { poolId } = useParams<{ poolId?: string }>();

  const { account, chainId } = useWeb3React();
  const [positionsData, setPositionsData] = useState<PositionsData>({});
  const [aprRewards, setAPrRewards] = useState<any>(null);

  const { data: poolData, loading: poolAddressLoading } =
    useSinglePoolDataUpdated(poolId?.toLowerCase(), chainId);



  const { poolId: poolIdPool, loading: poolIdLoading } = usePoolId(poolId);

  const { data: stakedIds, loading: StakedIdsLoading } = useGetStakedIdsForPool(
    account,
    poolId,
    chainId
  );

  const { stakedPositionData, loading: StakedPositionsDataLoading } =
    useGetStakedPositionsData(stakedIds?.lps, chainId);


  const { poolRewards: totalPoolRewardsAllocPoints } = useGetPoolRewardApr();

  const { poolRewards: poolAllocPoints } = useGetPoolAllocPoints(poolIdPool);

  useEffect(() => {

    if (poolAllocPoints && totalPoolRewardsAllocPoints) {
      const rewards = (poolAllocPoints / totalPoolRewardsAllocPoints) * 100;
      setAPrRewards(rewards);
    }
  }, [totalPoolRewardsAllocPoints, poolAllocPoints]);

  const { data: EpochData } = usegetEltkAmountInEpoch();

  const rewardsPerDay = () => {
    if (EpochData?.newUpkeepPeriods?.length) {
      const upKeep = EpochData?.newUpkeepPeriods[0];
      const reardsPercentage = aprRewards / 100;
      const reward = (upKeep?.eltkAmount || 0) * reardsPercentage;

      return reward;
    }
    return 0;
  };
  
  const { oracleUsdPrice, loading: oracleUsdPriceLoading } =
    useGetUsdOraclePrice(
      rewardsPerDay() / 10 ** 18,
      getChainAddresses(chainId ?? 1891)?.ElektrikUtility,
      1
    );

  let token0Share = 0;
  let token1Share = 0;
  let swaps = [];
  let mints = [];
  let burns = [];
  let submergedArray: any[] = [];
  let token0Symbol = "";
  let token1Symbol = "";

  if (poolData?.pool) {
    const { token0Share: t0s, token1Share: t1s } =
      calculatePoolShareInPercentage(
        poolData?.pool?.totalValueLockedToken0,
        poolData?.pool?.totalValueLockedToken1
      );
    token0Share = t0s;
    token1Share = t1s;
    token0Symbol = poolData?.pool?.token0?.symbol;
    token1Symbol = poolData?.pool?.token1?.symbol;

    swaps = poolData?.pool?.swaps.map((swap: any) => {
      return {
        amount0:
          parseFloat(swap.amount0) > 0
            ? parseFloat(swap.amount0)
            : parseFloat(swap.amount0) * -1,
        amount1:
          parseFloat(swap.amount1) > 0
            ? parseFloat(swap.amount1)
            : parseFloat(swap.amount1) * -1,
        token0: swap.token0.symbol,
        token1: swap.token1.symbol,
        txHash: swap.transaction.id,
        timestamp: swap.timestamp,
      };
    });

    mints = poolData?.pool?.mints.map((mint: any) => {
      return {
        amount0:
          parseFloat(mint.amount0) > 0
            ? parseFloat(mint.amount0)
            : parseFloat(mint.amount0) * -1,
        amount1:
          parseFloat(mint.amount1) > 0
            ? parseFloat(mint.amount1)
            : parseFloat(mint.amount1) * -1,
        token0: mint.token0.symbol,
        token1: mint.token1.symbol,
        txHash: mint.transaction.id,
        timestamp: mint.timestamp,
      };
    });
    burns = poolData?.pool?.burns.map((mint: any) => {
      return {
        amount0:
          parseFloat(mint.amount0) > 0
            ? parseFloat(mint.amount0)
            : parseFloat(mint.amount0) * -1,
        amount1:
          parseFloat(mint.amount1) > 0
            ? parseFloat(mint.amount1)
            : parseFloat(mint.amount1) * -1,
        token0: mint.token0.symbol,
        token1: mint.token1.symbol,
        txHash: mint.transaction.id,
        timestamp: mint.timestamp,
      };
    });
    mints = mints?.map((obj: any) => ({
      ...obj,
      type: "Add",
    }));
    burns = burns?.map((obj: any) => ({
      ...obj,
      type: "Remove",
    }));

    submergedArray = [...mints, ...burns].sort(
      (a, b) => a?.timestamp - b?.timestamp
    );
  }

  useEffect(() => {
    if (poolData && account) {
      (async () => {
        const positions = await fetchUserPositions(poolData?.pool?.id.toLowerCase(), account);
        setPositionsData(positions);
      })();
    }
  }, [poolData, account, chainId]);

  const navigate = useNavigate();
  const navigateToPosition = (item: any) => {
    navigate(`/positions/${item?.id}`);
  };

  const navigateToAddNewPosition = (poolData: any) => {
    navigate(
      `/increase/${poolData?.pool?.token0?.id}/${poolData?.pool?.token1?.id}`
    );
  };

  const navigateToPools = () => {
    navigate("/pools");
  };

  const consolidatedResponse = {
    positions: stakedPositionData.reduce((accumulator, currentArray) => {
      accumulator.push(...currentArray.positions);
      return accumulator;
    }, []),
  };

  const mergedObject = {
    positions: [
      ...(consolidatedResponse.positions || []),
      ...(positionsData.positions || []),
    ],
  };

  console.log("CONSOSO", consolidatedResponse?.positions,
    positionsData?.positions
  )

  const uniqueArr =
    mergedObject &&
    mergedObject.positions?.filter(
      (item: any, index: any, self: any) =>
        index === self.findIndex((t: any) => t.id === item.id)
    );

  return (
    <PoolDetailPageWrapper>
      <OverviewAndDepth>
        <YieldOverviewContainer>
          <ThemedText.DeprecatedWhite
            fontSize="18px"
            fontWeight={500}
            marginBottom="20px"
          >
            <div> Yield Overview </div>
          </ThemedText.DeprecatedWhite>
          <YieldOverview>
            <YieldBox>
              <ThemedText.Caption
                style={{ color: "#868E9B" }}
                fontWeight={400}
                fontSize="14px"
              >
                <div>Fee APR</div>
              </ThemedText.Caption>
              <ThemedText.HeadlineMedium
                fontWeight={600}
                fontSize={24}
                marginTop={10}
              >
                {!poolData ? (
                  <>
                    <LoadingBubble height="25px" width="80px" delay="300ms" />
                  </>
                ) : (
                  <YieldContainer>
                    <div>
                      <span>
                        {poolData?.pool?.feesUSD &&
                        poolData?.pool?.totalValueLockedUSD
                          ? parseFloat(poolData.pool.totalValueLockedUSD) !== 0
                            ? (
                                ((parseFloat(poolData.pool.feesUSD))) /
                                parseFloat(poolData.pool.totalValueLockedUSD) * 100
                              ).toFixed(2)
                            : "0"
                          : "0"}
                        %
                      </span>
                      &nbsp;&nbsp;
                      <span
                        style={{
                          color: "#19EF81",
                          fontSize: 18,
                          fontWeight: 500,
                        }}
                      ></span>
                    </div>

                    <YeldGreen>
                      $
                      {formatNumberWithSuffix(
                        (parseFloat(poolData?.pool?.feesUSD))
                      )}
                    </YeldGreen>
                  </YieldContainer>
                )}
              </ThemedText.HeadlineMedium>
            </YieldBox>
            <YieldBox>
              <ThemedText.Caption
                style={{ color: "#868E9B" }}
                fontWeight={400}
                fontSize="14px"
              >
                <div>Reward APR</div>
              </ThemedText.Caption>
              <ThemedText.HeadlineMedium
                fontWeight={600}
                fontSize={24}
                marginTop={10}
              >
                {!aprRewards ? (
                  <>
                    <LoadingBubble height="25px" width="80px" delay="300ms" />
                  </>
                ) : (
                  <YieldContainer>
                    <div>
                      <span>{Number(aprRewards)}% </span>
                      &nbsp;&nbsp;
                      <span
                        style={{
                          color: "#19EF81",
                          fontSize: 18,
                          fontWeight: 500,
                        }}
                      ></span>
                    </div>
                    <YeldGreen>
                      {" "}
                      ${formatNumberWithSuffix(oracleUsdPrice) || 0}
                    </YeldGreen>
                  </YieldContainer>
                )}
              </ThemedText.HeadlineMedium>
            </YieldBox>
            <YieldBox>
              <ThemedText.Caption
                style={{ color: "#868E9B" }}
                fontWeight={400}
                fontSize="14px"
              >
                <div>Total APR</div>
              </ThemedText.Caption>
              <ThemedText.HeadlineMedium
                fontWeight={600}
                fontSize={24}
                marginTop={10}
              >
                {!poolData ? (
                  <>
                    <LoadingBubble height="25px" width="80px" delay="300ms" />
                  </>
                ) : (
                  <YieldContainer>
                    <div>
                      <span>
                        {isNaN(
                          Number(
                            (
                              ((parseFloat(poolData?.pool?.feesUSD) * 7) /
                                poolData?.pool?.totalValueLockedUSD) *
                              100
                            )?.toFixed(2)
                          ) + Number(aprRewards)
                        )
                          ? "0" // or any other fallback value you prefer
                          : (
                              Number(
                                (
                                  ((parseFloat(poolData?.pool?.feesUSD)) /
                                    poolData?.pool?.totalValueLockedUSD) *
                                  100
                                )?.toFixed(2)
                              ) + Number(aprRewards)
                            ).toFixed(2) + "%"}
                      </span>
                      &nbsp;&nbsp;
                      <span
                        style={{
                          color: "#19EF81",
                          fontSize: 18,
                          fontWeight: 500,
                        }}
                      ></span>
                    </div>
                    <YeldGreen>
                      $
                      {formatNumberWithSuffix(
                        oracleUsdPrice +
                          Number(parseFloat(poolData?.pool?.feesUSD))
                      )}
                    </YeldGreen>
                  </YieldContainer>
                )}
              </ThemedText.HeadlineMedium>
            </YieldBox>
          </YieldOverview>
        </YieldOverviewContainer>
        <DepthChartContainer>
          <ThemedText.DeprecatedWhite
            fontSize="18px"
            fontWeight={500}
            marginBottom="20px"
          >
            <div> Depth Chart </div>
          </ThemedText.DeprecatedWhite>
          <CompositionTableWrapper style={{ minHeight: 330 }}>
            {poolData && poolData?.pool  ? (
              <LPDCHART
                address={poolId}
                poolData={poolData?.pool}
                width={500}
                height={300}
              />
            ) : (
              <LoaderV2 />
            )}
          </CompositionTableWrapper>
        </DepthChartContainer>
      </OverviewAndDepth>

      <TradesAndLiquidity>
        <TradesContainer>
          <PoolActivityWrapper style={{ minHeight: 330 }}>
            <TableContainer>
              {poolAddressLoading ? (
                <>
                  <LoaderV2 />
                </>
              ) : (
                <>
                  {swaps?.length ? (
                    <>
                      <div style={{ margin: "5px 0px", fontSize: "18px" }}>
                        Trades
                      </div>
                      <TableHead>
                        <ResponsiveGridThriceTrade>
                          <ClickableTextPools onClick={() => null}>
                            Swap in
                          </ClickableTextPools>
                          <ClickableTextPools onClick={() => null}>
                            Swap out
                          </ClickableTextPools>
                          <ClickableTextPools
                            style={{ justifySelf: "end" }}
                            onClick={() => null}
                          >
                            Time
                          </ClickableTextPools>
                        </ResponsiveGridThriceTrade>
                      </TableHead>
                      <TableBody>
                        {swaps.slice(0, 5).map((swap: any, i: number) => {
                          return <TradeRowPool swap={swap} />;
                        })}
                      </TableBody>
                    </>
                  ) : (
                    <EmptyComponent content="Data not Available" />
                  )}
                </>
              )}
            </TableContainer>
          </PoolActivityWrapper>
        </TradesContainer>
        <TradesContainer>
          <PoolActivityWrapper>
            <TableContainer>
              {poolAddressLoading ? (
                <>
                  <LoaderV2 />
                </>
              ) : (
                <>
                  {submergedArray.length ? (
                    <>
                      <div style={{ margin: "5px 0px", fontSize: "18px" }}>
                        Liquidity
                      </div>
                      <TableHead>
                        <ResponsiveGridThriceShorten>
                          <ClickableTextPools onClick={() => null}>
                            Add/Remove
                          </ClickableTextPools>
                          <ClickableTextPools onClick={() => null}>
                            Amount
                          </ClickableTextPools>
                          <ClickableTextPools
                            style={{ justifySelf: "end", paddingRight: "14px" }}
                            onClick={() => null}
                          >
                            Time
                          </ClickableTextPools>
                        </ResponsiveGridThriceShorten>
                      </TableHead>
                      <TableBody>
                        {submergedArray
                          .slice(-5)
                          .map((mint: any, i: number) => (
                            <LiquidityRow mint={mint} />
                          ))}
                      </TableBody>{" "}
                    </>
                  ) : (
                    <EmptyComponent content="Data not Available" />
                  )}
                </>
              )}
            </TableContainer>
          </PoolActivityWrapper>
        </TradesContainer>
      </TradesAndLiquidity>

      <CompositionTableWrapperPosition
        style={{ minHeight: uniqueArr?.length > 0 ? "320px" : "auto" }}
      >
        {!uniqueArr ? (
          <LoaderV2 />
        ) : (
          <>
            {uniqueArr && uniqueArr?.length > 0 ? (
              <TableContainer>
                <div style={{ display: "flex", padding: "0.4rem" }}>
                  <ThemedText.DeprecatedWhite
                    marginBottom="10px"
                    marginTop="10px"
                    fontWeight={400}
                    fontSize={18}
                  >
                    <div> My Positions &nbsp;</div>
                  </ThemedText.DeprecatedWhite>
                  &nbsp; &nbsp;&nbsp;
                  <ButtonRow>
                    <GradientText
                      onClick={() => navigateToAddNewPosition(poolData)}
                      style={{ fontSize: "14px" }}
                    >
                      + Add new position
                    </GradientText>
                  </ButtonRow>
                </div>

                <>
                  <TableHead>
                    <ResponsiveGridPosition>
                      <ClickableText onClick={() => null}>
                        Position
                      </ClickableText>
                      <ClickableText onClick={() => null}>
                        {" "}
                        Unstaked
                      </ClickableText>

                      <ClickableText onClick={() => null}>
                        {" "}
                        Staked
                      </ClickableText>
                      <ClickableText onClick={() => null}>
                        Total Rewards
                      </ClickableText>

                      <ClickableText onClick={() => null}>
                        Rewards APR
                      </ClickableText>
                      <ClickableText onClick={() => null}>Fees</ClickableText>
                    </ResponsiveGridPosition>
                  </TableHead>
                  <TableBody style={{ padding: 0 }}>
                    {uniqueArr &&
                      uniqueArr?.map((item: any) => (
                        <RowMapping
                          item={item}
                          navigateToPosition={navigateToPosition}
                          chainId={chainId}
                          aprRewards={aprRewards}
                        />
                      ))}
                  </TableBody>
                </>
              </TableContainer>
            ) : (
              <TableContainer>
                <div style={{ display: "flex", padding: "0.4rem" }}>
                  <ThemedText.DeprecatedWhite
                    marginBottom="10px"
                    marginTop="10px"
                    fontWeight={400}
                    fontSize={18}
                  >
                    <div> My Positions &nbsp;</div>
                  </ThemedText.DeprecatedWhite>
                  &nbsp; &nbsp;&nbsp;
                  <ButtonRow>
                    <GradientText
                      onClick={() => navigateToAddNewPosition(poolData)}
                      style={{ fontSize: "14px" }}
                    >
                      + Add new position
                    </GradientText>
                  </ButtonRow>
                </div>

                <EmptyComponent
                  style={{ height: "20vh" }}
                  content="Data not Available"
                />
              </TableContainer>
            )}
          </>
        )}
      </CompositionTableWrapperPosition>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <RowFixed>
          <div
            style={{
              borderRadius: "50%",
              background:
                "linear-gradient(91deg, #A1EEFF 7.28%, #029AF0 89.82%)",
              cursor: "pointer",
            }}
            onClick={() => navigateToPools()}
          >
            <StyledArrowLeftChevron
              color="#000000"
              style={{ display: "flex", padding: "4px" }}
            />
          </div>
        </RowFixed>
      </div>
    </PoolDetailPageWrapper>
  );
};

export default PoolDetail;
