import Badge from "components/Badge"
import Row from "components/Row"
import styled from "styled-components"
import { ReactComponent as DotLine } from '../../assets/svg/dot_line.svg'
import { Box } from "rebass"
import { Z_INDEX } from "theme/zIndex"
import { ThemedText } from "theme"

export const Wrapper = styled(Box)`
  align-items: center;
  width: 100%;
`

export const RouteContainerRow = styled(Row)`
  display: grid;
  grid-template-columns: 24px 1fr 24px;
`

export const RouteRow = styled(Row)`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0.1rem 0.5rem;
  position: relative;
`



export const DottedLine = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  width: calc(100%);
  z-index: ${Z_INDEX.default};
  opacity: 0.5;
`

export const DotColor = styled(DotLine)`
  path {
    stroke: ${({ theme }) => theme.deprecated_bg4};
  }
`



export const BadgeText = styled(ThemedText.DeprecatedSmall)`
  word-break: normal;
`
