import { useEffect, useState } from "react";
import { Staking, StakerWallet } from "@eltk/staking-sdk";
import { Signer, providers } from "ethers";
import { useSignerWallet } from "hooks/useSignerWallet";
import { useStaking } from "./useStaking";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { StakingClient, apolloClient,  apolloClientMainnet,  apolloClientTestnet,  voteEscrowClient } from "graphql/thegraph/apollo";

const GET_POOL_ADDRESS = gql`
query MyQuery($token0: String!, $token1: String!, $feeTier: String!) {
  pools(where: {token0: $token0, token1: $token1, feeTier: $feeTier}) {
    id
  }
}

`;

const GET_HARVEST_TIME_DURATION = gql`
  query MyQuery($tokenId: String!) {
    harvests(where: { tokenId: $tokenId }) {
      blockTimestamp
    }
  }
`;


export const useCheckHarvestTimeDuration = (tokenId: any) => {


  const { loading, error, data } = useQuery(GET_HARVEST_TIME_DURATION, {
    variables: { tokenId },
    client: StakingClient,
  });

  return {
    data,
  };
};

export const useGetPoolAddressFromTokenIds = (token1:any, token0:any, feeTier:any,chainId:any) => {

  const { loading, error, data, refetch } = useQuery(GET_POOL_ADDRESS, {
    variables: { token0, token1, feeTier },
    client: chainId == 1890 ?  apolloClientMainnet : apolloClientTestnet,
    fetchPolicy:'network-only'
  });


  useEffect(() => {

    refetch();
  }, [token0, token1, feeTier, refetch,chainId]);

 

  return {
    loading,
    error,
    data,
  };
};







export const POSITIONSTAKEDORNOT = gql`
  query MyQuery($tokenId: String!) {
    deposits(first: 1, where: { tokenId: $tokenId }) {
      id
    }
  }
`;

export const POSITIONSTAKEDORNOTDETAILS = gql`
query MyQuery($id: String!) {
  lps(where: {id: $id}) {
    id
    withdrawn
  }
}
`;


const GET_STAKED_POSITIONS_DATA = gql`
query userLiquidity($positionId: String!) {
  positions(where: {id: $positionId}) {
    id
    liquidity
    token0 {
      id
      symbol
      name
    }
    token1 {
      id
      symbol
      name
    }
    pool {
      feeTier
    }
  }
}
`;


export const UPKEEP_PERIOD = gql`
query MyQuery {
  newUpkeepPeriods(first: 1, orderBy: blockTimestamp, orderDirection: desc) {
    eltkAmount
    endTime
  }
}
`;



export async function checkPositionIsStakedOrNot(tokenId: any): Promise<any> {
  try {
    const { data: id } = await StakingClient.query<any>({
      query: POSITIONSTAKEDORNOT,
      variables: { tokenId },
    });

    return id;
  } catch (error) {
    console.log(error);
  }
}

export async function checkPositionIsStakedOrNotDetails(tokenId: any): Promise<any> {
  try {
    const { data: id } = await StakingClient.query<any>({
      query: POSITIONSTAKEDORNOTDETAILS,
      variables: { id: tokenId },
      fetchPolicy: 'no-cache', 


    });

    return id;
  } catch (error) {
    console.log(error);
  }
}


export async function getStakedPositionData(positionId: any,chainId:any): Promise<any> {
  try {
    const { data: id } = await apolloClient.query<any>({
      query: GET_STAKED_POSITIONS_DATA,
      variables: { positionId ,chainId},
      fetchPolicy: 'no-cache', 
    });

    return id;
  } catch (error) {
    console.log(error);
  }
}




export const useCheckPositionStakingStatus = (tokenId: any) => {


  const { loading, error, data } = useQuery(POSITIONSTAKEDORNOT, {
    variables: { tokenId },
    client: StakingClient,
  });

  return {
    data,
  };
};



export const   usegetEltkAmountInEpoch=()=>{
  
  const { loading, error, data } = useQuery(UPKEEP_PERIOD, {
    client: StakingClient,
    fetchPolicy:'network-only'
  });

  return {
    data,
  };
}
