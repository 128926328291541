import { transparentize } from "polished"
import styled from "styled-components"

export enum TooltipSize {
  Small = '256px',
  Large = '400px',
}

export const getPaddingForSize = (size: TooltipSize) => {
  switch (size) {
    case TooltipSize.Small:
      return '12px'
    case TooltipSize.Large:
      return '16px 20px'
  }
}

export const TooltipContainer = styled.div<{ size: TooltipSize }>`
  max-width: ${({ size }) => size};
  width: calc(100vw - 16px);
  cursor: default;
  padding: ${({ size }) => getPaddingForSize(size)};
  pointer-events: auto;

  color: ${({ theme }) => theme.textPrimary};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  word-break: break-word;

  border-radius: 12px; 
  box-shadow: 0 4px 8px 0 ${({ theme }) => transparentize(0.9, theme.shadow1)};
  position: relative;
  --border-width: 2px;
  --border-radius: 12px;
  background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
  border: 0;
  padding: 1rem 1rem;
  position: relative;
  border-radius: var(--border-radius);
  z-index: 1};
  &::before {
    content: "";
    display: block;
    height: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    width: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    background: ${({ theme }) => theme.backgroundSurface};
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: calc(var(--border-radius) - var(--border-width));
    z-index: -1;
`




