import { useEffect, useState } from "react";
import { Staking, StakerWallet } from "@eltk/staking-sdk";
import { Signer, providers } from "ethers";
import { useSignerWallet } from "hooks/useSignerWallet";
import { useStaking } from "./useStaking";

export const useGetPoolRewardApr = () => {
  const [poolRewards,setPoolRewards] = useState<any>(null);
  

  const { staking } = useStaking();

  useEffect(() => {
    const getPendingRewards = async () => {

      try {
        if ( staking) {
          const rewards = await staking.totalAllocPoints();

          setPoolRewards(parseInt(rewards?._hex, 16))

        }
      } catch (error) {
        console.error(`ErrorfetchingPoolsRewards:`, error);
      }
    };

    getPendingRewards();
  }, [staking]);

  return {
    poolRewards
  };
};
