import { LoaderV2 } from "components/Icons/LoadingSpinner";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  AreaChart,
  Area,
  Label
} from "recharts";
import { Wrapper, ControlsWrapper, ActionButton } from "./chartStyle";
import CustomToolTip from "./CustomToolTip";
import { isAddress } from "utils";
import { Token, CurrencyAmount } from "@uniswap/sdk-core";
import { Pool, TickMath, TICK_SPACINGS, FeeAmount } from "@uniswap/v3-sdk";
import { BigNumber } from "@ethersproject/bignumber";
import JSBI from "jsbi";
import { fetchTicksSurroundingPrice } from "graphql/thegraph/tickData";
import { apolloClient } from "graphql/thegraph/apollo";
import { CurrentPriceLabelArea } from "./CurrentPriceLabelArea";
import { ChartEntry, ZoomStateProps } from "./types";

const INITIAL_TICKS_TO_FETCH = 20;
const ZOOM_INTERVAL = 200;

const initialState = {
  left: 0,
  right: INITIAL_TICKS_TO_FETCH * 2 + 1,
  refAreaLeft: "",
  refAreaRight: "",
};

export const MAX_UINT128 = BigNumber.from(2).pow(128).sub(1);

export default function LPDCHART({
  address,
  poolData,
  width = 500,
  height = 300,
}: any) {
  const formattedAddress0 = isAddress(poolData?.token0?.address);
  const formattedAddress1 = isAddress(poolData?.token1?.address);
  const feeTier = poolData && poolData?.feeTier;
  const [poolTickData, updatePoolTickData] = useState<any>(null);


  const token0 = useMemo(() => {
    return poolData && formattedAddress0 && formattedAddress1
      ? new Token(1, formattedAddress0, poolData.token0.decimals)
      : undefined;
  }, [formattedAddress0, formattedAddress1, poolData]);

  const token1 = useMemo(() => {
    return poolData && formattedAddress1 && formattedAddress1
      ? new Token(1, formattedAddress1, poolData.token1.decimals)
      : undefined;
  }, [formattedAddress1, poolData]);

  const [ticksToFetch, setTicksToFetch] = useState(INITIAL_TICKS_TO_FETCH);
  const amountTicks = ticksToFetch * 2 + 1;

  const [loading, setLoading] = useState(false);
  const [zoomState, setZoomState] = useState<ZoomStateProps>(initialState);

  useEffect(() => {
    async function fetch() {
      const { data } = await fetchTicksSurroundingPrice(
        address,
        apolloClient,
        ticksToFetch
      );




      updatePoolTickData((prev: any) => ({
        ...prev,
        ...data,
      }));
    }

    if (
      !poolTickData ||
      (poolTickData && poolTickData.ticksProcessed.length < amountTicks)
    ) {
      fetch();
    }
  }, [address, poolTickData, updatePoolTickData, ticksToFetch, amountTicks]);

  const [formattedData, setFormattedData] = useState<
    ChartEntry[] | undefined
  >();

  useEffect(() => {
    async function formatData() {
      if (poolTickData) {
        const newData = await Promise.all(
          poolTickData.ticksProcessed.map(async (t: any, i: any) => {
            const active = t.tickIdx === poolTickData.activeTickIdx;
            const sqrtPriceX96 = TickMath.getSqrtRatioAtTick(t.tickIdx);
            const feeAmount: FeeAmount = poolData.feeTier;
            const mockTicks = [
              {
                index: t.tickIdx - TICK_SPACINGS[feeAmount],
                liquidityGross: t.liquidityGross,
                liquidityNet: JSBI.multiply(t.liquidityNet, JSBI.BigInt("-1")),
              },
              {
                index: t.tickIdx,
                liquidityGross: t.liquidityGross,
                liquidityNet: t.liquidityNet,
              },
            ];
            const pool =
              token0 && token1 && feeTier
                ? new Pool(
                    token0,
                    token1,
                    feeTier,
                    sqrtPriceX96,
                    t.liquidityActive,
                    t.tickIdx,
                    mockTicks
                  )
                : undefined;
            const nextSqrtX96 = poolTickData.ticksProcessed[i - 1]
              ? TickMath.getSqrtRatioAtTick(
                  poolTickData.ticksProcessed[i - 1].tickIdx
                )
              : undefined;
            const maxAmountToken0 = token0
              ? CurrencyAmount.fromRawAmount(token0, MAX_UINT128.toString())
              : undefined;
            const outputRes0 =
              pool && maxAmountToken0
                ? await pool.getOutputAmount(maxAmountToken0, nextSqrtX96)
                : undefined;

            const token1Amount = outputRes0?.[0] as
              | CurrencyAmount<Token>
              | undefined;

            const amount0 = token1Amount
              ? parseFloat(token1Amount.toExact()) * parseFloat(t.price1)
              : 0;
            const amount1 = token1Amount
              ? parseFloat(token1Amount.toExact())
              : 0;

            return {
              index: i,
              isCurrent: active,
              activeLiquidity: parseFloat(t.liquidityActive.toString()),
              price0: parseFloat(t.price0),
              price1: parseFloat(t.price1),
              tvlToken0: amount0,
              tvlToken1: amount1,
            };
          })
        );
        newData?.map((entry: any, i: any) => {
          if (i > 0) {
            newData[i - 1].tvlToken0 = entry.tvlToken0;
            newData[i - 1].tvlToken1 = entry.tvlToken1;
          }
        });

        if (newData) {
          if (loading) {
            setLoading(false);
          }
          setFormattedData(newData);
        }
        return;
      } else {
        return [];
      }
    }
    if (!formattedData) {
      formatData();
    }
  }, [
    feeTier,
    formattedData,
    loading,
    poolData.feeTier,
    poolTickData,
    token0,
    token1,
  ]);

  const atZoomMax =
    zoomState.left + ZOOM_INTERVAL >= zoomState.right - ZOOM_INTERVAL - 1;
  const atZoomMin = zoomState.left - ZOOM_INTERVAL < 0;



  const zoomedData = useMemo(() => {
    if (formattedData) {
      return formattedData.slice(zoomState.left, zoomState.right);
    }
    return undefined;
  }, [formattedData, zoomState.left, zoomState.right]);


  useEffect(() => {
    setFormattedData(undefined);
  }, [address]);

  const midpoint = Math.ceil((zoomedData?.length ?? 0) / 2);
  const firstHalfData = zoomedData?.slice(11, midpoint + 65); // Include the midpoint in the first half
  const secondHalfData = zoomedData?.slice(midpoint - 18);

  const CustomXAxisTick = (props: any) => {
    const { x, y, payload } = props;

    // Function to format tick value
    const formatTick = (value: number) => {
      const valueStr = value.toString();
      if (valueStr.length > 4) {
        return `${valueStr.substring(0, 5)}..`;
      }
      return valueStr;
    };

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#fff" fontSize={10}>
          $ {formatTick(payload.value)}
        </text>
      </g>
    );
  };

  const formatXAxis = (value: any) => {
    return value;
  };

  if (!poolTickData) {
    return <LoaderV2 />;
  }

  return (
    <Wrapper>
      {!loading ? (
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={width}
            height={height}
            data={zoomedData}
            margin={{
              top: 100,
              right: 0,
              left: 0,
              bottom: 10,
            }}
          >
            <defs>
              <linearGradient id="gradientFill" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#23FF95" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#23FF95" stopOpacity={0} />
              </linearGradient>
            </defs>

            <Tooltip
              content={(props) => (
                <CustomToolTip
                  chartProps={props}
                  poolData={poolData}
                  currentPrice={poolData.token0Price}
                />
              )}
            />
            <Area
              type="monotone"
              dataKey="activeLiquidity"
              stroke="#23FF95"
              fill="url(#gradientFill)"
            >
              <LabelList
                dataKey="activeLiquidity"
                position="left"
                content={(props) => (
                  <CurrentPriceLabelArea
                    chartProps={props}
                    poolData={poolData}
                    data={zoomedData}
                  />
                )}
              />
            </Area>
            <XAxis dataKey="price0" tickFormatter={formatXAxis} />
          </AreaChart>
        </ResponsiveContainer>
      ) : (
        <LoaderV2 />
      )}
    </Wrapper>
  );
}
