import { formatPrice, NumberType } from '@uniswap/conedison/format'

import { Bound } from '../state/mint/v3/actions'
import { FormatTickPriceArgs } from 'types'


export function formatTickPrice({ price, atLimit, direction, placeholder, numberType }: FormatTickPriceArgs) {
  if (atLimit[direction]) {
    return direction === Bound.LOWER ? '0' : '∞'
  }

  if (!price && placeholder !== undefined) {
    return placeholder
  }

  return formatPrice(price, numberType ?? NumberType.TokenNonTx)
}
