import { Token } from "@uniswap/sdk-core";
import { SupportedChainId } from "constants/chains";
import { WARNING_LEVEL, Warning } from "constants/tokenSafety";
import { TimePeriod } from "graphql/data/util";
import { ReactNode } from "react";
import { TokenPriceQuery } from "graphql/data/__generated__/types-and-hooks";
import { Chain, TokenQuery, TokenQueryData } from "graphql/data/Token";
import { Nullish } from "@uniswap/conedison/types";

export class UserAddedToken extends Token {}
export interface TokenSafetyModalProps extends TokenSafetyProps {
  isOpen: boolean;
}

export type TokenSafetyMessageProps = {
  warning: Warning;
  tokenAddress: string;
};

export type TokenWarningLabelProps = {
  level: WARNING_LEVEL;
  canProceed: boolean;
  children: ReactNode;
};

export type AboutSectionProps = {
  address: string;
  chainId: SupportedChainId;
  description?: string | null;
  homepageUrl?: string | null;
  twitterName?: string | null;
};
export type OnChangeTimePeriod = (t: TimePeriod) => void;
export type TokenDetailsProps = {
  urlAddress?: string;
  inputTokenAddress?: string;
  chain: Chain;
  tokenQuery: TokenQuery;
  tokenPriceQuery?: TokenPriceQuery;
  onChangeTimePeriod: OnChangeTimePeriod;
};

export interface TokenAmountProps {
  amount: Nullish<any>;
  defaultValue?: string;
  tokenSymbol?: any["token"];
  className?: string;
  hideTokenSymbol?: boolean;
  round?: boolean;
  opacitySymbol?: boolean;
}

export interface TokenSafetyProps {
  tokenAddress: string | null
  secondTokenAddress?: string
  onContinue: () => void
  onCancel: () => void
  onBlocked?: () => void
  showCancel?: boolean
}

export enum TokenSortMethod {
  PRICE = 'Price',
  PERCENT_CHANGE = 'Change',
  TOTAL_VALUE_LOCKED = 'TVL',
  VOLUME = 'Volume',
}
