import { Text } from "rebass";
import styled from "styled-components/macro";

export const Wrapper = styled.div`
  position: relative;
  padding: 20px;
`;

export const YieldOverviewContainer = styled.div`
  width: 25%;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const DepthChartContainer = styled.div`
  width: 75%;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;
export const TradesContainer = styled.div`
  width: 50%;
  @media (max-width: 1200px) {
    width: 100%;
    margin: 10px 0;
  }
`;

export const TradesAndLiquidity = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  @media (max-width: 1200px) {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
  }
`;

export const OverviewAndDepth = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  @media (max-width: 1200px) {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
  }
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  background: linear-gradient(
    90deg,
    #131d20 0%,
    #131d20 23.51%,
    #131d20 48%,
    #131d20 65.38%,
    #131d20 72%,
    #131d20 78.5%,
    #11262d 85.5%,
    #112c36 93.72%,
    #112e38 100%
  );
  margin-top: 25,
            padding: 10px  65px 15px 65px,
            min-height: 125,
  @media (max-width: 1200px) {
    padding: 10px  215px 15px 65px,


  }
`;
export const HeaderWrapperSinglePool = styled.div`
  margin-top: 25px;
  padding: 10px 65px;
  width: 100%;
  height: auto;
  position: relative;
  background: linear-gradient(
    90deg,
    #091012 0%,
    #091012 23.51%,
    #121b1e 48%,
    #121e23 65.38%,
    #122227 72%,
    #12262d 78.5%,
    #112d37 85.5%,
    #112d38 93.72%,
    #112d38 100%
  );
  @media (max-width: 750px) {
    padding: 10px 10px;
  }
`;

export const TokenHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1200px) {
    display: grid;
    justify-content: center;
  }
`;

export const GradientText = styled.div`
  display: flex;
  justifycontent: center;
  alignitems: center;
  position: relative;
  background: var(
    --Gradient,
    linear-gradient(191deg, #a1eeff 5.4%, #029af0 92.07%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 39px */
`;

export const GreySubText = styled.div`
  color: #868e9b;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  display: flex;
  width: 91px;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;

  @media (max-width: 750px) {
    width: 100%;
  }
`;

export const GreySubTextV2 = styled.div`
  color: #868e9b;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
`;

export const TokenTextTitle = styled.div`
  color: #fff;
  font-family: Inter;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 38px */
  margin-bottom: 10px;
  @media (max-width: 750px) {
    font-size: 24px;
  }
`;

export const RemoveLiquidityBTN = styled.div`
  width: 190px;
  height: 42px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--Gradient, #a1eeff);
`;

export const WalletLink = styled.div`
  font-size: 10px;
  display: flex;
  text-decoration: underline;
  font-weight: 200;
  /* width: 125px;  */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 750px) {
    width: 65px;
  }
`;
export const RightTokenLogo = styled.div`
  position: relative;
  left: -14px;
  @media (max-width: 501px) {
    left: -24px;
  }
`;

export const TokenContainer = styled.div`
  position: relative;
  left: -14px;
  @media (max-width: 501px) {
    left: 0px;
    top: -14px;
  }
`;

export const LeftBorderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  justify-content: space-between;
  border-left: 1px solid #263236;
  height: 100%;

  @media (max-width: 750px) {
    margin-top: 10px;
    border-left: none;
    padding-left: 0;
    display: grid;
    justify-content: center;
  }
`;

export const TokenData = styled.div`
  font-size: 12px;
  display: flex;
  min-width: 150px; /* Set the width to limit the display */
  white-space: nowrap; /* Prevent line break */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Show ellipsis (...) for overflowed content */
`;
export const TokenDelta = styled.div`
  color: #868e9b;
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px; /* 14px */
  margin-left: 15px;
`;

export const RangeText = styled.div`
  color: #19ef81;
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
  margin-left: 10px;
`;
export const PoolNumberText = styled.span`
  color: #fff;
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 14px */
  margin-left: 10px;
`;

export const PoolText = styled.div`
  color: #fff;
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 14px */
`;

export const HeaderContentWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

export const LeftSection = styled.div`
  width: 100%;
  border-right: 1px solid #263236;
  display: flex;
  margin-right: 30px;
  align-items: flex-start;
  @media (max-width: 1200px) {
    display: grid;
    justify-content: center;
    border-right: none;
    margin-right: 0px;
  }
`;
export const RightSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  @media (max-width: 750px) {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
  }
`;

export const YieldContainer = styled.div`
  display: Flex;
  align-items: baseline;
`;

export const YeldGreen = styled.div`
  margin-left: 10px;
  color: #19ef81;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const TopSectionWrapper = styled.div`
  width: 100%;
  margin-top:5px;
`;

export const TopSectionWrapperPosition = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const PairDetails = styled.div`
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const GradientIcon = styled.div`
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
<path d="M10.5 1.5V1H11V1.5H10.5ZM5.8535 6.8535C5.7592 6.94458 5.6329 6.99498 5.5018 6.99384C5.3707 6.9927 5.24529 6.94011 5.15259 6.84741C5.05989 6.75471 5.0073 6.6293 5.00616 6.4982C5.00502 6.3671 5.05542 6.2408 5.1465 6.1465L5.8535 6.8535ZM10 5.5V1.5H11V5.5H10ZM10.5 2H6.5V1H10.5V2ZM10.8535 1.8535L5.8535 6.8535L5.1465 6.1465L10.1465 1.1465L10.8535 1.8535Z" fill="url(#paint0_linear_1_717)"/>
<path d="M10 7.5C10 8.435 10 8.902 9.799 9.25C9.66735 9.47801 9.47801 9.66735 9.25 9.799C8.902 10 8.435 10 7.5 10H5C3.586 10 2.8785 10 2.4395 9.5605C2 9.1215 2 8.414 2 7V4.5C2 3.565 2 3.098 2.201 2.75C2.33265 2.52199 2.52199 2.33265 2.75 2.201C3.098 2 3.565 2 4.5 2" stroke="url(#paint1_linear_1_717)" strokeLinecap="round"/>
<defs>
  <linearGradient id="paint0_linear_1_717" x1="5.6719" y1="1.25913" x2="6.79553" y2="7.22142" gradientUnits="userSpaceOnUse">
    <stop stopColor="#A2EEFF"/>
    <stop offset="1" stopColor="#029AF0"/>
  </linearGradient>
  <linearGradient id="paint1_linear_1_717" x1="2.88859" y1="2.34586" x2="4.38829" y2="10.3037" gradientUnits="userSpaceOnUse">
    <stop stopColor="#A2EEFF"/>
    <stop offset="1" stopColor="#029AF0"/>
  </linearGradient>
</defs>
</svg>
`;

export const PairIconsWithName = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1200px) {
    display: grid;
  }
`;

export const IconImage = styled.img<{
  width: string;
  height: string;
  rounded?: boolean;
}>`
  width: ${(props) => props.height};
  height: ${(props) => props.width};
  border-radius: ${(props) => (props.rounded ? "50%" : "")};
`;
export const StackedIcons = styled.div`
  display: flex;
  align-items: center;
  margin: auto 1rem auto 0px;
  @media (max-width: 1200px) {
    justify-content: center;
  }

  & > :nth-child(2) {
    margin-left: -10px;
  }
`;

export const FeeTierSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const RangeWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 750px) {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
  }
`;

export const HeaderImage = styled.img`
  max-width: 100%;
  height: auto;
`;
export const BottomSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const TabsSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
`;

export const PoolStatsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
  padding-bottom: 19px;
  @media (max-width: 1200px) {
    display: grid;
  }
`;

export const PoolInfoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 2;
  margin: 15px 0px;
  @media (max-width: 1200px) {
    display: grid;
    justify-content: center;
  }
`;

export const PoolStatsItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 30px;
  justify-content: flex-start;
  position: relative; /* Add position relative for absolute positioning */
  &:last-child {
    border-right: none;
  }

  /* Add the pseudo-element for the border with 90% height */
  &::after {
    content: "";
    position: absolute;
    top: 5%; /* Adjust top positioning as needed */
    right: 0;
    height: 90%; /* Set the border height to 90% of the item's height */
    width: 1px;
    background-color: #5d7279;
  }
  @media (max-width: 1200px) {
    margin-right: 0px;
    &::after {
      content: "";
      width: 0px;
      background-color: #5d7279;
    }
  }
`;

export const PoolStatsItemNoBorder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 30px;
  justify-content: flex-start;
`;
export const PoolPageContentWrapper = styled.div`
  max-width: 90%;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(8, 1fr) repeat(4, 1fr);
  grid-gap: 20px;
`;
export const PoolPageContentWrapperDetails = styled.div`
  max-width: 1600px;
  width: 100%;
  padding: 0 65px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    padding: 0 35px;
  }

  @media (max-width: 700px) {
    padding: 0 15px;
  }
`;
export const LockingPageContentWrapperDetails = styled.div`
  max-width: 100%;
  width: 100%;
  padding: 0 65px;
  margin-top: 10px;

  @media (max-width: 1200px) {
    padding: 0 35px;
  }

  @media (max-width: 700px) {
    padding: 0 15px;
  }
`;
export const LeftContentWrapper = styled.div`
  width: 100%;
`;

export const RightContentWrapper = styled.div`
  width: 100%;
  grid-column: span 4;
  border-left: 0.1px solid #ffffff45;
  padding-left: 1rem;
`;

export const BoxWrapper = styled.div`
  width: 100%;
  height: auto;

  margin-bottom: 2rem;
  position: relative;
  --border-width: 2px;
  --border-radius: 12px;
  background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
  margin: 10px;
  border: 0;
  padding: 1rem 2rem;
  position: relative;
  border-radius: var(--border-radius);
  z-index: 1;
  &::before {
    content: "";
    display: block;
    height: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    width: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    background: ${({ theme }) => theme.backgroundSurface};
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: calc(var(--border-radius) - var(--border-width));
    z-index: -1;
  }
`;

export const BoxHeader = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const BoxItem = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background: #121b1e;
  padding: 1rem;
  margin-top: 0.7rem;
`;
export const RewardContainer = styled.div`
  border-radius: 10px;
  background: #091012;
  padding: 1rem;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  > button {
    margin: auto 5px;
  }
`;

export const PoolDetailPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: auto;
`;

export const YieldOverview = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
export const YieldBox = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  --border-width: 2px;
  --border-radius: 12px;
  background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
  /* margin: 10px;  */
  border: 0;
  padding: 22px 30px;
  position: relative;
  border-radius: var(--border-radius);
  z-index: 1;
  &::before {
    content: "";
    display: block;
    height: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    width: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    background: ${({ theme }) => theme.backgroundSurface};
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: calc(var(--border-radius) - var(--border-width));
    z-index: -1;
  }
`;

export const CompositionTableWrapper = styled.div`
  height: auto;
  overflow: hidden;
  padding: 0;
  position: relative;
  --border-width: 2px;
  --border-radius: 12px;
  background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
  border: 0;
  padding: 25px 30px;
  position: relative;
  border-radius: var(--border-radius);
  z-index: 1;
  &::before {
    content: "";
    display: block;
    height: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    width: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    background: ${({ theme }) => theme.backgroundSurface};
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: calc(var(--border-radius) - var(--border-width));
    z-index: -1;
  }
`;

export const CompositionTableWrapperStake = styled.div`
  min-height: 632px;
  overflow: hidden;
  padding: 0;
  position: relative;
  --border-width: 2px;
  --border-radius: 12px;
  background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
  border: 0;
  padding: 25px 30px 37px 30px;
  position: relative;
  border-radius: var(--border-radius);
  z-index: 1;
  &::before {
    content: "";
    display: block;
    height: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    width: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    background: ${({ theme }) => theme.backgroundSurface};
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: calc(var(--border-radius) - var(--border-width));
    z-index: -1;
  }
`;

export const CompositionTableWrapperStakeChart = styled.div`
  min-height: 395px;
  overflow: hidden;
  padding: 0;
  position: relative;
  --border-width: 2px;
  --border-radius: 12px;
  background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
  border: 0;
  padding: 25px 30px 37px 30px;
  position: relative;
  border-radius: var(--border-radius);
  z-index: 1;
  &::before {
    content: "";
    display: block;
    height: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    width: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    background: ${({ theme }) => theme.backgroundSurface};
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: calc(var(--border-radius) - var(--border-width));
    z-index: -1;
  }
`;

export const CompositionTableWrapperPosition = styled.div`
  /* min-height:392px; 
  max-height:352px; 

  height: 352px; */
  overflow: hidden;
  padding: 0;
  position: relative;
  --border-width: 2px;
  --border-radius: 12px;
  background: linear-gradient(180deg, #92d5e3 0%, #091920 75%);
  border: 0;
  padding: 5px 30px;
  position: relative;
  border-radius: var(--border-radius);
  z-index: 1;
  &::before {
    content: "";
    display: block;
    height: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    width: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    background: ${({ theme }) => theme.backgroundSurface};
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: calc(var(--border-radius) - var(--border-width));
    z-index: -1;
  }
`;
export const BlurDiv = styled.div`
  filter: blur(10px);
`;
export const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
export const TableContainerEmpty = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TableContainerEmptySwap = styled.div`
  position: relative;
  top:20%;
  flex-direction: column; 
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TableContainerEmptyPool = styled.div`
  position: relative;
  top:70%;
  flex-direction: column; 
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TableContainerEmptyGraph = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TableHead = styled.div`
  width: 100%;
  min-height: 30px;
`;

export const TableBody = styled.div`
  width: 100%;
  height: auto;
`;

export const TableRow = styled.div`
  width: 100%;
  border-bottom: 1px solid #22282a;
  &:last-child {
    border-bottom: none;
  }
`;
export const TableRowWithoutBorder = styled.div`
  width: 100%;
  &:last-child {
    border-bottom: none;
  }
`;
export const TokenNameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TableText = styled(Text)`
  :hover {
    cursor: pointer;
  }
  text-align: left;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 22.5px */
  color: ${({ theme }) => theme.white};
  padding: 1rem;
  @media (max-width: 1200px) {
    max-width: 75px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 700px) {
    max-width: 45px;
  }
`;

export const TableTextPool = styled(Text)`
  :hover {
    cursor: pointer;
  }
  text-align: left;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 22.5px */
  color: ${({ theme }) => theme.white};
  padding: 0.5rem;
  @media (max-width: 1200px) {
    max-width: 75px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 700px) {
    max-width: 45px;
  }
`;

export const TableTextPoolEdit = styled(Text)`
`;
export const TableTextPoolBold = styled(Text)`
  :hover {
    cursor: pointer;
  }
  text-align: left;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 22.5px */
  color: ${({ theme }) => theme.white};
  /* padding: 0.5rem; */
  @media (max-width: 1200px) {
    max-width: 75px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 700px) {
    max-width: 45px;
  }
`;

export const TokenImage = styled.img`
  width: 33px;
  height: 33px;
  border-radius: 50%;
`;

export const CardContent = styled.div`
  max-width: 50%;
`;
export const DlpWrapper = styled.section`
  display: flex;
  align-items: center;
  background: linear-gradient(
    191deg,
    rgba(161, 238, 255, 0.2) 5.4%,
    rgba(2, 154, 240, 0.2) 92.07%
  );
  padding: 0.6rem;
  border-radius: 8px;
`;

export const PoolActivityWrapper = styled.div`
  height: 100%;
  position: relative;
  overflow: hidden;
  --border-width: 2px;
  --border-radius: 12px;
  background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
  /* margin: 10px;  */
  border: 0;
  padding: 25px 34px;
  position: relative;
  border-radius: var(--border-radius);
  z-index: 1;
  &::before {
    content: "";
    display: block;
    height: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    width: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    background: ${({ theme }) => theme.backgroundSurface};
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: calc(var(--border-radius) - var(--border-width));
    z-index: -1;
  }
`;

export const ActivityHeader = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  > p {
    width: 50% !important;
  }
`;

export const SwapColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const TimestampSpan = styled.div`
  font-size: 10px;
`;

export const TxLink = styled.a``;
export const TokenText = styled.div<{ success?: boolean }>`
  margin: 0px;
  text-align: left;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: ${(props) => (props.success ? "#1DF68A" : "#F73939")};

  max-width: 75px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 1200px) {
  }
`;

export const ResponsiveGridPoolBody = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  grid-template-columns: 0.3fr 1fr 1fr 1fr 1fr 1fr 1fr;

  @media screen and (max-width: 500px) {
    grid-template-columns: 2fr repeat(1, 1fr);
    & > *:nth-child(2) {
      display: none;
    }
  }
`;
export const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
  /* padding:1rem 0px; */

  @media screen and (max-width: 500px) {
    grid-template-columns: 2fr repeat(1, 1fr);
    & > *:nth-child(2) {
      display: none;
    }
  }
`;

export const ResponsiveGridThrice = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;

  @media screen and (max-width: 500px) {
    grid-template-columns: 2fr repeat(1, 1fr);
    & > *:nth-child(2) {
      display: none;
    }
  }
`;
export const ResponsiveGridThriceTrade = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  grid-template-columns: 1fr 1fr 1.5fr;

  @media screen and (max-width: 500px) {
    grid-template-columns: 2fr repeat(1, 1fr);
    & > *:nth-child(2) {
      display: none;
    }
  }
`;

export const ResponsiveGridThriceLiquidity = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  grid-template-columns: 0.5fr 1fr 1fr;

  @media screen and (max-width: 500px) {
    grid-template-columns: 2fr repeat(1, 1fr);
    & > *:nth-child(2) {
      display: none;
    }
  }
`;

export const ResponsiveGridFour = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  grid-template-columns: 1.5fr 1.5fr 2fr 1fr;

  @media screen and (max-width: 500px) {
    grid-template-columns: 1.5fr 1.5fr 2fr 1fr;
    & > *:nth-child(2) {
      display: none;
    }
  }
`;
export const ResponsiveGridThriceShorten = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  grid-template-columns: 0.5fr 1fr 1fr;

  @media screen and (max-width: 500px) {
    grid-template-columns: 2fr repeat(1, 1fr);
    & > *:nth-child(2) {
      display: none;
    }
  }
`;
export const YeldBoxSubtilte = styled.div`
  color: #868e9b;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ResponsiveGridPosition = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  padding: 0.4rem;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(
      3,
      1fr
    ); /* Change to 3 columns for smaller screens */
    & > *:nth-child(2) {
      display: none; /* Hide the second column on smaller screens */
    }
  }
`;
export const ResponsiveLockPosition = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  padding: 0.4rem;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(
      3,
      1fr
    ); /* Change to 3 columns for smaller screens */
    & > *:nth-child(2) {
      display: none; /* Hide the second column on smaller screens */
    }
  }
`;

export const ResponsiveLockPositionActive = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  padding: 0.4rem;
  grid-template-columns: 1fr 1fr 1fr ;

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(
      3,
      1fr
    ); /* Change to 3 columns for smaller screens */
    & > *:nth-child(2) {
      display: none; /* Hide the second column on smaller screens */
    }
  }
`;

export const BackLogoDiv = styled.div`
  display: flex;
  jusify-content: flex-end;
`;

export const BackLogoGradientInnerDiv = styled.div`
  border-radius: 50%;
  background: linear-gradient(91deg, #a1eeff 7.28%, #029af0 89.82%);
  cursor: pointer;
`;

export const WrappingDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const WrappingDivPool = styled.div``;

export const WrappingDivPoolStake = styled.div``;

export const WrappingDivLock = styled.div`
  /* display: flex;
    align-items: center;
    justify-content: flex-start; */
`;

export const WrappingSpan = styled.span``;

export const FlexSpan = styled.span`
  display: flex;
`;

export const CenterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PoolLink = styled.a`
  margin-left: 10px;
`;

export const CopiedSpan = styled.span`
  font-size: 12px;
  margin-left: 10px;
  color: green;
`;

export const HandleCopySpan = styled.span`
  margin-left: 10px;
  cursor: pointer;
  z-index: 999;
  display: inline-block;
`;

export const FlexDiv = styled.div`
  display: flex;
  font-size: 14px;
`;

export const WhiteListedTokenDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
`;

export const WhiteListedTokenDivInner = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const FeeTierSpan = styled.span`
  background: linear-gradient(194deg, #d96dff 3.9%, #ad00ff 98.12%);
  font-size: 10px;
  font-weight: 300;
  height: 20px;
  max-width: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  border-radius: 12px;
  padding: 5px 18px;
`;
