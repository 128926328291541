import { useState, useEffect } from 'react';
import { SupportedChainId } from 'constants/chains';

interface TokenInfo {
  address?: string;
  circulating_market_cap?: number | null;
  decimals?: string;
  exchange_rate?: number | null;
  holders?: string;
  icon_url?: string;
  name?: string;
  symbol?: string;
  total_supply?: string;
  type?: string;
}

const ChainUrlMapping = {
  [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]: 'https://pegasus.lightlink.io/api/v2/tokens/', //'https://phoenix.lightlink.io/api/v2/tokens/',
  [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]: 'https://pegasus.lightlink.io/api/v2/tokens/',
} as { [key in SupportedChainId]: string };


export const useTokenInfo = (
  chainId: SupportedChainId,
  tokenAddress: string
): TokenInfo | null => {
  const [tokenInfo, setTokenInfo] = useState<TokenInfo | null>(null);

  useEffect(() => {
    const fetchTokenInfo = async () => {
      try {
        const urlSuffix = ChainUrlMapping[chainId];
        const response = await fetch(`${urlSuffix}${tokenAddress}`);
        if (!response.ok) {
          throw new Error('Failed to fetch token information');
        }
        const data = await response.json();
        setTokenInfo(data);
      } catch (error) {
        setTokenInfo(null);
      }
    };

    if (chainId && tokenAddress) {
      fetchTokenInfo();
    }
  }, [chainId, tokenAddress]);

  return tokenInfo;
};

