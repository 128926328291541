import { sendAnalyticsEvent, Trace, useTrace } from "@uniswap/analytics";
import {
  InterfaceModalName,
  SwapEventName,
  SwapPriceUpdateUserResponse,
} from "@uniswap/analytics-events";
import { Percent } from "@uniswap/sdk-core";
import { useWeb3React } from "@web3-react/core";
import Badge from "components/Badge";
import Modal from "components/Modal";
import { RowFixed } from "components/Row";
import { getChainInfo } from "constants/chainInfo";
import { useMaxAmountIn } from "hooks/useMaxAmountIn";
import { AllowanceState } from "hooks/usePermit2Allowance";
import usePrevious from "hooks/usePrevious";
import { getPriceUpdateBasisPoints } from "lib/utils/analytics";
import { useCallback, useEffect, useState } from "react";
import { InterfaceTrade } from "state/routing/types";
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import { isL2ChainId } from "utils/chains";
import { formatSwapPriceUpdatedEventProperties } from "utils/loggingFormatters";
import { didUserReject } from "utils/swapErrorToUserReadableMessage";
import { tradeMeaningfullyDiffers } from "utils/tradeMeaningFullyDiffer";

import { ConfirmationModalContent } from "../TransactionConfirmationModal";
import {
  PendingConfirmModalState,
  PendingModalContent,
} from "./PendingModalContent";
import {
  ErrorModalContent,
  PendingModalError,
} from "./PendingModalContent/ErrorModalContent";
import SwapModalFooter from "./SwapModalFooter";
import SwapModalHeader from "./SwapModalHeader";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "state/hooks";
import { createOrders } from "../../state/orders/actions";
import { useSignedHasedData } from "hooks/useSignHashedData";
import { addPopup } from "state/application/reducer";
import { Allowance } from "types";
import { MODAL_TRANSITION_DURATION } from "constants/common";

export enum ConfirmModalState {
  REVIEWING,
  APPROVING_TOKEN,
  PERMITTING,
  PENDING_CONFIRMATION,
}

const StyledL2Badge = styled(Badge)`
  padding: 6px 8px;
`;

const StyledL2Logo = styled.img`
  height: 16px;
  width: 16px;
`;

function isInApprovalPhase(confirmModalState: ConfirmModalState) {
  return (
    confirmModalState === ConfirmModalState.APPROVING_TOKEN ||
    confirmModalState === ConfirmModalState.PERMITTING
  );
}

function useConfirmModalState({
  trade,
  allowedSlippage,
  onSwap,
  allowance,
  doesTradeDiffer,
}: {
  trade: InterfaceTrade;
  allowedSlippage: Percent;
  onSwap: () => void;
  allowance: Allowance;
  doesTradeDiffer: boolean;
}) {
  const [confirmModalState, setConfirmModalState] = useState<ConfirmModalState>(
    ConfirmModalState.REVIEWING
  );
  const [approvalError, setApprovalError] = useState<PendingModalError>();
  const [pendingModalSteps, setPendingModalSteps] = useState<
    PendingConfirmModalState[]
  >([]);

  const { pathname } = useLocation();

  const prepareSwapFlow = useCallback(() => {
    const steps: PendingConfirmModalState[] = [];
    if (
      allowance.state === AllowanceState.REQUIRED &&
      allowance.needsPermit2Approval
    ) {
      steps.push(ConfirmModalState.APPROVING_TOKEN);
    }
    if (
      allowance.state === AllowanceState.REQUIRED &&
      allowance.needsSignature
    ) {
      steps.push(ConfirmModalState.PERMITTING);
    }
    steps.push(ConfirmModalState.PENDING_CONFIRMATION);
    setPendingModalSteps(steps);
  }, [allowance]);

  // console.log("allowance",allowance)

  const trace = useTrace();
  const maximumAmountIn = useMaxAmountIn(trade, allowedSlippage);
  const dispatchData = useAppDispatch();

  const { chainId, provider, account } = useWeb3React();

  const startSwapFlow = useCallback(async () => {
    setApprovalError(undefined);
    if (allowance.state === AllowanceState.REQUIRED) {
      try {
        if (allowance.needsPermit2Approval) {
          setConfirmModalState(ConfirmModalState.APPROVING_TOKEN);
          await allowance.approve();
        } else {
          setConfirmModalState(ConfirmModalState.PERMITTING);
          await allowance.permit();
        }
      } catch (e) {
        setConfirmModalState(ConfirmModalState.REVIEWING);
        if (didUserReject(e)) {
          return;
        }
        setApprovalError(
          allowance.needsPermit2Approval
            ? PendingModalError.TOKEN_APPROVAL_ERROR
            : PendingModalError.PERMIT_ERROR
        );
      }
    } else {
      setConfirmModalState(ConfirmModalState.PENDING_CONFIRMATION);
      onSwap();
    }
  }, [
    allowance,
    chainId,
    maximumAmountIn?.currency.address,
    maximumAmountIn?.currency.symbol,
    onSwap,
  ]);

  const previousPermitNeeded = usePrevious(
    allowance.state === AllowanceState.REQUIRED
      ? allowance.needsPermit2Approval
      : undefined
  );
  useEffect(() => {
    if (
      allowance.state === AllowanceState.REQUIRED &&
      allowance.needsSignature &&
      !allowance.needsPermit2Approval &&
      previousPermitNeeded
    ) {
      prepareSwapFlow();
    }
  }, [allowance, previousPermitNeeded, startSwapFlow, prepareSwapFlow]);

  useEffect(() => {
    if (
      isInApprovalPhase(confirmModalState) &&
      allowance.state === AllowanceState.ALLOWED
    ) {
      if (doesTradeDiffer) {
        setConfirmModalState(ConfirmModalState.REVIEWING);
        return;
      }

      startSwapFlow();
    }
  }, [allowance, confirmModalState, doesTradeDiffer, startSwapFlow]);

  const onCancel = () => {
    setConfirmModalState(ConfirmModalState.REVIEWING);
    setApprovalError(undefined);
  };

  return {
    startSwapFlow,
    prepareSwapFlow,
    onCancel,
    confirmModalState,
    approvalError,
    pendingModalSteps,
  };
}

export default function ConfirmSwapModal({
  formattedAmountsLimit,
  trade,
  originalTrade,
  onAcceptChanges,
  allowedSlippage,
  allowance,
  onConfirm,
  onDismiss,
  swapError,
  txHash,
  swapQuoteReceivedDate,
  fiatValueInput,
  fiatValueOutput,
  limitOrderPayload,
  LimitOrderCallback,
}: {
  formattedAmountsLimit?: any;
  trade: InterfaceTrade;
  originalTrade?: InterfaceTrade;
  txHash?: string;
  allowedSlippage: Percent;
  allowance: Allowance;
  onAcceptChanges: () => void;
  onConfirm: () => void;
  swapError?: Error;
  onDismiss: () => void;
  swapQuoteReceivedDate?: Date;
  fiatValueInput: { data?: number; isLoading: boolean };
  fiatValueOutput: { data?: number; isLoading: boolean };
  limitOrderPayload?: any;
  LimitOrderCallback?: any;
}) {
  const { pathname } = useLocation();
  const { chainId, account, provider } = useWeb3React();
  const hashedDataContract = useSignedHasedData(chainId);
  const doesTradeDiffer =
    originalTrade &&
    tradeMeaningfullyDiffers(trade, originalTrade, allowedSlippage);
  const {
    startSwapFlow,
    onCancel,
    confirmModalState,
    approvalError,
    pendingModalSteps,
    prepareSwapFlow,
  } = useConfirmModalState({
    trade,
    allowedSlippage,
    onSwap: onConfirm,
    allowance,
    doesTradeDiffer: Boolean(doesTradeDiffer),
  });
  const dispatch = useAppDispatch();

  const swapFailed = Boolean(swapError) && !didUserReject(swapError);
  useEffect(() => {
    if (swapError && !swapFailed) {
      onCancel();
    }
  }, [onCancel, swapError, swapFailed]);

  const showAcceptChanges = Boolean(
    trade &&
      doesTradeDiffer &&
      confirmModalState !== ConfirmModalState.PENDING_CONFIRMATION
  );

  const [lastExecutionPrice, setLastExecutionPrice] = useState(
    trade?.executionPrice
  );
  const [priceUpdate, setPriceUpdate] = useState<number>();
  useEffect(() => {
    if (
      lastExecutionPrice &&
      !trade.executionPrice.equalTo(lastExecutionPrice)
    ) {
      setPriceUpdate(
        getPriceUpdateBasisPoints(lastExecutionPrice, trade.executionPrice)
      );
      setLastExecutionPrice(trade.executionPrice);
    }
  }, [lastExecutionPrice, setLastExecutionPrice, trade]);

  const onModalDismiss = useCallback(() => {
    if (showAcceptChanges) {
      sendAnalyticsEvent(
        SwapEventName.SWAP_PRICE_UPDATE_ACKNOWLEDGED,
        formatSwapPriceUpdatedEventProperties(
          trade,
          priceUpdate,
          SwapPriceUpdateUserResponse.REJECTED
        )
      );
    }
    onDismiss();
    setTimeout(() => {
      // Reset local state after the modal dismiss animation finishes, to avoid UI flicker as it dismisses
      onCancel();
    }, MODAL_TRANSITION_DURATION);
  }, [onCancel, onDismiss, priceUpdate, showAcceptChanges, trade]);

  const modalHeader = useCallback(() => {
    if (
      confirmModalState !== ConfirmModalState.REVIEWING &&
      !showAcceptChanges
    ) {
      return null;
    }
    return (
      <SwapModalHeader
        trade={trade}
        allowedSlippage={allowedSlippage}
        limitOrderPayload={limitOrderPayload}
        formattedAmountsLimit={formattedAmountsLimit}
      />
    );
  }, [allowedSlippage, confirmModalState, showAcceptChanges, trade]);

  const modalBottom = useCallback(() => {
    if (
      confirmModalState === ConfirmModalState.REVIEWING ||
      showAcceptChanges
    ) {
      return (
        <SwapModalFooter
          onConfirm={() => {
            prepareSwapFlow();
            startSwapFlow();
          }}
          trade={trade}
          hash={txHash}
          allowedSlippage={allowedSlippage}
          disabledConfirm={showAcceptChanges}
          swapQuoteReceivedDate={swapQuoteReceivedDate}
          fiatValueInput={fiatValueInput}
          fiatValueOutput={fiatValueOutput}
          showAcceptChanges={showAcceptChanges}
          onAcceptChanges={onAcceptChanges}
          swapErrorMessage={swapFailed ? swapError?.message : undefined}
        />
      );
    }
    return (
      <PendingModalContent
        formattedAmountsLimit={formattedAmountsLimit}
        hideStepIndicators={pendingModalSteps.length === 1}
        steps={pendingModalSteps}
        currentStep={confirmModalState}
        trade={trade}
        swapTxHash={txHash}
        tokenApprovalPending={
          allowance.state === AllowanceState.REQUIRED &&
          allowance.isApprovalPending
        }
        allowance={allowance}
        onReallowance={() => {
          prepareSwapFlow();
          startSwapFlow();
        }}
        onDismiss={onDismiss}
      />
    );
  }, [
    confirmModalState,
    showAcceptChanges,
    pendingModalSteps,
    trade,
    txHash,
    allowance,
    allowedSlippage,
    swapQuoteReceivedDate,
    fiatValueInput,
    fiatValueOutput,
    onAcceptChanges,
    swapFailed,
    swapError?.message,
    prepareSwapFlow,
    startSwapFlow,
  ]);

  const l2Badge = () => {
    if (
      isL2ChainId(chainId) &&
      confirmModalState !== ConfirmModalState.REVIEWING
    ) {
      const info = getChainInfo(chainId);
      return (
        <StyledL2Badge>
          <RowFixed data-testid="confirmation-modal-chain-icon" gap="sm">
            <ThemedText.SubHeaderSmallWhite>
              {info.label}
            </ThemedText.SubHeaderSmallWhite>
            <StyledL2Logo src={info.logoUrl} />
          </RowFixed>
        </StyledL2Badge>
      );
    }
    return undefined;
  };

  return (
    <Trace modal={InterfaceModalName.CONFIRM_SWAP}>
      <Modal isOpen $scrollOverlay onDismiss={onModalDismiss} maxHeight={90}>
        {approvalError || swapFailed ? (
          <ErrorModalContent
            errorType={approvalError ?? PendingModalError.CONFIRMATION_ERROR}
            onRetry={startSwapFlow}
          />
        ) : (
          <ConfirmationModalContent
            title={
              confirmModalState === ConfirmModalState.REVIEWING ? (
                <div>Review swap</div>
              ) : undefined
            }
            onDismiss={onModalDismiss}
            topContent={modalHeader}
            bottomContent={modalBottom}
            headerContent={l2Badge}
          />
        )}
      </Modal>
    </Trace>
  );
}
