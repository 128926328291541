import { AutoColumn } from 'components/Column'
import {  useCallback, useEffect, useState } from 'react'
import { Minus, Plus } from 'react-feather'
import { ButtonLabel, FocusedOutlineCard, InputRow, InputTitle, InputTitleDark,  SmallButton, StyledInput } from './styled'
import { StepCounterProps } from 'types'

const StepCounter = ({
  value,
  decrement,
  increment,
  decrementDisabled = false,
  incrementDisabled = false,
  width, 
  locked,
  onUserInput,
  title,
  tokenA,
  tokenB,
}: StepCounterProps) => {
  //  for focus state, styled components doesnt let you select input parent container
  const [active, setActive] = useState(false)

  // let user type value and only update parent value on blur
  const [localValue, setLocalValue] = useState('')
  const [useLocalValue, setUseLocalValue] = useState(false)

  // animation if parent value updates local value
  const [pulsing, setPulsing] = useState<boolean>(false)

  const handleOnFocus = () => {
    setUseLocalValue(true)
    setActive(true)
  }

  const handleOnBlur = useCallback(() => {
    setUseLocalValue(false)
    setActive(false)
    onUserInput(localValue) // trigger update on parent value
  }, [localValue, onUserInput])

  // for button clicks
  const handleDecrement = useCallback(() => {
    setUseLocalValue(false)
    onUserInput(decrement())
  }, [decrement, onUserInput])

  const handleIncrement = useCallback(() => {
    setUseLocalValue(false)
    onUserInput(increment())
  }, [increment, onUserInput])

  useEffect(() => {
    if (localValue !== value && !useLocalValue) {
      setTimeout(() => {
        setLocalValue(value) // reset local value to match parent
        setPulsing(true) // trigger animation
        setTimeout(function () {
          setPulsing(false)
        }, 1800)
      }, 0)
    }
  }, [localValue, useLocalValue, value])
console.log("DECREMNETDISABLED")
  return (
    <FocusedOutlineCard pulsing={pulsing} active={active} onFocus={handleOnFocus} onBlur={handleOnBlur} width={width} style={{ height: '120px', display: 'flex',  }} >
      <AutoColumn gap="6px">
        <InputTitle fontSize={14} textAlign="center">
          {title}
        </InputTitle>

        <InputRow>
          {!locked && (
            <SmallButton onClick={handleDecrement} disabled={decrementDisabled} style={{ background: 'linear-gradient(146deg, #a1eeff 0%, #029af0 100%)'}}>
              <ButtonLabel disabled={decrementDisabled} fontSize="12px">
                <Minus size={22} />
              </ButtonLabel>
            </SmallButton>
          )}

          <StyledInput
            className="rate-input-0"
            value={localValue}
            fontSize="20px" 
            disabled={locked}
            onUserInput={(val) => {
              setLocalValue(val)
            }}
            style={{color:'#ffffff'}}
          />

          {!locked && (
            <SmallButton onClick={handleIncrement} disabled={incrementDisabled} style={{ background: 'linear-gradient(146deg, #a1eeff 0%, #029af0 100%)'}} >
              <ButtonLabel disabled={incrementDisabled} fontSize="12px">
                <Plus size={22} />
              </ButtonLabel>
            </SmallButton>
          )}
        </InputRow>

        <InputTitleDark fontSize={14} textAlign="center" style={{marginTop:10}}>
          <div>
            {tokenB} per {tokenA}
          </div>
        </InputTitleDark>
      </AutoColumn>
    </FocusedOutlineCard>
  )
}

export default StepCounter
