import { CustomDarkCard } from 'components/Card'
import styled, { keyframes } from 'styled-components/macro'
import { ThemedText } from 'theme'

export const ResponsiveText = styled(ThemedText.DeprecatedLabel)`
  line-height: 16px;
  font-size: 14px;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    font-size: 12px;
    line-height: 12px;
  `};
`


export const pulse = (color: string) => keyframes`
  0% {
    box-shadow: 0 0 0 0 ${color};
  }

  70% {
    box-shadow: 0 0 0 2px ${color};
  }

  100% {
    box-shadow: 0 0 0 0 ${color};
  }
`
export const FocusedOutlineCard = styled(CustomDarkCard)<{ pulsing: boolean }>`
align-items: center;
display: flex;
  animation: ${({ pulsing, theme }) => pulsing && pulse(theme.accentAction)} 0.6s linear;
  align-self: center;
  height:50px;
  font-size: 14px;

`

export const Select = styled.div`
  align-items: flex-start;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 12px;
`
