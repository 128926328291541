import { useWeb3React } from '@web3-react/core'
import Column from 'components/Column'
import { LoadingRows } from 'components/Loader/styled'
import RoutingDiagram from 'components/RoutingDiagram/RoutingDiagram'
import { SUPPORTED_GAS_ESTIMATE_CHAIN_IDS } from 'constants/chains'
import useAutoRouterSupported from 'hooks/useAutoRouterSupported'
import { InterfaceTrade } from 'state/routing/types'
import { CloseIcon, Separator, ThemedText } from 'theme'
import getRoutingDiagramEntries from 'utils/getRoutingDiagramEntries'

import RouterLabel from './RouterLabel'
import { SwapRouteWrapper } from './styleds'
import { formatNumberWithSuffix } from 'utils/numbers'
export default function SwapRoute({ trade, syncing,setShowRoute }: { trade: InterfaceTrade; syncing: boolean, setShowRoute?:any }) {
  const { chainId } = useWeb3React()
  const autoRouterSupported = useAutoRouterSupported()

  const routes = getRoutingDiagramEntries(trade)

  const gasPrice =
    trade.gasUseEstimateUSD && chainId && SUPPORTED_GAS_ESTIMATE_CHAIN_IDS.includes(chainId)
      ? trade.gasUseEstimateUSD === '0.00'
        ? '<$0.01'
        : '$' + trade.gasUseEstimateUSD
      : undefined

      let formattedNumber = gasPrice && gasPrice.toString();
       formattedNumber = formattedNumber && formattedNumber.replace('$', '');


console.log("SHSHHS", formattedNumber && formatNumberWithSuffix( Number(formattedNumber)), gasPrice)
  return (
    <SwapRouteWrapper>
    <Column gap="md">
      <div style={{display:'flex', justifyContent:'space-between'}}>
      <RouterLabel />
      <CloseIcon
          onClick={()=>setShowRoute(false)}
          style={{ zIndex: 99,  }}
          stroke="#647383"
        />
      </div>

   
      <Separator />
      {syncing ? (
        <LoadingRows>
          <div style={{ width: '100%', height: '30px' }} />
        </LoadingRows>
      ) : (
        <RoutingDiagram
          currencyIn={trade.inputAmount.currency}
          currencyOut={trade.outputAmount.currency}
          routes={routes}
        />
      )}
      {autoRouterSupported && (
        <>
          <Separator />
          {syncing ? (
            <LoadingRows>
              <div style={{ width: '100%', height: '15px' }} />
            </LoadingRows>
          ) : (
            <ThemedText.Caption color="textSecondary">
              {gasPrice ? <div>Best price route costs ~${formatNumberWithSuffix( Number(formattedNumber))} in gas.</div> : null}{' '}
              <div>
                This route optimizes your total output by considering split routes, multiple hops, and the gas cost of
                each step.
              </div>
            </ThemedText.Caption>
          )}
        </>
      )}
    </Column>
    </SwapRouteWrapper>

  )
}
