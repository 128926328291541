import { MaxButton } from "pages/Pool/styleds";
import { Text } from "rebass";
import styled from "styled-components/macro";

export const Wrapper = styled.div`
  position: relative;
  padding: 35px 38px;
  min-width: 550px;
  margin-top:70px;
  margin-bottom: 100px;
  position: relative;
  --border-width: 2px;
  --border-radius: 12px;

  background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
  border: 0; 
  position: relative;
  border-radius: var(--border-radius);
  z-index: 1;
  &::before {
    content: "";
    display: block;
    height: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    width: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    background: ${({ theme }) => theme.backgroundSurface};
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: calc(var(--border-radius) - var(--border-width));
    z-index: -1; 
  }
  overflow-y: auto;

  /* Style the scrollbar */
  ::-webkit-scrollbar {
    width: 8px; /* Set the width of the scrollbar */
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.inputGray};
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToExtraSmall`
    min-width: 340px;
  `};
`;

export const SmallMaxButton = styled(MaxButton)`
  font-size: 12px;
  background: linear-gradient(180deg, #2C444B 0%, #113649 100%);
  &:hover { 
    background: var(--Gradient-1, linear-gradient(191deg, #A1EEFF 5.4%, #029AF0 92.07%));
    color: #091012;
    border: none;
    
  }
`;

export const ResponsiveHeaderText = styled(Text)`
  font-size: 40px;
  font-weight: 500;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToExtraSmall`
     font-size: 24px
  `};
`;
