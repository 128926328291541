import { SupportedChainId } from "./chains";

interface EthereumAddressToNameMap {
  [address: string]: string;
}

export const ethereumAddressToNameMap: EthereumAddressToNameMap = {
  "0x7EbeF2A4b1B09381Ec5B9dF8C5c6f2dBECA59c73": "ethereum",
  "0x8Fd8Bc93518Ea586bE25C31a8973636192734555": "arbitrum",
  "0x49F65C3FfC6e45104ff5cB00e6030C626157a90b": "dai",
  "0xD80BEf7938ddFd95Ba0C13410fb7708e63f6F204": "bitkub",
  "0x0b0eDaC05440669c5EE81FF8Ad98B8d2e7F3d57B": "chainlink",
  "0x0B0a417dC62721b16A8A2a6a3807b97F557D6209": "matic-network",
  "0xb4c16Cc8d80fdD59B6937Ce9072f4863DCe20077": "uniswap",
  "0x18fB38404DADeE1727Be4b805c5b242B5413Fa40": "usd-coin",
  "0x6308fa9545126237158778e74AE1b6b89022C5c0": "tether",
  "0x46A5e3Fa4a02B9Ae43D9dF9408C86eD643144A67": "wrapped-bitcoin",
  "0x81A1f39f7394c4849E4261Aa02AaC73865d13774": "wbnb",
  "0xe06FE7298243D8e47092a1E0679351f305e5f856": "binance-usd",
  "0xe03bA6851335D01d38Dd6087c46C26008D16aF92": "carbon-browser",
};

export const timeOptions = [
  // "1Min", "5Min", 
  "1H", "1D", "1M", "1Y"];
export const geckoTimeOptions = ["1D", "1W", "1M"];

export const dayToQuery: any = {
  "1D": 1,
  "1W": 7,
  "1M": 30,
  "1Y": 365,
};

// export const DEFAULT_TOKEN = '0x3cf2c147d43C98Fa96d267572e3FD44A4D3940d4'

export const DEFAULT_TOKEN: any = {
  [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]:
    "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
  [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]:
    "0x3cf2c147d43C98Fa96d267572e3FD44A4D3940d4",
};
