import { AlertTriangle } from "react-feather";
import styled from "styled-components/macro";
import { ExternalLink, ThemedText } from "theme";
import { flexColumnNoWrap, flexRowNoWrap } from "theme/styles";

export const StyledLink = styled(ExternalLink)`
  font-weight: 600;
  color: ${({ theme }) => theme.textSecondary};
`;

export const OptionCardLeft = styled.div`
  ${flexColumnNoWrap};
  flex-direction: row;
  align-items: center;
`;

export const OptionCardClickable = styled.button<{ selected: boolean }>`
  align-items: center;
  border-radius: 10px;
  background: #121b1e;
  border: none;
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  opacity: ${({ disabled, selected }) => (disabled && !selected ? "0.5" : "1")};
  padding: 18px;
  // transition: ${({ theme }) => theme.transition.duration.fast};
  &:hover {
    background: linear-gradient(91deg, #39484b 7.28%, #1a3848 89.82%);
  }
`;

export const HeaderText = styled.div`
  ${flexRowNoWrap};
  align-items: center;
  justify-content: center;
  color: ${(props) =>
    props.color === "blue"
      ? ({ theme }) => theme.accentAction
      : ({ theme }) => theme.textPrimary};
  font-size: 16px;
  font-weight: 600;
  padding: 0 8px;
`;
export const IconWrapper = styled.div`
  ${flexColumnNoWrap};
  align-items: center;
  margin-right: 20px;
  justify-content: center;
  & > img,
  span {
    height: 40px;
    width: 40px;
  }
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    align-items: flex-end;
  `};
`;

export const TOGGLE_SIZE = 24;
export const WCv2PopoverToggle = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.textTertiary};
  cursor: pointer;
  display: flex;
  height: ${TOGGLE_SIZE}px;
  justify-content: center;
  margin: 0;
  max-width: 48px;
  padding: 0;
  position: absolute;
  right: 16px;
  top: calc(50% - ${TOGGLE_SIZE / 2}px);
  width: ${TOGGLE_SIZE}px;

  &:hover {
    opacity: 0.6;
  }
`;
export const Wrapper = styled.div<{ disabled: boolean }>`
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  margin-bottom: 6px;
  width: 100%;
  border-radius: 10px;
  background-color: #121b1e;

  &:hover {
    cursor: ${({ disabled }) => !disabled && "pointer"};
  }
  &:focus {
  }
  background: ${({ theme }) => theme.inputGray};
`;

export const WCv2Icon = styled.img`
  height: 20px !important;
  width: 20px !important;
`;
export const WCv2BodyText = styled(ThemedText.BodyPrimary)`
  margin-bottom: 4px;
  text-align: left;
`;
export const WCv2Caption = styled(ThemedText.Caption)`
  text-align: left;
`;

export const WalletModalWrapper = styled.div`
  max-width: 360px;

  ${flexColumnNoWrap};

  flex: 1;
`;

export const OptionGrid = styled.div`
  display: grid;
  grid-gap: 2px;
  /* margin-left:5px; */
  overflow: hidden;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    grid-template-columns: 1fr;
  `};
`;

export const PrivacyPolicyWrapper = styled.div`
  padding: 0 4px;
  margin: 0px 0 3px 3px;
`;

export const ConnectionErrorWrapper = styled.div`
  ${flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const AlertTriangleIcon = styled(AlertTriangle)`
  width: 90px;
  height: 90px;
  stroke-width: 1;
  margin: 36px;
  color: ${({ theme }) => theme.accentCritical};
`;
