import { TraceEvent } from '@uniswap/analytics'
import { BrowserEvent, InterfaceElementName, InterfaceEventName } from '@uniswap/analytics-events'
import { useAccountDrawer } from 'components/AccountDrawer'
import { ButtonEmphasis, ButtonSize, ThemeButton } from 'components/Button'
import Loader from 'components/Icons/LoadingSpinner'
import { walletConnectV2Connection } from 'connection'
import { ActivationStatus, useActivationState } from 'connection/activate'
import {  ConnectionType } from 'connection/types'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { MouseEvent, useEffect, useRef, useState } from 'react'
import { MoreHorizontal } from 'react-feather'
import styled from 'styled-components/macro'
import { useIsDarkMode } from 'theme/components/ThemeToggle'
import { Z_INDEX } from 'theme/zIndex'

import NewBadge from './NewBadge'
import { HeaderText, IconWrapper, OptionCardClickable, OptionCardLeft, WCv2BodyText, WCv2Caption, WCv2Icon, WCv2PopoverToggle, Wrapper } from './styled'
import { OptionProps, PopupButtonContentProps } from 'types'



const WCv2PopoverContent = styled(ThemeButton)`
  background: ${({ theme }) => theme.backgroundSurface};
  border: 1px solid ${({ theme }) => theme.backgroundOutline};
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  max-width: 240px;
  padding: 16px;
  position: absolute;
  right: 12px;
  top: 52px;
  z-index: ${Z_INDEX.popover};
`


function PopupButtonContent({ connection, isDarkMode, show, onClick, onClose }: PopupButtonContentProps) {
  const popoverElement = useRef<HTMLButtonElement>(null)
  useOnClickOutside(popoverElement, onClose)
  if (!show) return null
  return (
    <WCv2PopoverContent onClick={onClick} ref={popoverElement} size={ButtonSize.small} emphasis={ButtonEmphasis.medium}>
      <IconWrapper>
        <WCv2Icon src={connection.getIcon?.(isDarkMode)} alt={connection.getName()} />
      </IconWrapper>
      <div>
        <WCv2BodyText>Connect with v2</WCv2BodyText>
        <WCv2Caption color="textSecondary">Under development and unsupported by most wallets</WCv2Caption>
      </div>
    </WCv2PopoverContent>
  )
}


export default function Option({ connection }: OptionProps) {
  const { activationState, tryActivation } = useActivationState()
  const [WC2PromptOpen, setWC2PromptOpen] = useState(false)
  const [accountDrawerOpen, toggleAccountDrawerOpen] = useAccountDrawer()
  const activate = () => tryActivation(connection, toggleAccountDrawerOpen)

  useEffect(() => {
    if (!accountDrawerOpen) setWC2PromptOpen(false)
  }, [accountDrawerOpen])

  const isSomeOptionPending = activationState.status === ActivationStatus.PENDING
  const isCurrentOptionPending = isSomeOptionPending && activationState.connection.type === connection.type
  const isDarkMode = useIsDarkMode()

  const handleClickConnectViaWCv2 = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    tryActivation(walletConnectV2Connection, () => {
      setWC2PromptOpen(false)
      toggleAccountDrawerOpen()
    })
  }
  const handleClickOpenWCv2Tooltip = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setWC2PromptOpen(true)
  }

  return (
    <Wrapper disabled={isSomeOptionPending}>
      <TraceEvent
        events={[BrowserEvent.onClick]}
        name={InterfaceEventName.WALLET_SELECTED}
        properties={{ wallet_type: connection.getName() }}
        element={InterfaceElementName.WALLET_TYPE_OPTION}
      >
        <OptionCardClickable
          disabled={isSomeOptionPending}
          onClick={activate}
          selected={isCurrentOptionPending}
          data-testid={`wallet-option-${connection.type}`}
        >
          <OptionCardLeft>
            <IconWrapper>
              <img src={connection.getIcon?.(isDarkMode)} alt={connection.getName()} />
            </IconWrapper>
            <HeaderText>{connection.getName()}</HeaderText>
            {connection.isNew && <NewBadge />}
          </OptionCardLeft>
          {isCurrentOptionPending && <Loader />}
        </OptionCardClickable>
      </TraceEvent>

      {connection.type === ConnectionType.WALLET_CONNECT && (
        <>
          <WCv2PopoverToggle onClick={handleClickOpenWCv2Tooltip} onMouseDown={handleClickOpenWCv2Tooltip}>
            <MoreHorizontal />
          </WCv2PopoverToggle>
          <PopupButtonContent
            connection={connection}
            isDarkMode={isDarkMode}
            show={WC2PromptOpen}
            onClick={handleClickConnectViaWCv2}
            onClose={() => setWC2PromptOpen(false)}
          />
        </>
      )}
    </Wrapper>
  )
}
