import styled from 'styled-components';

const SwitchContainer = styled.label<{ size?: string; }>`
  position: relative;
  display: flex;
  width: ${props => props.size === 'lg' ? '45px' : '30px'};
  height: ${props => props.size === 'lg' ? '24px' : '16px'} ;
  margin-left: 10px;
  margin-right: 10px;
  align-items: center;
`;

const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const Slider = styled.span<{ size?: string; }>`
  display: flex;
  align-items: center;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1F2831;
  border-radius: 8px;
  width: ${props => props.size === 'lg' ? '45px' : '30px'};
  height: ${props => props.size === 'lg' ? '24px' : '16px'} ;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    width: ${props => props.size === 'lg' ? '15px' : '10px'};
    height: ${props => props.size === 'lg' ? '15px' : '10px'} ;
    left: 4px;
    background-color: #FFF;
    transition: 0.4s;
    border-radius: 50%;
  }

  ${SwitchInput}:checked + & {
    background-color: #FFF;
  }

  ${SwitchInput}:focus + & {
    box-shadow: 0 0 1px #2196F3;
  }

  ${SwitchInput}:checked + &:before {
    background-color: #535353;
    transform: ${props => props.size === 'lg' ? 'translateX(19px)' : 'translateX(12px)'};
  }
`;

function CustomSwitch({ isChecked, toggleSwitch, size ,disabled=false}: any) {


  return (
    <SwitchContainer size={size} >
      <SwitchInput type="checkbox" checked={isChecked} onChange={toggleSwitch}  disabled={disabled}/>
      <Slider size={size} className="slider round"></Slider>
    </SwitchContainer>
  );
}

export default CustomSwitch;