import { ButtonConfirmed, ButtonPrimary } from "components/Button"
import { RowBetween } from "components/Row"
import styled from "styled-components"
import { ThemedText } from "theme"
import { Z_INDEX } from "theme/zIndex"

export const PositionPageButtonPrimary = styled(ButtonPrimary)`
  width: 228px;
  height: 40px;
  font-size: 16px;
  line-height: 20px;
  border-radius: 12px;
`


export const TokenLogoContainer = styled.div` 
font-size: 32px;
font-weight: 600;
@media (max-width: 503px) {   
  font-size: 24px;
}

`

export const GradientConteinerLeft = styled.div`
 width: 30%;
 background: linear-gradient(191deg, #203035 5.4%, #112833 92.07%);
 border-radius: 12px 0px 0px 12px;
 justify-content: center;
 display: flex;
 align-items: center;

@media (max-width: 710px) {  
}

`
export const PinkBageContainer = styled.div`
 

@media (max-width: 710px) {  
  display: flex;
  justify-content: center;
}

`


export const DarkContainerRight = styled.div`

width: 70%;
padding: 25px 27px;
border-radius: 0px 12px 12px 0px;
background: #121B1E; 
@media (max-width: 710px) {  
  padding: 15px 17px;
}

`

 
export const CalimContainer = styled.div`

display: flex;
width: 100%;
justify-content: space-between;
@media (max-width: 710px) {  
  display: grid;
  grid-template-columns: 1fr;
}

`

export const ElektrikStakedContainer = styled.div`
display:flex;
flex-direction:column;
justify-content: space-between;

 
@media (max-width: 710px) {  
  display: flex; 
  justify-content: flex-start;
  gap: 10px;
}

`

export const PoolBadgeContainer = styled.div`
 
display: flex;
jsutify-content: flex-end;
align-items: center; 
@media (max-width: 710px) {  
   margin-left: 0px;
   justify-content: center;
}
`

export const WalletContinerGrid = styled.div`
  
display: grid;
font-size: 12px;
grid-template-columns: 1fr 2fr 2fr;
@media (max-width: 710px) {  
   justify-content: center;
   grid-template-columns: 1fr;
}

`

export const TopSectionHeader = styled.div`

 

width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
@media (max-width: 710px) {  
  display: grid;

  justify-content: center;
  grid-template-columns: 1fr; 
}

`
 

export const PoolCardsContainer = styled.div`
width: 100%;
display: flex;
background: none;
gap:10px;

@media (max-width: 710px) { 
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
}

`

 
export const DarkCardGradient = styled.div`
  width:100%;
  height:100%; 
  overflow: hidden;
   padding: 25px 30px 22px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  --border-width: 2px;
  --border-radius: 12px;
  background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
  border: 0; 
  border-radius: var(--border-radius);
  z-index: 1;
  &::before {
    content: "";
    display: block;
    height: calc(
      100% - (var(--border-width) * 2)
    );  
    width: calc(
      100% - (var(--border-width) * 2)
    );  
    background: ${({ theme }) => theme.backgroundSurface};
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: calc(var(--border-radius) - var(--border-width));
    z-index: -1;
 

`

export const PositionPageWrapper = styled.div`
  padding: 68px 16px 16px 16px;

  min-width: 800px;
  max-width: 960px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    min-width: 100%;
    padding: 16px;
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    min-width: 100%;
    padding: 16px;
  }
  margin-bottom: 100px;
`

export const BadgeText = styled.div`
  font-weight: 500;
  font-size: 14px;
`
export const Label = styled(({ end, ...props }) => <ThemedText.DeprecatedLabel {...props} />) <{ end?: boolean }>`
  display: flex;
  font-size: 16px;
  justify-content: ${({ end }) => (end ? 'flex-end' : 'flex-start')};
  align-items: center;
`

export const LabelPosition = styled(({ end, ...props }) => <ThemedText.DeprecatedBlack {...props} />) <{ end?: boolean }>`
  display: flex;
  font-size: 16px;
  justify-content: ${({ end }) => (end ? 'flex-end' : 'flex-start')};
  align-items: center;
`

export const ExtentsText = styled.span`
  color: ${({ theme }) => theme.textSecondary};
  font-size: 14px;
  text-align: center;
  margin-right: 4px;
  font-weight: 500;
`
export const HoverText = styled(ThemedText.DeprecatedMain)`
  text-decoration: none;
  color: ${({ theme }) => theme.textTertiary};
  :hover {
    color: ${({ theme }) => theme.textPrimary};
    text-decoration: none;
  }
`

export const DoubleArrow = styled.span`
  color: ${({ theme }) => theme.textTertiary};
  margin: 0 1rem;
`
export const ResponsiveRow = styled(RowBetween)`
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
    width: 100%;
  }
`

export const ActionButtonResponsiveRow = styled(ResponsiveRow)`
  width: 50%;
  justify-content: flex-end;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    width: 100%;
    flex-direction: row;
    * {
      width: 100%;
    }
  }
`

export const ResponsiveButtonConfirmed = styled(ButtonConfirmed)`
  border-radius: 10px;
  padding: 6px 8px;
  width: fit-content;
  font-size: 16px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    width: fit-content;
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    width: fit-content;
  }
`

export const NFTGrid = styled.div`
  display: grid;
  grid-template: 'overlap';
  min-height: 400px; 
`

export const NFTCanvas = styled.canvas`
  grid-area: overlap;
`

export const NFTImage = styled.img`
  grid-area: overlap;
  height: 400px;
  z-index: ${Z_INDEX.default};
`