import { Currency } from "@uniswap/sdk-core";
import { useWeb3React } from "@web3-react/core";
import { ButtonText } from "components/Button";
import useCurrencyBalance from "lib/hooks/useCurrencyBalance";
import styled from "styled-components/macro";
import { ThemedText } from "theme";

import { RowBetween, RowFixed } from "../Row";
import { useLocation } from "react-router-dom";

export const StyledSwapHeader = styled(RowBetween)`
  color: ${({ theme }) => theme.textSecondary};
`;

export const HeaderButtonContainer = styled(RowFixed)`
  padding: 0;
  gap: 16px;
`;

export const StyledTextButton = styled(ButtonText)`
  color: ${({ theme }) => theme.textSecondary};
  gap: 5px;
  margin: 2px;
  font-size: 10px;
  width: 22px;
  height: 22px;
  &:focus {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:hover {
    color: #a2eeff;
    width: 38px;
    height: 21px;
    background: linear-gradient(191deg, #263c42 5.4%, #092d3f 92.07%);
  }
`;



export const GradientText = styled.div`
  background: linear-gradient(90.8deg, #a2eeff 7.28%, #029af0 89.82%);
  -webkit-background-clip: text;
  color: transparent;
  display: inline;
  font-size: 14px;
  font-weight: bold;
  gap: 10px;
  margin: 4px;
  cursor: pointer;
  text-decoration: none;


`;

export const GradientTextHover = styled.div`
  background: linear-gradient(90.8deg, #a2eeff 7.28%, #029af0 89.82%);
  -webkit-background-clip: text;
  color: transparent;
  display: inline;
  font-size: 12px;
  font-weight: bold;
  gap: 10px;
  margin: 4px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: linear-gradient(90.8deg, #029af0 7.28%, #a2eeff 89.82%);
    -webkit-background-clip: text;
    color: transparent;
    display: inline;
    font-size: 12px;
    font-weight: bold;
    gap: 10px;
    margin: 4px;
    cursor: pointer;
    text-decoration: none;
  }
`;

export default function SwapInputHeader({
  title,
  showMaxButton,
  onMax,
  currency,
  handlePercentageInput,
  isSwap = true,
}: {
  title: string;
  showMaxButton: boolean;
  onMax?: () => void;
  currency?: Currency | null;
  handlePercentageInput: (perc: number) => void;
  isSwap?: boolean;
}) {
  const { account } = useWeb3React();
  const selectedCurrencyBalance = useCurrencyBalance(
    account ?? undefined,
    currency ?? undefined
  );
  const { pathname } = useLocation();

  return (
    <StyledSwapHeader>
      <HeaderButtonContainer>
        <ThemedText.SubHeader fontSize={12}>
          <div>{title}</div>
        </ThemedText.SubHeader>
      </HeaderButtonContainer>
    </StyledSwapHeader>
  );
}
