import { AutoColumn } from "components/Column";
import CurrencyInputPanel from "components/CurrencyInputPanel";
import Input from "components/NumericalInput";
import { BodyWrapper } from "pages/app.styled";
import styled from "styled-components/macro";

export const PageWrapper = styled(BodyWrapper)<{ wide: boolean }>` 
min-width: 769px;
  width: 100%;
  margin-bottom: 100px;
  padding: 34px 35px;
  border-color: #fff;
  background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
  --border-width: 2px;
  --border-radius: 12px;
  background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
  border: 0; 

  @media only screen and (max-width: 750px) {
    min-width: 365px;
    padding: 15px 18px;
}
  &::before {
    content: "";
    display: block;
    height: calc(
      100% - (var(--border-width) * 2)
    );  
    width: calc(
      100% - (var(--border-width) * 2)
    );  
    background: ${({ theme }) => theme.backgroundSurface};
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: calc(var(--border-radius) - var(--border-width));
    z-index: -1;
  }
`;

// export const Wrapper = styled.div`
  
//   margin-bottom: 100px;
//   position: relative;
//   --border-width: 2px;
//   --border-radius: 12px;

//   background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
//   border: 0;
//   position: relative;
//   border-radius: var(--border-radius);
//   z-index: 1;
//   &::before {
//     content: "";
//     display: block;
//     height: calc(
//       100% - (var(--border-width) * 2)
//     ); /* Corrected the syntax here */
//     width: calc(
//       100% - (var(--border-width) * 2)
//     ); /* Corrected the syntax here */
//     background: ${({ theme }) => theme.backgroundSurface};
//     position: absolute;
//     top: var(--border-width);
//     left: var(--border-width);
//     border-radius: calc(var(--border-radius) - var(--border-width));
//     z-index: -1;
//     border-radius: 18px;
//   }
//   overflow-y: auto;

//   /* Style the scrollbar */
//   ::-webkit-scrollbar {
//     width: 8px; /* Set the width of the scrollbar */
//   }

//   ::-webkit-scrollbar-thumb {
//     background-color: ${({ theme }) => theme.inputGray};
//     border-radius: 4px;
//   }

//   ::-webkit-scrollbar-track {
//     background-color: transparent;
//   }
//   ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToExtraSmall`
//     min-width: 340px;
//   `};

//   ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
//     min-width: 400px;
//   `};

//   ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToExtraSmall`
//   min-width: 340px;
// `};
// `;
// export const Wrapper = styled.div`
// `
// export const Wrapper = styled.div`
//   position: relative;
//   padding: 20px;
//   min-width: 460px;
//   margin-bottom: 100px;
//   position: relative;
//   --border-width: 2px;
//   --border-radius: 12px;

//   background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
//   border: 0;
//   padding: 1rem 2rem;
//   position: relative;
//   border-radius: var(--border-radius);
//   z-index: 1;
//   &::before {
//     content: "";
//     display: block;
//     height: calc(
//       100% - (var(--border-width) * 2)
//     ); /* Corrected the syntax here */
//     width: calc(
//       100% - (var(--border-width) * 2)
//     ); /* Corrected the syntax here */
//     background: ${({ theme }) => theme.backgroundSurface};
//     position: absolute;
//     top: var(--border-width);
//     left: var(--border-width);
//     border-radius: calc(var(--border-radius) - var(--border-width));
//     z-index: -1;
//     border-radius: 18px;
//   }
//   overflow-y: auto;

//   /* Style the scrollbar */
//   ::-webkit-scrollbar {
//     width: 8px; /* Set the width of the scrollbar */
//   }

//   ::-webkit-scrollbar-thumb {
//     background-color: ${({ theme }) => theme.inputGray};
//     border-radius: 4px;
//   }

//   ::-webkit-scrollbar-track {
//     background-color: transparent;
//   }
//   ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToExtraSmall`
//     min-width: 340px;
//   `};
// `;


export const Wrapper = styled.div``
export const ScrollablePage = styled.div`
  padding: 68px 8px 0px;
  position: relative;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium` 
    margin: 0 auto;
  `};

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.md}px`}) {
    padding: 48px 8px 0px;
  }

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.sm}px`}) {
    padding-top: 20px;
  }
`;

export const DynamicSection = styled(AutoColumn)<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => (disabled ? "0.2" : "1")};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "initial")};
`;

export const CurrencyDropdown = styled(CurrencyInputPanel)`
  width: 48.5%;
  font-size: 15px;
  background: red;
`;

export const StyledInput = styled(Input)`
  background-color: ${({ theme }) => theme.backgroundSurface};
  text-align: left;
  font-size: 18px;
  width: 100%;
`;

/* two-column layout where DepositAmount is moved at the very end on mobile. */
export const ResponsiveTwoColumns = styled.div<{ wide: boolean }>`
  display: grid;
  grid-column-gap: 25px; 
  grid-template-columns: ${({ wide }) => (wide ? "1fr 1fr" : "1fr")};
  grid-template-rows: max-content;
  grid-auto-flow: row;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    grid-template-columns: 1fr;

    margin-top: 0;
  `};
`;

export const RightContainer = styled(AutoColumn)`
  grid-row: 1 / 3;
  grid-column: 2;
  height: fit-content;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
  grid-row: 2 / 3;
  grid-column: 1;
  `};
`;

export const StackedContainer = styled.div`
  display: grid;
`;

export const StackedItem = styled.div<{ zIndex?: number }>`
  grid-column: 1;
  grid-row: 1;
  height: 100%;
  z-index: ${({ zIndex }) => zIndex};
`;

export const MediumOnly = styled.div`

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    display: none;
  `};
`;

export const HideMedium = styled.div`
  display: none;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    display: block;
  `};
`;

export const ReviewWrapper = styled.div`
  padding-top: 12px;
`;
