import styled from 'styled-components/macro'

export const Image = styled.img`
  max-width: 510px;
  width: 100%;
  padding: 0 75px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Header = styled(Container)`
  gap: 30px;
`

export const NotFoundWrapper = styled(Container)`
  flex: 1;
  justify-content: center;
  gap: 50px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    justify-content: space-between;
    padding-top: 64px;
  }
`