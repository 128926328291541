import styled from 'styled-components/macro'
import { Z_INDEX } from 'theme/zIndex';

export const HigherLogoWrapper = styled.div<{ sizeraw: number }>`
  z-index: ${Z_INDEX.default};
  background: #192427;
  border-radius: 50%;
  border: 10px solid #091012;
  padding: ${({ sizeraw }) => (sizeraw / 4).toString() + 'px'} !important;
`
export const CoveredLogoWapper = styled.div<{ sizeraw: number }>`
  position: absolute;
  left: ${({ sizeraw }) => '-' + (sizeraw / 0.67).toString() + 'px'} !important;
  background: #192427;
  border-radius: 50%;
  border: 10px solid #091012;
  padding: ${({ sizeraw }) => (sizeraw / 4).toString() + 'px'} !important;

`
export const Wrapper = styled.div<{ margin: boolean; sizeraw: number }>`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-left: ${({ sizeraw, margin }) => margin && (sizeraw / 1 + 10 ).toString() + 'px'};
`