import {
  formatNumber,
  formatPriceImpact,
  NumberType,
} from "@uniswap/conedison/format";
import { Percent } from "@uniswap/sdk-core";
import Row from "components/Row";
import { LoadingBubble } from "components/Tokens/loading";
import { MouseoverTooltip } from "components/Tooltip";
import { useMemo } from "react";
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import { warningSeverity } from "utils/prices";
import { FiatLoadingBubble } from "./styled";
import { useLocation } from "react-router-dom";

export function truncateFormatPriceImpact(value: any) {

  if (value && value.length <= 10) {
    return value;
  }
  const truncatedValue = value && value.slice(0, 10) + "...";

  return truncatedValue;
}



export function truncateAddress(value: any) {

  if (value && value.length <= 11) {
    return value;
  }
  const truncatedValue = value && value.slice(0, 6) + "..." + value.slice(6, 11);

  return truncatedValue;
}

export function truncateAddressLong(value: any) {

  if (value && value.length <= 11) {
    return value;
  }
  const truncatedValue = value && value.slice(0, 10) + "..." + value.slice(10, 20);

  return truncatedValue;
}



export function truncateFormatPriceImpactPool(value: any) {

  if (value && value.length <= 6) {
    return value;
  }
  const truncatedValue = value && value.slice(0, 6);

  return truncatedValue;
}



export function formatAndTruncateNumber(value: any, numberType?: any) {
  // Format the number using your existing function
  const formattedValue = formatNumber(value, numberType);

  // Check if the formatted value is already shorter than 10 characters
  if (formattedValue.length <= 10) {
    return formattedValue;
  }

  // Truncate the value to 10 characters
  const truncatedValue = formattedValue.slice(0, 10) + "...";

  return truncatedValue;
}

export function FiatValue({
  fiatValue,
  priceImpact,
  pathname,
}: {
  fiatValue: { data?: number; isLoading: boolean };
  priceImpact?: Percent;
  pathname?: any;
}) {
  const priceImpactColor = useMemo(() => {
    if (!priceImpact) return undefined;
    if (priceImpact.lessThan("0")) return "accentSuccess";
    const severity = warningSeverity(priceImpact);
    if (severity < 1) return "textTertiary";
    if (severity < 3) return "deprecated_yellow1";
    return "accentFailure";
  }, [priceImpact]);

  if (fiatValue.isLoading) {
    return <FiatLoadingBubble />;
  }

  return (
    <Row gap="sm">
      {pathname === "/limit" ? (
        <ThemedText.BodySmall>
          {fiatValue.data ? (
            <MouseoverTooltip
              text={
                <div>
                  {formatNumber(fiatValue.data, NumberType.FiatTokenPrice)}
                </div>
              }
            >
              {formatAndTruncateNumber(
                fiatValue.data,
                NumberType.FiatTokenPrice
              )}
            </MouseoverTooltip>
          ) : (
            <MouseoverTooltip
              text={
                <div>
                  Not enough liquidity to show accurate USD value.ss
                </div>
              }
            >
              -
            </MouseoverTooltip>
          )}
        </ThemedText.BodySmall>
      ) : (
        <ThemedText.BodySmall>
          {fiatValue.data ? (
            <MouseoverTooltip
              text={
                <div>
                  {formatNumber(fiatValue.data, NumberType.FiatTokenPrice)}
                </div>
              }
            >
              {formatAndTruncateNumber(
                fiatValue.data,
                NumberType.FiatTokenPrice
              )}
            </MouseoverTooltip>
          ) : (
            <MouseoverTooltip
              text={
                <div>
                  Not enough liquidity to show accurate USD value.ss
                </div>
              }
            >
              -
            </MouseoverTooltip>
          )}
        </ThemedText.BodySmall>
      )}

      {priceImpact && pathname !== "/limit" && (
        <ThemedText.BodySmall color={priceImpactColor}>
          <MouseoverTooltip
            text={
              <div>
                `The estimated difference between the USD values of input and
                output amounts.${formatPriceImpact(priceImpact)}`
              </div>
            }
          >
            (
            <div>
              {truncateFormatPriceImpact(formatPriceImpact(priceImpact))}
            </div>
            )
          </MouseoverTooltip>
        </ThemedText.BodySmall>
      )}
    </Row>
  );
}
