import { useEffect, useState } from "react";
import { StaticUsdOracle } from "@eltk/staking-sdk";
import { useSignerWallet } from "hooks/useSignerWallet";

export const useOracle = () => {
  const [oracle, setOracle] = useState<any>();
  const { wallet: signerWallet } = useSignerWallet();

  useEffect(() => {
    const initializeOracle = async () => {
      try {
        if (signerWallet) {
          const oracleInstance = await StaticUsdOracle.getInstance(signerWallet);
          setOracle(oracleInstance);
        }
      } catch (error) {
        console.error("Error initializing oracle:", error);
      }
    };

    initializeOracle();
  }, [signerWallet]);

  return {
    oracle,
  };
};
