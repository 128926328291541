import { useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useDeltaTimestamps } from "utils/queries";
import { useBlocksFromTimestamps } from "hooks/useBlocksFromTimestamps";
import { get2DayChange } from "utils/data";
import { formatTokenName, formatTokenSymbol } from "utils/tokens";
import {
  StakingClient,
  apolloClient,
  apolloClientMainnet,
  apolloClientTestnet,
} from "./apollo";
import { triggerAsyncId } from "async_hooks";

export interface PoolData {
  // basic token info
  address: string;
  feeTier: number;
  feesAPR: number;

  token0: {
    name: string;
    symbol: string;
    address: string;
    decimals: number;
    derivedETH: number;
  };

  token1: {
    name: string;
    symbol: string;
    address: string;
    decimals: number;
    derivedETH: number;
  };
  swaps: any;
  mints: any;
  // for tick math
  liquidity: number;
  sqrtPrice: number;
  tick: number;

  // volume
  volumeUSD: number;
  volumeUSDChange: number;
  volumeUSDWeek: number;

  // liquidity
  tvlUSD: number;
  tvlUSDChange: number;

  // prices
  token0Price: number;
  token1Price: number;

  // token amounts
  tvlToken0: number;
  tvlToken1: number;
  feesUSD: number;
}

export const POOLS_BULK_UPDATED = (
  block: number | undefined,
  pools: string[]
) => {
  let poolString = `[`;
  pools.map((address) => {
    return (poolString += `"${address}",`);
  });
  poolString += "]";
  const queryString =
    `
    query pools {
      pools(where: {id_in: ${poolString}},` +
    (block ? `block: {number: ${block}} ,` : ``) +
    ` orderBy: totalValueLockedUSD, orderDirection: desc, subgraphError: allow) {
        id
        feeTier
        liquidity
        sqrtPrice
        tick
        token0 {
            id
            symbol 
            name
            decimals
            derivedETH
        }
        token1 {
            id
            symbol 
            name
            decimals
            derivedETH
        }
        swaps{
          timestamp
          amount0
          amount1
          token0 {
            id
            symbol
          }
          token1 {
            id
            symbol
          }
          transaction {
            id
          }
        }
        mints {
          timestamp
          amount0
          amount1
          token0 {
            id
            symbol
          }
          token1 {
            id
            symbol
          }
          transaction {
            id
          }
        }
        feesUSD
        token0Price
        token1Price
        volumeUSD
        volumeToken0
        volumeToken1
        txCount
        totalValueLockedToken0
        totalValueLockedToken1
        totalValueLockedUSD
      }
      bundles (where: {id: "1"}) {
        ethPriceUSD
      }
    }
    `;
  return gql(queryString);
};

export const POOLS_BULK = (block: number | undefined, pools: string[]) => {
  let poolString = `[`;
  pools.map((address) => {
    return (poolString += `"${address}",`);
  });
  poolString += "]";
  const queryString =
    `
    query pools {
      pools(where: {id_in: ${poolString}},` +
    (block ? `block: {number: ${block}} ,` : ``) +
    ` orderBy: totalValueLockedUSD, orderDirection: desc, subgraphError: allow) {
        id
        feeTier
        liquidity
        sqrtPrice
        tick
        token0 {
            id
            symbol 
            name
            decimals
            derivedETH
        }
        token1 {
            id
            symbol 
            name
            decimals
            derivedETH
        }
        swaps{
          timestamp
          amount0
          amount1
          token0 {
            id
            symbol
          }
          token1 {
            id
            symbol
          }
          transaction {
            id
          }
        }
        mints {
          timestamp
          amount0
          amount1
          token0 {
            id
            symbol
          }
          token1 {
            id
            symbol
          }
          transaction {
            id
          }
        }
        feesUSD
        token0Price
        token1Price
        volumeUSD
        volumeToken0
        volumeToken1
        txCount
        totalValueLockedToken0
        totalValueLockedToken1
        totalValueLockedUSD
      }
      bundles (where: {id: "1"}) {
        ethPriceUSD
      }
    }
    `;
  return gql(queryString);
};

export const USER_LIQUIDITY = gql`
  query userLiquidity($pool: String, $owner: String!) {
    positions(
      orderBy: transaction__timestamp
      orderDirection: desc
      where: { pool: $pool, owner: $owner }
    ) {
      id
      liquidity
      token0 {
        id
        symbol
        name
      }
      token1 {
        id
        symbol
        name
      }
      pool {
        feeTier
      }
    }
  }
`;

export const LP_USER_LIQUIDITY = gql`
  query userLiquidity($pool: String, $owner: String!) {
    positions(where: { pool: $pool, owner: $owner }) {
      id
      liquidity
      token0 {
        id
        symbol
        name
      }
      token1 {
        id
        symbol
        name
      }
      pool {
        feeTier
      }
    }
  }
`;
export const POSITIONDETAILS = gql`
  query userLiquidity($tokenId: String!, $owner: String!) {
    positions(where: { id: $tokenId, owner: $owner }) {
      id
      depositedToken0
      withdrawnToken0
      depositedToken1
      withdrawnToken1
      token0 {
        id
        symbol
        derivedETH
      }
      token1 {
        id
        symbol
        derivedETH
      }
    }
  }
`;

export const POSITIONDETAILS_STAKED = gql`
  query userLiquidity($tokenId: String!) {
    positions(where: { id: $tokenId }) {
      id
      depositedToken0
      withdrawnToken0
      depositedToken1
      withdrawnToken1
      token0 {
        id
        symbol
        derivedETH
      }
      token1 {
        id
        symbol
        derivedETH
      }
    }
  }
`;

export const ALL_POOLS_IDS = gql`
  query allPoolsIds {
    pools {
      id
    }
  }
`;

export const ETH_PRICE = gql`
  query ethPrice {
    current: bundles(first: 1, subgraphError: allow) {
      ethPriceUSD
    }
  }
`;

export const BLOCK_TIMESTAMP = gql`
  query MyQuery($tokenId: String!) {
    deposits(where: { tokenId: $tokenId }) {
      blockTimestamp
    }
  }
`;

export const POSITIONSTAKEDORNOT = gql`
  query MyQuery($tokenId: String!) {
    deposits(first: 1, where: { tokenId: $tokenId }) {
      id
    }
  }
`;
// 0x2fe0dd159b80994537f9f7ba62577f13b44bdd46
export const POOLS_QUERY_UPDATED = gql`
  query GetPoolInfo($poolId: String!) {
    pool(id: $poolId) {
      id
      feeTier
      liquidity
      sqrtPrice
      tick
      token0 {
        id
        symbol
        name
        decimals
        derivedETH
      }
      token1 {
        id
        symbol
        name
        decimals
        derivedETH
      }
      swaps(orderBy: timestamp, orderDirection: desc) {
        timestamp
        amount0
        amount1
        token0 {
          id
          symbol
        }
        token1 {
          id
          symbol
        }
        transaction {
          id
        }
      }
      mints(orderBy: timestamp, orderDirection: desc) {
        timestamp
        amount0
        amount1
        token0 {
          id
          symbol
        }
        token1 {
          id
          symbol
        }
        transaction {
          id
        }
      }
      burns(orderBy: timestamp, orderDirection: desc) {
        timestamp
        amount0
        amount1
        token0 {
          id
          symbol
        }
        token1 {
          id
          symbol
        }
        transaction {
          id
        }
      }
      feesUSD
      token0Price
      token1Price
      volumeUSD
      volumeToken0
      volumeToken1
      txCount
      totalValueLockedToken0
      totalValueLockedToken1
      totalValueLockedUSD
    }
  }
`;
interface PoolFields {
  id: string;
  feeTier: string;
  liquidity: string;
  sqrtPrice: string;
  tick: string;
  token0: {
    id: string;
    symbol: string;
    name: string;
    decimals: string;
    derivedETH: string;
  };
  token1: {
    id: string;
    symbol: string;
    name: string;
    decimals: string;
    derivedETH: string;
  };
  swaps: any;
  mints: any;
  feesUSD: string;
  token0Price: string;
  token1Price: string;
  volumeUSD: string;
  volumeToken0: string;
  volumeToken1: string;
  txCount: string;
  totalValueLockedToken0: string;
  totalValueLockedToken1: string;
  totalValueLockedUSD: string;
}

interface PoolDataResponse {
  pools: PoolFields[];
  bundles: {
    ethPriceUSD: string;
  }[];
}

interface TopPoolsResponse {
  pools: {
    id: string;
  }[];
}

export const useSinglePoolDataUpdated = (poolId: any, chainId?: any) => {
  const { loading, error, data, refetch } = useQuery(POOLS_QUERY_UPDATED, {
    variables: { poolId: poolId, chainId },
    client: chainId == 1890 ? apolloClientMainnet : apolloClientTestnet,
    fetchPolicy: "network-only",
  });



  return {
    data,
    loading,
    error,
  };
};


// export function useSinglePoolDataUpdated(poolAddress:string)

export function useSinglePoolData(poolAddress: string): {
  loading: boolean;
  error: boolean;
  data: PoolData[] | undefined;
} {
  // get blocks from historic timestamps

  const [t24, t48, tWeek] = useDeltaTimestamps();
  const { blocks, error: blockError } = useBlocksFromTimestamps([
    t24,
    t48,
    tWeek,
  ]);
  const [block24, block48, blockWeek] = blocks ?? [];

  const { loading, error, data } = useQuery<PoolDataResponse>(
    POOLS_BULK(undefined, [poolAddress]),
    {
      client: apolloClient,
    }
  );
  const {
    loading: loading24,
    error: error24,
    data: data24,
  } = useQuery<PoolDataResponse>(POOLS_BULK(block24?.number, [poolAddress]), {
    client: apolloClient,
  });
  const {
    loading: loading48,
    error: error48,
    data: data48,
  } = useQuery<PoolDataResponse>(POOLS_BULK(block48?.number, [poolAddress]), {
    client: apolloClient,
  });
  const {
    loading: loadingWeek,
    error: errorWeek,
    data: dataWeek,
  } = useQuery<PoolDataResponse>(POOLS_BULK(blockWeek?.number, [poolAddress]), {
    client: apolloClient,
  });

  const anyError = Boolean(
    error || error24 || error48 || blockError || errorWeek
  );
  const anyLoading = Boolean(loading || loading24 || loading48 || loadingWeek);

  // return early if not all data yet
  if (anyError || anyLoading) {
    return {
      loading: anyLoading,
      error: anyError,
      data: undefined,
    };
  }

  const ethPriceUSD = data?.bundles?.[0]?.ethPriceUSD
    ? parseFloat(data?.bundles?.[0]?.ethPriceUSD)
    : 0;

  const parsed = data?.pools
    ? data.pools.reduce(
        (accum: { [address: string]: PoolFields }, poolData) => {
          accum[poolData.id] = poolData;
          return accum;
        },
        {}
      )
    : {};
  const parsed24 = data24?.pools
    ? data24.pools.reduce(
        (accum: { [address: string]: PoolFields }, poolData) => {
          accum[poolData.id] = poolData;
          return accum;
        },
        {}
      )
    : {};
  const parsed48 = data48?.pools
    ? data48.pools.reduce(
        (accum: { [address: string]: PoolFields }, poolData) => {
          accum[poolData.id] = poolData;
          return accum;
        },
        {}
      )
    : {};
  const parsedWeek = dataWeek?.pools
    ? dataWeek.pools.reduce(
        (accum: { [address: string]: PoolFields }, poolData) => {
          accum[poolData.id] = poolData;
          return accum;
        },
        {}
      )
    : {};

  // format data and calculate daily changes
  const formatted = [poolAddress].reduce(
    (accum: { [address: string]: PoolData }, address) => {
      const current: PoolFields | undefined = parsed[address];
      const oneDay: PoolFields | undefined = parsed24[address];
      const twoDay: PoolFields | undefined = parsed48[address];
      const week: PoolFields | undefined = parsedWeek[address];

      const [volumeUSD, volumeUSDChange] =
        current && oneDay && twoDay
          ? get2DayChange(current.volumeUSD, oneDay.volumeUSD, twoDay.volumeUSD)
          : current
            ? [parseFloat(current.volumeUSD), 0]
            : [0, 0];

      const volumeUSDWeek =
        current && week
          ? parseFloat(current.volumeUSD) - parseFloat(week.volumeUSD)
          : current
            ? parseFloat(current.volumeUSD)
            : 0;

      // Hotifx: Subtract fees from TVL to correct data while subgraph is fixed.
      /**
       * Note: see issue desribed here https://github.com/Uniswap/v3-subgraph/issues/74
       * During subgraph deploy switch this month we lost logic to fix this accounting.
       * Grafted sync pending fix now.
       */
      const feePercent = current
        ? parseFloat(current.feeTier) / 10000 / 100
        : 0;
      const tvlAdjust0 = current?.volumeToken0
        ? (parseFloat(current.volumeToken0) * feePercent) / 2
        : 0;
      const tvlAdjust1 = current?.volumeToken1
        ? (parseFloat(current.volumeToken1) * feePercent) / 2
        : 0;
      const tvlToken0 = current
        ? parseFloat(current.totalValueLockedToken0) - tvlAdjust0
        : 0;
      const tvlToken1 = current
        ? parseFloat(current.totalValueLockedToken1) - tvlAdjust1
        : 0;
      let tvlUSD = current ? parseFloat(current.totalValueLockedUSD) : 0;

      const tvlUSDChange =
        current && oneDay
          ? ((parseFloat(current.totalValueLockedUSD) -
              parseFloat(oneDay.totalValueLockedUSD)) /
              parseFloat(
                oneDay.totalValueLockedUSD === "0"
                  ? "1"
                  : oneDay.totalValueLockedUSD
              )) *
            100
          : 0;

      // Part of TVL fix
      const tvlUpdated = current
        ? tvlToken0 * parseFloat(current.token0.derivedETH) * ethPriceUSD +
          tvlToken1 * parseFloat(current.token1.derivedETH) * ethPriceUSD
        : undefined;
      if (tvlUpdated) {
        tvlUSD = tvlUpdated;
      }

      const feeTier = current ? parseInt(current.feeTier) : 0;

      if (current) {
        accum[address] = {
          address,
          feeTier,
          liquidity: parseFloat(current.liquidity),
          sqrtPrice: parseFloat(current.sqrtPrice),
          tick: parseFloat(current.tick),
          token0: {
            address: current.token0.id,
            name: formatTokenName(current.token0.id, current.token0.name),
            symbol: formatTokenSymbol(current.token0.id, current.token0.symbol),
            decimals: parseInt(current.token0.decimals),
            derivedETH: parseFloat(current.token0.derivedETH),
          },
          token1: {
            address: current.token1.id,
            name: formatTokenName(current.token1.id, current.token1.name),
            symbol: formatTokenSymbol(current.token1.id, current.token1.symbol),
            decimals: parseInt(current.token1.decimals),
            derivedETH: parseFloat(current.token1.derivedETH),
          },
          swaps: current.swaps,
          mints: current.mints,
          feesUSD: parseFloat(current.feesUSD),
          token0Price: parseFloat(current.token0Price),
          token1Price: parseFloat(current.token1Price),
          volumeUSD,
          volumeUSDChange,
          volumeUSDWeek,
          tvlUSD,
          tvlUSDChange,
          tvlToken0,
          tvlToken1,
          feesAPR:
            current?.feesUSD && tvlUSD
              ? ((parseFloat(current.feesUSD) * 365) / tvlUSD) * 100
              : 0,
        };
      }

      return accum;
    },
    {}
  );

  return {
    loading: anyLoading,
    error: anyError,
    data: Object.values(formatted),
  };
}

/**
 * Fetch top addresses by volume
 */

export function usePoolDatas(): {
  loading: boolean;
  error: boolean;
  data: PoolData[] | undefined;
} {
  const { addresses: poolAddresses = [] } = usePoolIds();
  // get blocks from historic timestamps

  const [t24, t48, tWeek] = useDeltaTimestamps();
  const { blocks, error: blockError } = useBlocksFromTimestamps([
    t24,
    t48,
    tWeek,
  ]);
  const [block24, block48, blockWeek] = blocks ?? [];

  const { loading, error, data } = useQuery<PoolDataResponse>(
    POOLS_BULK(undefined, poolAddresses),
    {
      client: apolloClient,
    }
  );

  const {
    loading: loading24,
    error: error24,
    data: data24,
  } = useQuery<PoolDataResponse>(POOLS_BULK(block24?.number, poolAddresses), {
    client: apolloClient,
  });
  const {
    loading: loading48,
    error: error48,
    data: data48,
  } = useQuery<PoolDataResponse>(POOLS_BULK(block48?.number, poolAddresses), {
    client: apolloClient,
  });
  const {
    loading: loadingWeek,
    error: errorWeek,
    data: dataWeek,
  } = useQuery<PoolDataResponse>(POOLS_BULK(blockWeek?.number, poolAddresses), {
    client: apolloClient,
  });

  const anyError = Boolean(
    error || error24 || error48 || blockError || errorWeek
  );
  const anyLoading = Boolean(loading || loading24 || loading48 || loadingWeek);

  // return early if not all data yet
  if (anyError || anyLoading) {
    return {
      loading: anyLoading,
      error: anyError,
      data: undefined,
    };
  }

  const ethPriceUSD = data?.bundles?.[0]?.ethPriceUSD
    ? parseFloat(data?.bundles?.[0]?.ethPriceUSD)
    : 0;

  const parsed = data?.pools
    ? data.pools.reduce(
        (accum: { [address: string]: PoolFields }, poolData) => {
          accum[poolData.id] = poolData;
          return accum;
        },
        {}
      )
    : {};
  const parsed24 = data24?.pools
    ? data24.pools.reduce(
        (accum: { [address: string]: PoolFields }, poolData) => {
          accum[poolData.id] = poolData;
          return accum;
        },
        {}
      )
    : {};
  const parsed48 = data48?.pools
    ? data48.pools.reduce(
        (accum: { [address: string]: PoolFields }, poolData) => {
          accum[poolData.id] = poolData;
          return accum;
        },
        {}
      )
    : {};
  const parsedWeek = dataWeek?.pools
    ? dataWeek.pools.reduce(
        (accum: { [address: string]: PoolFields }, poolData) => {
          accum[poolData.id] = poolData;
          return accum;
        },
        {}
      )
    : {};

  // format data and calculate daily changes
  const formatted = poolAddresses.reduce(
    (accum: { [address: string]: PoolData }, address) => {
      const current: PoolFields | undefined = parsed[address];
      const oneDay: PoolFields | undefined = parsed24[address];
      const twoDay: PoolFields | undefined = parsed48[address];
      const week: PoolFields | undefined = parsedWeek[address];

      const [volumeUSD, volumeUSDChange] =
        current && oneDay && twoDay
          ? get2DayChange(current.volumeUSD, oneDay.volumeUSD, twoDay.volumeUSD)
          : current
            ? [parseFloat(current.volumeUSD), 0]
            : [0, 0];

      const volumeUSDWeek =
        current && week
          ? parseFloat(current.volumeUSD) - parseFloat(week.volumeUSD)
          : current
            ? parseFloat(current.volumeUSD)
            : 0;

      // Hotifx: Subtract fees from TVL to correct data while subgraph is fixed.
      /**
       * Note: see issue desribed here https://github.com/Uniswap/v3-subgraph/issues/74
       * During subgraph deploy switch this month we lost logic to fix this accounting.
       * Grafted sync pending fix now.
       */
      const feePercent = current
        ? parseFloat(current.feeTier) / 10000 / 100
        : 0;
      const tvlAdjust0 = current?.volumeToken0
        ? (parseFloat(current.volumeToken0) * feePercent) / 2
        : 0;
      const tvlAdjust1 = current?.volumeToken1
        ? (parseFloat(current.volumeToken1) * feePercent) / 2
        : 0;
      const tvlToken0 = current
        ? parseFloat(current.totalValueLockedToken0) - tvlAdjust0
        : 0;
      const tvlToken1 = current
        ? parseFloat(current.totalValueLockedToken1) - tvlAdjust1
        : 0;
      let tvlUSD = current ? parseFloat(current.totalValueLockedUSD) : 0;

      const tvlUSDChange =
        current && oneDay
          ? ((parseFloat(current.totalValueLockedUSD) -
              parseFloat(oneDay.totalValueLockedUSD)) /
              parseFloat(
                oneDay.totalValueLockedUSD === "0"
                  ? "1"
                  : oneDay.totalValueLockedUSD
              )) *
            100
          : 0;

      // Part of TVL fix
      const tvlUpdated = current
        ? tvlToken0 * parseFloat(current.token0.derivedETH) * ethPriceUSD +
          tvlToken1 * parseFloat(current.token1.derivedETH) * ethPriceUSD
        : undefined;
      if (tvlUpdated) {
        tvlUSD = tvlUpdated;
      }

      const feeTier = current ? parseInt(current.feeTier) : 0;

      if (current) {
        accum[address] = {
          address,
          feeTier,
          liquidity: parseFloat(current.liquidity),
          sqrtPrice: parseFloat(current.sqrtPrice),
          tick: parseFloat(current.tick),
          token0: {
            address: current.token0.id,
            name: formatTokenName(current.token0.id, current.token0.name),
            symbol: formatTokenSymbol(current.token0.id, current.token0.symbol),
            decimals: parseInt(current.token0.decimals),
            derivedETH: parseFloat(current.token0.derivedETH),
          },
          token1: {
            address: current.token1.id,
            name: formatTokenName(current.token1.id, current.token1.name),
            symbol: formatTokenSymbol(current.token1.id, current.token1.symbol),
            decimals: parseInt(current.token1.decimals),
            derivedETH: parseFloat(current.token1.derivedETH),
          },
          swaps: current.swaps,
          mints: current.mints,
          feesUSD: parseFloat(current.feesUSD),
          token0Price: parseFloat(current.token0Price),
          token1Price: parseFloat(current.token1Price),
          volumeUSD,
          volumeUSDChange,
          volumeUSDWeek,
          tvlUSD,
          tvlUSDChange,
          tvlToken0,
          tvlToken1,
          feesAPR:
            current?.feesUSD && tvlUSD
              ? ((parseFloat(current.feesUSD) * 365) / tvlUSD) * 100
              : 0,
        };
      }

      return accum;
    },
    {}
  );

  return {
    loading: anyLoading,
    error: anyError,
    data: Object.values(formatted),
  };
}

export function usePoolIds() {
  const { loading, error, data } = useQuery<TopPoolsResponse>(ALL_POOLS_IDS, {
    client: apolloClient,
  });

  const formattedData = useMemo(() => {
    if (data) {
      return data?.pools?.map((p) => p.id);
    } else {
      return undefined;
    }
  }, [data]);

  return {
    loading: loading,
    error: Boolean(error),
    addresses: formattedData,
  };
}

export async function getUserPositionOfPool(pool: string, owner: string) {
  const { data } = await apolloClient.query<any>({
    query: USER_LIQUIDITY,
    variables: { pool, owner },
    fetchPolicy: "network-only",
  });

  return data;
}

export async function getPositionData(
  tokenId: string,
  owner: string,
  isStaked?: boolean
) {
  let query;
  let variables;

  if (isStaked) {
    query = POSITIONDETAILS_STAKED;
    variables = { tokenId };
  } else {
    query = POSITIONDETAILS;
    variables = { tokenId, owner };
  }

  const { data } = await apolloClient.query<any>({
    query,
    variables,
  });

  return data;
}
export async function fetchUserLiquidity(
  pool: any,
  owner: string
): Promise<any> {
  const poolCopy = { ...pool };
  try {
    const { data } = await apolloClient.query<any>({
      query: USER_LIQUIDITY,
      variables: { pool: poolCopy?.id, owner: owner },
    });



    const updatePoolData = (positions: any) => {
      if (!positions.length) {
        poolCopy.positions = [];
      } else {
        poolCopy.positions = positions;
      }
    };

    if (data) {
      updatePoolData(data?.positions);
    }

    return poolCopy;
  } catch (error) {
    console.log(error);
  }
}

export async function fetchUserLiquidityOnPools(
  pool: any,
  owner: string
): Promise<any> {
  try {
  } catch (error) {
    console.log(error);
  }
}

export async function fetchUserRewardsDepositTimestamp(
  tokenId: any
): Promise<any> {
  try {
    const { data: blockTimeStamp } = await StakingClient.query<any>({
      query: BLOCK_TIMESTAMP,
      variables: { tokenId },
      fetchPolicy: "no-cache", // Setting the fetchPolicy to 'no-cache'
    });

    return blockTimeStamp;
  } catch (error) {
    console.log(error);
  }
}

export async function checkPositionIsStakedOrNot(tokenId: any): Promise<any> {
  try {
    const { data: id } = await StakingClient.query<any>({
      query: POSITIONSTAKEDORNOT,
      variables: { tokenId },
    });

    return id;
  } catch (error) {
    console.log(error);
  }
}
