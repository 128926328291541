import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import {
  StakingClient,
  apolloClient,
  apolloClientMainnet,
  apolloClientTestnet,
  voteEscrowClient,
} from "graphql/thegraph/apollo";

const GET_TOTAL_VOLUME_TOKEN = gql`
  query MyQuery($poolId: String!) {
    pools(where: { id: $poolId }) {
      totalValueLockedToken0
      totalValueLockedToken1
    }
  }
`;

export const useGetTotalVolumesOfTokenPool = (poolId: any,chainId?:any) => {
  const { loading, error, data } = useQuery(GET_TOTAL_VOLUME_TOKEN, {
    variables: { poolId: poolId, },
    client: chainId ===1890 ? apolloClientMainnet : apolloClientTestnet ,
  });

  return {
    data,
    loading,
  };
};
