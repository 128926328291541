import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { StakingClient, apolloClient,  voteEscrowClient } from "graphql/thegraph/apollo";
import { useEffect } from "react";

const GET_POOL_WHITELIST_INFO = gql`

query MyQuery($poolAddress: String!) {
  pools(where: { v3Pool: $poolAddress }) {
    id
  }
}
`

export const useCheckWhitelisted=(poolAddress:any,chainId:any)=>{
  
  const {data, } = useQuery(GET_POOL_WHITELIST_INFO, {
    client: StakingClient,
    variables:{poolAddress, chainId}

  });




  return {
    data,
  };
}
