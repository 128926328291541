import { TradeType } from "@uniswap/sdk-core";
import { useWeb3React } from "@web3-react/core";
import { LoadingRows } from "components/Loader/styled";

import useNativeCurrency from "lib/hooks/useNativeCurrency";
import formatPriceImpact from "utils/formatPriceImpact";

import { ThemedText } from "../../theme";
import Column from "../Column";
import { RowBetween, RowFixed } from "../Row";

import SwapRoute from "./SwapRoute";
import { AdvancedSwapDetailsProps } from "types";
import { useState } from "react";
import Modal from "components/Modal";
import { Backdrop } from "components/IntroModal/styled";
import { ShowGradient } from "./styleds";
import { InfoIcon } from "components/Icons/shared";
import { formatNumberWithSuffix } from "utils/numbers";

export function TextWithLoadingPlaceholder({
  syncing,
  width,
  children,
}: {
  syncing: boolean;
  width: number;
  children: JSX.Element;
}) {
  return syncing ? (
    <LoadingRows data-testid="loading-rows">
      <div style={{ height: "15px", width: `${width}px` }} />
    </LoadingRows>
  ) : (
    children
  );
}

export function AdvancedSwapDetails({
  trade,
  allowedSlippage,
  syncing = false,
}: AdvancedSwapDetailsProps) {
  const { chainId } = useWeb3React();
  const nativeCurrency = useNativeCurrency(chainId);
  const [showRoute, setShowRoute] = useState(false);

  return (
    <Column gap="sm">
      <RowBetween>
        <ThemedText.Caption color="white" style={{display:'flex'}}>
          <div>Price impact</div> &nbsp;
          <InfoIcon
            content={
              "The impact your trade has on the market price of this pool."
            }
            size={12}
          />
        </ThemedText.Caption>
        <TextWithLoadingPlaceholder syncing={syncing} width={50}>
          <ThemedText.Caption>
            {formatPriceImpact(trade.priceImpact)}
          </ThemedText.Caption>
        </TextWithLoadingPlaceholder>
      </RowBetween>

      <RowBetween>
        <RowFixed>
          <ThemedText.Caption color="white" style={{display:'flex'}}>
            <div>Expected output</div> &nbsp;
            <InfoIcon
              content={
                " The amount you expect to receive at the current market price.You may receive less or more if the market price changes while your transaction is pending."
              }
              size={12}
            />
          </ThemedText.Caption>
        </RowFixed>
        <TextWithLoadingPlaceholder syncing={syncing} width={65}>
          <ThemedText.Caption>
            {`${formatNumberWithSuffix(Number(trade.outputAmount.toSignificant(6)))  } ${
              trade.outputAmount.currency.symbol
            }`}
          </ThemedText.Caption>
        </TextWithLoadingPlaceholder>
      </RowBetween>
      <RowBetween>
        <RowFixed>
          <ThemedText.Caption color="white">
            {trade.tradeType === TradeType.EXACT_INPUT ? (
              <div>
                Minimum received after {allowedSlippage.toSignificant(6)}%
                slippage
              </div>
            ) : (
              <>
                <div>Maximum input</div> &nbsp;

                <InfoIcon
                  content={` The maximum amount you are guaranteed to spend. If the price slips any further, your transaction will revert.`}
                  size={12}
                />
              </>
            )}
          </ThemedText.Caption>
        </RowFixed>
        <TextWithLoadingPlaceholder syncing={syncing} width={70}>
          <ThemedText.Caption>
            {trade.tradeType === TradeType.EXACT_INPUT
              ? `${ formatNumberWithSuffix(Number(trade.minimumAmountOut(allowedSlippage).toSignificant(6)))} ${
                  trade.outputAmount.currency.symbol
                }`
              : `${formatNumberWithSuffix( Number(trade.maximumAmountIn(allowedSlippage).toSignificant(6)))} ${
                  trade.inputAmount.currency.symbol
                }`}
          </ThemedText.Caption>
        </TextWithLoadingPlaceholder>
      </RowBetween>

      <RowBetween>
        <ThemedText.Caption color="white">
          <div>Route Taken</div>
        </ThemedText.Caption>
        <TextWithLoadingPlaceholder syncing={syncing} width={70}>
          <ShowGradient
            onClick={() => {
              setShowRoute(true);
            }}
          >
            Show
          </ShowGradient>
        </TextWithLoadingPlaceholder>

        {showRoute && <Backdrop />}
        {showRoute && (
          <Modal isOpen={showRoute}>
            <SwapRoute
              data-testid="swap-route-info"
              trade={trade}
              syncing={syncing}
              setShowRoute={setShowRoute}
            />
          </Modal>
        )}
      </RowBetween>
    </Column>
  );
}
