import { AutoRow } from 'components/Row'
import { ThemedText } from 'theme'
import { PresetsButtonsProps } from 'types'
import { Button } from './styled'



export default function PresetsButtons({ onSetFullRange }: PresetsButtonsProps) {
  return (
    <AutoRow gap="4px" width="auto">
      <Button onClick={onSetFullRange} style={{ height:36, border:10}}>
        <ThemedText.DeprecatedBody fontSize={14} >
          <div>Full Range</div>
        </ThemedText.DeprecatedBody>
      </Button>
    </AutoRow>
  )
}
