import { StyledArrowLeftChevron } from "components/NavigationTabs/styled";
import PositionListItem from "components/PositionListItem";
import { RowFixed } from "components/Row";
import { PositionListProps } from "types";

export default function PositionList({
  positions,
  setUserHideClosedPositions,
  userHideClosedPositions,
}: PositionListProps) {
  return (
    <>
      {positions.map((p: any, index: any) => {
        return (
          <div key={p.tokenId.toString()} style={{ marginBottom: "20px" }}>
            <PositionListItem {...p} />
          </div>
        );
      })}
    </>
  );
}
