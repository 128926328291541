import { useWeb3React } from '@web3-react/core'
import { RowFixed } from 'components/Row'
import { getChainInfo } from 'constants/chainInfo'
import useCurrentBlockTimestamp from 'hooks/useCurrentBlockTimestamp'
import { useIsLandingPage } from 'hooks/useIsLandingPage'
import { useIsNftPage } from 'hooks/useIsNftPage'
import useMachineTimeMs from 'hooks/useMachineTime'
import { useEffect, useMemo, useState } from 'react'
import { ExternalLink } from 'theme'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'
import { MouseoverTooltip } from '../Tooltip'
import { ChainConnectivityWarning } from './ChainConnectivityWarning'
import { Spinner, StyledPolling, StyledPollingBlockNumber, StyledPollingDot } from './styled'
import { DEFAULT_MS_BEFORE_WARNING, NETWORK_HEALTH_CHECK_MS } from 'constants/common'
import useBlockNumber from 'lib/hooks/useBlockNumber'



//ISSUE
export default function Polling() {
  const { chainId } = useWeb3React()
  const blockNumber = useBlockNumber()
  const [isMounting, setIsMounting] = useState(false)
  const [isHover, setIsHover] = useState(false)
  const machineTime = useMachineTimeMs(NETWORK_HEALTH_CHECK_MS)
  const blockTime = useCurrentBlockTimestamp()
  const isNftPage = useIsNftPage()
  const isLandingPage = useIsLandingPage()

  const waitMsBeforeWarning =
    (chainId ? getChainInfo(chainId)?.blockWaitMsBeforeWarning : DEFAULT_MS_BEFORE_WARNING) ?? DEFAULT_MS_BEFORE_WARNING

  const warning = Boolean(!!blockTime && machineTime - blockTime.mul(1000).toNumber() > waitMsBeforeWarning)

  useEffect(
    () => {
      if (!blockNumber) {
        return
      }

      setIsMounting(true)
      const mountingTimer = setTimeout(() => setIsMounting(false), 1000)

      // this will clear Timeout when component unmount like in willComponentUnmount
      return () => {
        clearTimeout(mountingTimer)
      }
    },
    [blockNumber] //useEffect will run only one time
    //if you pass a value to array, like this [data] than clearTimeout will run every time this value changes (useEffect re-run)
  )

  //TODO - chainlink gas oracle is really slow. Can we get a better data source?

  const blockExternalLinkHref = useMemo(() => {
    if (!chainId || !blockNumber) return ''
    return getExplorerLink(chainId, blockNumber.toString(), ExplorerDataType.BLOCK)
  }, [blockNumber, chainId])

  if (isNftPage || isLandingPage) {
    return null
  }

  return (
    <span style={{ zIndex:2}} >
      <StyledPolling onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
        <StyledPollingBlockNumber breathe={isMounting} hovering={isHover} warning={warning}>
          <ExternalLink href={blockExternalLinkHref}>
            <MouseoverTooltip
              text={<div>The most recent block number on this network. Prices update on every block.</div>}
            >
              {blockNumber}&ensp;
            </MouseoverTooltip>
          </ExternalLink>
        </StyledPollingBlockNumber>
        <StyledPollingDot warning={warning}>{isMounting && <Spinner warning={warning} />}</StyledPollingDot>{' '}
      </StyledPolling>
      {warning && <ChainConnectivityWarning />}
    </span>
  )
}
