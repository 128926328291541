import { Percent } from '@uniswap/sdk-core'
import { ButtonLight } from "components/Button";
import Expand from 'components/Expand'
import Row, { RowBetween } from 'components/Row'
import { useState } from 'react'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { SlippageTolerance } from 'state/user/types'
import { CautionTriangle, ThemedText } from 'theme'

import { Input, InputContainer } from '../Input'
import { MAXIMUM_RECOMMENDED_SLIPPAGE, MINIMUM_RECOMMENDED_SLIPPAGE, NUMBER_WITH_MAX_TWO_DECIMAL_PLACES } from 'constants/common'
import { Option, Switch } from './styled'
import { SlippageError } from 'types'

export default function MaxSlippageSettings({ autoSlippage, setIsSettingModalOpen }: { autoSlippage: Percent, setIsSettingModalOpen:(s:boolean)=>void }) {
  const [userSlippageTolerance, setUserSlippageTolerance] = useUserSlippageTolerance()

  // In order to trigger `custom` mode, we need to set `userSlippageTolerance` to a value that is not `auto`.
  // To do so, we use `autoSlippage` value. However, since users are likely to change that value,
  // we render it as a placeholder instead of a value.
  const defaultSlippageInputValue =
    userSlippageTolerance !== SlippageTolerance.Auto && !userSlippageTolerance.equalTo(autoSlippage)
      ? userSlippageTolerance.toFixed(2)
      : ''

  // If user has previously entered a custom slippage, we want to show that value in the input field
  // instead of a placeholder.
  const [slippageInput, setSlippageInput] = useState(defaultSlippageInputValue)
  const [slippageError, setSlippageError] = useState<SlippageError | false>(false)

  // If user has previously entered a custom slippage, we want to show the settings expanded by default.
  const [isOpen, setIsOpen] = useState(true)

  const parseSlippageInput = (value: string) => {
    // Do not allow non-numerical characters in the input field or more than two decimals
    if (value.length > 0 && !NUMBER_WITH_MAX_TWO_DECIMAL_PLACES.test(value)) {
      return
    }

    setSlippageInput(value)
    setSlippageError(false)

    // If the input is empty, set the slippage to the default
    if (value.length === 0) {
      setUserSlippageTolerance(SlippageTolerance.Auto)
      return
    }

    if (value === '.') {
      return
    }

    // Parse user input and set the slippage if valid, error otherwise
    try {
      const parsed = Math.floor(Number.parseFloat(value) * 100)
      if (parsed > 5000) {
        setSlippageError(SlippageError.InvalidInput)
      } else {
        setUserSlippageTolerance(new Percent(parsed, 10_000))
      }
    } catch (e) {
      setSlippageError(SlippageError.InvalidInput)
    }
  }

  const tooLow =
    userSlippageTolerance !== SlippageTolerance.Auto && userSlippageTolerance.lessThan(MINIMUM_RECOMMENDED_SLIPPAGE)
  const tooHigh =
    userSlippageTolerance !== SlippageTolerance.Auto && userSlippageTolerance.greaterThan(MAXIMUM_RECOMMENDED_SLIPPAGE)

  return (
    <Expand
      testId="max-slippage-settings"
      isOpen={isOpen}
      onToggle={() => null}
      header={
        <Row width="auto">
          {/* <ThemedText.BodySecondary fontSize={14}>
            <div>Max Slippage</div>
          </ThemedText.BodySecondary>
          <QuestionHelper
            text={
              <div>Your transaction will revert if the price changes unfavorably by more than this percentage.</div>
            }
          /> */}
        </Row>
      }
      button={
        <ThemedText.BodyPrimary>
          {/* {userSlippageTolerance === SlippageTolerance.Auto ? (
            <div>Auto</div>
          ) : (
            `${userSlippageTolerance.toFixed(2)}%`
          )} */}
        </ThemedText.BodyPrimary>
      }
    >
      <RowBetween gap="md">
        <Switch>
          <Option
            onClick={() => {
              setSlippageInput('')
              setUserSlippageTolerance(SlippageTolerance.Auto)
            }}
            isActive={userSlippageTolerance === SlippageTolerance.Auto}
          >
            <div  >Auto</div>
          </Option>
          <Option
            onClick={() => {
              // When switching to custom slippage, use `auto` value as a default.
              setUserSlippageTolerance(autoSlippage)
            }}
            isActive={userSlippageTolerance !== SlippageTolerance.Auto}
          >
            <div>Custom</div>
          </Option>
        </Switch>
        <InputContainer gap="md" error={!!slippageError}>
          <Input
            data-testid="slippage-input"
            placeholder={autoSlippage.toFixed(2)}
            value={slippageInput}
            onChange={(e) => parseSlippageInput(e.target.value)}
            onBlur={() => {
              // When the input field is blurred, reset the input field to the default value
              setSlippageInput(defaultSlippageInputValue)
              setSlippageError(false)
            }}
          />
          <ThemedText.BodyPrimary>%</ThemedText.BodyPrimary>
        </InputContainer>
      </RowBetween>
      {tooLow || tooHigh ? (
        <RowBetween gap="md">
          <CautionTriangle />
          <ThemedText.Caption color="accentWarning">
            {tooLow ? (
              <div>
                Slippage below {MINIMUM_RECOMMENDED_SLIPPAGE.toFixed(2)}% may result in a failed transaction
              </div>
            ) : (
              <div>Your transaction may be frontrun and result in an unfavorable trade.</div>
            )}
          </ThemedText.Caption>
        </RowBetween>
      ) : null}
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <ButtonLight
          onClick={() => setIsSettingModalOpen(false)}
          fontWeight={600}
          style={{
            background: `linear-gradient(95.72deg, #a2eeff 13.1%, #029bf0 98.51%)`,
            color: "#000",
            width: "100%",
            height: 40,
            fontSize: 12,
            // marginTop: '',
            border: 'none',
          }}
        >
          <div>Accept & Continue</div>
        </ButtonLight>
      </div>
    </Expand>
  )
}
