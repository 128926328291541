import { useEffect, useState } from "react";
import { useElektrikToken } from "hooks/elektrikToken/useElektrikToken";
import { useWeb3React } from "@web3-react/core";
import { ELTK_UNIT } from "constants/misc";

export const useGetEltk = (account: any) => {
  const { chainId } = useWeb3React();

  const [balance, setBalance] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const { elektrikToken: elektrikTokenInstance } = useElektrikToken();


  useEffect(() => {
    const initializeGetEltk = async () => {
      try {
        if (elektrikTokenInstance && account && chainId) {
          setLoading(true);
          const eltkBalance = await elektrikTokenInstance.balanceOf(account);
          const balanceString = eltkBalance.toString();
          const parsedBalance = Number(balanceString);
          setBalance(parsedBalance/ELTK_UNIT);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error initializeGetEltk", error);
        setLoading(false);
      }
    };

    initializeGetEltk();
  }, [elektrikTokenInstance]);

  return {
    balance,
    loading,
  };
};
