import { useEffect, useState } from "react";
import { Staking, StakerWallet } from "@eltk/staking-sdk";
import { Signer, providers } from "ethers";
import { useSignerWallet } from "hooks/useSignerWallet";
import { useStaking } from "./useStaking";

export const useGetUserStakedPositions = (tokenId: any) => {
  const [positions, setPositions] = useState<any>(null);
  const [positions0, setPositions0] = useState<any>(null);
  const [positions1, setPositions1] = useState<any>(null);  

  
  const { staking } = useStaking();

  useEffect(() => {
    const getUserStakedPositions = async () => {
      if (!tokenId) return;

      try {
        if (tokenId && staking) {
          const positions = await staking.getUserStakedPosition(tokenId.toString());
          setPositions0(positions[0])
          setPositions1(positions[1])
          setPositions(positions);

        }
      } catch (error) {
        console.error(`Error fetching balance for token ID ${tokenId}:`, error);
      }
    };

    getUserStakedPositions();
  }, [tokenId,staking]);

  return {
    positions,
    positions0,
    positions1
  };
};
