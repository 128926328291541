import React from 'react'
import styled from 'styled-components'
import { LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { RowBetween } from 'components/Row'
import { formatAmount, formatAmountChart } from 'utils/numbers'

export interface PoolData {
  // basic token info
  address: string
  feeTier: number

  token0: {
    name: string
    symbol: string
    address: string
    decimals: number
    derivedETH: number
  }

  token1: {
    name: string
    symbol: string
    address: string
    decimals: number
    derivedETH: number
  }

  // for tick math
  liquidity: number
  sqrtPrice: number
  tick: number

  // volume
  volumeUSD: number
  volumeUSDChange: number
  volumeUSDWeek: number

  // liquidity
  tvlUSD: number
  tvlUSDChange: number

  // prices
  token0Price: number
  token1Price: number

  // token amounts
  tvlToken0: number
  tvlToken1: number
}
const TooltipWrapper = styled(LightCard)`
  padding: 12px;
  width: 320px;
  opacity: 0.6;
  font-size: 12px;
  z-index: 10;
`

interface CustomToolTipProps {
  chartProps: any
  poolData: PoolData
  currentPrice: number | undefined
}

export function CustomToolTip({ chartProps, poolData, currentPrice }: CustomToolTipProps) {
  const price0 = chartProps?.payload?.[0]?.payload.price0
  const price1 = chartProps?.payload?.[0]?.payload.price1
  const tvlToken0 = chartProps?.payload?.[0]?.payload.tvlToken0
  const tvlToken1 = chartProps?.payload?.[0]?.payload.tvlToken1
  const activeLiquidity = chartProps?.payload?.[0]?.payload.activeLiquidity

  return (
    <TooltipWrapper>
      <div >
        <p style={{color:'#fff', fontWeight:600  }} >Tick stats</p>
        <div style={{display:'flex', justifyContent:'space-between', marginTop:'-10px'}}>
          <p>{poolData?.token0?.symbol} Price: </p>
          <p>
            {price0
              ? formatAmountChart(price0) : ''}{' '}
            {poolData?.token1?.symbol}
          </p>
        </div>
        <div style={{display:'flex', justifyContent:'space-between', marginTop:'-10px'}}>
          <p>{poolData?.token1?.symbol} Price: </p>
          <p>
            {price1
              ? formatAmountChart(price1) : ''}{' '}
            {poolData?.token0?.symbol}
          </p>
        </div>
        {currentPrice && price0 && currentPrice > price1 ? (
          <div style={{display:'flex', justifyContent:'space-between', marginTop:'-10px'}}>
            <p>{poolData?.token0?.symbol} Locked: </p>
            <p>
              {tvlToken0 ? formatAmountChart(tvlToken0) : ''} {poolData?.token0?.symbol}
            </p>
          </div>
        ) : (
          <div style={{display:'flex', justifyContent:'space-between', marginTop:'-10px'}}>
            <p>{poolData?.token1?.symbol} Locked: </p>
            <p>
              {tvlToken1 ? formatAmountChart(tvlToken1) : ''} {poolData?.token1?.symbol}
            </p>
          </div>
        )}

        <div style={{display:'flex', justifyContent:'space-between', marginTop:'-10px'}}>
          <p>Active Liquidity</p>
          <p>{activeLiquidity}</p>
        </div>
      </div>
    </TooltipWrapper>
  )
}

export default CustomToolTip
