import { useEffect, useState } from "react";
import { Staking, StakerWallet } from "@eltk/staking-sdk";
import { Signer, providers } from "ethers";
import { useSignerWallet } from "hooks/useSignerWallet";

export const useStaking = () => {
  const [staking, setStaking] = useState<any>();
  const { wallet: signerWallet } = useSignerWallet();

  useEffect(() => {
    const initializeStaking = async () => {
      try {
        if (signerWallet) {
          const stakingInstance = await Staking.getInstance(signerWallet);
          setStaking(stakingInstance);
        }
      } catch (error) {
        console.error("Error initializing staking:", error);
      }
    };

    initializeStaking();
  }, [signerWallet]);

  return {
    staking,
  };
};
