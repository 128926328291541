import { transparentize } from "polished";
import styled, { css } from "styled-components/macro";
import { Z_INDEX } from "theme/zIndex";

import { AutoColumn } from "../Column";
import { ButtonEmpty } from "components/Button";
import { ThemedText } from "theme";

export const LeftDiv = styled.div`
  /* flex: 1.75; */
  width: 100%;
  min-width: 747px;
  max-width: 900px;

  @media only screen and (max-width: 1200px) {
    min-width: 550px;
    max-width: 690px;
  }
  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.md}px`}) {
    flex: 1;
    width: 100%;
    min-width: 100%;
  }

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.sm}px`}) {
    flex: 1;
    width: 100%;
    min-width: 100%;
  }
`;

export const RightDiv = styled.div`
  width: 100%;
  max-width: 690px;
  @media only screen and (max-width: 1200px) {
    margin-bottom: 20px;
  }
  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.md}px`}) {
    flex: 1;
    width: 100%;
  }

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.sm}px`}) {
    flex: 1;
    width: 100%;
  }
`;

export const PageWrapperGlobal = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: row;
  border-radius: 12px;
  justify-content: center;
  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.md}px`}) {
    flex-direction: column-reverse;
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 1200px) {
    flex-direction: column-reverse;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
  }
`;

export const OuterSwapPageWrapper = styled.div`
  padding: 0px 100px 150px 100px;
  width: 100%;
  border-radius: 12px;
  margin-top: 30px;

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.md}px`}) {
    padding-top: 48px;
    padding: 68px 10px 15px 10px;
  }

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.sm}px`}) {
    padding-top: 20px;
    padding: 68px 10px 15px 10px;
  }
`;
export const PageWrapper = styled.div`
  padding: 0px 16px 0px;
  height: 100%;

  width: 100%;
  border-radius: 12px;

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.md}px`}) {
    padding: 0px 0px 0px;
  }

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.sm}px`}) {
    padding: 0px 0px 0px;
  }
`;
export const PageWrapperLimit = styled.div`
  height: 100%;

  width: 100%;
  border-radius: 12px;

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.md}px`}) {
    padding: 0px 0px 0px;
  }

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.sm}px`}) {
    padding: 0px 0px 0px;
  }
`;
export const SwapPageWrapper = styled.div`
  height: 100% !important;
  width: 100%;
  /* width:550px; */
  border-radius: 12px;

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.md}px`}) {
    padding: 0px 0px 0px;
  }

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.sm}px`}) {
    padding-top: 20px;
  }
`;
export const FlexDiv = styled.div`
  display: flex;
  flex: 0.25;

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.md}px`}) {
    flex: 0;
  }

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.sm}px`}) {
    flex: 0;
  }
`;

export const GraphHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
`;

export const LogoSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
`;

export const IntervalSection = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.inputGray};
  border-radius: 16;
  align-items: center;
  justify-content: center;
  margin-right: 20;
`;
export const PageWrapperGraph = styled.div`
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 16px;
   max-height: 520px;
  overflow-x: hidden;
  overflow-y: hidden;
  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.md}px`}) {
    padding-top: 48px;
    margin-top: 68px;
  }

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.sm}px`}) {
    padding-top: 20px;
    margin-top: 68px;
  }
  position: relative;
  --border-width: 2px;
  --border-radius: 12px;

  background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
  border: 0;
  padding: 25px 20px 25px 20px;
  position: relative;
  border-radius: var(--border-radius);
  z-index: ${Z_INDEX.default};
  &::before {
    content: "";
    display: block;
    height: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    width: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    background: ${({ theme }) => theme.backgroundSurface};
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: calc(var(--border-radius) - var(--border-width));
    z-index: ${Z_INDEX.negative};
  }
`;

export const PageWrapperGraphLimit = styled.div`
  padding: 16px;
  width: 100%;
  background: transparent;
  border-radius: 16px;
  height: 100%;
  overflow: hidden;
  max-height: 520px;

  position: relative;
  --border-width: 2px;
  --border-radius: 12px;

  background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
  border: 0;
  padding: 25px 20px 25px 20px;
  border-radius: var(--border-radius);
  z-index: ${Z_INDEX.default};

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.md}px`}) {
    padding-top: 48px;
    margin-top: 68px;
  }

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.sm}px`}) {
    padding-top: 20px;
    margin-top: 68px;
  }

  &::before {
    content: "";
    display: block;
    height: calc(100% - (var(--border-width) * 2));
    width: calc(100% - (var(--border-width) * 2));
    background: ${({ theme }) => theme.backgroundSurface};
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: calc(var(--border-radius) - var(--border-width));
    z-index: ${Z_INDEX.negative};
  }
`;


export const OuterWrapperGraph = styled.div`
  /* max-height: 530px !important; */
  height: 100%;

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.md}px`}) {
    padding: 0px 0px 0px 0px;
    height: 200px;
  }

  @media screen and (max-width: 768px) {
    height: 300px;
  }

  @media screen and (max-width: 1200px) {
    height: 300px;
  }
`;

export const OuterWrapperGraphLimit = styled.div`
  /* padding: 0px 16px 0px; */
  height: 100%;

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.sm}px`}) {
    padding: 0px 0px 0px 0px;
    height: 200px;
  }

  @media screen and (max-width: 768px) {
    height: 300px;
  }

  @media screen and (max-width: 1200px) {
    height: 300px;
  }
`;

export const GradientBorderWrapper = styled.div`
  border-radius: 16px;
`;

// Mostly copied from `AppBody` but it was getting too hard to maintain backwards compatibility.
export const SwapWrapper = styled.main<{ chainId?: number }>`
  height: 100%;
  position: relative;
  --border-width: 2px;
  --border-radius: 12px;

  background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);

  border: 0;

  padding: 32px 38px 37px 38px;

  position: relative;

  border-radius: var(--border-radius);
  z-index: ${Z_INDEX.default};

  &::before {
    content: "";
    display: block;
    height: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    width: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */

    background: ${({ theme }) => theme.backgroundSurface};

    position: absolute;

    top: var(--border-width);
    left: var(--border-width);

    border-radius: calc(var(--border-radius) - var(--border-width));

    z-index: ${Z_INDEX.negative};
  }
`;

export const ArrowWrapper = styled.div<{ clickable: boolean }>`
  border-radius: 12px;
  height: 40px;
  width: 40px;
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;

  z-index: ${Z_INDEX.hover};
  ${({ clickable }) =>
    clickable
      ? css`
          :hover {
            cursor: pointer;
            opacity: 0.8;
          }
        `
      : null}
`;

// styles
export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: ".";
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: ".";
    }
    33% {
      content: "..";
    }
    66% {
      content: "...";
    }
  }
`;

const SwapCallbackErrorInner = styled.div`
  background-color: ${({ theme }) => transparentize(0.9, theme.accentFailure)};
  border-radius: 1rem;
  display: flex;
  align-items: center;
  font-size: 0.825rem;
  width: 100%;
  padding: 3rem 1.25rem 1rem 1rem;
  margin-top: -2rem;
  color: ${({ theme }) => theme.accentFailure};
  z-index: ${Z_INDEX.negative};
  p {
    padding: 0;
    margin: 0;
    font-weight: 500;
  }
`;

const SwapCallbackErrorInnerAlertTriangle = styled.div`
  background-color: ${({ theme }) => transparentize(0.9, theme.accentFailure)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 12px;
  min-width: 48px;
  height: 48px;
`;

export const SwapShowAcceptChanges = styled(AutoColumn)`
  background-color: ${({ theme }) =>
    transparentize(0.95, theme.deprecated_primary3)};
  color: ${({ theme }) => theme.accentAction};
  padding: 12px;
  border-radius: 12px;
  margin-top: 8px;
`;

export const DetailsFooter = styled.div<{ show: boolean }>`
  padding-top: calc(16px + 2rem);
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -2rem;
  width: 100%;
  max-width: 400px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: ${({ theme }) => theme.textSecondary};
  background-color: ${({ theme }) => theme.deprecated_advancedBG};
  z-index: ${Z_INDEX.deprecated_zero};

  transform: ${({ show }) => (show ? "translateY(0%)" : "translateY(-100%)")};
  transition: transform 300ms ease-in-out;
  text-align: center;
`;

export const StyledButtonEmpty = styled(ButtonEmpty)`
  text-decoration: none;
`;

export const AddressText = styled(ThemedText.DeprecatedBlue)`
  font-size: 12px;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    font-size: 10px;
`}
`;
export const StyledPriceContainer = styled.button`
  background-color: transparent;
  border: none;
  color: #b9cbd1;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  grid-template-columns: 1fr auto;
  grid-gap: 0.25rem;
  display: flex;
  flex-direction: row;
  text-align: left;
  flex-wrap: wrap;
  user-select: text;
`;

export const StyledGasContainer = styled.button`
  border-radius: 10px;
  background: #1e2c30;
  color: #b9cbd1;
  font-family: Inter;
  border: none;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
`;

export const StyledGasText = styled.button`
  color: #5d7279;
  background: #1e2c30;
  border: none;
  font-size: 12px;
`;

export const StyledArrowWrapper = styled(ArrowWrapper)`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: space-between;

  padding: 8px;
  border: ${({ theme }) => `1px solid ${theme.backgroundOutline}`};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.backgroundSurface};
`;

export const Blob = styled.div<{ width?: number; radius?: number }>`
  background-color: ${({ theme }) => theme.backgroundModule};
  border-radius: ${({ radius }) => (radius ?? 4) + "px"};
  height: 56px;
  width: ${({ width }) => (width ? width + "px" : "100%")};
`;

export const ModuleBlob = styled(Blob)`
  background-color: ${({ theme }) => theme.backgroundOutline};
  height: 36px;
`;

export const TitleColumn = styled.div`
  padding: 8px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InputColumn = styled.div`
  display: flex;
  flex-flow: column;
  background-color: ${({ theme }) => theme.backgroundModule};
  border-radius: 10px;
  display: flex;
  gap: 30px;
  padding: 48px 12px;
`;

export const OutputWrapper = styled.div`
  position: relative;
`;

export const CountBadge = styled.span`
height:14px;
width:14px;
  position: relative;
  top: 0;
  left: 4px;
  color: #fff;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  display:'flex';
  justify-content:'center',
  align-items:center;
  padding: 2px;
`;
export const FlipWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  background: #121b1e;
  padding: 15px 16px;
  border-radius: 10px;
`;

export const ShowGradient = styled.div`
  position: relative;
  font-size: 12px;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px; /* Adjust the thickness of the underline as needed */
    background-image: linear-gradient(90.8deg, #a1eeff 7.28%, #029af0 89.82%);
    visibility: visible; /* Make the underline initially visible */
    transform: scaleX(1);
    transition: all 0.3s ease-in-out;
  }

  background-image: linear-gradient(90.8deg, #a1eeff 7.28%, #029af0 89.82%);
  color: transparent;
  -webkit-background-clip: text; /* For older WebKit browsers */
  background-clip: text;
  text-decoration: none; /* Remove the default underline */
`;

export const DiscoverPoolGradient = styled.div`
  position: relative;
  font-size: 14px;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px; /* Adjust the thickness of the underline as needed */
    background-image: linear-gradient(90.8deg, #a1eeff 7.28%, #029af0 89.82%);
    visibility: visible; /* Make the underline initially visible */
    transform: scaleX(1);
    transition: all 0.3s ease-in-out;
  }

  background-image: linear-gradient(90.8deg, #a1eeff 7.28%, #029af0 89.82%);
  color: transparent;
  -webkit-background-clip: text; /* For older WebKit browsers */
  background-clip: text;
  text-decoration: none; /* Remove the default underline */
`;

export const ShowGradientText = styled.div`
  position: relative;
  font-size: 15px;
  cursor: pointer;

  background-image: linear-gradient(90.8deg, #a1eeff 7.28%, #029af0 89.82%);
  color: transparent;
  -webkit-background-clip: text; /* For older WebKit browsers */
  background-clip: text;
  text-decoration: none; /* Remove the default underline */
`;

export const SwapRouteWrapper = styled.div`
  position: relative;
  --border-width: 2px;
  --border-radius: 18px;
  background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
  border: 0;
  padding: 2rem 2rem;
  position: relative;
  border-radius: var(--border-radius);
  z-index: ${Z_INDEX.default};
  &::before {
    content: "";
    display: block;
    height: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    width: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    background: ${({ theme }) => theme.backgroundSurface};
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: calc(var(--border-radius) - var(--border-width));
    z-index: ${Z_INDEX.negative};
  }
`;

export const ModalGradientWrapper = styled.div`
  max-width: 420px;
  position: relative;
  --border-width: 2px;
  --border-radius: 12px;
  background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
  border: 0;
  padding: 1rem 2rem;
  position: relative;
  border-radius: var(--border-radius);
  z-index: ${Z_INDEX.default};
  &::before {
    content: "";
    display: block;
    height: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    width: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    background: ${({ theme }) => theme.backgroundSurface};
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: calc(var(--border-radius) - var(--border-width));
    z-index: ${Z_INDEX.negative};
  }
`;
