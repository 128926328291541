import { Currency, CurrencyAmount } from "@uniswap/sdk-core"
import { SupportedChainId } from "constants/chains"
import { CSSProperties, FunctionComponent, PropsWithChildren, ReactNode } from "react"
import { NavLinkProps } from "react-router-dom"
import { Connection } from 'connection/types'
import { MouseEvent } from 'react'
import { VoteOption } from "state/governance/types"
import { TooltipSize } from "components/Tooltip/styled"
import { PricePoint, TimePeriod } from "graphql/data/util"
import { BigNumber } from "ethers"
import { PositionDetails } from "./position"
import { Placement } from '@popperjs/core'
import { Percent } from '@uniswap/sdk-core'
import { InterfaceTrade } from "state/routing/types"
import { Protocol } from "@uniswap/router-sdk"
import { FeeAmount, FeeOptions } from "@uniswap/v3-sdk"
import { Price, Token } from '@uniswap/sdk-core'
import { Bound } from "state/mint/v3/actions"
import { NumberType } from "@uniswap/conedison/format"
import { PermitSignature } from "hooks/usePermitAllowance"
import JSBI from "jsbi"
import { AllowanceState } from "hooks/usePermit2Allowance"
import { Pair } from "@uniswap/v2-sdk"
// import { FlyoutAlignment } from "components/Menu"
import { Nullish } from "@uniswap/conedison/types"
import { FractionLike } from "utils/limitOrders/fractionUtils"
import { PoolState } from "hooks/usePools"
import { useFeeTierDistribution } from "hooks/useFeeTierDistribution"
import { FeatureFlag } from "featureFlags"
import { SparklineMap, TopToken } from "graphql/data/TopTokens"
import { CurveFactory } from "d3"
import {
  ButtonProps as ButtonPropsOriginal,
} from "rebass/styled-components";
import { BadgeVariant } from "components/Badge"
import { Icon } from 'react-feather'
import { ClassValue } from 'clsx'

import {  TransactionStatus } from 'graphql/data/__generated__/types-and-hooks'
import { Atoms } from 'global/css/atoms'
import {
  AssetActivityPartsFragment,
  NftApprovalPartsFragment,
  NftApproveForAllPartsFragment,
  NftTransferPartsFragment,
  TokenApprovalPartsFragment,
  TokenTransferPartsFragment,
} from 'graphql/data/__generated__/types-and-hooks'

export enum FlyoutAlignment {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}


export interface PopoverProps {
  content: React.ReactNode
  show: boolean
  children?: React.ReactNode
  placement?: Placement
  offsetX?: number
  offsetY?: number
  hideArrow?: boolean
  showInline?: boolean
  style?: CSSProperties
}
export type MultiLogoProps = {
  chainId: SupportedChainId
  accountAddress?: string
  currencies?: Array<Currency | undefined>
  images?: (string | undefined)[]
  size?: string
  style?: React.CSSProperties
}
export interface Page {
  title: React.ReactNode
  key: string
  component: ({ account }: { account: string }) => JSX.Element
  loggingElementName: string
}

export interface BodyWrapperProps {
  $margin?: string
  $maxWidth?: string
}

export interface Props {
  children: JSX.Element[] | JSX.Element
}

export interface MenuItemProps {
  href: string;
  id?: NavLinkProps["id"];
  isActive?: boolean;
  children: ReactNode;
  dataTestId?: string;
  onClick?:any;
  isLp?:any;
  additionalStyles?:any
}


export interface PopupButtonContentProps {
  connection: Connection
  isDarkMode: boolean
  show: boolean
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
  onClose: () => void
}

export interface OptionProps {
  connection: Connection
}
export interface VoteModalProps {
  isOpen: boolean
  onDismiss: () => void
  title: ReactNode
}
export interface ExecuteModalProps {
  isOpen: boolean
  onDismiss: () => void
  proposalId?: string // id for the proposal to execute
}

export interface EmptyStateProps {
  HeaderContent: () => JSX.Element
  SubHeaderContent: () => JSX.Element
}

export interface QueueModalProps {
  isOpen: boolean
  onDismiss: () => void
  proposalId?: string // id for the proposal to queue
}

export interface VoteModalProps {
  isOpen: boolean
  onDismiss: () => void
  voteOption?: VoteOption
  proposalId?: string // id for the proposal to vote on
}


export type TooltipProps = Omit<PopoverProps, 'content'> & {
  text: ReactNode
  open?: () => void
  close?: () => void
  size?: TooltipSize
  disabled?: boolean
  timeout?: number
}

export type MouseoverTooltipProps = Omit<PopoverProps, 'content' | 'show'> &
  PropsWithChildren<{
    text: ReactNode
    size?: TooltipSize
    disabled?: boolean
    timeout?: number
    placement?: PopoverProps['placement']
    onOpen?: () => void
  }>
  export interface PriceChartProps {
    width: number
    height: number
    prices?: PricePoint[] | null
    timePeriod: TimePeriod
  }
  export interface InputSliderProps {
    value: number
    onChange: (value: number) => void
    step?: number
    min?: number
    max?: number
    size?: number
  }

  export interface RateImpactProps {
    rateImpact: number
    inputCurrency: Currency | null
  }

 export  interface PresetsButtonsProps {
    onSetFullRange: () => void
  }
  
  export interface RadioProps {
    className?: string
    isActive: boolean
    toggle: () => void
  }

  export interface PositionListItemProps {
    token0: string
    token1: string
    tokenId: BigNumber
    fee: number
    liquidity: BigNumber
    tickLower: number
    tickUpper: number
  }

export   type PositionListProps = React.PropsWithChildren<{
    positions: PositionDetails[]
    setUserHideClosedPositions: any
    userHideClosedPositions: boolean
  }>
  

  export interface AnalyticsEventProps {
    trade: InterfaceTrade
    hash?: string
    allowedSlippage: Percent
    transactionDeadlineSecondsSinceEpoch?: number
    isAutoSlippage: boolean
    isAutoRouterApi: boolean
    swapQuoteReceivedDate?: Date
    routes: RoutingDiagramEntry[]
    fiatValueInput?: number
    fiatValueOutput?: number
  }

  
  export type SupportedChainsType = SupportedChainId
  export interface RoutingDiagramEntry {
    percent: Percent
    path: [Currency, Currency, FeeAmount][]
    protocol: Protocol
  }
  export interface FormatTickPriceArgs {
    price?: Price<Token, Token>
    atLimit: { [bound in Bound]?: boolean | undefined }
    direction: Bound
    placeholder?: string
    numberType?: NumberType
  }
  export type CurrencyKey = string
  export interface UseV3PositionsResults {
    loading: boolean
    positions?: PositionDetails[]
  }
  export interface SwapOptions {
    slippageTolerance: Percent;
    deadline?: BigNumber;
    permit?: PermitSignature;
    feeOptions?: FeeOptions;
  }

 export type TokenId = number | JSBI | BigNumber
 export type UsePositionTokenURIResult =
 | {
     valid: true
     loading: false
     result: {
       name: string
       description: string
       image: string
     }
   }
 | {
     valid: false
     loading: false
   }
 | {
     valid: true
     loading: true
   }
   export interface TickProcessed {
    tick: number
    liquidityActive: JSBI
    liquidityNet: JSBI
    price0: string
  }
  
  interface AllowanceRequired {
    state: AllowanceState.REQUIRED
    token: Token
    isApprovalLoading: boolean
    isApprovalPending: boolean
    approveAndPermit: () => Promise<void>
    approve: () => Promise<void>
    permit: () => Promise<void>
    needsPermit2Approval: boolean
    needsSignature: boolean
  }
  
  export type Allowance =
    | { state: AllowanceState.LOADING }
    | {
        state: AllowanceState.ALLOWED
        permitSignature?: PermitSignature
      }
    | AllowanceRequired
    export interface FeeTierDistribution {
      isLoading: boolean
      isError: boolean
      largestUsageFeeTier?: FeeAmount
    
      // distributions as percentages of overall liquidity
      distributions?: Record<FeeAmount, number | undefined>
    }

 export type Maybe<T> = T | null | undefined
 export interface TradePriceProps {
  price: Price<Currency, Currency>
}

export interface AdvancedSwapDetailsProps {
  trade: InterfaceTrade
  allowedSlippage: Percent
  syncing?: boolean
}
export interface CurrencySearchModalProps {
  isOpen: boolean
  onDismiss: () => void
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Currency) => void
  otherSelectedCurrency?: Currency | null
  showCommonBases?: boolean
  showCurrencyAmount?: boolean
  disableNonToken?: boolean
  onlyShowCurrenciesWithBalance?: boolean
}

export interface CurrencySearchProps {
  isOpen: boolean
  onDismiss: () => void
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Currency, hasWarning?: boolean) => void
  otherSelectedCurrency?: Currency | null
  showCommonBases?: boolean
  showCurrencyAmount?: boolean
  disableNonToken?: boolean
  onlyShowCurrenciesWithBalance?: boolean
}

export interface PositionCardProps {
  pair: Pair
  showUnwrapped?: boolean
  border?: string
  stakedBalance?: CurrencyAmount<Token> // optional balance to indicate that liquidity is deposited in mining pool
}


export interface SushiPositionCardProps {
  tokenA: Token
  tokenB: Token
  liquidityToken: Token
  border?: string
}

export interface ModalProps {
  isOpen: boolean
  onDismiss?: () => void
  onSwipe?: () => void
  height?: number // takes precedence over minHeight and maxHeight
  minHeight?: number | false
  maxHeight?: number
  maxWidth?: number
  initialFocusRef?: React.RefObject<any>
  children?: React.ReactNode
  $scrollOverlay?: boolean
  hideBorder?: boolean
}
export type StyledDialogProps = {
  $minHeight?: number | false
  $maxHeight?: number
  $scrollOverlay?: boolean
  $hideBorder?: boolean
  $maxWidth: number
}


export interface MenuProps {
  flyoutAlignment?: FlyoutAlignment
  ToggleUI?: FunctionComponent<PropsWithChildren<unknown>>
  menuItems: {
    content: any
    link: string
    external: boolean
  }[]
}
export interface StepCounterProps {
  value: string
  onUserInput: (value: string) => void
  decrement: () => string
  increment: () => string
  decrementDisabled?: boolean
  incrementDisabled?: boolean
  feeAmount?: FeeAmount
  label?: string
  width?: string
  locked?: boolean // disable input
  title: ReactNode
  tokenA?: string
  tokenB?: string
}


export interface FiatAmountProps {
  amount: Nullish<FractionLike>
  accurate?: boolean
  defaultValue?: string
  className?: string
}

export interface FeeOptionProps {
  feeAmount: FeeAmount
  active: boolean
  distributions: ReturnType<typeof useFeeTierDistribution>['distributions']
  poolState: PoolState
  onClick: () => void
}
export interface FeatureFlagProps {
  variant: Record<string, string>
  featureFlag: FeatureFlag
  value: string
  label: string
}

export interface ExecutionPriceProps {
  executionPrice: Price<Currency, Currency>
  isInverted: boolean
  showBaseCurrency?: boolean
  hideSeparator?: boolean
  separatorSymbol?: string
  hideFiat?: boolean
}
export interface DoubleCurrencyLogoProps {
  margin?: boolean 
  size?: number
  currency0?: Currency
  currency1?: Currency
}
export interface ConnectedAccountBlockedProps {
  account?: string | null
  isOpen: boolean
}

export interface SparklineChartProps {
  width: number
  height: number
  tokenData: TopToken
  pricePercentChange?: number | null
  sparklineMap: SparklineMap
}


export interface LineChartProps<T> {
  data: T[]
  getX: (t: T) => number
  getY: (t: T) => number
  marginTop?: number
  curve: CurveFactory
  color?: string
  strokeWidth: number
  children?: ReactNode
  width: number
  height: number
}

export type ButtonProps = Omit<ButtonPropsOriginal, "css">;
export type BaseButtonProps = {
  padding?: string;
  width?: string;
  $borderRadius?: string;
  altDisabledStyle?: boolean;
} & ButtonProps;

export interface BadgeProps {
  variant?: BadgeVariant
}

export enum MenuState {
  DEFAULT,
  SETTINGS,
}
export interface BaseProps {
  Icon: Icon
  children?: React.ReactNode
}



export interface IconLinkProps extends React.ComponentPropsWithoutRef<'a'>, BaseProps {}
export interface IconButtonProps extends React.ComponentPropsWithoutRef<'button'>, BaseProps {}

export type IconBlockProps = React.ComponentPropsWithoutRef<'a' | 'button'>

export type IconWithTextProps = (IconButtonProps | IconLinkProps) & {
  text: string
  onConfirm?: () => void
  onShowConfirm?: (on: boolean) => void
}

type HTMLProperties<T = HTMLElement> = Omit<
  React.AllHTMLAttributes<T>,
  'as' | 'className' | 'color' | 'height' | 'width'
>

export type PropsBox = Atoms &
  HTMLProperties & {
    as?: React.ElementType
    className?: ClassValue
  }

  export  type AnimatedInLineChartProps<T> = Omit<LineChartProps<T>, 'height' | 'width' | 'children'>
  export type FadedInLineChartProps<T> = Omit<LineChartProps<T>, 'height' | 'width' | 'children'> & { dashed?: boolean }

  export type EmptyWalletContent = {
    title: React.ReactNode
    subtitle: React.ReactNode
    actionText?: React.ReactNode
    urlPath?: string
    icon: React.ReactNode
  }
  export type EmptyWalletContentType = 'nft' | 'token' | 'activity' | 'pool'


  export interface EmptyWalletContentProps {
  type?: EmptyWalletContentType
  onNavigateClick?: () => void
}


export type SVGProps = React.SVGProps<SVGSVGElement> & {
  fill?: string
  height?: string | number
  width?: string | number
  gradientId?: string
}

export interface InfoIconProps {
  content: ReactNode
  className?: string
}


export interface ChainSelectorProps {
  leftAlign?: boolean
}

export interface ChainSelectorRowProps {
  disabled?: boolean
  targetChain: SupportedChainId
  onSelectChain: (targetChain: number) => void
  isPending: boolean
}


export interface NavIconProps {
  children: ReactNode
  isActive?: boolean
  label?: string
  onClick: () => void
  activeBackground?: boolean
}
export interface ToggleProps {
  id?: string
  bgColor?: string
  isActive: boolean
  toggle: () => void
}

export interface RateInputProps {
  // trade: Trade | null;
  trade: any;
  setVarCheck: React.Dispatch<React.SetStateAction<any>>;
  varCheck: number | null | string;
  inputAddress?: string;
  outputAddress?: string;
  currencyInput?: Currency | null;
  currencyOutput?: Currency | null;
}


export interface SwapCurrencyInputPanelProps {
  value: number | string;
  onUserInput: (value: any) => any;
  onMax?: () => void;
  showMaxButton: boolean;
  onCurrencySelect?: (inputCurrency: any) => any;
  currency?: Currency | null;
  otherCurrency?: Currency | null;
  id?: string;
  showCommonBases: boolean;
  showCurrencyAmount?: boolean;
  disableNonToken?: boolean;
  renderBalance?: (balance: any) => React.ReactNode;
  fiatValue?: boolean | object;
  priceImpact?: number | Percent;
  hideBalance?: boolean;
  pair?: { token0: Currency; token1: Currency } | null;
  hideInput?: boolean;
  locked?: boolean;
  loading?: boolean;
  disabled?: boolean;
  numericInputDisabled?: boolean;
  currencyInput?: Currency | null;
  usdValue?: number | null | string;
  label: any;
}
export interface ConfirmLimitModalProps {
  onDismiss: () => void;
  onConfirm: () => void;
  allowance: any;
  formattedAmountsLimit: any;
  limitOrderPayload: any;
  LimitOrderCallback: any;
  allowedSlippage: any;
  txHash: any;
  currencyInput?: any;
  currencyOutput?: any;
}


export interface ChartOptions {
  chart: {
    type: string;
    zoomType: string;
    backgroundColor: string;
    color: string;
    height: number;
  };
  title: {
    text: string;
  };
  xAxis: {
    minPadding: number;
    maxPadding: number;
    plotLines: {
      color: string;
      value: number;
      width: number;
      label: {
        text: string;
        rotation: number;
      };
    }[];
    title: {
      text: string;
    };
    labels: {
      style: {
        color: string;
      };
    };
  };
  yAxis: [
    {
      lineWidth: number;
      gridLineWidth: number;
      title: null;
      tickWidth: number;
      tickLength: number;
      tickPosition: string;
      labels: {
        align: string;
        x: number;
        style: {
          color: string;
        };
      };
    },
    {
      opposite: boolean;
      linkedTo: number;
      lineWidth: number;
      gridLineWidth: number;
      title: null;
      tickWidth: number;
      tickLength: number;
      tickPosition: string;
      labels: {
        align: string;
        x: number;
        style: {
          color: string;
        };
      };
    }
  ];
  legend: {
    enabled: boolean;
  };
  plotOptions: {
    area: {
      fillOpacity: number;
      lineWidth: number;
      step: string;
    };
  };
  tooltip: {
    headerFormat: string;
    valueDecimals: number;
  };
  series: {
    name: string;
    data: [number, number][];
    color: string;
  }[];
}

export interface LineChartPropsDex   {
  data: any[];
  isPhoenix: boolean;
  isLimit: boolean;
}



//About
export enum CardType {
  Primary = 'Primary',
  Secondary = 'Secondary',
}

//MiniPortfolio




type Receipt = AssetActivityPartsFragment['transaction']

export type Activity = {
  hash: string
  chainId: SupportedChainId
  status: TransactionStatus
  timestamp: number
  title: string
  descriptor?: string
  logos?: Array<string | undefined>
  currencies?: Array<Currency | undefined>
  otherAccount?: string
  receipt?: Omit<Receipt, 'nonce'>
  nonce?: number | null
}

export type ActivityMap = { [hash: string]: Activity | undefined }

export interface ActivityGroup {
  title: string
  transactions: Array<Activity>
}
export type TransactionChanges = {
  NftTransfer: NftTransferPartsFragment[]
  TokenTransfer: TokenTransferPartsFragment[]
  TokenApproval: TokenApprovalPartsFragment[]
  NftApproval: NftApprovalPartsFragment[]
  NftApproveForAll: NftApproveForAllPartsFragment[]
}


//Rate Input
export interface TokenInfo {
  chainId: number;
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  logoURI: string;
  mainnet?: string;
}
export interface TokenData {
  name: string;
  logoURI: string;
  keywords: string[];
  timestamp: string;
  tokens: { [address: string]: TokenInfo };
  version: {
    major: number;
    minor: number;
    patch: number;
  };
}

//CurrencyInputPanel

export interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: ReactNode
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  fiatValue?: { data?: number; isLoading: boolean }
  id: string
  showCommonBases?: boolean
  showCurrencyAmount?: boolean
  disableNonToken?: boolean
  renderBalance?: (amount: CurrencyAmount<Currency>) => ReactNode
  locked?: boolean
  loading?: boolean
}

export interface DeadlineSelectorProps {
  deadline?: any;
  customDeadline: number | null;
  selectDeadline(deadline: any): void;
  selectCustomDeadline(deadline: number | null): void;
}


export interface SwapDetailsInlineProps {
  trade?: InterfaceTrade;
  syncing: boolean;
  loading: boolean;
  allowedSlippage: Percent;
}

export interface PriceImpactWarningProps {
  priceImpact: Percent
}
export interface StyledGasIconProps {
  isFreeTransactions?: boolean;
}

export enum ConfirmModalState {
  REVIEWING,
  APPROVING_TOKEN,
  PERMITTING,
  PENDING_CONFIRMATION,
}
export enum DeadlineError {
  InvalidInput = 'InvalidInput',
}
export enum SlippageError {
  InvalidInput = 'InvalidInput',
}
export enum CurrencyModalView {
  search,
  importToken,
  tokenSafety,
}


export enum SlippageValidationResult {
  TooLow,
  TooHigh,
  Valid,
}

export enum ValidationSchema {
  LIST = 'list',
  TOKENS = 'tokens',
}

export enum CurrencyAPI {
  ETH = "ETH",
  LOOKS = "LOOKS",
  MATIC = "MATIC",
}