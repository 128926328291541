import { SupportedChainId } from "constants/chains";

export const pegagsusbaseUrl = "https://web2-backend.elektriktest.net/";
export const phoenixbaseUrl = "https://backend.elektrik.network/";
// export const Project_id = "e358a8416fba8a9c883caa3e19e9d490";

export const getLimitOrderBaseUrl = (
  chainId: string | number | undefined
): string => {
  if (chainId === SupportedChainId.LIGHTLINK_PEGASUS_TESTNET) {
    return pegagsusbaseUrl;
  } else if (chainId === SupportedChainId.LIGHTLINK_PHOENIX_MAINNET) {
    return phoenixbaseUrl;
  }
  return phoenixbaseUrl;
};
