import styled, { css, keyframes } from 'styled-components/macro'

export const FeeTierSpan = styled.span`
background: linear-gradient(194deg, #D96DFF 3.9%, #AD00FF 98.12%);
height:10px;
font-size: 10px;
width:95px;
display:flex;
justify-content:center;
align-items:center;
border-radius:12px;
padding:10px

`
