// eslint-disable-next-line no-restricted-imports
import { Percent } from "@uniswap/sdk-core";
import { useWeb3React } from "@web3-react/core";
import { L2_CHAIN_IDS } from "constants/chains";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { isSupportedChainId } from "lib/hooks/routing/clientSideSmartOrderRouter";
import { useRef, useState } from "react";
import { useModalIsOpen, useToggleSettingsMenu } from "state/application/hooks";
import { ApplicationModal } from "state/application/reducer";
import { CloseIcon, Divider, ThemedText } from "theme";

import MaxSlippageSettings from "./MaxSlippageSettings";
import MenuButton from "./MenuButton";
import RouterPreferenceSettings from "./RouterPreferenceSettings";
import TransactionDeadlineSettings from "./TransactionDeadlineSettings";
import { Menu, MenuFlyout } from "./styled";
import Modal from "components/Modal";
import { Backdrop } from "components/IntroModal/styled";
import {
  IconChart,
  IconContainer,
  IconContainerRight,
  IconSettings,
} from "components/swap/SwapHeader";

export default function SettingsTab({
  autoSlippage,
  chainId,
  isButton = true,
}: {
  autoSlippage: Percent;
  chainId?: number;
  isButton?: boolean;
}) {
  const { chainId: connectedChainId } = useWeb3React();
  const showDeadlineSettings = Boolean(
    chainId && !L2_CHAIN_IDS.includes(chainId)
  );
  const [isSettingModalOpen, setIsSettingModalOpen] = useState(false);

  const node = useRef<HTMLDivElement | null>(null);

  const isSupportedChain = isSupportedChainId(chainId);

  const modalRef = useRef(null);
  const handleCloseModal = () => {
    setIsSettingModalOpen(false)
  };

  useOnClickOutside(modalRef, handleCloseModal);

  return (
    <>
      {isButton ? (
        <IconContainerRight onClick={() => setIsSettingModalOpen(true)}>
          <IconSettings  /> &nbsp;
          <div>Settings</div>
        </IconContainerRight>
      ) : (
        <IconSettings onClick={() => setIsSettingModalOpen(true)}  />
      )}
      {isSettingModalOpen && <Backdrop />}
      <Modal isOpen={isSettingModalOpen} >
        <MenuFlyout ref={modalRef}>
          <div style={{ display: "flex", justifyContent: "space-between", margin:'10px 0px' }}>
            <ThemedText.DeprecatedLargeHeader>
              Settings
            </ThemedText.DeprecatedLargeHeader>
            <CloseIcon
              onClick={() => setIsSettingModalOpen(false)}
              style={{ zIndex: 99 }}
              stroke="#647383"
            />
          </div>
          {/* <RouterPreferenceSettings /> */}
          <MaxSlippageSettings 
          setIsSettingModalOpen={(s:boolean)=>setIsSettingModalOpen(s)}
          autoSlippage={autoSlippage} />
          {showDeadlineSettings && (
            <>
              <Divider />
              <TransactionDeadlineSettings />
            </>
          )}
        </MenuFlyout>
      </Modal>
    </>
  );
}
