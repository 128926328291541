import { useEffect, useState } from "react";
import { useStaking } from "./useStaking";
import { useGetLPTokenIds } from "hooks/locking/useGetLPTokenIds";

export const useGetAllPositionsPendingRewards = (account: any) => {
  const { data: tokenIds } = useGetLPTokenIds(account);
  const [totalBalance, setTotalBalance] = useState(0);
  const [loading, setLoading] = useState(true);
  const [balances, setBalances] = useState<{ [key: string]: number }>({});

  const { staking } = useStaking();
  useEffect(() => {

    const getPendingRewards = async () => {
      if (!tokenIds || !tokenIds.deposits) return;
      let total = 0;
      const balancesData: { [key: string]: number } = {};
      const depositTokens = tokenIds.deposits;
      try {
        if(depositTokens){
          for (const deposit of depositTokens) {
            const tokenId = deposit.tokenId;
            setLoading(true);
            try {
              const balance = await staking.checkPendingRewards(tokenId.toString());
              const balanceValue = parseInt(balance?._hex,16);
              total += balanceValue;
              balancesData[tokenId] = balanceValue;
            } catch (error) {
              console.error(`Error fetching balance for token ID ${tokenId}:`,error)
            }
          }
          setLoading(false);
          setBalances(balancesData);
          setTotalBalance(total);
        }
  

      } catch (error) {
        console.error(`Error useGetAllPositionsPendingRewards`, error);
      }
    };

    getPendingRewards();
  }, [staking, tokenIds]);

  return {
    totalBalance,
    loading,
    balances,
    tokenIds
  };
};
