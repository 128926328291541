import { formatNumber, NumberType } from "@uniswap/conedison/format";
import { formatAndTruncateNumber } from "components/CurrencyInputPanel/FiatValue";
import { MouseoverTooltip } from "components/Tooltip";
import { useUSDPrice } from "hooks/useUSDPrice";
import tryParseCurrencyAmount from "lib/utils/tryParseCurrencyAmount";
import { useCallback, useState } from "react";
import { ThemedText } from "theme";
import { TradePriceProps } from "types";
import {
  formatTransactionAmount,
  priceToPreciseFloat,
} from "utils/formatNumbers";
import {
  StyledPriceContainer,
  StyledGasContainer,
  StyledGasText,
} from "./styleds";
import styled, { keyframes } from "styled-components";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  animation: ${rotate360} 1s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  transform: translateZ(0);
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 2px solid ${({ theme }) => theme.textPrimary};
  background: transparent;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: relative;
  transition: 250ms ease border-color;
  left: -3px;
  top: -3px;
`;

export default function TradePrice({ price }: TradePriceProps) {

  const [showInverted, setShowInverted] = useState<boolean>(false);

  const { baseCurrency, quoteCurrency } = price;

  const { data: usdPrice } = useUSDPrice(
    tryParseCurrencyAmount("1", showInverted ? baseCurrency : quoteCurrency)
  );
  let formattedPrice: string;
  try {
    formattedPrice = showInverted
      ? formatTransactionAmount(priceToPreciseFloat(price))
      : formatTransactionAmount(priceToPreciseFloat(price.invert()));
  } catch (error) {
    formattedPrice = "0";
  }

  const label = showInverted
    ? `${price.quoteCurrency?.symbol}`
    : `${price.baseCurrency?.symbol} `;
  const labelInverted = showInverted
    ? `${price.baseCurrency?.symbol} `
    : `${price.quoteCurrency?.symbol}`;
  const flipPrice = useCallback(
    () => setShowInverted(!showInverted),
    [setShowInverted, showInverted]
  );
  const text = `${
    "1 " + labelInverted + " ≈ " + formattedPrice ?? "-"
  } ${label}`;


  return (
    <StyledPriceContainer
      onClick={(e) => {
        e.stopPropagation(); // dont want this click to affect dropdowns / hovers
        flipPrice();
      }}
      title={text}
    >
      <ThemedText.BodyQuote>{text}</ThemedText.BodyQuote>{" "}
      {/* {usdPrice ? (
        <div>{formatNumber(usdPrice, NumberType.FiatTokenPrice)}</div>
      ) : null} */}
    </StyledPriceContainer>
  );
}
