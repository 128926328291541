import { Currency, CurrencyAmount, Fraction } from '@uniswap/sdk-core';
import { DEFAULT_LOCALE, SupportedLocale } from 'constants/locales';
import JSBI from 'jsbi';
import formatLocaleNumber from 'lib/utils/formatLocaleNumber';

export function formatCurrencyAmount(
  amount: CurrencyAmount<Currency> | undefined| null,
  sigFigs: number,
  locale: SupportedLocale = DEFAULT_LOCALE,
  fixedDecimals?: number
): string {
  if (!amount) {
    return '-';
  }

  // Check if the amount is zero
  if (JSBI.equal(amount.quotient, JSBI.BigInt(0))) {
    return '0';
  }

  // Check if the amount is less than a threshold
  if (amount.divide(amount.decimalScale).lessThan(new Fraction(1, 100000))) {
    return `<${formatLocaleNumber({ number: 0.00001, locale })}`;
  }

  // Format the number with the specified locale, significant figures, and fixed decimals
  let formattedNumber = formatLocaleNumber({ number: amount, locale, sigFigs, fixedDecimals });

  // Limit to 10 digits, appending an ellipsis if exceeded
  // if (formattedNumber.replace(/[^0-9]/g, "").length > 10) {
  //   formattedNumber = formattedNumber.substring(0, 10) + '...';
  // }

  return formattedNumber;
}
