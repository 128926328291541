
import { useWeb3React } from "@web3-react/core";
import {
  IconImage,
  ResponsiveGridThriceTrade,
  SwapColumn,
  TableRow,
  TableText,
  TimestampSpan,
  TokenText,
  TxLink,
} from "./poolStyle";
import OpenIcon from "assets/svg/open.svg";
import { getExplorerPrefix } from "constants/utilsConstants";
import { formatNumberWithSuffix } from "utils/numbers";
import { formatTimestamp } from "utils/pools";

export const TradeRowPool = (props: any) => {
  
  const { swap } = props;
  const formattedTimestamp = formatTimestamp(swap.timestamp);
  const {chainId} = useWeb3React()

  return (
    <>
      <TableRow>
        <ResponsiveGridThriceTrade>
          <SwapColumn>
            <TokenText success={false}>
              <div>{swap.token0}</div>
            </TokenText>
            <TableText style={{ padding: "0.4rem" }}>
              <div>{ formatNumberWithSuffix(swap.amount0) }</div>
            </TableText>
          </SwapColumn>
          <SwapColumn>
            <TokenText success={true}>
              <div>{swap.token1}</div>
            </TokenText>
            <TableText style={{ padding: "0.4rem" }}>
              <div>{formatNumberWithSuffix(swap.amount1)  }</div>
            </TableText>
          </SwapColumn>
          <TableText
            style={{
              display: "flex",
              justifySelf: "end",
              padding: "0.4rem",
            }}
          >
            <div>
              <TimestampSpan>{formattedTimestamp}</TimestampSpan>
              </div>
            <TxLink
              href={`https://${getExplorerPrefix(chainId!)}.io/tx/${swap?.txHash}`}
              target="_blank"
              rel="noreferrer"
            >
              <IconImage
                style={{ marginLeft: "10px" }}
                src={OpenIcon}
                width="20px"
                height="20px"
                rounded={false}
              />
            </TxLink>
          </TableText>
        </ResponsiveGridThriceTrade>
      </TableRow>
    </>
  );
};
