import styled from "styled-components"

export const TextWrapper = styled.span<{
  margin: boolean
  link?: boolean
  fontSize?: string
  adjustSize?: boolean
  textColor?: string
}>`
  margin-left: ${({ margin }) => margin && '4px'};
  font-size: ${({ fontSize }) => fontSize ?? 'inherit'};

  @media screen and (max-width: 600px) {
    font-size: ${({ adjustSize }) => adjustSize && '12px'};
  }
`
