import { Warning, WARNING_LEVEL } from 'constants/tokenSafety'

import { BlockedIcon, WarningContainer, WarningIcon } from './styled'


export default function TokenSafetyIcon({ warning }: { warning: Warning | null }) {
  switch (warning?.level) {
    case WARNING_LEVEL.BLOCKED:
      return (
        <WarningContainer>
          <BlockedIcon data-cy="blocked-icon" strokeWidth={2.5} />
        </WarningContainer>
      )
    case WARNING_LEVEL.UNKNOWN:
      return (
        <WarningContainer>
          <WarningIcon />
        </WarningContainer>
      )
    default:
      return null
  }
}
