import { BLUR_STEPS } from 'constants/common'
import { BlurGroup, BlurLayer } from './styled'


export default function Blur() {
  return (
    <BlurGroup>
      {Array.from(Array(BLUR_STEPS), (_, index) => (
        <BlurLayer index={index} key={`blur-${index}`} />
      ))}
    </BlurGroup>
  )
}
