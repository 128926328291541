import {
  IconImage,
  ResponsiveGridThriceLiquidity,
  SwapColumn,
  TableRow,
  TableText,
  TokenText,
} from "./poolStyle";
import OpenIcon from "assets/svg/open.svg";
import { formatTimestamp } from "utils/pools";
import { formatNumberWithSuffix } from "utils/numbers";
import { getExplorerPrefix } from "constants/utilsConstants";
import { useWeb3React } from "@web3-react/core";

export const LiquidityRow = (props: any) => {
  const { mint } = props;
  const formattedTimestamp = formatTimestamp(mint.timestamp);
  const {chainId} = useWeb3React()

  return (
    <>
      <TableRow>
        <ResponsiveGridThriceLiquidity>
          <SwapColumn>
            <TokenText success={mint.type === "Add" ? true : false}>
              <div>{mint.type}</div>
            </TokenText>
          </SwapColumn>
          <SwapColumn>
            <TableText style={{ padding: "0.4rem" }}>
              <div>{formatNumberWithSuffix(mint.amount0)}</div>
            </TableText>
            <TableText style={{ padding: "0.4rem" }}>
              <div>{mint.token0}</div>
            </TableText>
            /
            <TableText style={{ padding: "0.4rem" }}>
              <div>{formatNumberWithSuffix(mint.amount1)}</div>
            </TableText>
            <TableText style={{ padding: "0.4rem" }}>
              <div>{mint.token1}</div>
            </TableText>
          </SwapColumn>
          <TableText
            style={{
              display: "flex",
              justifySelf: "end",
              padding: "0.4rem",
            }}
          >
            <div>
              <span style={{ fontSize: 10 }}>{formattedTimestamp}</span>
            </div>
            <a
              href={`https://${getExplorerPrefix(chainId!)}.io/tx/${mint?.txHash}`}
              target="_blank"
              rel="noreferrer"
            >
              <IconImage
                style={{ marginLeft: "10px" }}
                src={OpenIcon}
                width="20px"
                height="20px"
                rounded={false}
              />
            </a>
          </TableText>
        </ResponsiveGridThriceLiquidity>
      </TableRow>
    </>
  );
};
