import * as Sentry from "@sentry/react";
import {
  sendAnalyticsEvent,
  Trace,
  TraceEvent,
  useTrace,
} from "@uniswap/analytics";
import {
  BrowserEvent,
  InterfaceElementName,
  InterfaceEventName,
  InterfacePageName,
  InterfaceSectionName,
  SharedEventName,
  SwapEventName,
} from "@uniswap/analytics-events";
import { Currency, CurrencyAmount, Token } from "@uniswap/sdk-core";
import { useWeb3React } from "@web3-react/core";
import { ReactComponent as DoubleArrow } from "assets/svg/double-arrow.svg";
import { useToggleAccountDrawer } from "components/AccountDrawer";
import { sendEvent } from "components/analytics";
import PriceImpactModal from "components/swap/PriceImpactModal";
import PriceImpactWarning from "components/swap/PriceImpactWarning";
import SwapDetailsDropdown from "components/swap/SwapDetailsDropdown";
import SwapInputHeader from "components/swap/SwapInputHeader";
import TokenSafetyModal from "components/TokenSafety/TokenSafetyModal";
import { universalRouterAddress } from "constants/addresses";
import { getChainInfo } from "constants/chainInfo";
import {
  isSupportedChain,
  isSupportedChainLightLink,
  SupportedChainId,
  SupportedChainIdNetwork,
} from "constants/chains";
import useENSAddress from "hooks/useENSAddress";
import { useMaxAmountIn } from "hooks/useMaxAmountIn";
import usePermit2Allowance, { AllowanceState } from "hooks/usePermit2Allowance";
import usePrevious from "hooks/usePrevious";
import { useSwapCallback } from "hooks/useSwapCallback";
import { useUSDPrice } from "hooks/useUSDPrice";
import JSBI from "jsbi";
import { formatSwapQuoteReceivedEventProperties } from "lib/utils/analytics";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { ArrowDown } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Text } from "rebass";
import { InterfaceTrade, TradeState } from "state/routing/types";
import { useTheme } from "styled-components/macro";
import {
  currencyAmountToPreciseFloat,
  formatTransactionAmount,
} from "utils/formatNumbers";
import { didUserReject } from "utils/swapErrorToUserReadableMessage";
import { switchChain } from "utils/switchChain";

import AddressInputPanel from "../../components/AddressInputPanel";
import {
  ButtonError,
  ButtonLight,
  ButtonPrimary,
} from "../../components/Button";
import { GrayCard } from "../../components/Card";
import SwapCurrencyInputPanel from "../../components/CurrencyInputPanel/SwapCurrencyInputPanel";
import { AutoRow } from "../../components/Row";
import confirmPriceImpactWithoutFee from "../../components/swap/confirmPriceImpactWithoutFee";
import ConfirmSwapModal from "../../components/swap/ConfirmSwapModal";
import {
  ArrowWrapper,
  LeftDiv,
  OuterSwapPageWrapper,
  PageWrapperGlobal,
  RightDiv,
  SwapPageWrapper,
  SwapWrapper,
} from "../../components/swap/styleds";
import SwapHeader from "../../components/swap/SwapHeader";
import { getSwapCurrencyId, TOKEN_SHORTHANDS } from "../../constants/tokens";
import { useCurrency, useDefaultActiveTokens } from "../../hooks/Tokens";
import { useIsSwapUnsupported } from "../../hooks/useIsSwapUnsupported";
import useWrapCallback, {
  WrapErrorText,
  WrapType,
} from "../../hooks/useWrapCallback";
import {
  Field,
  replaceSwapState,
  resetSwapState,
} from "../../state/swap/actions";
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
} from "../../state/swap/hooks";
import swapReducer, {
  initialState as initialSwapState,
  SwapState,
} from "../../state/swap/reducer";
import { LinkStyledButton, ThemedText } from "../../theme";
import { computeFiatValuePriceImpact } from "../../utils/computeFiatValuePriceImpact";
import { maxAmountSpend } from "../../utils/maxAmountSpend";
import {
  computeRealizedPriceImpact,
  warningSeverity,
} from "../../utils/prices";
import { supportedChainId } from "../../utils/supportedChainId";
import {
  ArrowContainer,
  OutputSwapSection,
  SwapHeading,
  SwapSection,
} from "./styled";
import { getIsValidSwapQuote, largerPercentValue } from "utils/common";
import { TRADE_STRING } from "constants/common";

import ChartContainer from "components/ChartComponent/ChartContainer";
import { IntroModal } from "components/IntroModal";
import { Backdrop } from "components/IntroModal/styled";
import ms from "ms.macro";
import useWhitelistedTokens from "graphql/thegraph/WhitelistedTokens";
import { useAppDispatch } from "state/hooks";
import { setWhitelistedTokens } from "state/application/reducer";
import { useSpring, animated } from "react-spring";
import {
  PoolActivityWrapper,
  ResponsiveGridFour,
  ResponsiveGridThrice,
  ResponsiveGridThriceShorten,
  TableBody,
  TableContainer,
  TableHead,
  TradesAndLiquidity,
  TradesContainer,
} from "pages/Pool/poolStyle";
import { LoaderV2 } from "components/Icons/LoadingSpinner";
import { ClickableTextPools } from "pages/Pool/styleds";
import { EmptyComponent } from "components/EmptyComponent";
import { TradeRow } from "pages/Pool/TradeRow";
import { LiquidityRow } from "pages/Pool/LiquidityRow";
import { useGetGlobalSwaps } from "hooks/useGetGlobalSwaps";

export default function SwapPage({ className }: { className?: string }) {
  const { chainId: connectedChainId } = useWeb3React();
  const loadedUrlParams = useDefaultsFromURLSearch();

  return (
    <Trace page={InterfacePageName.SWAP_PAGE} shouldLogImpression>
      <>
        <OuterSwapPageWrapper>
          <Swap
            className={className}
            chainId={connectedChainId}
            prefilledState={{
              [Field.INPUT]: {
                currencyId: loadedUrlParams?.[Field.INPUT]?.currencyId,
              },
              [Field.OUTPUT]: {
                currencyId: loadedUrlParams?.[Field.OUTPUT]?.currencyId,
              },
            }}
            disableTokenInputs={!isSupportedChainLightLink(connectedChainId)}
          />
        </OuterSwapPageWrapper>
        {/* <SwitchLocaleLink /> */}
      </>
    </Trace>
  );
}

export function Swap({
  className,
  prefilledState = {},
  chainId,
  onCurrencyChange,
  disableTokenInputs = false,
}: {
  className?: string;
  prefilledState?: Partial<SwapState>;
  chainId?: SupportedChainId;
  onCurrencyChange?: (
    selected: Pick<SwapState, Field.INPUT | Field.OUTPUT>
  ) => void;
  disableTokenInputs?: boolean;
}) {
  const { account, chainId: connectedChainId, connector } = useWeb3React();
  const trace = useTrace();
  const dispatchAPi = useAppDispatch();

  const [isChartVisible, setChartVisible] = useState(false);
  const [isTransactionVisible, setIsTransactionVisible] = useState(false);
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (
      chainId == SupportedChainId.LIGHTLINK_PEGASUS_TESTNET ||
      chainId == SupportedChainId.LIGHTLINK_PHOENIX_MAINNET
    ) {

      setChartVisible(false);
      setIsTransactionVisible(false);
    }
  }, [chainId]);

  const toggleChartVisibility = () => {
    setChartVisible(!isChartVisible);
    if (isTransactionVisible) setIsTransactionVisible(false);
  };

  const toggleTransactionsVisibility = () => {
    if (isChartVisible) setChartVisible(false);
    setIsTransactionVisible(!isTransactionVisible);
  };

  // token warning stuff
  const prefilledInputCurrency = useCurrency(
    prefilledState?.[Field.INPUT]?.currencyId
  );
  const prefilledOutputCurrency = useCurrency(
    prefilledState?.[Field.OUTPUT]?.currencyId
  );

  const [loadedInputCurrency, setLoadedInputCurrency] = useState(
    prefilledInputCurrency
  );
  const [loadedOutputCurrency, setLoadedOutputCurrency] = useState(
    prefilledOutputCurrency
  );

  const [showTrade, setShowTrade] = useState<boolean>(() => {
    const storedValue = localStorage.getItem("showTrade");
    return storedValue ? JSON.parse(storedValue) : false;
  });

  useEffect(() => {
    localStorage.setItem("showTrade", JSON.stringify(showTrade));
  }, [showTrade]);

  useEffect(() => {
    setLoadedInputCurrency(prefilledInputCurrency);
    setLoadedOutputCurrency(prefilledOutputCurrency);
  }, [prefilledInputCurrency, prefilledOutputCurrency]);

  const [dismissTokenWarning, setDismissTokenWarning] =
    useState<boolean>(false);
  const [showPriceImpactModal, setShowPriceImpactModal] =
    useState<boolean>(false);

  const urlLoadedTokens: Token[] = useMemo(
    () =>
      [loadedInputCurrency, loadedOutputCurrency]?.filter(
        (c): c is Token => c?.isToken ?? false
      ) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  );
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true);
  }, []);

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useDefaultActiveTokens(chainId);
  const importTokensNotInDefault = useMemo(
    () =>
      urlLoadedTokens &&
      urlLoadedTokens
        .filter((token: Token) => {
          return !(token.address in defaultTokens);
        })
        .filter((token: Token) => {
          // Any token addresses that are loaded from the shorthands map do not need to show the import URL
          const supported = supportedChainId(chainId);
          if (!supported) return true;
          return !Object.keys(TOKEN_SHORTHANDS).some((shorthand) => {
            const shorthandTokenAddress =
              TOKEN_SHORTHANDS[shorthand][supported];
            return (
              shorthandTokenAddress && shorthandTokenAddress === token.address
            );
          });
        }),
    [chainId, defaultTokens, urlLoadedTokens]
  );

  const theme = useTheme();

  // toggle wallet when disconnected
  const toggleWalletDrawer = useToggleAccountDrawer();

  // Elektrik swap state
  const [state, dispatch] = useReducer(swapReducer, {
    ...initialSwapState,
    ...prefilledState,
  });
  const { typedValue, recipient, independentField } = state;

  const previousConnectedChainId = usePrevious(connectedChainId);
  const previousPrefilledState = usePrevious(prefilledState);
  useEffect(() => {
    const combinedInitialState = { ...initialSwapState, ...prefilledState };
    const chainChanged =
      previousConnectedChainId && previousConnectedChainId !== connectedChainId;
    const prefilledInputChanged =
      previousPrefilledState &&
      previousPrefilledState?.[Field.INPUT]?.currencyId !==
        prefilledState?.[Field.INPUT]?.currencyId;
    const prefilledOutputChanged =
      previousPrefilledState &&
      previousPrefilledState?.[Field.OUTPUT]?.currencyId !==
        prefilledState?.[Field.OUTPUT]?.currencyId;
    if (chainChanged || prefilledInputChanged || prefilledOutputChanged) {
      dispatch(
        replaceSwapState({
          ...initialSwapState,
          ...prefilledState,
          field: combinedInitialState.independentField ?? Field.INPUT,
          inputCurrencyId: combinedInitialState.INPUT.currencyId ?? undefined,
          outputCurrencyId: combinedInitialState.OUTPUT.currencyId ?? undefined,
        })
      );
      // reset local state
      setSwapState({
        tradeToConfirm: undefined,
        swapError: undefined,
        showConfirm: false,
        txHash: undefined,
      });
    }
  }, [
    connectedChainId,
    prefilledState,
    previousConnectedChainId,
    previousPrefilledState,
  ]);

  const {
    trade: { state: tradeState, trade },
    allowedSlippage,
    autoSlippage,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo(state, chainId);

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  );
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE;
  const { address: recipientAddress } = useENSAddress(recipient);
  // useEffect(() => {
  //   dispatch(resetSwapState());
  // }, [connectedChainId]);

  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
            [Field.INPUT]: parsedAmount,
            [Field.OUTPUT]: parsedAmount,
          }
        : {
            [Field.INPUT]:
              independentField === Field.INPUT
                ? parsedAmount
                : trade?.inputAmount,
            [Field.OUTPUT]:
              independentField === Field.OUTPUT
                ? parsedAmount
                : trade?.outputAmount,
          },
    [independentField, parsedAmount, showWrap, trade]
  );

  const fiatValueInput = useUSDPrice(parsedAmounts[Field.INPUT]);
  const fiatValueOutput = useUSDPrice(parsedAmounts[Field.OUTPUT]);
  const showFiatValueInput = Boolean(parsedAmounts[Field.INPUT]);
  const showFiatValueOutput = Boolean(parsedAmounts[Field.OUTPUT]);

  const [routeNotFound, routeIsLoading, routeIsSyncing] = useMemo(
    () => [
      !trade?.swaps,
      TradeState.LOADING === tradeState,
      TradeState.LOADING === tradeState && Boolean(trade),
    ],
    [trade, tradeState]
  );

  const fiatValueTradeInput = useUSDPrice(trade?.inputAmount);
  const fiatValueTradeOutput = useUSDPrice(trade?.outputAmount);
  const stablecoinPriceImpact = useMemo(
    () =>
      routeIsSyncing || !trade
        ? undefined
        : computeFiatValuePriceImpact(
            fiatValueTradeInput.data,
            fiatValueTradeOutput.data
          ),
    [fiatValueTradeInput, fiatValueTradeOutput, routeIsSyncing, trade]
  );

  const {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
  } = useSwapActionHandlers(dispatch);
  const isValid = !swapInputError;
  const dependentField: Field =
    independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value);
      setPercentage(0);
    },
    [onUserInput]
  );
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value);
      setPercentage(0);
    },
    [onUserInput]
  );

  const navigate = useNavigate();
  const swapIsUnsupported = useIsSwapUnsupported(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT]
  );

  // reset if they close warning without tokens in params
  const handleDismissTokenWarning = useCallback(() => {
    setDismissTokenWarning(true);
    navigate("/swap/");
  }, [navigate]);

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapError, txHash }, setSwapState] =
    useState<{
      showConfirm: boolean;
      tradeToConfirm?: InterfaceTrade;
      swapError?: Error;
      txHash?: string;
    }>({
      showConfirm: false,
      tradeToConfirm: undefined,
      swapError: undefined,
      txHash: undefined,
    });

  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: showWrap
        ? parsedAmounts[independentField]?.toExact() ?? ""
        : formatTransactionAmount(
            currencyAmountToPreciseFloat(parsedAmounts[dependentField])
          ),
    }),
    [dependentField, independentField, parsedAmounts, showWrap, typedValue]
  );

  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] &&
      currencies[Field.OUTPUT] &&
      parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  );

  const universal_router_address = universalRouterAddress(chainId);
  const maximumAmountIn = useMaxAmountIn(trade, allowedSlippage);
  const allowance = usePermit2Allowance(
    maximumAmountIn ??
      (parsedAmounts[Field.INPUT]?.currency.isToken
        ? (parsedAmounts[Field.INPUT] as CurrencyAmount<Token>)
        : undefined),
    isSupportedChain(chainId)
      ? universal_router_address
      : "0x6B3ea22C757BbF9C78CcAaa2eD9562b57001720B"
  );

  const maxInputAmount = useMemo(
    () => maxAmountSpend(currencyBalances[Field.INPUT], chainId),
    [currencyBalances]
  );
  const maxOutputAmount = useMemo(
    () => maxAmountSpend(currencyBalances[Field.OUTPUT], chainId),
    [currencyBalances]
  );

  const showMaxButton = Boolean(
    maxInputAmount?.greaterThan(0) &&
      !parsedAmounts[Field.INPUT]?.equalTo(maxInputAmount)
  );
  const swapFiatValues = useMemo(() => {
    return {
      amountIn: fiatValueTradeInput.data,
      amountOut: fiatValueTradeOutput.data,
    };
  }, [fiatValueTradeInput, fiatValueTradeOutput]);

  // the callback to execute the swap
  const { callback: swapCallback } = useSwapCallback(
    trade,
    swapFiatValues,
    allowedSlippage,
    allowance.state === AllowanceState.ALLOWED
      ? allowance.permitSignature
      : undefined
  );

  const handleContinueToReview = useCallback(() => {
    setSwapState({
      tradeToConfirm: trade,
      swapError: undefined,
      showConfirm: true,
      txHash: undefined,
    });
  }, [trade]);

  const handleSwap = useCallback(() => {
    if (!swapCallback) {
      return;
    }
    if (
      stablecoinPriceImpact &&
      !confirmPriceImpactWithoutFee(stablecoinPriceImpact)
    ) {
      return;
    }
    setSwapState((currentState) => ({
      ...currentState,
      swapError: undefined,
      txHash: undefined,
    }));
    swapCallback()
      .then((hash) => {
        setSwapState((currentState) => ({
          ...currentState,
          swapError: undefined,
          txHash: hash,
        }));

        // setTimeout(() => {
        //   setSwapState((currentState) => ({
        //     ...currentState,
        //     showConfirm: false,
        //   }));
        //   onUserInput(Field.INPUT, "");
        // }, 5000);

        sendEvent({
          category: "Swap",
          action: "transaction hash",
          label: hash,
        });
        sendEvent({
          category: "Swap",
          action:
            recipient === null
              ? "Swap w/o Send"
              : (recipientAddress ?? recipient) === account
                ? "Swap w/o Send + recipient"
                : "Swap w/ Send",
          label: [
            TRADE_STRING,
            trade?.inputAmount?.currency?.symbol,
            trade?.outputAmount?.currency?.symbol,
            "MH",
          ].join("/"),
        });
      })
      .catch((error) => {
        if (!didUserReject(error)) {
          Sentry.withScope((scope) => {
            scope.setExtra("confirmedTrade", tradeToConfirm);
            Sentry.captureException(error);
          });
        }
        setSwapState((currentState) => ({
          ...currentState,
          swapError: error,
          txHash: undefined,
        }));
      });
  }, [
    swapCallback,
    stablecoinPriceImpact,
    recipient,
    recipientAddress,
    account,
    trade?.inputAmount?.currency?.symbol,
    trade?.outputAmount?.currency?.symbol,
    tradeToConfirm,
  ]);

  // errors
  const [swapQuoteReceivedDate, setSwapQuoteReceivedDate] = useState<
    Date | undefined
  >();

  // warnings on the greater of fiat value price impact and execution price impact
  const { priceImpactSeverity, largerPriceImpact } = useMemo(() => {
    const marketPriceImpact = trade?.priceImpact
      ? computeRealizedPriceImpact(trade)
      : undefined;
    const largerPriceImpact = largerPercentValue(
      marketPriceImpact,
      stablecoinPriceImpact
    );
    return {
      priceImpactSeverity: warningSeverity(largerPriceImpact),
      largerPriceImpact,
    };
  }, [stablecoinPriceImpact, trade]);

  const handleConfirmDismiss = useCallback(() => {
    setSwapState((currentState) => ({ ...currentState, showConfirm: false }));
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, "");
    }
  }, [onUserInput, txHash]);

  const handleAcceptChanges = useCallback(() => {
    setSwapState((currentState) => ({
      ...currentState,
      tradeToConfirm: trade,
    }));
  }, [trade]);

  const handleInputSelect = useCallback(
    (inputCurrency: Currency) => {
      onCurrencySelection(Field.INPUT, inputCurrency);
      onCurrencyChange?.({
        [Field.INPUT]: {
          currencyId: getSwapCurrencyId(inputCurrency),
        },
        [Field.OUTPUT]: state[Field.OUTPUT],
      });
    },
    [onCurrencyChange, onCurrencySelection, state]
  );

  const handleMaxInput = useCallback(() => {
    maxInputAmount && onUserInput(Field.INPUT, maxInputAmount.toExact());
    sendEvent({
      category: "Swap",
      action: "Max",
    });
  }, [maxInputAmount, onUserInput]);

  const handleMaxOutput = useCallback(() => {
    maxOutputAmount && onUserInput(Field.OUTPUT, maxOutputAmount.toExact());
    sendEvent({
      category: "Swap",
      action: "Max",
    });
  }, [maxOutputAmount, onUserInput]);

  const handlePercentageInput = useCallback(
    (perc: number) => {
      maxInputAmount &&
        onUserInput(
          Field.INPUT,
          maxInputAmount.multiply(perc).divide(100).toExact()
        );
    },
    [maxInputAmount, onUserInput]
  );

  const handlePercentageOutput = useCallback(
    (perc: number) => {
      maxOutputAmount &&
        onUserInput(
          Field.OUTPUT,
          maxOutputAmount.multiply(perc).divide(100).toExact()
        );
    },
    [maxOutputAmount, onUserInput]
  );

  const handleOutputSelect = useCallback(
    (outputCurrency: Currency) => {
      onCurrencySelection(Field.OUTPUT, outputCurrency);
      onCurrencyChange?.({
        [Field.INPUT]: state[Field.INPUT],
        [Field.OUTPUT]: {
          currencyId: getSwapCurrencyId(outputCurrency),
        },
      });
    },
    [onCurrencyChange, onCurrencySelection, state]
  );

  const showPriceImpactWarning = largerPriceImpact && priceImpactSeverity > 3;

  const prevTrade = usePrevious(trade);
  useEffect(() => {
    if (!trade || prevTrade === trade) return;

    setSwapQuoteReceivedDate(new Date());
    sendAnalyticsEvent(SwapEventName.SWAP_QUOTE_RECEIVED, {
      ...formatSwapQuoteReceivedEventProperties(
        trade,
        trade.gasUseEstimateUSD ?? undefined
      ),
      ...trace,
    });
  }, [prevTrade, trade]);

  const showDetailsDropdown = Boolean(
    !showWrap &&
      userHasSpecifiedInputOutput &&
      (trade || routeIsLoading || routeIsSyncing)
  );

  const fadeIn = useSpring({
    opacity: showTrade ? 1 : 0,
  });

  const fadeOut = useSpring({
    opacity: showTrade ? 0 : 1,
  });

  const {
    isLoading,
    error,
    data: whitelistStatusUpdateds,
  } = useWhitelistedTokens(ms`30s`, connectedChainId);
  useEffect(() => {
    if (whitelistStatusUpdateds?.whitelistStatusUpdateds) {
      dispatchAPi(
        setWhitelistedTokens(whitelistStatusUpdateds?.whitelistStatusUpdateds)
      );
    }
  }, [whitelistStatusUpdateds, connectedChainId]);

  const isCurrEth = currencies[Field.INPUT]?.symbol == "ETH";

  const AnimatedLeftDiv = animated(LeftDiv);
  const AnimatedRightDiv = animated(RightDiv);

  const leftDivAnimation = useSpring({
    from: { opacity: 0, transform: "translateX(-50px)" },
    to: { opacity: 1, transform: "translateX(0px)" },
  });

  const rightDivAnimation = useSpring({
    from: { opacity: 0, transform: "translateX(50px)" },
    to: { opacity: 1, transform: "translateX(0px)" },
  });

  const {
    data: swapsData,
    loading: swapsLoading,
    error: swapsError,
  } = useGetGlobalSwaps(chainId, account!);

  function isConnectedChainIdSupported(connectedChainId: any): boolean {
    return Object.values(SupportedChainIdNetwork).includes(connectedChainId);
  }

  // console.log("")
  return (
    <Fragment>
      <SwapHeading>Swap</SwapHeading>

      <PageWrapperGlobal>
        {isChartVisible && (
          <AnimatedLeftDiv style={leftDivAnimation}>
            <ChartContainer
              currencies={currencies}
              trade={trade}
              syncing={routeIsSyncing}
            />
          </AnimatedLeftDiv>
        )}

        {isTransactionVisible && (
          <AnimatedLeftDiv style={leftDivAnimation}>
            <TradesAndLiquidity style={{ maxWidth: "1500px", height: "100%" }}>
              <TradesContainer style={{ width: "100%" }}>
                <PoolActivityWrapper style={{ minHeight: 330 }}>
                  <TableContainer>
                    {swapsLoading ? (
                      <>
                        <LoaderV2 />
                      </>
                    ) : (
                      <>
                        {swapsData?.swaps?.length ? (
                          <>
                            <div
                              style={{ margin: "5px 0px", fontSize: "18px" }}
                            >
                              Swap History
                            </div>
                            <TableHead>
                              <ResponsiveGridFour>
                                <ClickableTextPools onClick={() => null}>
                                  Sold
                                </ClickableTextPools>
                                <ClickableTextPools onClick={() => null}>
                                  Received
                                </ClickableTextPools>
                                <ClickableTextPools onClick={() => null}>
                                  Price
                                </ClickableTextPools>
                                <ClickableTextPools onClick={() => null}>
                                  Date/Time
                                </ClickableTextPools>
                              </ResponsiveGridFour>
                            </TableHead>
                            <TableBody>
                              {swapsData?.swaps
                                // .slice(0, 10)
                                .map((swap: any, i: number) => {
                                  return <TradeRow swap={swap} />;
                                })}
                            </TableBody>
                          </>
                        ) : (
                          <EmptyComponent content="Data not Available" />
                        )}
                      </>
                    )}
                  </TableContainer>
                </PoolActivityWrapper>
              </TradesContainer>
            </TradesAndLiquidity>
          </AnimatedLeftDiv>
        )}

        <IntroModal
          setShowTrade={setShowTrade}
          chainId={chainId}
          showTrade={showTrade}
        />

        {showTrade && (
          <AnimatedRightDiv style={rightDivAnimation}>
            <div style={{ height: "100%" }}>
              <SwapPageWrapper>
                <Fragment>
                  <SwapWrapper
                    chainId={chainId}
                    className={className}
                    id="swap-page"
                  >
                    {isConnectedChainIdSupported(connectedChainId) && (
                      <TokenSafetyModal
                        isOpen={
                          importTokensNotInDefault.length > 0 &&
                          !dismissTokenWarning
                        }
                        tokenAddress={importTokensNotInDefault[0]?.address}
                        secondTokenAddress={
                          importTokensNotInDefault[1]?.address
                        }
                        onContinue={handleConfirmTokenWarning}
                        onCancel={handleDismissTokenWarning}
                        showCancel={true}
                      />
                    )}
                    <SwapHeader
                      autoSlippage={autoSlippage}
                      chainId={chainId}
                      title="Swap"
                      isshowSettingTab={true}
                      isChartVisible={isChartVisible}
                      toggleChartVisibility={toggleChartVisibility}
                      isTransactionVisible={isTransactionVisible}
                      toggleTransactionsVisibility={
                        toggleTransactionsVisibility
                      }
                      currencies={currencies}

                    />
                    {trade && showConfirm && (
                      <>
                        <Backdrop />

                        <ConfirmSwapModal
                          trade={trade}
                          originalTrade={tradeToConfirm}
                          onAcceptChanges={handleAcceptChanges}
                          txHash={txHash}
                          allowedSlippage={allowedSlippage}
                          onConfirm={handleSwap}
                          allowance={allowance}
                          swapError={swapError}
                          onDismiss={handleConfirmDismiss}
                          swapQuoteReceivedDate={swapQuoteReceivedDate}
                          fiatValueInput={fiatValueTradeInput}
                          fiatValueOutput={fiatValueTradeOutput}
                        />
                      </>
                    )}
                    {showPriceImpactModal && showPriceImpactWarning && (
                      <>
                        <Backdrop />
                        <PriceImpactModal
                          priceImpact={largerPriceImpact}
                          onDismiss={() => setShowPriceImpactModal(false)}
                          onContinue={() => {
                            setShowPriceImpactModal(false);
                            handleContinueToReview();
                          }}
                        />
                      </>
                    )}

                    <div style={{ display: "relative" }}>
                      <SwapInputHeader
                        showMaxButton={true}
                        title=""
                        onMax={handleMaxInput}
                        currency={currencies[Field.INPUT] ?? null}
                        handlePercentageInput={handlePercentageInput}
                      />
                      <SwapSection>
                        <Trace
                          section={InterfaceSectionName.CURRENCY_INPUT_PANEL}
                        >
                          <SwapCurrencyInputPanel
                            label={
                              independentField === Field.OUTPUT && !showWrap ? (
                                <div>From (at most)</div>
                              ) : (
                                <div>From</div>
                              )
                            }
                            disabled={disableTokenInputs}
                            value={formattedAmounts[Field.INPUT]}
                            showMaxButton={false}
                            currency={currencies[Field.INPUT] ?? null}
                            onUserInput={handleTypeInput}
                            onMax={handleMaxInput}
                            fiatValue={
                              showFiatValueInput ? fiatValueInput : undefined
                            }
                            onCurrencySelect={handleInputSelect}
                            otherCurrency={currencies[Field.OUTPUT]}
                            showCommonBases
                            id={InterfaceSectionName.CURRENCY_INPUT_PANEL}
                            loading={
                              independentField === Field.OUTPUT &&
                              routeIsSyncing
                            }
                            handlePercentageInput={handlePercentageInput}
                            percentage={percentage}
                            setPercentage={setPercentage}
                          />
                        </Trace>
                      </SwapSection>
                    </div>
                    <ArrowWrapper
                      clickable={isSupportedChain(chainId)}
                      style={{ position: "relative", top: 15 }}
                    >
                      <TraceEvent
                        events={[BrowserEvent.onClick]}
                        name={SwapEventName.SWAP_TOKENS_REVERSED}
                        element={
                          InterfaceElementName.SWAP_TOKENS_REVERSE_ARROW_BUTTON
                        }
                      >
                        <ArrowContainer
                          data-testid="swap-currency-button"
                          onClick={() => {
                            onSwitchTokens();
                          }}
                          color={theme.textPrimary}
                        >
                          <DoubleArrow height={40} width={40} />
                        </ArrowContainer>
                      </TraceEvent>
                    </ArrowWrapper>
                    <div style={{ display: "relative" }}>
                      <SwapInputHeader
                        showMaxButton={true}
                        title=""
                        handlePercentageInput={handlePercentageOutput}
                        onMax={handleMaxOutput}
                        currency={currencies[Field.OUTPUT] ?? null}
                      />
                      <div>
                        <OutputSwapSection>
                          <Trace
                            section={InterfaceSectionName.CURRENCY_OUTPUT_PANEL}
                          >
                            <SwapCurrencyInputPanel
                              value={formattedAmounts[Field.OUTPUT]}
                              disabled={disableTokenInputs}
                              onUserInput={handleTypeOutput}
                              label={
                                independentField === Field.INPUT &&
                                !showWrap ? (
                                  <div>To (at least)</div>
                                ) : (
                                  <div>To</div>
                                )
                              }
                              onMax={handleMaxOutput}
                              showMaxButton={false}
                              hideBalance={false}
                              fiatValue={
                                showFiatValueOutput
                                  ? fiatValueOutput
                                  : undefined
                              }
                              priceImpact={stablecoinPriceImpact}
                              currency={
                                // connectedChainId === 1891
                                // ? isCurrEth
                                //   ? null
                                //   : currencies[Field.OUTPUT] ?? null
                                // :
                                currencies[Field.OUTPUT] ?? null
                              }
                              onCurrencySelect={handleOutputSelect}
                              otherCurrency={currencies[Field.INPUT]}
                              showCommonBases
                              id={InterfaceSectionName.CURRENCY_OUTPUT_PANEL}
                              titleType="output"
                              loading={
                                independentField === Field.INPUT &&
                                routeIsSyncing
                              }
                              handlePercentageInput={handlePercentageOutput}
                              percentage={percentage}
                              setPercentage={setPercentage}
                            />
                          </Trace>
                          {recipient !== null && !showWrap ? (
                            <>
                              <AutoRow
                                justify="space-between"
                                style={{ padding: "0 1rem" }}
                              >
                                <ArrowWrapper clickable={false}>
                                  <ArrowDown
                                    size="16"
                                    color={theme.textSecondary}
                                  />
                                </ArrowWrapper>
                                <LinkStyledButton
                                  id="remove-recipient-button"
                                  onClick={() => onChangeRecipient(null)}
                                >
                                  <div>- Remove recipient</div>
                                </LinkStyledButton>
                              </AutoRow>
                              <AddressInputPanel
                                id="recipient"
                                value={recipient}
                                onChange={onChangeRecipient}
                              />
                            </>
                          ) : null}
                        </OutputSwapSection>
                      </div>
                      {showDetailsDropdown && (
                        <SwapDetailsDropdown
                          trade={trade}
                          syncing={routeIsSyncing}
                          loading={routeIsLoading}
                          allowedSlippage={allowedSlippage}
                        />
                      )}
                      {showPriceImpactWarning && (
                        <PriceImpactWarning priceImpact={largerPriceImpact} />
                      )}
                      <div style={{ marginTop: "35px" }}>
                        <div style={{ marginTop: "15px" }} />
                        {swapIsUnsupported ? (
                          <ButtonPrimary disabled={true}>
                            <ThemedText.DeprecatedMain mb="4px">
                              <div>Unsupported Asset</div>
                            </ThemedText.DeprecatedMain>
                          </ButtonPrimary>
                        ) : !account ? (
                          <TraceEvent
                            events={[BrowserEvent.onClick]}
                            name={
                              InterfaceEventName.CONNECT_WALLET_BUTTON_CLICKED
                            }
                            properties={{
                              received_swap_quote: getIsValidSwapQuote(
                                trade,
                                tradeState,
                                swapInputError
                              ),
                            }}
                            element={InterfaceElementName.CONNECT_WALLET_BUTTON}
                          >
                            <ButtonLight
                              onClick={toggleWalletDrawer}
                              fontWeight={600}
                              fontSize={15}
                              style={{
                                background: `linear-gradient(95.72deg, #a2eeff 13.1%, #029bf0 98.51%)`,
                                color: "#000",
                                border: "none",
                              }}
                            >
                              <div>Connect wallet</div>
                            </ButtonLight>
                          </TraceEvent>
                        ) : chainId && chainId !== connectedChainId ? (
                          <ButtonPrimary
                            onClick={async () => {
                              try {
                                await switchChain(connector, chainId);
                              } catch (error) {
                                if (didUserReject(error)) {
                                } else {
                                  throw error;
                                }
                              }
                            }}
                          >
                            Connect to {getChainInfo(chainId)?.label}
                          </ButtonPrimary>
                        ) : showWrap ? (
                          <ButtonPrimary
                            disabled={Boolean(wrapInputError)}
                            onClick={onWrap}
                            fontWeight={600}
                            data-testid="wrap-button"
                            fontSize={15}
                          >
                            {wrapInputError ? (
                              <WrapErrorText wrapInputError={wrapInputError} />
                            ) : wrapType === WrapType.WRAP ? (
                              <div>Wrap</div>
                            ) : wrapType === WrapType.UNWRAP ? (
                              <div>Unwrap</div>
                            ) : null}
                          </ButtonPrimary>
                        ) : routeNotFound &&
                          userHasSpecifiedInputOutput &&
                          !routeIsLoading &&
                          !routeIsSyncing ? (
                          <GrayCard
                            style={{
                              textAlign: "center",
                              backgroundColor:
                                "linear-gradient(91deg, #19272A 7.28%, #091E29 89.82%) ",
                              borderRadius: "10px",
                              color: "#68D0FA",
                            }}
                          >
                            <ThemedText.DeprecatedMain mb="4px" fontSize="14px">
                              <div>Insufficient liquidity for this trade.</div>
                            </ThemedText.DeprecatedMain>
                          </GrayCard>
                        ) : (
                          <TraceEvent
                            events={[BrowserEvent.onClick]}
                            name={SharedEventName.ELEMENT_CLICKED}
                            element={InterfaceElementName.SWAP_BUTTON}
                          >
                            <ButtonError
                              onClick={() => {
                                showPriceImpactWarning
                                  ? setShowPriceImpactModal(true)
                                  : handleContinueToReview();
                              }}
                              id="swap-button"
                              data-testid="swap-button"
                              disabled={
                                !isValid || routeIsSyncing || routeIsLoading
                              }
                              error={
                                isValid &&
                                priceImpactSeverity > 2 &&
                                allowance.state === AllowanceState.ALLOWED
                              }
                              style={{
                                background:
                                  !isValid || routeIsSyncing || routeIsLoading
                                    ? "linear-gradient(91deg, #19272A 0.97%, #0A1E29 95.57%, #091E29 100.23%)"
                                    : priceImpactSeverity > 2
                                      ? "#dd5e5e"
                                      : " linear-gradient(91deg, #A1EEFF 7.28%, #029AF0 89.82%)",
                                borderRadius: "10px",
                                color: "#68D0FA!important",
                                opacity: "1",
                              }}
                            >
                              {!isValid || routeIsSyncing || routeIsLoading ? (
                                <Text
                                  fontSize={15}
                                  fontWeight={600}
                                  style={{
                                    color: "#68D0FA",
                                    background:
                                      " linear-gradient(191deg, #A1EEFF 5.4%, #029AF0 92.07%)",
                                    backgroundClip: "text",
                                    WebkitBackgroundClip: "text",
                                    WebkitTextFillColor: "transparent",
                                  }}
                                >
                                  {swapInputError ? (
                                    swapInputError
                                  ) : routeIsSyncing || routeIsLoading ? (
                                    <div>Swap</div>
                                  ) : priceImpactSeverity > 2 ? (
                                    <div>Swap Anyway</div>
                                  ) : (
                                    <div>Swap</div>
                                  )}
                                </Text>
                              ) : (
                                <Text
                                  fontSize={15}
                                  fontWeight={600}
                                  style={{
                                    color: "#000000",
                                  }}
                                >
                                  {swapInputError ? (
                                    swapInputError
                                  ) : routeIsSyncing || routeIsLoading ? (
                                    <div>Swap</div>
                                  ) : priceImpactSeverity > 2 ? (
                                    <div>Swap Anyway</div>
                                  ) : (
                                    <div>Swap</div>
                                  )}
                                </Text>
                              )}
                            </ButtonError>
                          </TraceEvent>
                        )}
                      </div>
                      {/* </AutoColumn> */}
                    </div>
                  </SwapWrapper>
                </Fragment>
              </SwapPageWrapper>
            </div>
          </AnimatedRightDiv>
        )}
      </PageWrapperGlobal>
    </Fragment>
  );
}
