
import CurrencyLogo from '../Logo/CurrencyLogo'
import { CoveredLogoWapper, HigherLogoWrapper, Wrapper } from './styled'
import { DoubleCurrencyLogoProps } from 'types'

export default function DoubleCurrencyLogo({
  currency0,
  currency1,
  size = 16, 
  margin = false,
}: DoubleCurrencyLogoProps) {
  return (
    <Wrapper sizeraw={size} margin={margin}  >
      {currency0 && (
        <HigherLogoWrapper sizeraw={size}>
          <CurrencyLogo hideL2Icon currency={currency0} size={size.toString() + 'px'} />
        </HigherLogoWrapper>
      )}
      {currency1 && (
        <CoveredLogoWapper sizeraw={size}  >
          <CurrencyLogo hideL2Icon currency={currency1} size={size.toString() + 'px'} />
        </CoveredLogoWapper>
      )}
    </Wrapper>
  )
}
