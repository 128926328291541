import { TraceEvent } from "@uniswap/analytics";
import {
  BrowserEvent,
  InterfaceElementName,
  InterfaceEventName,
} from "@uniswap/analytics-events";
import { Currency, CurrencyAmount, Token } from "@uniswap/sdk-core";
import { useWeb3React } from "@web3-react/core";
import Loader from "components/Icons/LoadingSpinner";
import TokenSafetyIcon from "components/TokenSafety/TokenSafetyIcon";
import { checkWarning } from "constants/tokenSafety";
import { CSSProperties, MutableRefObject, useCallback, useMemo } from "react";
import { FixedSizeList } from "react-window";
import { useIsUserAddedToken } from "../../../hooks/Tokens";
import { useCurrencyBalance } from "../../../state/connection/hooks";
import { WrappedTokenInfo } from "../../../state/lists/wrappedTokenInfo";
import { ThemedText } from "../../../theme";
import Column, { AutoColumn } from "../../Column";
import CurrencyLogo from "../../Logo/CurrencyLogo";
import Row, { RowFixed } from "../../Row";
import { MouseoverTooltip } from "../../Tooltip";
import { LoadingRows, MenuItem } from "../styleds";
import { scrollbarStyle } from "./index.css";
import { useLocation } from "react-router-dom";
import {
  CheckIcon,
  CurrencyName,
  ListWrapper,
  StyledBalanceText,
  Tag,
  TagContainer,
  WarningContainer,
} from "./styled";
import { useAppSelector } from "state/hooks";
import { FilterWrapper } from "constants/addresses";
import { formatAmountLessThan, formatNumberWithSuffix } from "utils/numbers";
import { getELTKAddress, SupportedChainId } from "constants/chains";

function currencyKey(currency: Currency): string {
  return currency.isToken ? currency.address : "ETHER";
}
function Balance({ balance }: { balance: CurrencyAmount<Currency> }) {
  return (
    <StyledBalanceText title={balance.toExact()}>
      {/* {formatAmountLessThan(Number(balance.toSignificant(4)))} */}
      {formatNumberWithSuffix(Number(balance.toSignificant(4)))}
    </StyledBalanceText>
  );
}

function TokenTags({ currency }: { currency: Currency }) {
  if (!(currency instanceof WrappedTokenInfo)) {
    return null;
  }

  const tags = currency.tags;
  if (!tags || tags.length === 0) return <span />;

  const tag = tags[0];

  return (
    <TagContainer>
      <MouseoverTooltip text={tag.description}>
        <Tag key={tag.id}>{tag.name}</Tag>
      </MouseoverTooltip>
      {tags.length > 1 ? (
        <MouseoverTooltip
          text={tags
            .slice(1)
            .map(({ name, description }) => `${name}: ${description}`)
            .join("; \n")}
        >
          <Tag>...</Tag>
        </MouseoverTooltip>
      ) : null}
    </TagContainer>
  );
}

export function CurrencyRow({
  currency,
  onSelect,
  isSelected,
  otherSelected,
  style,
  showCurrencyAmount,
  eventProperties,
}: {
  currency: Currency;
  onSelect: (hasWarning: boolean) => void;
  isSelected: boolean;
  otherSelected: boolean;
  style?: CSSProperties;
  showCurrencyAmount?: boolean;
  eventProperties: Record<string, unknown>;
}) {
  const { account } = useWeb3React();
  const key = currencyKey(currency);
  const customAdded = useIsUserAddedToken(currency);
  const balance = useCurrencyBalance(account ?? undefined, currency);
  const warning = currency.isNative ? null : checkWarning(currency.address);
  const isBlockedToken = !!warning && !warning.canProceed;
  const blockedTokenOpacity = "0.6";
  // only show add or remove buttons if not on selected list
  const { pathname } = useLocation();
  return (
    <TraceEvent
      events={[BrowserEvent.onClick, BrowserEvent.onKeyPress]}
      name={InterfaceEventName.TOKEN_SELECTED}
      properties={{ is_imported_by_user: customAdded, ...eventProperties }}
      element={InterfaceElementName.TOKEN_SELECTOR_ROW}
    >
      {/* change customAdded */}
      <MenuItem
        tabIndex={0}
        style={style}
        className={`token-item-${key}`}
        onKeyPress={(e) =>
          !isSelected && e.key === "Enter" ? onSelect(!!warning) : null
        }
        onClick={() => (isSelected ? null : onSelect(!!warning))}
        disabled={isSelected || (pathname === "/limit" && customAdded)}
        selected={otherSelected}
        dim={isBlockedToken}
      >
        <Column>
          <CurrencyLogo
            currency={currency}
            size="36px"
            style={{ opacity: isBlockedToken ? blockedTokenOpacity : "1" }}
          />
        </Column>
        <AutoColumn
          style={{ opacity: isBlockedToken ? blockedTokenOpacity : "1" }}
        >
          <Row>
            <CurrencyName title={currency.name}>{currency.name}</CurrencyName>
            <WarningContainer>
              <TokenSafetyIcon warning={warning} />
            </WarningContainer>
          </Row>
          <ThemedText.DeprecatedDarkGray
            ml="0px"
            fontSize="12px"
            fontWeight={300}
          >
            {currency.symbol}
          </ThemedText.DeprecatedDarkGray>
        </AutoColumn>
        <Column>
          <RowFixed style={{ justifySelf: "flex-end" }}>
            <TokenTags currency={currency} />
          </RowFixed>
        </Column>
        {showCurrencyAmount ? (
          <RowFixed style={{ justifySelf: "flex-end" }}>
            {balance ? (
              <Balance balance={balance} />
            ) : account ? (
              <Loader />
            ) : null}
            {isSelected && <CheckIcon />}
          </RowFixed>
        ) : (
          isSelected && (
            <RowFixed style={{ justifySelf: "flex-end" }}>
              <CheckIcon />
            </RowFixed>
          )
        )}
      </MenuItem>
    </TraceEvent>
  );
}

interface TokenRowProps {
  data: Array<Currency>;
  index: number;
  style: CSSProperties;
}

export const formatAnalyticsEventProperties = (
  token: Token,
  index: number,
  data: any[],
  searchQuery: string,
  isAddressSearch: string | false
) => ({
  token_symbol: token?.symbol,
  token_address: token?.address,
  is_suggested_token: false,
  is_selected_from_list: true,
  scroll_position: "",
  token_list_index: index,
  token_list_length: data.length,
  ...(isAddressSearch === false
    ? { search_token_symbol_input: searchQuery }
    : { search_token_address_input: isAddressSearch }),
});

const LoadingRow = () => (
  <LoadingRows data-testid="loading-rows">
    <div />
    <div />
    <div />
  </LoadingRows>
);

export default function CurrencyList({
  height,
  currencies,
  otherListTokens,
  selectedCurrency,
  onCurrencySelect,
  otherCurrency,
  fixedListRef,
  showCurrencyAmount,
  isLoading,
  searchQuery,
  isAddressSearch,
}: {
  height: number;
  currencies: Currency[];
  otherListTokens?: WrappedTokenInfo[];
  selectedCurrency?: Currency | null;
  onCurrencySelect: (currency: Currency, hasWarning?: boolean) => void;
  otherCurrency?: Currency | null;
  fixedListRef?: MutableRefObject<FixedSizeList | undefined>;
  showCurrencyAmount?: boolean;
  isLoading: boolean;
  searchQuery: string;
  isAddressSearch: string | false;
}) {
  const { chainId } = useWeb3React();
  const { pathname } = useLocation();

  const isLimitUnsupportedMainnet = chainId == 1890 && pathname == "/limit";
  const isLimitUnsupportedTestnet = chainId == 1891 && pathname == "/limit";

  const isSwap = pathname == "/swap";
  const isTwap = pathname == "/twap"

  const whitelistedTokens = useAppSelector(
    ({ application }) =>
      application?.whitelistedTokens.map(
        (item: any) => item && item?.token && item?.token?.toLowerCase()
      )
  );

  const itemData: Currency[] = useMemo(() => {
    if (otherListTokens && otherListTokens?.length > 0) {
      return [...currencies, ...otherListTokens];
    }
    return currencies;
  }, [currencies, otherListTokens]);

  let index = itemData?.findIndex(
    (obj: any) =>
      obj.wrapped?.address.toLowerCase() ==
      getELTKAddress(chainId).toLowerCase()
  );

  let usdcIndex = itemData?.findIndex(
    (obj: any) => obj.wrapped?.symbol === "USDC"
  );

  let usdtIndex = itemData?.findIndex(
    (obj: any) => obj.wrapped?.symbol === "USDT"
  );

  let lleIndex = itemData?.findIndex(
    (obj: any) => obj.wrapped?.symbol === "LL.e" || obj.wrapped.symbol === 'LL'
  );

  if (index !== -1) {
    const [objToMove] = itemData?.splice(index, 1);
    itemData.unshift(objToMove);
  }

  if (lleIndex !== -1 && lleIndex < itemData.length) {
    const [objToMove] = itemData?.splice(lleIndex, 1);

    const targetIndex = lleIndex < 3 ? 3 - 1 : 3;

    itemData.splice(targetIndex, 0, objToMove);
  }

  if (usdcIndex !== -1 && usdcIndex < itemData?.length) {
    const [objToMove] = itemData?.splice(usdcIndex, 1);

    const targetIndex = usdcIndex < 4 ? 4 - 1 : 4;

    itemData.splice(targetIndex, 0, objToMove);
  }


  if (usdtIndex !== -1 && usdtIndex < itemData?.length) {
    const [objToMove] = itemData?.splice(usdtIndex, 1);

    const targetIndex = usdtIndex < 5 ? 5 - 1 : 5;

    itemData.splice(targetIndex, 0, objToMove);
  }



  const itemDataWhitelistedLimit =
    itemData &&
    itemData.filter((item) => {
      if (item && item?.symbol === "ETH") {
        return false;
      }
      return true;
    });

  const Row = useCallback(
    function TokenRow({ data, index, style }: TokenRowProps) {
      const row: Currency = data[index];

      const currency = row;

      const isSelected = Boolean(
        currency && selectedCurrency && selectedCurrency.equals(currency)
      );
      const otherSelected = Boolean(
        currency && otherCurrency && otherCurrency.equals(currency)
      );
      const handleSelect = (hasWarning: boolean) =>
        currency && onCurrencySelect(currency, hasWarning);

      const token = currency?.wrapped;

      if (isLoading) {
        return LoadingRow();
      } else if (currency) {
        return (
          <CurrencyRow
            style={style}
            currency={currency}
            isSelected={isSelected}
            onSelect={handleSelect}
            otherSelected={otherSelected}
            showCurrencyAmount={showCurrencyAmount}
            eventProperties={formatAnalyticsEventProperties(
              token,
              index,
              data,
              searchQuery,
              isAddressSearch
            )}
          />
        );
      } else {
        return null;
      }
    },
    [
      onCurrencySelect,
      otherCurrency,
      selectedCurrency,
      showCurrencyAmount,
      isLoading,
      isAddressSearch,
      searchQuery,
    ]
  );

  const itemKey = useCallback((index: number, data: typeof itemData) => {
    const currency = data[index];
    return currencyKey(currency);
  }, []);

  return (
    <ListWrapper data-testid="currency-list-wrapper">
      {isLoading ? (
        <FixedSizeList
          className={scrollbarStyle}
          height={height}
          ref={fixedListRef as any}
          width="100%"
          itemData={[]}
          itemCount={10}
          itemSize={56}
        >
          {LoadingRow}
        </FixedSizeList>
      ) : (
        <FixedSizeList
          className={scrollbarStyle}
          height={height}
          ref={fixedListRef as any}
          width="100%"
          itemData={ isTwap ? itemDataWhitelistedLimit :
            isLimitUnsupportedMainnet 
              ? itemDataWhitelistedLimit
              : isSwap
                ? itemData
                : isLimitUnsupportedTestnet
                  ? itemDataWhitelistedLimit
                  : itemData
          }
          itemCount={ isTwap ? itemDataWhitelistedLimit.length :
            isLimitUnsupportedMainnet
              ? itemDataWhitelistedLimit.length
              : isSwap
                ? itemData.length
                : isLimitUnsupportedTestnet
                  ? itemDataWhitelistedLimit.length
                  : itemData.length
          }
          itemSize={56}
          itemKey={itemKey}
        >
          {Row}
        </FixedSizeList>
      )}
    </ListWrapper>
  );
}
