import styled from 'styled-components/macro'

import Row from '../Row'

export const IconInput = styled.input`
  width: 100%;
	height: 100%;
  display: flex;
  flex: 1;
  font-size: 12px;
  border: 0;
  outline: none;
  background: transparent;
  text-align: left;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  ::placeholder {
    color: #797F80;
  }
`

export const IconInputContainer = styled(Row)<{ error?: boolean}>`
  padding: 8px 16px;
  border-radius: 10px;
	background: #121B1E;
	border: 1px solid #121B1E;
	/* min-width: 280px; */
  margin: 0.5rem;
  width: auto;
	height: 38px;
  input {
    color: ${({ theme, error }) => (error ? theme.accentFailure : theme.textPrimary)};
  }
  border: 1px solid #121B1E;
  ${({ theme, error }) =>
    error
      ? `
        border: 1px solid ${theme.accentFailure};
        :focus-within {
          border-color: ${theme.accentFailureSoft};
        }
      `
      : `
        border: 1px solid #121B1E;
        :focus-within {
          border-color: ${theme.accentActiveSoft};
        }
      `}
`
