import { useTokenWarningColor, useTokenWarningTextColor } from 'hooks/useTokenWarningColor'
import { AlertTriangle, Slash } from 'react-feather'

import { TokenWarningLabelProps } from 'types/tokens'
import { SafetyLabel, Title } from './styled'



export default function TokenSafetyLabel({ level, canProceed, children }: TokenWarningLabelProps) {
  return (
    <SafetyLabel color={useTokenWarningTextColor(level)} backgroundColor={useTokenWarningColor(level)}>
      <Title marginRight="5px">{children}</Title>
      {canProceed ? <AlertTriangle strokeWidth={2.5} size="14px" /> : <Slash strokeWidth={2.5} size="14px" />}
    </SafetyLabel>
  )
}
