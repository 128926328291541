import { ButtonPrimary } from "components/Button";
import { AutoColumn } from "components/Column";
import { LoadingRows as BaseLoadingRows } from "components/Loader/styled";
import { RowBetween, RowFixed } from "components/Row";
import {
  BLUR_FADE,
  BLUR_STEPS,
  MAX_STRENGTH,
  NAV_HEIGHT,
} from "constants/common";
import { AlertTriangle, Inbox } from "react-feather";
import { Text } from "rebass";
import styled, { css } from "styled-components/macro";

export const Wrapper = styled.div`
  position: relative;
  padding: 20px;
`;

export const PositionHeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
  @media (max-width: 720px) {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
  }
`;

export const GradientText = styled.span`
  background: linear-gradient(191deg, #a1eeff 5.4%, #029af0 92.07%);
  font-family: Inter;
  font-size: 10px;
  font-weight: 600;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  cursor: pointer;
`;

export const GradientGreen = styled.span`
  background: linear-gradient(270deg, #23ff95 12.87%, #03cd54 91.25%);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  cursor: pointer;
`;

export const HeaderTitle = styled.div`
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 45px */
  margin-bottom: 14px;
`;

export const HeaderSubTitle = styled.div`
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
  margin-bottom: 26px;
`;

export const HeaderTitleSecondary = styled.div`
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 33px */
  margin-bottom: 8px;
`;

export const HeaderSubTitleSecondary = styled.div`
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
  margin-bottom: 24px;
`;

export const ClickableText = styled(Text)`
  font-size: 10px !important;
  :hover {
    cursor: pointer;
  }
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  justify-content: flex-start;
  /* margin: 10px 5px 10px 10px !important; */
  text-align: left;
  color: #868e9b;
`;
export const ClickableTextEmission = styled(Text)`
  font-size: 12px !important;
  :hover {
    cursor: pointer;
  }
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  justify-content: flex-start;
  /* margin: 10px 5px 10px 10px !important; */
  text-align: left;
  color: #868e9b;
`;
export const ClickableTextPools = styled(Text)`
  font-size: 10px !important;
  :hover {
    cursor: pointer;
  }
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  justify-content: flex-start;
  margin: 10px 5px 10px 0px !important;
  text-align: left;
  color: #868e9b;
`;

export const ClickableText2 = styled(Text)`
  font-size: 10px !important;
  :hover {
    cursor: pointer;
  }
  display: flex;
  align-items: center;
  margin-bottom: 8px !important;
  font-size: 10px;
  font-weight: 400;
  justify-content: flex-start;
  text-align: left;
  color: #868e9b;

  @media (max-width: 1200px) {
    font-size: 12px;
    text-align: center;
    justify-content: center;
    margin: 10px auto !important;
  }
`;
export const MaxButton = styled.button<{ width: string }>`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.deprecated_primary5};
  border: 1px solid ${({ theme }) => theme.deprecated_primary5};
  border-radius: 0.5rem;
  font-size: 1rem;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    padding: 0.25rem 0.5rem;
  `};
  font-weight: 500;
  cursor: pointer;
  margin: 0.25rem;
  overflow: hidden;
  color: ${({ theme }) => theme.accentAction};
  :hover {
    border: 1px solid ${({ theme }) => theme.accentAction};
  }
  :focus {
    background: var(
      --Gradient-1,
      linear-gradient(191deg, #a1eeff 5.4%, #029af0 92.07%)
    );
    border: 1px solid ${({ theme }) => theme.accentAction};
    color: #091012;
    outline: none;
  }
`;

export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: ".";
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: ".";
    }
    33% {
      content: "..";
    }
    66% {
      content: "...";
    }
  }
`;

export const LoadingRows = styled(BaseLoadingRows)`
  padding-top: 36px;
  min-width: 75%;
  max-width: 960px;
  grid-column-gap: 0.5em;
  grid-row-gap: 0.8em;
  grid-template-columns: repeat(3, 1fr);
  padding: 8px;

  & > div:nth-child(4n + 1) {
    grid-column: 1 / 3;
  }
  & > div:nth-child(4n) {
    grid-column: 3 / 4;
    margin-bottom: 2em;
  }
`;

export const MainContentWrapperPositions = styled.main`
  padding: 0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

export const PositionsTitle = styled.main`
  color: #fff;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 45px */
  margin: 20px 0;
  @media (max-width: 720px) {
    margin: 20px auto;
  }
`;

export const MainContentWrapper = styled.main`
  background: ${({ theme }) => theme.backgroundSurface};
  padding: 0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow:
    0px 0px 1px rgba(0, 0, 0, 0.01),
    0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
`;
export const ConnectWalletWrapper = styled.main`
  width: 100%;
  background: ${({ theme }) => theme.backgroundSurface};
  padding: 0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow:
    0px 0px 1px rgba(0, 0, 0, 0.01),
    0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
`;

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`;
export const NetworkIcon = styled(AlertTriangle)`
  ${IconStyle}
`;

export const InboxIcon = styled(Inbox)`
  ${IconStyle}
`;

export const PoolPageWrapper = styled(AutoColumn)`
  padding: 50px 65px 8px 65px;
  width: 100%;
  max-width: 1600px;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    max-width: 500px;
  `};

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.md}px`}) {
    padding-top: 48px;
  }

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.sm}px`}) {
    padding-top: 20px;
  }
  margin-bottom: 100px;
`;

export const TitleRow = styled(RowBetween)`
  color: ${({ theme }) => theme.textSecondary};
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
  `};
`;
export const ButtonRow = styled(RowFixed)`
  & > *:not(:last-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    flex-direction: row-reverse;
  `};
`;
export const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 300px;
  min-height: 25vh;
`;

export const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  border-radius: 10px;
  font-size: 16px;
  padding: 8px 20px;
  width: fit-content;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex: 1 1 auto;
    width: 100%;
  `};
`;

export const PoolMenuItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 500;
`;

export const HeaderWrapper = styled.div`
  max-width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const UserPoolStats = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 710px) {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;
  }
`;
export const StatsContainer = styled.div<{ $isDlp?: boolean }>`
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 27px 33px 30px 33px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  --border-width: 2px;
  --border-radius: 12px;
  background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
  border: 0;

  border-radius: var(--border-radius);
  z-index: 1;
  @media (max-width: 710px) {
    margin: 0;
  }
  &::before {
    content: "";
    display: block;
    height: calc(100% - (var(--border-width) * 2));
    width: calc(100% - (var(--border-width) * 2));
    background: ${({ theme }) => theme.backgroundSurface};
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: calc(var(--border-radius) - var(--border-width));
    z-index: -1;
  }
`;

export const RewardsBottomContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`;

export const DlpTextWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PoolsSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 1rem;
`;

export const PoolTableWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding: 5px 2px;
  --border-width: 2px;
  --border-radius: 12px;
  background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
  border: 0;

  border-radius: var(--border-radius);
  z-index: 1;

  &::before {
    content: "";
    display: block;
    height: calc(100% - (var(--border-width) * 2));
    width: calc(100% - (var(--border-width) * 2));
    background: ${({ theme }) => theme.backgroundSurface};
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: calc(var(--border-radius) - var(--border-width));
    z-index: -1;
  }
`;
export const PoolTableWrapperPool = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding: 9px 2px;
  --border-width: 2px;
  --border-radius: 12px;
  background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
  border: 0;

  border-radius: var(--border-radius);
  z-index: 1;

  &::before {
    content: "";
    display: block;
    height: calc(100% - (var(--border-width) * 2));
    width: calc(100% - (var(--border-width) * 2));
    background: ${({ theme }) => theme.backgroundSurface};
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: calc(var(--border-radius) - var(--border-width));
    z-index: -1;
  }
`;



export const PoolTableHeader = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 750px) {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
  }
`;

export const TabsWrapper = styled.div`
  width: 100% !important;
  height: auto;
  display: flex;
  margin-left: 7px;
  align-items: center;
  justify-content: flex-start;
  /* padding: 1rem 2rem; */
  @media (max-width: 750px) {
    justify-content: center;
  }
`;

export const PoolFilterWrapper = styled.div`
  width: 100% !important;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 750px) {
    display: grid;
    justify-content: center;
  }
`;

export const PoolTableContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const TableHead = styled.div`
  width: 100%;
  background: #121b1e;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
`;

export const TableBody = styled.div`
  width: 100%;
  height: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

export const TableText = styled(Text)`
  :hover {
    cursor: pointer;
  }
  text-align: left;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 22.5px */
  color: ${({ theme }) => theme.white};
`;

export const TableRow = styled.div`
  width: 100%;
  border-bottom: 1px solid #22282a;
  /* padding: auto 1rem; */
  &:last-child {
    border-bottom: none;
  }
`;

export const PoolNameWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5px;
`;

export const PoolNameWrapperPool = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5px;

`;

export const LogoOutlineImage = styled.img``;

export const PaginationWrapper = styled.section`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  margin-top: 1rem;
`;

export const NumbersContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 150px;
`;

export const PageNumber = styled.div<{ selected?: boolean }>`
  width: 20px;
  height: 20px;
  padding: 1rem;
  margin: 0.3rem;
  background: ${(props) =>
    props.selected
      ? "linear-gradient(191deg, #284149 5.4%, #173747 92.07%)"
      : "transparent"};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  span {
    background: ${(props) =>
      props.selected
        ? "linear-gradient(191deg, rgba(161, 238, 255, 1) 5.4%, rgba(2, 154, 240, 1) 92.07%)"
        : "#fff"};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const LpCardWrapper = styled.div<{ url: string }>` 
  height: auto;
  position: relative;

  border-radius: 12px;
  min-height: 220px;
  padding: 2rem;
  margin-top: 1rem;
   

 
  overflow: hidden;
   padding: 1rem 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  --border-width: 2px;
  --border-radius: 12px;
  background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
  border: 0; 
  
  margin: 10px;
  border-radius: var(--border-radius);
  z-index: 1;
  &::before {
    content: "";
    display: block;
    height: calc(
      100% - (var(--border-width) * 2)
    );  
    width: calc(
      100% - (var(--border-width) * 2)
    );  
    background-image: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 47%, rgba(0,0,0,0) 100%), url(${({
      url,
    }) => url});
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: calc(var(--border-radius) - var(--border-width));
    z-index: -1;
 





`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TableLoaderContainer = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TokenLogoWrapper = styled.div`
  magin-left: 8px;
  display: flex;
  align-items: center;
`;

export const TokenImage = styled.img`
  width: 40px;
  height: 40px;
  padding: 12px;
  background: #192427;
  border-radius: 50%;
`;
export const ELektrikImage = styled.div`
  width: 30px;
  height: 30px;
  padding: 10px;
  background: #192427;
  border-radius: 50%;
  border: 7px solid #131c1f;
`;

export const TokenImage2 = styled.img`
  width: 56px;
  height: 56px;
  padding: 10px;
  background: #192427;
  border-radius: 50%;
  border: 7px solid #131c1f;
`;

export const PoolCardWrapper = styled.div<{ url: string }>`
  width: 100%;
  height: 100%;
  position: relative;
  border-top: 1px solid #22282a;
  background-image: linear-gradient(
      90deg,
      #091012 0%,
      #091012 20%,
      rgba(0, 0, 0, 0) 100%
    ),
    url(${({ url }) => url});
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover; /* Stretch the background image while maintaining aspect ratio */
  border-radius: 0px 0px 12px 12px;
  min-height: 220px;
  padding: 2rem 0px 35px 47px;
  margin-top: 1rem;
`;

export const ResponsiveGridPoolHead = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  grid-template-columns: 2fr 1.3fr 1fr 1fr 1fr 0.9fr 1fr 1fr;

  @media screen and (max-width: 940px) {
    grid-template-columns: 1.5fr 2fr 1.5fr repeat(3, 1fr);
    & > *:nth-child(5) {
      display: none;
    }
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 2.2fr 2fr 1.5fr;
    & > *:nth-child(5) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
    & > *:nth-child(4) {
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 2fr 1.5fr;
    & > *:nth-child(5) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
    & > *:nth-child(4) {
      display: none;
    }
    & > *:nth-child(2) {
      display: none;
    }
    & > *:nth-child(6) {
      display: none;
    }
  }
`;

export const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  grid-template-columns: 2fr 1fr 1fr repeat(3, 1fr) 1fr 1fr 1fr;
  padding: 1rem 0px;

  @media screen and (max-width: 940px) {
    grid-template-columns: 1.5fr 2fr 1.5fr repeat(3, 1fr);
    & > *:nth-child(5) {
      display: none;
    }
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 2.2fr 2fr 1.5fr;
    & > *:nth-child(5) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
    & > *:nth-child(4) {
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 2fr 1.5fr;
    & > *:nth-child(5) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
    & > *:nth-child(4) {
      display: none;
    }
    & > *:nth-child(2) {
      display: none;
    }
    & > *:nth-child(6) {
      display: none;
    }
  }
`;

export const ResponsiveGridPoolRow = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  grid-template-columns: 2fr 1.3fr 1fr 1fr 1fr 0.9fr 1fr 1fr;

  @media screen and (max-width: 940px) {
    grid-template-columns: 1.5fr 2fr 1.5fr repeat(3, 1fr);
    & > *:nth-child(5) {
      display: none;
    }
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 2.2fr 2fr 1.5fr;
    & > *:nth-child(5) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
    & > *:nth-child(4) {
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 2fr 1.5fr;
    & > *:nth-child(5) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
    & > *:nth-child(4) {
      display: none;
    }
    & > *:nth-child(2) {
      display: none;
    }
    & > *:nth-child(6) {
      display: none;
    }
  }
`;
export const ResponsiveGridLockingPoolRow = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr ;
  padding: 1rem 0px;

  @media screen and (max-width: 940px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr
    & > *:nth-child(5) {
      display: none;
    }
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 2.2fr 2fr 1.5fr;
    & > *:nth-child(5) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
    & > *:nth-child(4) {
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 2fr 1.5fr;
    & > *:nth-child(5) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
    & > *:nth-child(4) {
      display: none;
    }
    & > *:nth-child(2) {
      display: none;
    }
    & > *:nth-child(6) {
      display: none;
    }
  }
`;

export const ResponsiveGridLockingLockRow = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr   ;
  padding: 1rem 0px;

  @media screen and (max-width: 940px) {
    grid-template-columns: 1fr 1fr 1fr  
    & > *:nth-child(5) {
      display: none;
    }
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 2.2fr 2fr ;
    & > *:nth-child(5) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
    & > *:nth-child(4) {
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 2fr 1.5fr;
    & > *:nth-child(5) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
    & > *:nth-child(4) {
      display: none;
    }
    & > *:nth-child(2) {
      display: none;
    }
    & > *:nth-child(6) {
      display: none;
    }
  }
`;
export const StakedBalancesDiv = styled.div``;
export const MyBalancesDiv = styled.div``;
export const PoolBalancesDiv = styled.div``;

export const FlexDiv = styled.div`
  display: flex;
`;

export const FlexDivSymbol = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 0.2rem 0px;
`;

export const ColoredSpan = styled.span`
  color: #868e9b;
`;

export const DollarSpan = styled.div`
  font-weight: 500;
  font-size: 15px;
`;

export const BlurGroup = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(${BLUR_FADE}, rgba(${BLUR_FADE}, 0));
`;

export const BlurLayer = styled.div<{ index: number }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${({ index }) => (NAV_HEIGHT / BLUR_STEPS) * index}px;
  backdrop-filter: blur(
    ${({ index }) => (MAX_STRENGTH / BLUR_STEPS) * (BLUR_STEPS - index)}px
  );
`;
