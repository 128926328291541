import { useEffect, useState } from "react";

const useGetTotalFeeUsd = (poolData:any) => {
    const [totalFeesUSD, setTotalFeesUSD] = useState(0);
    const [loading, setLoading] = useState(true); 
    console.log("poolData",poolData)

    useEffect(() => {
        if (Array.isArray(poolData)) {
            let sum = 0;
            poolData.forEach(pool => {
                sum += parseFloat(pool.feesUSD);
            });
            setTotalFeesUSD(sum);
            setLoading(false); 
        }
    }, [poolData]);

    return { totalFeesUSD, loading }; 
};

export default useGetTotalFeeUsd;
