import { ButtonPrimary } from "components/Button";
import { AutoColumn } from "components/Column";
import { AlertTriangle, Slash } from "react-feather";
import { Text } from "rebass";
import styled, { css } from "styled-components";
import { ButtonText, ExternalLink } from "theme";
import { ExternalLink as LinkIconFeather } from 'react-feather'

export const Label = styled.div<{ color: string; backgroundColor: string }>`
  width: 100%;
  padding: 12px 20px 16px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.backgroundOutline};
  color: ${({ color }) => color};
`

export const SafetyLabel = styled.div<{ color: string; backgroundColor: string }>`
  padding: 4px 4px;
  font-size: 12px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 8px;
  color: ${({ color }) => color};
  display: inline-flex;
  align-items: center;
`

export const Title = styled(Text)`
  margin-right: 5px;
  font-weight: 700;
  font-size: 12px;
`


export const WarningContainer = styled.div`
  margin-left: 4px;
  display: flex;
  justify-content: center;
`

export const WarningIconStyle = css<{ size?: string }>`
  width: ${({ size }) => size ?? '1em'};
  height: ${({ size }) => size ?? '1em'};
`

export const WarningIcon = styled(AlertTriangle)`
  ${WarningIconStyle};
  color: ${({ theme }) => theme.textTertiary};
`

export const BlockedIcon = styled(Slash)`
  ${WarningIconStyle}
  color: ${({ theme }) => theme.textSecondary};
`
 

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
`

export const Container = styled.div`
  width: 100%;
  padding: 32px 40px;
  display: flex;
  flex-flow: column;
  align-items: center;
`

export const LogoContainer = styled.div`
  display: flex;
  gap: 16px;
`

export const ShortColumn = styled(AutoColumn)`
  margin-top: 10px;
`

export const StyledButton = styled(ButtonPrimary)`
  margin-top: 24px;
  width: 100%;
  font-weight: 600;
`

export const StyledCancelButton = styled(ButtonText)`
  margin-top: 16px;
  color: ${({ theme }) => theme.textSecondary};
  font-weight: 600;
  font-size: 14px;
`

export const StyledCloseButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.backgroundInteractive};
  color: ${({ theme }) => theme.textPrimary};

  &:hover {
    background-color: ${({ theme }) => theme.backgroundInteractive};
    opacity: ${({ theme }) => theme.opacity.hover};
    transition: opacity 250ms ease;
  }
`

export const LinkColumn = styled(AutoColumn)`
  width: 100%;
  margin-top: 16px;
  position: relative;
`

export const ExplorerContainer = styled.div`
  width: 100%;
  height: 32px;
  margin-top: 10px;
  font-size: 20px;
  background-color: ${({ theme }) => theme.accentActionSoft};
  color: ${({ theme }) => theme.accentAction};
  border-radius: 8px;
  padding: 2px 12px;
  display: flex;
  align-items: center;
  overflow: hidden;
`

export const ExplorerLinkWrapper = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
  cursor: pointer;

  :hover {
    opacity: ${({ theme }) => theme.opacity.hover};
  }
  :active {
    opacity: ${({ theme }) => theme.opacity.click};
  }
`

export const ExplorerLink = styled.div`
  display: block;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const ExplorerLinkIcon = styled(LinkIconFeather)`
  height: 16px;
  width: 18px;
  margin-left: 8px;
`

export const LinkIconWrapper = styled.div`
  justify-content: center;
  display: flex;
`

export const StyledExternalLink = styled(ExternalLink)`
  color: ${({ theme }) => theme.accentAction};
  stroke: currentColor;
  font-weight: 600;
`