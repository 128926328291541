import { useWeb3React } from "@web3-react/core";

import { DEFAULT_TOKEN } from "constants/graphConstants";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { getBidAskData } from "services/LimitOrderServices";
import { Field } from "state/swap/actions";
import "./areachart.css";
import { formatLimitPrice } from "utils/limitPrice";

function DepthChartContainer(props: HighchartsReact.Props) {
  const { chainId } = useWeb3React();

  const { currencies, setLimitPriceValue } = props;
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const handlePointClick = (event: any) => {
    if (event.point && event.point.series.name) {
      const type = event.point.series.name.toLowerCase(); // Convert series name to lowercase
      const price = event.point.x;
      const value = event.point.y;

      if (type === "bids") {
        setSelectedPoint({ price, bid: value });
        setLimitPriceValue(formatLimitPrice(price));
      } else if (type === "asks") {
        setSelectedPoint({ price, ask: value });
        setLimitPriceValue(formatLimitPrice(price));
      }
    }
  };

  const [chartOptions, setChartOptions] = useState<any>({
    chart: {
      type: "area",
      zoomType: "xy",
      backgroundColor: "#091012",
      color: "#f0f0f0",
      height: 350,
    },
    title: {
      text: "Market Depth",
    },
    xAxis: {
      minPadding: 0,
      maxPadding: 0,
      plotLines: [
        {
          color: "#888",
          value: 0.1523,
          width: 1,
          label: {
            text: "Actual price",
            rotation: 90,
          },
        },
      ],
      title: {
        text: "Price",
      },
      credits: {
        enabled: false,
      },
      labels: {
        style: {
          color: "#f0f0f0",
        },
      },
    },
    yAxis: [
      {
        lineWidth: 1,
        gridLineWidth: 0,
        title: null,
        tickWidth: 1,
        tickLength: 5,
        tickPosition: "inside",
        labels: {
          align: "left",
          x: 8,
          style: {
            color: "#f0f0f0",
          },
        },
      },
      {
        opposite: true,
        linkedTo: 0,
        lineWidth: 1,
        gridLineWidth: 0,
        title: null,
        tickWidth: 1,
        tickLength: 5,
        tickPosition: "inside",
        labels: {
          align: "right",
          x: -8,
          style: {
            color: "#f0f0f0", // Set y-axis labels text color to white
          },
        },
      },
    ],
    legend: {
      enabled: false,
    },
    plotOptions: {
      area: {
        fillOpacity: 0.2,
        lineWidth: 1,
        step: "center",
        point: {
          events: {
            click: handlePointClick,
          },
        },
      },
    },
    tooltip: {
      headerFormat:
        '<span style="font-size=10px;">Price: {point.key}</span><br/>',
      pointFormatter: function () {
        const type = (this as any).series.name === "Bids" ? "Bid" : "Ask";
        return `<span style="color:${(this as any).series.color}">${type}: ${
          (this as any).y
        }</span>`;
      },
      valueDecimals: 2,
    },
    series: [
      {
        name: "Bids",
        data: [],
        color: "#03a7a8",
      },
      {
        name: "Asks",
        data: [],
        color: "#fc5857",
      },
    ],
  });
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedPoint, setSelectedPoint] = useState<any>(null);
  const { pathname } = useLocation();

  const isLimitUnsupported = chainId == 1891 && pathname == "/limit";

  useEffect(() => {
    const fetchData = async () => {
      if (currencies[Field.INPUT]) {
        try {
          setLoading(true);
          const data = await getBidAskData(
            isLimitUnsupported && currencies[Field.INPUT].symbol === "ETH"
              ? "0x54390D6FD8C4d4e77c13Ac1E051C9A06eC19112f"
              : currencies[Field.INPUT]?.wrapped?.address,

            isLimitUnsupported && !currencies[Field.OUTPUT]
              ? "0xA4CBbd0f503720a0cA3C950D647A8c23FBE9702B"
              : currencies[Field.OUTPUT]?.wrapped.address,
            chainId
          );

          setLoading(false);

          setChartOptions({
            ...chartOptions,
            series: [
              {
                name: "Bids",
                data: data?.bids.map((bid: any) => [
                  parseFloat(bid.price),
                  parseFloat(bid.volume),
                ]),
                color: "#03a7a8",
              },
              {
                name: "Asks",
                data: data?.asks.map((ask: any) => [
                  parseFloat(ask.price),
                  parseFloat(ask.volume),
                ]),
                color: "#fc5857",
              },
            ],
          });
          setData(data);
        } catch (error) {
          console.error("Error fetching market depth data:", error);
        }
      }
    };

    fetchData();
  }, [currencies[Field.INPUT], currencies[Field.OUTPUT]]);

  useEffect(() => {
    if (chartComponentRef.current && chartComponentRef.current.chart) {
      chartComponentRef.current.chart.update(chartOptions);
    }
  }, [chartOptions]);

  return (
    <>
      {loading ? (
        <>
          {" "}
          <img
            src="https://res.cloudinary.com/hemergy/image/upload/v1718374081/jqxzideqghwwfps0ngox.svg"
            style={{ height: 300 }}
            alt="Loading..."
          />
        </>
      ) : (
        <>
          {data && (data.asks.length !== 0 || data.bids.length !== 0) ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={chartOptions}
              ref={chartComponentRef}
              {...props}
            />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
                color: "#5D6785",
                fontWeight: "bolc",
              }}
            >
              No data available for Preview
            </div>
          )}
        </>
      )}
    </>
  );
}

export default DepthChartContainer;
