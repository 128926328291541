import { getChainInfo } from 'constants/chainInfo'
import { SupportedChainId } from 'constants/chains'

import { ThemedText } from '../../theme'

import { ColumnContainer, PopupAlertTriangle, RowNoFlex } from './styled'

export default function FailedNetworkSwitchPopup({ chainId }: { chainId: SupportedChainId }) {
  const chainInfo = getChainInfo(chainId)

  return (
    <RowNoFlex gap="12px">
      <PopupAlertTriangle />
      <ColumnContainer gap="sm">
        <ThemedText.SubHeader color="textPrimary">
          <div>Failed to switch networks</div>
        </ThemedText.SubHeader>

        <ThemedText.BodySmall color="textPrimary">
          <div>To use Elektrik on {chainInfo.label}, switch the network in your wallet’s settings.</div>
        </ThemedText.BodySmall>
      </ColumnContainer>
    </RowNoFlex>
  )
}
