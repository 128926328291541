const WETH_ADDRESS = '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2';
const ARBITRUM_WETH_ADDRESS = '0x82af49447d8a07e3bd95bd0d56f35241523fbab1';
const PEGASUS_WETH_ADDRESS = '0xF42991f02C07AB66cFEa282E7E482382aEB85461';
const PHOENIX_WETH_ADDRESS = '0x7EbeF2A4b1B09381Ec5B9dF8C5c6f2dBECA59c73';

export const WETH_ADDRESSES = [
  WETH_ADDRESS,
  ARBITRUM_WETH_ADDRESS,
  PEGASUS_WETH_ADDRESS,
  PHOENIX_WETH_ADDRESS,
];

export function formatTokenSymbol(address: string, symbol: string) {
  if (WETH_ADDRESSES.includes(address)) {
    return 'ETH';
  }
  return symbol;
}

export function formatTokenName(address: string, name: string) {
  if (WETH_ADDRESSES.includes(address)) {
    return 'Ether';
  }
  return name;
}
