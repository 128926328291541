import { useWeb3React } from '@web3-react/core'
import { getChainInfoOrDefault } from 'constants/chainInfo'
import { BodyRow, CautionTriangle, SpanWrapper, TitleRow, TitleText, Wrapper } from './styled'
import { RowBetween } from 'components/Row'
import { CloseIcon } from 'theme'



export function ApprovalWarning(props:any) {
  const {setApprovalWarning} = props;

  const { chainId } = useWeb3React()
  const info = getChainInfoOrDefault(chainId)

  return (
    <Wrapper >
      <RowBetween>
      <TitleRow>
        <CautionTriangle />
        <TitleText>
          <div>Approval Warning</div>
        </TitleText>
      </TitleRow>
      <CloseIcon
        onClick={()=>setApprovalWarning(false)}
        style={{ zIndex: 99 }}
        stroke="#647383"
      />
      </RowBetween>
   
      <BodyRow>
        <div>Your transaction might get failed by Metamask Smart gas estimation.</div> <br />
        <SpanWrapper>
          <div>Try increase gas on Metamask</div>{' '}
        </SpanWrapper>
      </BodyRow>
    </Wrapper>
  )
}
