import {
  ResponsiveGridFour,
  SwapColumn,
  TableRowWithoutBorder,

} from "./poolStyle";

import { useCurrency } from "hooks/Tokens";
import CurrencyLogo from "components/Logo/CurrencyLogo";
import { formatNumberWithSuffix } from "utils/numbers";

export const TradeRow = (props: any) => {
  const { swap } = props;

  const date = new Date(swap.timestamp * 1000);
  const formattedDate = `${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${date
    .getDate()
    .toString()
    .padStart(2, "0")}/${date.getFullYear()} ${date
    .getHours()
    .toString()
    .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
  const currencyDetailsBase =   useCurrency(parseFloat(swap.amount0) > 0 ? swap?.pool.token0?.id : swap?.pool.token1?.id);
  const currencyDetailsQuote = useCurrency(parseFloat(swap.amount1) > 0 ? swap?.pool.token0?.id : swap?.pool.token1?.id);


  const abs0 =parseFloat(swap.amount0) > 0 ? Math.abs(parseFloat(swap.amount0)): Math.abs(parseFloat(swap.amount1));
  const abs1 = parseFloat(swap.amount1) > 0 ? Math.abs(parseFloat(swap.amount0)): Math.abs(parseFloat(swap.amount1));
  const outputTokenSymbol = parseFloat(swap.amount1) > 0 ? swap.pool.token0.symbol: swap.pool.token1.symbol;
  const inputTokenSymbol = parseFloat(swap.amount0) > 0 ? swap.pool.token0.symbol: swap.pool.token1.symbol;
  const price = formatNumberWithSuffix(abs1 / abs0);

  return (
    <>
      <TableRowWithoutBorder>
        <ResponsiveGridFour>
          <SwapColumn style={{ padding: "0px 0px 18px 0px" }}>
            <div style={{ fontSize: 12, fontWeight: 500 }}>
              <div
                style={{
                  display: "flex",
                  fontSize: 12,
                  fontWeight: 400,
                  alignItems: "center",
                }}
              >
                <CurrencyLogo currency={currencyDetailsBase} /> &nbsp;&nbsp;
                {formatNumberWithSuffix(abs0)}
              </div>
            </div>
            &nbsp;
            <div style={{ padding: "0px", fontWeight: 500, fontSize: "12px" }}>
              {inputTokenSymbol}
            </div>
          </SwapColumn>
          <SwapColumn style={{ padding: "0px 0px 18px 0px" }}>
            <div style={{ fontSize: 12, fontWeight: 500 }}>
              <div
                style={{
                  display: "flex",
                  fontSize: 12,
                  fontWeight: 400,
                  alignItems: "center",
                }}
              >
                <CurrencyLogo currency={currencyDetailsQuote} />
                &nbsp;&nbsp;&nbsp;
                {formatNumberWithSuffix(abs1)}
              </div>
            </div>
            &nbsp;
            <div style={{ padding: "0px", fontWeight: 500, fontSize: "12px" }}>
              {outputTokenSymbol}
            </div>
          </SwapColumn>
          <SwapColumn
            style={{
              fontWeight: 400,
              fontSize: "12px",
              padding: "0px 0px 18px 0px",
            }}
          >
            1 &nbsp; {inputTokenSymbol} = {price} &nbsp;
            {outputTokenSymbol}
          </SwapColumn>
          <SwapColumn
            style={{
              fontWeight: 400,
              fontSize: "12px",
              padding: "0px 0px 18px 0px",
            }}
          >
            {formattedDate}
          </SwapColumn>
        </ResponsiveGridFour>
      </TableRowWithoutBorder>
    </>
  );
};
