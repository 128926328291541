// src/components/MobileMessage.tsx
import React from 'react';
import { ReactComponent as ElektrikLogo } from "assets/svg/elektrik_logo.svg";
import { ReactComponent as ElektrikLogoWhite } from "assets/svg/elektrik_logo_white.svg";
import { useIsMobile, useIsNavMobile } from "utils/common";
import styled from 'styled-components';

const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  color: white;
  font-size: 24px;
  text-align: center;
  z-index: 9999;
  padding: 20px;
  @media (min-width: 768px) {
    display: none;
  }
`;

const MobileMessage: React.FC = () => {
  const IsNavMobile = useIsNavMobile();
  return (
    <Message>
      {!IsNavMobile ? (
        <ElektrikLogo
          width={40}
          height={40}
          style={{ marginRight: "0px" }}
        />
      ) : (
        <ElektrikLogoWhite width={160} height={38} />
      )}
      <br/>
      This application is best viewed on a desktop or larger screen.
    </Message>
  );
};

export default MobileMessage;
