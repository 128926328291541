import styled from "styled-components"

export const BadgeWrapper = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
`

export const BadgeText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-left: 8px;
`

export const ActiveDot = styled.span`
  background-color: #19EF81;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  @media (max-width: 750px) {  
   display: none;
  }
`

export const LabelText = styled.div<{ color: string }>`
  align-items: center;
  color: ${props => props.color || '#19EF81'}; 
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
