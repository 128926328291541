import { ButtonSecondary } from 'components/Button'
import { darken } from 'polished'
import styled from 'styled-components/macro'
import { flexRowNoWrap } from 'theme/styles'
import { IconWrapper } from 'theme'
import { AlertTriangle } from 'react-feather'
import { colors } from 'theme/colors'

const FULL_BORDER_RADIUS = 9999


export const Web3StatusGeneric = styled(ButtonSecondary)`
  ${flexRowNoWrap};
  width: 150px;

  align-items: center;
  padding: 0.5rem;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  // height: 36px;
  height: 40px;

  margin-right: 2px;
  margin-left: 2px;
  :focus {
    outline: none;
  }
  @media only screen and (max-width: 500px) {
    max-width: 100px; 
    width:100px;
 
  }
`
export const Web3StatusError = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.accentFailure};
  border: 1px solid ${({ theme }) => theme.accentFailure};
  color: ${({ theme }) => theme.white};
  font-weight: 500;
  :hover,
  :focus {
    background-color: ${({ theme }) => darken(0.1, theme.accentFailure)};
  }
`

export const Web3StatusConnectWrapper = styled.div<{ faded?: boolean }>`
  ${flexRowNoWrap};
  align-items: center;
  background: linear-gradient(95.72deg, #a2eeff 13.1%, #029bf0 98.51%);
  border-radius: 10px;
  border: none;
  padding: 0;
  height: 36px;

  color: ${({ theme }) => theme.accentAction};
  :hover {
    color: ${({ theme }) => theme.accentActionSoft};
    stroke: ${({ theme }) => theme.accentActionSoft};
  }

  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => `${duration.fast} color ${timing.in}`};

  @media only screen and (max-width: 500px) {
    max-width: 100px; 
 
  }
`

export const Web3StatusConnected = styled(Web3StatusGeneric)<{
  pending?: boolean
  isClaimAvailable?: boolean
}>`
  background-color: ${({ pending, theme }) => (pending ? theme.accentAction : theme.deprecated_bg1)};
  border: 1px solid ${({ pending, theme }) => (pending ? theme.accentAction : theme.deprecated_bg1)};
  color: ${({ pending, theme }) => (pending ? theme.white : theme.textPrimary)};
  font-weight: 500;
  border: ${({ isClaimAvailable }) => isClaimAvailable && `1px solid ${colors.purple300}`};
  :hover,
  :focus {
    border: 1px solid ${({ theme }) => darken(0.05, theme.deprecated_bg3)};

    :focus {
      border: 1px solid
        ${({ pending, theme }) =>
          pending ? darken(0.1, theme.accentAction) : darken(0.1, theme.backgroundInteractive)};
    }
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
     

    ${IconWrapper} {
      margin-right: 0;
    }
  }
  background: linear-gradient(95.72deg, #a2eeff 13.1%, #029bf0 98.51%);
  color: rgba(0, 0, 0, 1);
`

export const AddressAndChevronContainer = styled.div`
  display: flex;
overlay:hidden;
   
`

export const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0.25rem;
  font-size: 0.9rem;
  width: fit-content;
  font-weight: 500;
`


export const TextMobile = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0.25rem;
  font-size: 0.7rem;
  width: fit-content;
  font-weight: 500;
`

export const NetworkIcon = styled(AlertTriangle)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`

// we want the latest one to come first, so return negative if a is after b

export const StyledConnectButton = styled.button`
  background-color: transparent;
  border: none;
  border-top-left-radius: ${FULL_BORDER_RADIUS}px;
  border-bottom-left-radius: ${FULL_BORDER_RADIUS}px;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;

  padding: 10px 12px;
  color: rgba(0, 0, 0, 1);
  display: flex;
  width: max-content;
`
