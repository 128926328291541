import { ChevronDown } from 'react-feather'
import styled from 'styled-components/macro'
import Row from '../Row'
import Column from 'components/Column'

export const ButtonContainer = styled(Row)`
  cursor: pointer;
  justify-content: flex-end;
  width: unset;
`

export const ExpandIcon = styled(ChevronDown)<{ $isOpen: boolean }>`
  color: ${({ theme }) => theme.textSecondary};
  transform: ${({ $isOpen }) => ($isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform ${({ theme }) => theme.transition.duration.medium};
`

export const Content = styled(Column)`
  padding-top: ${({ theme }) => theme.grids.md};
`