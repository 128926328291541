import styled from 'styled-components/macro'

export const ToggleWrapper = styled.button<{ width?: string }>`
  display: flex;
  align-items: center;
  min-height: 16px
  width: 30px;
  padding: 1px;
  background: #091012;
  border-radius: 6px;
  border: #091012;
  cursor: pointer;
  outline: none;
`

export const ToggleElement = styled.span<{ isActive?: boolean; fontSize?: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  margin-right:5px;
  min-width: 30px;
  padding: 4px 0.5rem;
  min-height: 26px;
  border-radius: 6px;
  justify-content: center; 
  background: ${({ theme, isActive }) => 
    isActive ? "linear-gradient(191deg, #1A3848 5.4%, #39484B 92.07%)" : "#1F2831"};

  color: ${({ theme, isActive }) => (isActive ? "#53C5F8" : "#FFF")};
  font-size: ${({ fontSize }) => fontSize ?? '1rem'};
  font-weight: 500;
  white-space: nowrap;
  :hover {
    user-select: initial;
    color: ${({ theme, isActive }) => (isActive ? "#53C5F8" : "#53C5F8" )};
  }
`
