import { useTheme } from 'styled-components/macro'
import { AnimatedConfirmationWrapper, Circle, PolyLine } from './styled'


export default function AnimatedConfirmation({ className }: { className?: string }) {
  const theme = useTheme()

  return (
    <AnimatedConfirmationWrapper className={className} data-testid="animated-confirmation">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
        <Circle
          className="path circle"
          fill="none"
          stroke={theme.accentSuccess}
          strokeWidth="6"
          strokeMiterlimit="10"
          cx="65.1"
          cy="65.1"
          r="62.1"
        />
        <PolyLine
          className="path check"
          fill="none"
          stroke={theme.accentSuccess}
          strokeWidth="6"
          strokeLinecap="round"
          strokeMiterlimit="10"
          points="100.2,40.2 51.5,88.8 29.8,67.5 "
        />
      </svg>
    </AnimatedConfirmationWrapper>
  )
}
