import {
  ApolloClient,
  ApolloLink,
  concat,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { SupportedChainId } from "constants/chains";

import store from "../../state/index";

/* ApolloClient */
const CHAIN_SUBGRAPH_URL: Record<number, string> = {
  [SupportedChainId.MAINNET]:
    "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3",

  [SupportedChainId.ARBITRUM_ONE]:
    "https://api.thegraph.com/subgraphs/name/ianlapham/arbitrum-minimal",

  [SupportedChainId.OPTIMISM]:
    "https://api.thegraph.com/subgraphs/name/ianlapham/optimism-post-regenesis",

  [SupportedChainId.POLYGON]:
    "https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-polygon",

  [SupportedChainId.CELO]:
    "https://api.thegraph.com/subgraphs/name/jesse-sawa/uniswap-celo",

  [SupportedChainId.BNB]:
    "https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-bsc",
  //Change
  [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]: process.env
    .REACT_APP_CHAIN_SUBGRAPH_URL_TESTNET
    ? process.env.REACT_APP_CHAIN_SUBGRAPH_URL_TESTNET
    : "https://graph.pegasus.lightlink.io/query/subgraphs/name/ELEKTRIK-GRAPH-V2",
  [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]: process.env
    .REACT_APP_CHAIN_SUBGRAPH_URL_MAINNET
    ? process.env.REACT_APP_CHAIN_SUBGRAPH_URL_MAINNET
    : "https://graph.phoenix.lightlink.io/query/subgraphs/name/ELEKTRIK-GRAPH-V2",
};

const authMiddleware = new ApolloLink((operation, forward) => {
  const chainId = store.getState().application.chainId;

  operation.setContext(() => ({
    uri:
      chainId && CHAIN_SUBGRAPH_URL[chainId]
        ? CHAIN_SUBGRAPH_URL[chainId]
        : CHAIN_SUBGRAPH_URL[SupportedChainId.LIGHTLINK_PEGASUS_TESTNET],
  }));

  return forward(operation);
});

const httpLink = new HttpLink({
  uri: CHAIN_SUBGRAPH_URL[SupportedChainId.LIGHTLINK_PEGASUS_TESTNET],
});

const httpLinkTestnet = new HttpLink({
  uri: process.env.REACT_APP_CHAIN_SUBGRAPH_URL_TESTNET
    ? process.env.REACT_APP_CHAIN_SUBGRAPH_URL_TESTNET
    : "https://graph.pegasus.lightlink.io/query/subgraphs/name/ELEKTRIK-GRAPH-V2",
});

const httpLinkMainnet = new HttpLink({
  uri: process.env.REACT_APP_CHAIN_SUBGRAPH_URL_MAINNET
    ? process.env.REACT_APP_CHAIN_SUBGRAPH_URL_MAINNET
    : "https://graph.phoenix.lightlink.io/query/subgraphs/name/ELEKTRIK-GRAPH-V2",
});

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
});

export const apolloClientTestnet = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLinkTestnet,
});

export const apolloClientMainnet = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLinkMainnet,
});

/* ApolloClient */

/* ApolloOHLCsClient */
const CHAIN_OHLC_SUBGRAPH_URL: Record<number, string> = {
  [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]: process.env
    .REACT_APP_CHAIN_SUBGRAPH_URL_TESTNET
    ? process.env.REACT_APP_CHAIN_SUBGRAPH_URL_TESTNET
    : "https://graph.pegasus.lightlink.io/query/subgraphs/name/ELEKTRIK-GRAPH-V2",
  // UP_FIX
  [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]: process.env
    .REACT_APP_CHAIN_SUBGRAPH_URL_MAINNET
    ? process.env.REACT_APP_CHAIN_SUBGRAPH_URL_MAINNET
    : "https://graph.phoenix.lightlink.io/query/subgraphs/name/ELEKTRIK-GRAPH-V2",
};

export const ohlcMiddleware = new ApolloLink((operation, forward) => {
  const chainId = store.getState().application.chainId ?? 1891;
  operation.setContext(() => ({
    uri:
      chainId && CHAIN_OHLC_SUBGRAPH_URL[chainId]
        ? CHAIN_OHLC_SUBGRAPH_URL[chainId]
        : CHAIN_OHLC_SUBGRAPH_URL[SupportedChainId.LIGHTLINK_PEGASUS_TESTNET],
  }));

  return forward(operation);
});

export const httpOHLCsLink = new HttpLink({
  uri: CHAIN_OHLC_SUBGRAPH_URL[SupportedChainId.LIGHTLINK_PEGASUS_TESTNET],
});

export const apolloOHLCsClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(ohlcMiddleware, httpOHLCsLink),
});
/* ApolloOHLCsClient */

/* ApolloStakingClient */
const CHAIN_STAKING: Record<number, string> = {
  [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]: process.env
    .REACT_APP_CHAIN_STAKING_TESTNET
    ? process.env.REACT_APP_CHAIN_STAKING_TESTNET
    : "https://graph.pegasus.lightlink.io/query/subgraphs/name/Elektrik-Staking",
  [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]: process.env
    .REACT_APP_CHAIN_STAKING_MAINNET
    ? process.env.REACT_APP_CHAIN_STAKING_MAINNET
    : "https://graph.phoenix.lightlink.io/query/subgraphs/name/Elektrik-Staking",
};

const stakingMiddleware = new ApolloLink((operation, forward) => {
  const chainId = store.getState().application.chainId ?? 1891;
  operation.setContext(() => ({
    uri:
      chainId && CHAIN_STAKING[chainId]
        ? CHAIN_STAKING[chainId]
        : CHAIN_STAKING[SupportedChainId.LIGHTLINK_PEGASUS_TESTNET],
  }));

  return forward(operation);
});

const httpStaking = new HttpLink({
  uri: CHAIN_STAKING[SupportedChainId.LIGHTLINK_PEGASUS_TESTNET],
});
const httpStakingMainnet = new HttpLink({
  uri: process.env.REACT_APP_CHAIN_STAKING_MAINNET
    ? process.env.REACT_APP_CHAIN_STAKING_MAINNET
    : "https://graph.phoenix.lightlink.io/query/subgraphs/name/Elektrik-Staking",
});
const httpStakingTestnet = new HttpLink({
  uri: process.env.REACT_APP_CHAIN_STAKING_TESTNET
    ? process.env.REACT_APP_CHAIN_STAKING_TESTNET
    : "https://graph.pegasus.lightlink.io/query/subgraphs/name/Elektrik-Staking",
});

export const StakingClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(stakingMiddleware, httpStaking),
});

export const StakingClientMainnet = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpStakingMainnet,
});
export const StakingClientTestnet = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpStakingTestnet,
});

/* ApolloStakingClient */

/* ApolloVotingEscrowClient */
const CHAIN_VOTE_ESCROW: Record<number, string> = {
  [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]: process.env
    .REACT_APP_CHAIN_VOTE_ESCROW_TESTNET
    ? process.env.REACT_APP_CHAIN_VOTE_ESCROW_TESTNET
    : "https://graph.pegasus.lightlink.io/query/subgraphs/name/Elektrik-Escrow",
  [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]: process.env
    .REACT_APP_CHAIN_VOTE_ESCROW_MAINNET
    ? process.env.REACT_APP_CHAIN_VOTE_ESCROW_MAINNET
    : "https://graph.phoenix.lightlink.io/query/subgraphs/name/Elektrik-Escrow",
};

const votingEscrowMiddleware = new ApolloLink((operation, forward) => {
  const chainId = store.getState().application.chainId ?? 1891;
  operation.setContext(() => ({
    uri:
      chainId && CHAIN_VOTE_ESCROW[chainId]
        ? CHAIN_VOTE_ESCROW[chainId]
        : CHAIN_VOTE_ESCROW[SupportedChainId.LIGHTLINK_PEGASUS_TESTNET],
  }));

  return forward(operation);
});

const httpVoteEscrow = new HttpLink({
  uri: CHAIN_VOTE_ESCROW[SupportedChainId.LIGHTLINK_PEGASUS_TESTNET],
});

const httpVoteEscrowTestnet = new HttpLink({
  uri: process.env.REACT_APP_CHAIN_VOTE_ESCROW_TESTNET
    ? process.env.REACT_APP_CHAIN_VOTE_ESCROW_TESTNET
    : "https://graph.pegasus.lightlink.io/query/subgraphs/name/Elektrik-Escrow",
});
const httpVoteEscrowMainnet = new HttpLink({
  uri: process.env.REACT_APP_CHAIN_VOTE_ESCROW_MAINNET
    ? process.env.REACT_APP_CHAIN_VOTE_ESCROW_MAINNET
    : "https://graph.phoenix.lightlink.io/query/subgraphs/name/Elektrik-Escrow",
});

export const voteEscrowClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(votingEscrowMiddleware, httpVoteEscrow),
});
export const voteEscrowClientTestnet = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpVoteEscrowTestnet,
});

export const voteEscrowClientMainnet = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpVoteEscrowMainnet,
});
/* ApolloVotingEscrowClient */

/* ApolloBlockClient */
const CHAIN_BLOCK_SUBGRAPH_URL: Record<number, string> = {
  [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]: process.env
    .REACT_APP_PEGASUS_BLOCKS
    ? process.env.REACT_APP_PEGASUS_BLOCKS
    : "https://graph.pegasus.lightlink.io/query/subgraphs/name/Blocks",
  [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]: process.env
    .REACT_APP_PHOENIX_BLOCKS
    ? process.env.REACT_APP_PHOENIX_BLOCKS
    : "https://graph.phoenix.lightlink.io/query/subgraphs/name/phoenix-blocks",
};

const httpBlockMiddleware = new ApolloLink((operation, forward) => {
  const chainId = store.getState().application.chainId ?? 1891;
  operation.setContext(() => ({
    uri:
      chainId && CHAIN_BLOCK_SUBGRAPH_URL[chainId]
        ? CHAIN_BLOCK_SUBGRAPH_URL[chainId]
        : CHAIN_BLOCK_SUBGRAPH_URL[SupportedChainId.LIGHTLINK_PEGASUS_TESTNET],
  }));

  return forward(operation);
});

const httpBlockLink = new HttpLink({
  uri: CHAIN_BLOCK_SUBGRAPH_URL[SupportedChainId.LIGHTLINK_PEGASUS_TESTNET],
});

export const apolloBlockClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(httpBlockMiddleware, httpBlockLink),
});
/* ApolloBlockClient */

/* ApolloLimitClient */
const CHAIN_OHLC_SUBGRAPH_URL_LIMIT: Record<number, string> = {
  [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]: process.env
    .REACT_APP_CHAIN_OHLC_SUBGRAPH_URL_LIMIT_TESTNET
    ? process.env.REACT_APP_CHAIN_OHLC_SUBGRAPH_URL_LIMIT_TESTNET
    : "https://graph.pegasus.lightlink.io/query/subgraphs/name/ELEKTRIK-LIMIT-TESTNET",
  [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]: process.env
    .REACT_APP_CHAIN_OHLC_SUBGRAPH_URL_LIMIT_MAINNET
    ? process.env.REACT_APP_CHAIN_OHLC_SUBGRAPH_URL_LIMIT_MAINNET
    : "https://graph.phoenix.lightlink.io/query/subgraphs/name/ELEKTRIK-LIMIT-MAINNET",
};

const limitMiddleware = new ApolloLink((operation, forward) => {
  const chainId = store.getState().application.chainId ?? 1891;
  operation.setContext(() => ({
    uri:
      chainId && CHAIN_OHLC_SUBGRAPH_URL_LIMIT[chainId]
        ? CHAIN_OHLC_SUBGRAPH_URL_LIMIT[chainId]
        : CHAIN_OHLC_SUBGRAPH_URL_LIMIT[
            SupportedChainId.LIGHTLINK_PEGASUS_TESTNET
          ],
  }));

  return forward(operation);
});

const httpOHLCsLimitLink = new HttpLink({
  uri: CHAIN_OHLC_SUBGRAPH_URL_LIMIT[
    SupportedChainId.LIGHTLINK_PHOENIX_MAINNET
  ],
});

export const apolloLimitOHLCsClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(limitMiddleware, httpOHLCsLimitLink),
});
/* ApolloLimitClient */
