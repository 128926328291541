import { Percent } from '@uniswap/sdk-core'
import { ReactNode } from 'react'
import { InterfaceTrade, TradeState } from 'state/routing/types'
import { isAddress } from '@ethersproject/address'
import { Fraction } from '@uniswap/sdk-core'
import JSBI from 'jsbi'
import { useScreenSize } from 'hooks/useScreenSize'

export function largerPercentValue(a?: Percent, b?: Percent) {
  if (a && b) {
    return a.greaterThan(b) ? a : b
  } else if (a) {
    return a
  } else if (b) {
    return b
  }
  return undefined
}

export function getIsValidSwapQuote(
  trade: InterfaceTrade | undefined,
  tradeState: TradeState,
  swapInputError?: ReactNode
): boolean {
  return Boolean(swapInputError && trade && tradeState === TradeState.VALID)
}

export function shortenAddress(address: string, charsStart = 4, charsEnd?: number): string {
  const parsed = isAddress(address)
  if (!parsed) return ''

  return `${address.substring(0, charsStart + 2)}...${address.substring(42 - (charsEnd || charsStart))}`
}

export function shortenAddressAlike(address: string, charsStart = 4, charsEnd?: number): string {
  if (!address) return ''

  return `${address.substring(0, charsStart + 2)}...${address.substring(42 - (charsEnd || charsStart))}`
}

export const putCommas = (value: number) => {
  try {
    if (!value) return value
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } catch (err) {
    return value
  }
}


// @deprecated in favor of useScreenSize

export function useIsMobile(): boolean {
  const isScreenSize = useScreenSize()
  const isMobile = !isScreenSize['sm']
  return isMobile
}

export function useIsNavMobile(): boolean {
  const isScreenSize = useScreenSize()
  const useIsNavMobile = !isScreenSize['lg']
  return useIsNavMobile
}

export function formatAmount(amountRaw: string, decimals: number, sigFigs: number): string {
  return new Fraction(amountRaw, JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(decimals))).toSignificant(sigFigs)
}
