import { OutlineCard } from 'components/Card'
import styled, { useTheme } from 'styled-components/macro'
import { opacify } from 'theme/utils'
import formatPriceImpact from 'utils/formatPriceImpact'

import { ThemedText } from '../../theme'
import { AutoColumn } from '../Column'
import { RowBetween, RowFixed } from '../Row'
import { MouseoverTooltip } from '../Tooltip'
import { PriceImpactWarningProps } from 'types'
import { InfoIcon } from 'components/Icons/shared'

const StyledCard = styled(OutlineCard)`
  padding: 12px 16px;
  background: #6b3234;
  border: none;
  margin-bottom:20px;
  margin-top:12px
 
`


export default function PriceImpactWarning({ priceImpact }: PriceImpactWarningProps) {
  const theme = useTheme()

  return (
    <StyledCard>
      <AutoColumn gap="sm">
   
          <RowBetween>
            <RowFixed>
              <ThemedText.DeprecatedSubHeader color={theme.accentFailure} style={{display:'flex'}}>
                <div>Price impact warning</div>&nbsp;<InfoIcon content={"  A swap of this size may have a high price impact, given the current liquidity in the pool. There may be a large difference between the amount of your input token and what you will receive in the output token"} size={12} color={'#ffffff'}/>
              </ThemedText.DeprecatedSubHeader>
            </RowFixed>
            <ThemedText.DeprecatedLabel textAlign="right" fontSize={14} color="accentFailure">
              {formatPriceImpact(priceImpact)}
            </ThemedText.DeprecatedLabel>
          </RowBetween>
      </AutoColumn>
    </StyledCard>
  )
} 