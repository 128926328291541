import { STAKER_CONTRACT } from "constants/addresses";
import { useWeb3React } from "@web3-react/core";
import { useState } from "react";
import { useStaking } from "./useStaking";
import { addPopup } from "state/application/reducer";

export const useClaimStakingRewards = (dispatch:any, account:any) => {
  const [txHash, setTxHash] = useState("");
  const [loading, setLoading] = useState(false);
  const { staking } = useStaking();

  const handleStakingRewards = async (stakingTokenId: any) => {
    try {
      setLoading(true);
      const response = await staking.harvestRewards(
        stakingTokenId,
        account
      );

      if (response && response.transactionHash) {
        dispatch(
          addPopup({
            content: { success: "Success" },
            key: "success",
          })
        );
        setTxHash(response.transactionHash);
      }
    } catch (error) {
      if(error.code == 'ACTION_REJECTED'){
        console.error("Error while staking:", error);

      }else{

        dispatch(
          addPopup({
            content: { error: "Error" },
            key: "error",
          })
        );
      }

    } finally {
      setLoading(false);
    }
  };

  return { handleStakingRewards, loading, txHash };
};
