import { useMemo } from "react";
import { useAppSelector } from "state/hooks";

export function useOrders() {
  const ordersState = useAppSelector<any>((state) => {
    return state.orders; 
  });

  return useMemo(() => {
    return {
      orders: ordersState.orders,
      metaData: ordersState.metaData,
      ordersLoading: ordersState.ordersLoading,

    };
  }, [ordersState]);
}