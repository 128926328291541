import CurrencyLogo from "components/Logo/CurrencyLogo";
import { ToggleElement, ToggleWrapper } from "components/Toggle/MultiToggle";
import { LogoContainer } from "components/swap/PendingModalContent/Logos";
import {
  GraphHeader,
  LogoSection,
  OuterWrapperGraph,
  PageWrapperGraph,
} from "components/swap/styleds";
import { Fragment, useState } from "react";
import styled, { css } from "styled-components/macro";
import LineChart from "../lineChart";
import { isPhoenixChain } from "constants/chains";
import { DEFAULT_TOKEN, timeOptions } from "constants/graphConstants";
import { Field } from "state/swap/actions";
import ms from "ms.macro";
import { useWeb3React } from "@web3-react/core";
import useChartDataQuery, { queryType } from "graphql/thegraph/PairMinOHLCs";
import { TrendingUp, TrendingDown } from "react-feather";
import DepthChartContainer from "./AreaChart";
import {
  PriceChangeContainer,
  GraphInnerWrapper,
  GraphLogoHeader,
  GraphTimeWrapper,
  ToggleGlobalWrapper,
  DataSourceSpan,
  DataNameSpan,
} from "./styled";
import { useIsMobile } from "utils/common";
import { EmptyComponent, EmptyComponentSwap } from "components/EmptyComponent";
import { ChartComponent } from "..";
import { useCurrency } from "hooks/Tokens";

export const LogoImage = styled.img<{ useBG?: boolean }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;

  ${({ useBG }) =>
    useBG &&
    css`
      background: radial-gradient(white 60%, #ffffff00 calc(70% + 1px));
      box-shadow: 0 0 1px white;
    `}
`;
export const LogoImageHeader = styled.img<{ useBG?: boolean }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;

  ${({ useBG }) =>
    useBG &&
    css`
      background: radial-gradient(white 60%, #ffffff00 calc(70% + 1px));
      box-shadow: 0 0 1px white;
    `}
`;

function ChartContainer({ currencies, trade, syncing }: any) {
  const { chainId } = useWeb3React();

  const [selectedQueryType, setSelectedQueryType] = useState(
    queryType.OneMonth
  );
  const [selectedQueryTypeAPI, setSelectedQueryTypeAPI] = useState("1M");
  const [selectedChartType, setSelectedChartType] = useState("line");
  const [isDex, setIsDex] = useState<boolean>(true);
  const [tokenA, setTokenA] = useState("");
  const [tokenB, setTokenB] = useState("");

  const {
    data: chartData,
    error,
    isLoading,
  } = useChartDataQuery(
    currencies[Field.OUTPUT]?.wrapped.address || DEFAULT_TOKEN[chainId ?? 1890],
    currencies[Field.INPUT]?.wrapped.address || DEFAULT_TOKEN[chainId ?? 1890],
    ms`30`,
    selectedQueryType,
    chainId,
    setTokenA,
    setTokenB
  );

  let dataToUse = [];

  if (chartData) {
    switch (selectedQueryType) {
      case "1H":
        dataToUse =
          chainId == 1890 ? chartData.poolMinDatas : chartData?.poolMinDatas;
        break;
      case "1D":
        dataToUse =
          chainId == 1890
            ? chartData?.poolHourDatas
            : chartData?.poolHourDatas ?? [];
        break;
      case "1M":
        dataToUse =
          chainId == 1890 ? chartData.poolDayDatas : chartData?.poolDayDatas;
        break;
      case "1Y":
        dataToUse =
          chainId == 1890
            ? chartData.poolMonthDatas
            : chartData?.poolMonthDatas;
        break;
      default:
        dataToUse = [];
    }
  } else {
    dataToUse = [];
  }
  console.log("OOO");
  const isPhoenix = isPhoenixChain(chainId);
  const isMobile = useIsMobile();
  const isPegasus = chainId == 1891;
  const tokenACurrency = useCurrency(tokenA);
  const tokenBCurrency = useCurrency(tokenB);

 
  return (
    <>
      <OuterWrapperGraph>
        <PageWrapperGraph>
          {tokenACurrency &&
          tokenBCurrency &&
          tokenA === "0x7EbeF2A4b1B09381Ec5B9dF8C5c6f2dBECA59c73" &&
          tokenB === "0x7EbeF2A4b1B09381Ec5B9dF8C5c6f2dBECA59c73" ? (
            <EmptyComponent content="Data not available" />
          ) : (
            <>
              <GraphHeader>
                <LogoSection>
                  {chainId == 1890 && isDex && (
                    <>
                      {tokenBCurrency && (
                        <CurrencyLogo
                          style={{ marginRight: "2px" }}
                          currency={tokenBCurrency}
                          size="32px"
                        />
                      )}
                      {tokenACurrency && (
                        <LogoContainer
                          style={{
                            position: "relative",
                            left: -15,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            background: "#091012",
                            height: 40,
                            width: 44,
                          }}
                        >
                          <CurrencyLogo currency={tokenACurrency} size="32px" />
                        </LogoContainer>
                      )}
                    </>
                  )}

                  {chainId == 1891 && (
                    <>
                      {tokenBCurrency && (
                        <CurrencyLogo
                          style={{ marginRight: "2px" }}
                          currency={tokenBCurrency}
                          size="32px"
                        />
                      )}
                      {tokenACurrency && (
                        <LogoContainer
                          style={{
                            position: "relative",
                            left: -15,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            background: "#091012",
                            height: 40,
                            width: 44,
                          }}
                        >
                          <CurrencyLogo currency={tokenACurrency} size="32px" />
                        </LogoContainer>
                      )}
                    </>
                  )}
                  <GraphLogoHeader>
                    <>
                      {" "}
                      <div style={{ fontSize: 18, fontWeight: 500 }}>
                        {tokenBCurrency?.symbol && tokenBCurrency?.symbol}/
                        {tokenACurrency?.symbol && tokenACurrency?.symbol}
                      </div>
                    </>
                    &nbsp; &nbsp; &nbsp;
                    <DataSourceSpan>Data Source</DataSourceSpan>&nbsp;{" "}
                    <DataNameSpan> {"Elektrik Dex"}</DataNameSpan>
                  </GraphLogoHeader>

                  <PriceChangeContainer></PriceChangeContainer>
                </LogoSection>
              </GraphHeader>

              <GraphInnerWrapper>
                <Fragment>
                  <GraphTimeWrapper>
                    {timeOptions.map((time: any) => (
                      <div
                        key={time}
                        style={{
                          padding: "6px",
                          background:
                            selectedQueryType === time
                              ? "linear-gradient(191deg, #1A3848 5.4%, #39484B 92.07%)"
                              : "#1F2831",
                          color:
                            selectedQueryType === time ? "#53C5F8" : "#ffffff",
                          borderRadius: "6px",
                          cursor: "pointer",
                          fontSize: 10,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",

                          width: 40,
                          height: 26,
                          marginRight: 5,
                        }}
                        onClick={() => {
                          setSelectedQueryType(time);
                          setSelectedQueryTypeAPI(time);
                        }}
                      >
                        {time}
                      </div>
                    ))}
                    {!isMobile && <div className="dividerChart"></div>}{" "}
                    <span>
                      <ToggleGlobalWrapper>
                        <ToggleWrapper width="fit-content">
                          <ToggleElement
                            isActive={selectedChartType === "candle"}
                            fontSize="10px"
                            onClick={() => setSelectedChartType("candle")}
                          >
                            <div>Candle</div>
                          </ToggleElement>
                          <ToggleElement
                            isActive={selectedChartType === "line"}
                            fontSize="10px"
                            onClick={() => setSelectedChartType("line")}
                          >
                            <div>Line</div>
                          </ToggleElement>
                        </ToggleWrapper>
                      </ToggleGlobalWrapper>
                    </span>
                  </GraphTimeWrapper>
                </Fragment>
              </GraphInnerWrapper>
              <>
                {selectedChartType === "depth" ? (
                  <DepthChartContainer currencies={currencies} />
                ) : (
                  <>
                    {selectedChartType === "candle" ? (
                      isLoading ? (
                        <img
                          src="https://res.cloudinary.com/hemergy/image/upload/v1718374081/jqxzideqghwwfps0ngox.svg"
                          style={{ height: 300 }}
                          alt="Loading..."
                        />
                      ) : dataToUse && dataToUse.length > 0 ? (
                        <ChartComponent
                          data={dataToUse || []}
                          isPhoenix={isPhoenix}
                          isLimit={false}
                          selectedQueryType={selectedQueryType}
                        />
                      ) : (
                        <EmptyComponentSwap content="Data not available" />
                      )
                    ) : isLoading ? (
                      <img
                        src="https://res.cloudinary.com/hemergy/image/upload/v1718374081/jqxzideqghwwfps0ngox.svg"
                        style={{ height: 300 }}
                        alt="Loading..."
                      />
                    ) : dataToUse && dataToUse.length > 0 ? (
                      <LineChart
                        data={dataToUse || []}
                        isPhoenix={isPhoenix}
                        isLimit={false}
                        selectedQueryType={selectedQueryType}
                      />
                    ) : (
                      <EmptyComponentSwap content="Data not available" />
                    )}
                  </>
                )}
              </>
            </>
          )}
        </PageWrapperGraph>
      </OuterWrapperGraph>
    </>
  );
}

export default ChartContainer;
