import { useWeb3React } from '@web3-react/core'
import { getChainInfoOrDefault, L2ChainInfo } from 'constants/chainInfo'
import { SupportedChainId } from 'constants/chains'
import { BodyRow, CautionTriangle, Link, TitleRow, TitleText, Wrapper } from './styled'


export function ChainConnectivityWarning() {
  const { chainId } = useWeb3React()
  const info = getChainInfoOrDefault(chainId)
  const label = info?.label

  return (
    <Wrapper>
      <TitleRow>
        <CautionTriangle />
        <TitleText>
          <div>Network Warning</div>
        </TitleText>
      </TitleRow>
      <BodyRow>
        {chainId === SupportedChainId.MAINNET ? (
          <div>You may have lost your network connection.</div>
        ) : (
          <div>{label} might be down right now, or you may have lost your network connection.</div>
        )}{' '}
        {(info as L2ChainInfo).statusPage !== undefined && (
          <span>
            <div>Check network status</div>{' '}
            <Link href={(info as L2ChainInfo).statusPage || ''}>
              <div>here.</div>
            </Link>
          </span>
        )}
      </BodyRow>
    </Wrapper>
  )
}
