import { useEffect, useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import {
  apolloClient,
  apolloClientMainnet,
  apolloClientTestnet,
  voteEscrowClient,
} from "graphql/thegraph/apollo";

const GLOBAL_SWAPS = gql`
  query MyQuery($owner: String!) {
    swaps(
      first: 10
      orderBy: timestamp
      orderDirection: desc
      subgraphError: allow
      where:{origin:$owner}
    ) {
      id
      timestamp
      pool {
        token0 {
          id
          symbol
        }
        token1 {
          id
          symbol
        }
      }
      origin
      amount0
      amount1
      amountUSD
    }
  }
`;

export const useGetGlobalSwaps = (chainId: any, owner:string) => {
  console.log("OSOSOS", chainId);
  const { loading, error, data, } = useQuery(GLOBAL_SWAPS, {
    client: chainId == 1890 ? apolloClientMainnet : apolloClientTestnet,
    variables: { owner, chainId},
    fetchPolicy: "no-cache",
  });



  return {
    data,
    loading,
    error,
  };
};
