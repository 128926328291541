import { STAKER_CONTRACT } from "constants/addresses";
import { useWeb3React } from "@web3-react/core";
import { useState } from "react";
import { useStaking } from "./useStaking";
import { addPopup } from "state/application/reducer";

export const useHandleUnStake = (dispatch: any) => {
  const [txHash, setTxHash] = useState("");
  const [loading, setLoading] = useState(false);
  const { account } = useWeb3React();
  const { staking } = useStaking();

  const handleUnStake = async (stakingTokenId: any) => {
    try {
      setLoading(true);
      const response = await staking.unStakePosition(
        parseInt(stakingTokenId?._hex).toString(),
        account
      );


      if (response && response?.hash) {
        setTimeout(() => {
          setTxHash(response?.hash);
        }, 5000);
        dispatch(
          addPopup({
            content: { success: "Successfully UnStaked" },
            key: "success",
          })
        );
      }
    } catch (error) {
      dispatch(
        addPopup({
          content: { error: "Failed to Unstake" },
          key: "error",
        })
      );
      console.error("ErrorwhileUnstaking:", error);
    } finally {
      setLoading(false);
    }
  };

  return { handleUnStake, loading, txHash };
};
