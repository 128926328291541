import Loader from "components/Icons/LoadingSpinner";
import Input from "components/NumericalInput";
import { transparentize } from "polished";
import styled from "styled-components/macro";

export const RateWrapper = styled.div`
  background:  ${({ theme }) => theme.inputGray};
  border-radius: 10px;
  padding: 10px 16px;
  // margin-bottom: 15px;
  flex: 1 1 70%;
  height: 45px;
  justify-content: space-between;
  display: flex;
  flex-flow: row wrap;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    gap: 10px;
  `}
`;
export const RateHeader = styled.div`
text-align:right;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
  margin-top: -50px;
  margin-left: -13px;
  width: 100%;
  color:'#ffffff' !important;
  /* color: ${({ theme }) => transparentize(0.3, theme.white)}; */
`;

export const RateFooter = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
  margin-top: -50px;
  margin-right: -10px;
  padding:0px 4px;
  width: 100%;
  color: '#ffffff' !important;
  /* Similar to the RateHeader, but adjust for your theme and positioning as needed */
`;

export const RateHeaderMarket = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
  margin-top: -50px;
    margin-left: -63px;
  width: 100%;
  color:'#ffffff' !important;
  /* color: ${({ theme }) => transparentize(0.3, theme.white)}; */
`;

export const HeaderContent = styled.div`
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  margin-left:5;
  color:'#445156' !important;
  line-height:34px;
  /* color: ${({ theme }) => transparentize(0.3, theme.white)}; */
`;
export const MarketPriceButton = styled.button`
  background: #1f2831;
  color:  ${({ theme }) => theme.white};
  white-space: nowrap;
  border: none;
  font-weight: 500;
  cursor: pointer;
  border-radius: 9px;
  padding: 5px 8px;
  font-size: 11px;

  &:disabled {
    cursor: default;
    opacity: 0.6;
  }
`
export const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
`;
export const NumericalInput = styled(Input)<{ $loading: boolean }>`
  display: flex;
  align-items: center;
  font-size:12px;
  background: none;
  border: none;
  width: 100%;
  text-align: left;

  &::placeholder {
    color: ${({ theme }) => transparentize(0.3, "#fff")};
  }
`;
export const ActiveCurrency = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: none;
  background: none;
  padding: 0;
  margin: 0 0 0 auto;
  gap: 8px;
  max-width: 130px;
  width: auto;
  cursor: pointer;
`;

export const ActiveSymbol = styled.span`
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  text-align: right;
  padding: 10px 0;
`;

export const ActiveIcon = styled.div`
  --size: 12px;
  color: #fff;
  width: var(--size);
  min-width: var(--size);
  height: var(--size);
  min-height: var(--size);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const RateLoader = styled(Loader)`
  margin: 5px;
`

export const EstimatedRate = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  min-height: 42px;
  margin: 0;
  padding: 12px 10px 14px;
  font-size: 13px;
  border-radius: 0 0 16px 16px;
  font-weight: 400;
  background: ${({ theme }) => theme.black};
  border: 2px solid ${({ theme }) => theme.textSecondary};

  > b {
    display: flex;
    flex-flow: row nowrap;
    font-weight: normal;
    text-align: left;
    color: ${({ theme }) => transparentize(0.3, theme.textPrimary)};
  }

  // TODO: Make the question helper icon transparent through a prop instead
  > b svg {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }

  > span > i {
    font-style: normal;
    color: ${({ theme }) => transparentize(0.3, theme.textPrimary)};
  }
`
