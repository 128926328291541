import gql from 'graphql-tag';
import { useState, useEffect, useMemo } from 'react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { apolloBlockClient } from 'graphql/thegraph/apollo';
import { splitQuery } from 'utils/queries';
import {
  useActiveNetworkVersion,
  SupportedNetwork,
} from 'hooks/useNetworkInfo';

export const START_BLOCKS: { [key: string]: number } = {
  [SupportedNetwork.ETHEREUM]: 14292820,
  [SupportedNetwork.POLYGON]: 25459720,
  [SupportedNetwork.ARBITRUM]: 175,
  [SupportedNetwork.OPTIMISM]: 10028767,
  [SupportedNetwork.CELO]: 13916355,
  [SupportedNetwork.BNB]: 26324014,
  [SupportedNetwork.AVALANCHE]: 31422450,
  [SupportedNetwork.BASE]: 1371680,
  [SupportedNetwork.LIGHTLINK_PEGASUS]: 29931240,
  [SupportedNetwork.LIGHTLINK_PHOENIX]: 29931240,
};

export const GET_BLOCKS = (timestamps: string[]) => {
  let queryString = 'query blocks {';
  queryString += timestamps.map((timestamp) => {
    return `t${timestamp}:blocks(first: 1, orderBy: timestamp, orderDirection: desc, where: { timestamp_gt: ${timestamp}, timestamp_lt: ${
      timestamp + 600
    } }) {
        number
      }`;
  });
  queryString += '}';
  return gql(queryString);
};

/**
 * for a given array of timestamps, returns block entities
 * @param timestamps
 */
export function useBlocksFromTimestamps(
  timestamps: number[],
  blockClientOverride?: ApolloClient<NormalizedCacheObject>
): {
  blocks:
    | {
        timestamp: string;
        number: any;
      }[]
    | undefined;
  error: boolean;
} {
  const [activeNetwork] = useActiveNetworkVersion();
  const [blocks, setBlocks] = useState<any>();
  const [error, setError] = useState(false);


  const activeBlockClient = apolloBlockClient;
  const networkBlocks = blocks?.[activeNetwork?.id];

  useEffect(() => {
    async function fetchData() {
      const results = await splitQuery(
        GET_BLOCKS,
        activeBlockClient,
        [],
        timestamps
      );
      if (results) {
        setBlocks({ ...(blocks ?? {}), [activeNetwork?.id]: results });
      } else {
        setError(true);
      }
    }
    if (!networkBlocks && !error) {
      fetchData();
    }
  });

  const blocksFormatted = useMemo(() => {
    if (blocks?.[activeNetwork?.id]) {
      const networkBlocks = blocks?.[activeNetwork?.id];
      const formatted = [];
      for (const t in networkBlocks) {
        if (networkBlocks[t].length > 0) {
          const number = networkBlocks[t][0]['number'];
          const deploymentBlock = START_BLOCKS[activeNetwork?.id];
          const adjustedNumber =
            number > deploymentBlock ? number : deploymentBlock;

          formatted.push({
            timestamp: t.split('t')[1],
            number: adjustedNumber,
          });
        }
      }
      return formatted;
    }
    return undefined;
  }, [activeNetwork?.id, blocks]);

  return {
    blocks: blocksFormatted,
    error,
  };
}
