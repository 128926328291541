import { TableContainerEmpty, TableContainerEmptyPool, TableContainerEmptySwap } from "pages/Pool/poolStyle";
import { InboxIcon } from "pages/Pool/styleds";

export const EmptyComponent = ({ content, style }: any) => {
  return (
    <TableContainerEmpty style={style}>
      <div style={{ textAlign: "center" }}>
        <InboxIcon strokeWidth={2} color="#52666c" />
        <div style={{ color: "#52666c", fontWeight: 500 }}>{content}</div>
      </div>
    </TableContainerEmpty>
  );
};


export const EmptyComponentSwap = ({ content, style }: any) => {
  return (
    <TableContainerEmptySwap style={style}>
      <div style={{ textAlign: "center" }}>
        <InboxIcon strokeWidth={2} color="#52666c" />
        <div style={{ color: "#52666c", fontWeight: 500 }}>{content}</div>
      </div>
    </TableContainerEmptySwap>
  );
};


export const EmptyComponentLock = ({ content, style }: any) => {
  return (
    <TableContainerEmptyPool style={style}>
      <div style={{ textAlign: "center" }}>
        <InboxIcon strokeWidth={2} color="#52666c" />
        <div style={{ color: "#52666c", fontWeight: 500 }}>{content}</div>
      </div>
    </TableContainerEmptyPool>
  );
};