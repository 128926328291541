import {  useQuery } from "@apollo/client";
import gql from "graphql-tag";
import {  useMemo } from "react";
import {apolloLimitOHLCsClient} from "./apollo";

import { useWeb3React } from "@web3-react/core";

export const enum queryTypeAPI {
  HOUR = "1h",
}

export const enum queryType {
  OneMIN = "1Min",
  OneHour = "1H",
  FiveMin = "5Min",
  OneDay = "1D",
  OneMonth = "1M",
  OneYear = "1Y",
}


export const updatedMinDataQuery = gql`
query pairMinDatas($token0: String, $token1: String,  $timestamp: Int) {
  pairMinOHLCs(
    where: {token1: $token1, token0: $token0,periodStartUnix_gte: $timestamp
}
    orderBy: periodStartUnix
    orderDirection: desc
  ) {
    periodStartUnix
    token0
    token1
    id
    high
    low
    open
    close
  }
}
`;

export const updatedHourDataQuery = gql`
 query pairHourDatas($token0: String, $token1: String, $timestamp: Int) {
  pairHourOHLCs(
    where: {token1: $token1, token0: $token0, periodStartUnix_gte:  $timestamp}
    orderBy: periodStartUnix
    orderDirection: desc
  ) {
    periodStartUnix
    token0
    token1
    id
    high
    low
    open
    close
  }
}
`;

export const updatedDayDataQuery = gql`
  query pairDayDatas($token0: String, $token1: String,$timestamp: Int) {
  pairDayOHLCs(
    where: {token1: $token1, token0: $token0, periodStartUnix_gte: $timestamp}
    orderBy: periodStartUnix
    orderDirection: desc
  ) {
    periodStartUnix
    token0
    token1
    id
    high
    low
    open
    close
  }
}
`;

export const monthDataQuery = gql`
  query pairMonthDatas($token0: String, $token1: String,$timestamp: Int) {
  pairMonthOHLCs(
    where: {token1: $token1, token0: $token0, periodStartUnix_gte: $timestamp}
    orderBy: periodStartUnix
    orderDirection: desc
  ) {
    periodStartUnix
    token0
    token1
    id
    high
    low
    open
    close
  }
}
`;

export function arrangeTokens(
  tokenA: string | undefined,
  tokenB: string | undefined,
  certainAddress: string
) {
  if (tokenA === undefined || tokenB === undefined) {
    return { tokenA, tokenB };
  }
  // Check if tokenA is the certain address
  if (tokenA.toLowerCase() === certainAddress.toLowerCase()) {
    return { tokenA, tokenB };
  }
  // Check if tokenB is the certain address
  else if (tokenB.toLowerCase() === certainAddress.toLowerCase()) {
    // Swap tokenA and tokenB
    return { tokenA: tokenB, tokenB: tokenA };
  }
  // If neither token is the certain address, return them as they are
  return { tokenA, tokenB };
}

export default function useLimitOrderChartDataQuery(
  token0: string | undefined,
  token1: string | undefined,
  interval: number,
  kind: queryType,
  selectedHistory: string
): any {
  const { chainId } = useWeb3React();
  const { tokenA, tokenB } = arrangeTokens(
    token0,
    token1,
    "0xA4CBbd0f503720a0cA3C950D647A8c23FBE9702B"
  );

  const OneHourAgoTimestamp = () => {
    const currentTimestamp = Date.now();
    const oneHourAgoTimestamp = currentTimestamp - 1 * 60 * 60 * 1000;
    const oneHourAgoTimestampInSeconds = Math.floor(oneHourAgoTimestamp / 1000);
    return oneHourAgoTimestampInSeconds;
  };

  const TwentyFourHoursAgoTimestamp = () => {
    const currentTimestamp = Date.now();
    const twentyFourHoursAgoTimestamp = currentTimestamp - 24 * 60 * 60 * 1000; // Subtracting 24 hours in milliseconds
    const twentyFourHoursAgoTimestampInSeconds = Math.floor(
      twentyFourHoursAgoTimestamp / 1000
    );
    return twentyFourHoursAgoTimestampInSeconds;
  };

  const OneMonthAgoTimestamp = () => {
    const currentTimestamp = Date.now();
    // Calculate 30 days ago (approximately, considering different month lengths)
    const oneMonthAgoTimestamp = currentTimestamp - 30 * 24 * 60 * 60 * 1000; // Subtracting 30 days in milliseconds
    const oneMonthAgoTimestampInSeconds = Math.floor(
      oneMonthAgoTimestamp / 1000
    );
    return oneMonthAgoTimestampInSeconds;
  };

  const OneYearAgoTimestamp = () => {
    const currentTimestamp = Date.now();
    // Calculate 365 days ago (approximately, considering leap years)
    const oneYearAgoTimestamp = currentTimestamp - 365 * 24 * 60 * 60 * 1000; // Subtracting 365 days in milliseconds
    const oneYearAgoTimestampInSeconds = Math.floor(oneYearAgoTimestamp / 1000);
    return oneYearAgoTimestampInSeconds;
  };



  const variables = useMemo(() => {
    return {
      token0: tokenA?.toLowerCase(),
      token1: tokenB?.toLowerCase(),
      timestamp:
        kind === queryType.OneHour
          ? OneHourAgoTimestamp()
          : kind === queryType.OneDay
            ? TwentyFourHoursAgoTimestamp()
            : kind === queryType.OneMonth
              ? OneMonthAgoTimestamp()
              : kind === queryType.OneYear
                ? OneYearAgoTimestamp()
                : OneMonthAgoTimestamp(),
    };
  }, [tokenA, tokenB, kind]);

  const {
    data,
    loading: isLoading,
    error,
  } = useQuery(
    kind === queryType.OneHour
      ? updatedMinDataQuery
      : kind === queryType.OneMonth
        ? updatedDayDataQuery
        : kind === queryType.OneYear
          ? monthDataQuery
          : kind === queryType.OneDay
            ? updatedHourDataQuery
            : updatedHourDataQuery,
    {
      variables,
      client: apolloLimitOHLCsClient,
      fetchPolicy: 'cache-first', 

    }
  );

  return useMemo(
    () => ({ error, isLoading, data }),
    [data, error, isLoading, selectedHistory]
  );
}
