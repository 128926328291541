import {
  formatNumber,
  formatPriceImpact,
  NumberType,
} from "@uniswap/conedison/format";
import { Percent } from "@uniswap/sdk-core";
import Row from "components/Row";
import { MouseoverTooltip } from "components/Tooltip";
import { useMemo } from "react";
import { ThemedText } from "theme";
import { warningSeverity } from "utils/prices";
import { FiatLoadingBubble } from "./styled";
import { formatDollarAmountSuffix, formatNumberWithSuffix } from "utils/numbers";

export function truncateFormatPriceImpact(value: any) {
  if (value.length <= 10) {
    return value;
  }
  const truncatedValue = value.slice(0, 10) + "...";

  return truncatedValue;
}

export function truncateFormatPriceImpactPosition(value: any) {
  if (value.length <= 10) {
    return value;
  }
  const truncatedValue = value.slice(0, 30) + "...";

  return truncatedValue;
}

export function formatAndTruncateNumber(value: any, numberType?: any) {
  // Format the number using your existing function
  const formattedValue = formatNumber(value, numberType);

  // Check if the formatted value is already shorter than 10 characters
  if (formattedValue.length <= 10) {
    return formattedValue;
  }

  // Truncate the value to 10 characters
  const truncatedValue = formattedValue.slice(0, 10) + "...";

  return truncatedValue;
}

export function FiatValue({
  fiatValue,
  priceImpact,
}: {
  fiatValue: { data?: number; isLoading: boolean };
  priceImpact?: Percent;
}) {
  const priceImpactColor = useMemo(() => {
    if (!priceImpact) return undefined;
    if (priceImpact.lessThan("0")) return "accentSuccess";
    const severity = warningSeverity(priceImpact);
    if (severity < 1) return "textTertiary";
    if (severity < 3) return "deprecated_yellow1";
    return "accentFailure";
  }, [priceImpact]);

  if (fiatValue.isLoading) {
    return <FiatLoadingBubble />;
  }

  return (
    <Row gap="sm">
      <span style={{ color: "#868E9B", fontSize: "12px"}}>
        {fiatValue.data ? (
          <div>

            {formatNumberWithSuffix(fiatValue.data)}
            </div>

        ) : (
          <MouseoverTooltip
            text={
              <div>Not enough liquidity to show accurate USD value.</div>
            }
          >
            -
          </MouseoverTooltip>
        )}
      </span>
      {priceImpact && (
        <ThemedText.BodySmall color={priceImpactColor}>
          
            <div>
              {formatNumberWithSuffix(Number(formatPriceImpact(priceImpact)))}
            </div>
          
        </ThemedText.BodySmall>
      )}
    </Row>
  );
}
