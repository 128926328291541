import { STAKER_CONTRACT } from "constants/addresses";
import { useWeb3React } from "@web3-react/core";
import { useState } from "react";
import { useStaking } from "./useStaking";
import { addPopup } from "state/application/reducer";
import { getChainAddresses } from "@eltk/staking-sdk";

export const useHandleStake = (dispatch: any, account: any, chainId: any) => {
  const [txHash, setTxHash] = useState("");
  const [loading, setLoading] = useState(false);
  const { staking } = useStaking();

  const handleStake = async (stakingTokenId: any) => {
    try {
      setLoading(true);
      if (staking) {
        const response = await staking.stakePosition(
          account,
          getChainAddresses(chainId ?? 1891)?.Staker,
          Number(stakingTokenId)
        );

        if (response?.hash) {

          setTimeout(() => {
            setTxHash(response?.hash);
          }, 5000);

          dispatch(
            addPopup({
              content: { success: "Successfully Staked" },
              key: "success",
            })
          );
        }
      }


     
    } catch (error) {
      dispatch(
        addPopup({
          content: { error: "Failed to Stake" },
          key: "error",
        })
      );
      console.error("Errorwhilestaking:", error);
    } finally {
      setLoading(false);
    }
  };

  return { handleStake, loading, txHash };
};
