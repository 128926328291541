import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import {
  StakingClient,
  StakingClientMainnet,
  StakingClientTestnet,
  apolloClient,
  apolloClientMainnet,
  apolloClientTestnet,
  voteEscrowClient,
} from "graphql/thegraph/apollo";
import { useEffect } from "react";

const GET_LPS = gql`
  query MyQuery($from: String!, $v3Pool: String!) {
    lps(where: { from: $from, pid_: { v3Pool: $v3Pool } }) {
      id
      liquidity
      tickLower
      tickUpper
      pid {
        v3Pool
      }
    }
  }
`;


export const LPS_BALANCE = gql`
  query MyQuery($ids: [String!]) {
    positions(
      orderBy: transaction__timestamp
      orderDirection: desc
      where: { id_in: $ids }
    ) {
      id
      depositedToken0
      depositedToken1
      withdrawnToken0
      withdrawnToken1
    }
  }
`;


const GET_ALL_LPS = gql`
query MyQuery($from: String!, $v3Pool: String!) {
  positions(
    where: {
      owner: $from
      pool: $v3Pool
    }
  ) {
    liquidity
    tickLower {
      tickIdx
    }
    tickUpper {
      tickIdx
    }
    pool {
      id
    }
    id
  }
}
`;

export const useGetLps = (account: any, poolId: any,chainId:any) => {
  const { loading, error, data, refetch } = useQuery(GET_LPS, {
    variables: { from: account, v3Pool: poolId },
    client: chainId == 1890 ? StakingClientMainnet : StakingClientTestnet,
    fetchPolicy:'network-only'
  });

  useEffect(() => {
    if (chainId) {
      refetch(); 
    }
  }, [chainId, refetch]); 

  return {
    data,
    loading,
  };
};


export const useLPSBalance = (ids:any,chainId:any) => {
  const { loading, error, data } = useQuery(LPS_BALANCE, {
    variables: { ids }, 
    client: chainId == 1890 ? apolloClientMainnet : apolloClientTestnet,
    fetchPolicy:'network-only'
  });

  return {
    data,
    loading,
    error,
  };
};


export const useGetUnstakeLps = (account: any, poolId: any,chainId:any) => {
  const { loading, error, data ,refetch} = useQuery(GET_ALL_LPS, {
    variables: { from: account, v3Pool: poolId },
    client: chainId == 1890 ? apolloClientMainnet : apolloClientTestnet,
    fetchPolicy:'network-only'
  });
  useEffect(() => {
    if (chainId) {
      refetch(); 
    }
  }, [chainId, refetch,poolId]); 

  return {
    data,
    loading,
  };
};
