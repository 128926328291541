import { useEffect, useState } from "react";
import { Pool, StakerWallet } from "@eltk/staking-sdk";
import { Signer, providers } from "ethers";
import { useSignerWallet } from "hooks/useSignerWallet";
import { usePool } from "./usePool";
import { getStakedPositionData } from "hooks/staking/useCheckHarvestTimeDuration";

export const useGetStakedPositionsData = (stakedIds: any,chainId:any) => {
  const [stakedPositionData, setStakedPositionData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const fetchData = async () => {
      try {
        if(stakedIds && stakedIds?.length){

        
        const stakedDataPromises = stakedIds.map(async (stakedId: any,chainId:any) => {
          return await getStakedPositionData(stakedId.id,chainId);
        });

        const stakedDataResults = await Promise.all(stakedDataPromises);
        setStakedPositionData(stakedDataResults);
      }

      } catch (error) {
        console.error("Error fetching staked position data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    

  }, [stakedIds,chainId]);

  return {
    stakedPositionData,
    loading,
  };
};