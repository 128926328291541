import { useWeb3React } from '@web3-react/core'
import Loader from 'components/Icons/LoadingSpinner'
import { getChainInfo } from 'constants/chainInfo'
import { CheckMarkIcon } from 'components/Icons'
import { useTheme } from 'styled-components/macro'
import { ChainSelectorRowProps } from 'types'
import {CaptionText, Container, Label, Logo, Status} from './styled'
import { LOGO_SIZE } from 'constants/common'

export default function ChainSelectorRow({ disabled, targetChain, onSelectChain, isPending }: ChainSelectorRowProps) {
  const { chainId } = useWeb3React()
  const active = chainId === targetChain
  const { label, logoUrl } = getChainInfo(targetChain)

  const theme = useTheme()

  return (
    <Container
      disabled={!!disabled}
      onClick={() => {
        if (!disabled) onSelectChain(targetChain)
      }}
      data-testid={`chain-selector-option-${label.toLowerCase()}`}
    >
      <Logo src={logoUrl} alt={label} />
      <Label>{label}</Label>
      {disabled && (
        <CaptionText>
          <div>Unsupported by your wallet</div>
        </CaptionText>
      )}
      {isPending && (
        <CaptionText>
          <div>Approve in wallet</div>
        </CaptionText>
      )}
      <Status>
        {active && <CheckMarkIcon width={LOGO_SIZE} height={LOGO_SIZE} color={theme.accentActive} />}
        {!active && isPending && <Loader width={LOGO_SIZE} height={LOGO_SIZE} />}
      </Status>
    </Container>
  )
}
