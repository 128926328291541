import { useEffect, useMemo, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { StakingClient, StakingClientMainnet, StakingClientTestnet, apolloClient , voteEscrowClient } from "graphql/thegraph/apollo";

const GET_WHITELIST_POOL_IDS = gql`
  query WhitelistPools {
    pools {
      v3Pool
    }
  }
`;


const GET_ACTIVE_PROPOSAL_POOLS = gql`
query MyQuery {
  proposals(where: {executed: false}) {
    pool
  }
}
`


const GET_POOL_BY_ID = gql`
query GetById($id: String!) {
  pools(where: {id: $id}) {
    feeTier
    id
    token1 {
      symbol
      id
    }
    token0 {
      symbol
      id
    }
    totalValueLockedUSD
    poolDayData(orderBy: date, orderDirection: desc, first: 7) {
      id
      tvlUSD
    }
  }
}
`;


const GET_POOLS_BY_IDS = gql`
  query GetPoolsByIds($ids: [String!]!) {
    pools(where: { id_not_in: $ids }) {
      feeTier
      id
      token1 {
        symbol
        id
      }
      token0 {
        symbol
        id
      }
      feesUSD
      totalValueLockedUSD

      poolDayData(orderBy: date, orderDirection: desc, first: 7) {
        id
        tvlUSD
      }
    }
  }
`;

export function useGetAllPools() {
  const { data, error } = useQuery(GET_WHITELIST_POOL_IDS, {
    client: StakingClient,
    fetchPolicy: "no-cache",

  });

  const { data:proposalPools, error:proposalError } = useQuery(GET_ACTIVE_PROPOSAL_POOLS, {
    client: voteEscrowClient,
    fetchPolicy: "no-cache",
  });

  const [loading, setLoading] = useState(false);
  const [pools, setPools] = useState<any[]>([]);

  useEffect(() => {
    const getPoolDetail = async () => {
      if (!data?.pools?.length && !proposalPools.proposals.length) return;
      setLoading(true);
      let poolIds = proposalPools.proposals.map((item:any)=>item?.pool);
      let ids = data?.pools.map((item: any) => item?.v3Pool);
      ids = [...ids , ...poolIds]
      let poolData = await getPoolData(ids);
      let pools = poolData.pools.map((pool: any) => {
        let poolCopy = { ...pool };
        let volume = poolCopy?.poolDayData?.reduce(
          (acc: number, currentValue: any) => {
            // Extract tvlUSD from the current object and convert it to a number
            const tvlUSD = parseFloat(currentValue.tvlUSD);

            // Add the tvlUSD to the accumulator
            return acc + tvlUSD;
          },
          0
        );
        poolCopy.volume = volume;
        return poolCopy;
      });
      setPools(pools);
      setLoading(false);
    };
    getPoolDetail();
  }, [data, proposalPools]);

  return {
    error: error,
    data: pools,
    loading,
  };
}

export function useGetPoolIds(chainId: any) {
  const { data, error, loading, refetch } = useQuery(GET_WHITELIST_POOL_IDS, {
    client: chainId === 1890 ? StakingClientMainnet : StakingClientTestnet,
    variables: { chainId },
    fetchPolicy: "network-only",
  });

  const poolIds = data?.pools.map((pool: any) => pool?.v3Pool) || [];

  return { error, data: poolIds, loading };
}


export async function getPoolData(ids: string[]) {
  const { data } = await apolloClient.query<any>({
    query: GET_POOLS_BY_IDS,
    variables: { ids },
  });
  return data;
}

export function useWhitelistPoolsLength() {
  const { data, error, loading } = useQuery(GET_WHITELIST_POOL_IDS, {
    client: StakingClient,
    fetchPolicy: "no-cache",

  });

  return {
    data: data?.pools?.length,
    error,
    loading,
  };
}



export  function getPoolById(id: string) {
  id = id.toLowerCase()
  const { data, error, loading } = useQuery(GET_POOL_BY_ID, {
    client: apolloClient,
    variables: { id },
  });

  return {
    data,
    loading,
    error
  };
}




