import {
  ApolloClient,
  ApolloLink,
  concat,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { SupportedChainId } from "constants/chains";

import store from "../../state/index";

/* ApolloOHLCsClient */
const CHAIN_OHLC_SUBGRAPH_URL: Record<number, string> = {
  [SupportedChainId.LIGHTLINK_PEGASUS_TESTNET]:process.env
  .REACT_APP_CHAIN_SUBGRAPH_URL_TESTNET
  ? process.env.REACT_APP_CHAIN_SUBGRAPH_URL_TESTNET
  : "https://graph.pegasus.lightlink.io/query/subgraphs/name/ELEKTRIK-GRAPH-V2",
  // UP_FIX
  [SupportedChainId.LIGHTLINK_PHOENIX_MAINNET]: process.env
  .REACT_APP_CHAIN_SUBGRAPH_URL_MAINNET
  ? process.env.REACT_APP_CHAIN_SUBGRAPH_URL_MAINNET
  : "https://graph.phoenix.lightlink.io/query/subgraphs/name/ELEKTRIK-GRAPH-V2",
};

export const httpOHLCsLink = new HttpLink({
  uri: CHAIN_OHLC_SUBGRAPH_URL[SupportedChainId.LIGHTLINK_PEGASUS_TESTNET],
});
export const ohlcMiddleware = new ApolloLink((operation, forward) => {
  const chainId = store.getState().application.chainId ?? 1891;
  operation.setContext(() => ({
    uri:
      chainId && CHAIN_OHLC_SUBGRAPH_URL[chainId]
        ? CHAIN_OHLC_SUBGRAPH_URL[chainId]
        : CHAIN_OHLC_SUBGRAPH_URL[SupportedChainId.LIGHTLINK_PEGASUS_TESTNET],
  }));

  return forward(operation);
});
export const apolloOHLCsClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(ohlcMiddleware, httpOHLCsLink),
});

