// eslint-disable-next-line no-restricted-imports
import { t, } from "@lingui/macro";
import { Trace } from "@uniswap/analytics";
import {
  InterfaceEventName,
  InterfaceModalName,
} from "@uniswap/analytics-events";
import { Currency, Token } from "@uniswap/sdk-core";
import { useWeb3React } from "@web3-react/core";
import { sendEvent } from "components/analytics";
import useDebounce from "hooks/useDebounce";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import useToggle from "hooks/useToggle";
import useNativeCurrency from "lib/hooks/useNativeCurrency";
import { getTokenFilter } from "lib/hooks/useTokenList/filtering";
import {
  tokenComparator,
  useSortTokensByQuery,
} from "lib/hooks/useTokenList/sorting";
import {
  ChangeEvent,
  KeyboardEvent,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import { Text } from "rebass";
import { useAllTokenBalances } from "state/connection/hooks";
import styled, { useTheme } from "styled-components/macro";
import { UserAddedToken } from "types/tokens";

import {
  useDefaultActiveTokens,
  useIsUserAddedToken,
  useSearchInactiveTokenLists,
  useToken,
} from "../../hooks/Tokens";
import { CloseIcon, ThemedText } from "../../theme";
import { isAddress } from "../../utils";
import Column from "../Column";
import Row, { RowBetween } from "../Row";
import CommonBases from "./CommonBases";
import { CurrencyRow, formatAnalyticsEventProperties } from "./CurrencyList";
import CurrencyList from "./CurrencyList";
import {
  ContentWrapper,
  PaddedColumn,
  SearchInput,
  Separator,
} from "./styleds";
import { CurrencySearchProps } from "types";
import { Backdrop } from "components/IntroModal/styled";

export function CurrencySearch({
  selectedCurrency,
  onCurrencySelect,
  otherSelectedCurrency,
  showCommonBases,
  showCurrencyAmount,
  disableNonToken,
  onDismiss,
  isOpen,
  onlyShowCurrenciesWithBalance,
}: CurrencySearchProps) {
  const { chainId } = useWeb3React();
  const theme = useTheme();

  const [tokenLoaderTimerElapsed, setTokenLoaderTimerElapsed] = useState(false);

  // refs for fixed size lists
  const fixedList = useRef<FixedSizeList>();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedQuery = useDebounce(searchQuery, 200);
  const isAddressSearch = isAddress(debouncedQuery);
  const searchToken = useToken(debouncedQuery);
  const searchTokenIsAdded = useIsUserAddedToken(searchToken);

  useEffect(() => {
    if (isAddressSearch) {
      sendEvent({
        category: "Currency Select",
        action: "Search by address",
        label: isAddressSearch,
      });
    }
  }, [isAddressSearch]);

  const defaultTokens = useDefaultActiveTokens(chainId);

  const filteredTokens: Token[] = useMemo(() => {
    return Object.values(defaultTokens).filter(getTokenFilter(debouncedQuery));
  }, [defaultTokens, debouncedQuery]);

  const [balances, balancesAreLoading] = useAllTokenBalances();
  const sortedTokens: Token[] = useMemo(
    () =>
      !balancesAreLoading
        ? filteredTokens
            .filter((token) => {
              if (onlyShowCurrenciesWithBalance) {
                return balances[token.address]?.greaterThan(0);
              }

              // If there is no query, filter out unselected user-added tokens with no balance.
              if (!debouncedQuery && token instanceof UserAddedToken) {
                if (
                  selectedCurrency?.equals(token) ||
                  otherSelectedCurrency?.equals(token)
                )
                  return true;
                return balances[token.address]?.greaterThan(0);
              }
              return true;
            })
            .sort(tokenComparator.bind(null, balances))
        : [],
    [
      balances,
      balancesAreLoading,
      debouncedQuery,
      filteredTokens,
      otherSelectedCurrency,
      selectedCurrency,
      onlyShowCurrenciesWithBalance,
    ]
  );
  const isLoading = Boolean(balancesAreLoading && !tokenLoaderTimerElapsed);

  const filteredSortedTokens = useSortTokensByQuery(
    debouncedQuery,
    sortedTokens
  );

  const native = useNativeCurrency(chainId);
  const wrapped = native.wrapped;

  const searchCurrencies: Currency[] = useMemo(() => {
    const s = debouncedQuery.toLowerCase().trim();

    const tokens = filteredSortedTokens.filter(
      (t) => !(t.equals(wrapped) || (disableNonToken && t.isNative))
    );
    const shouldShowWrapped =
      !onlyShowCurrenciesWithBalance ||
      (!balancesAreLoading && balances[wrapped.address]?.greaterThan(0));
    const natives = (
      disableNonToken || native.equals(wrapped)
        ? [wrapped]
        : shouldShowWrapped
          ? [native, wrapped]
          : [native]
    ).filter(
      (n) =>
        n.symbol?.toLowerCase()?.indexOf(s) !== -1 ||
        n.name?.toLowerCase()?.indexOf(s) !== -1
    );

    return [...natives, ...tokens];
  }, [
    debouncedQuery,
    filteredSortedTokens,
    onlyShowCurrenciesWithBalance,
    balancesAreLoading,
    balances,
    wrapped,
    disableNonToken,
    native,
  ]);

  const handleCurrencySelect = useCallback(
    (currency: Currency, hasWarning?: boolean) => {
      onCurrencySelect(currency, hasWarning);
      if (!hasWarning) onDismiss();
    },
    [onDismiss, onCurrencySelect]
  );

  // clear the input on open
  useEffect(() => {
    if (isOpen) setSearchQuery("");
  }, [isOpen]);

  // manage focus on modal show
  const inputRef = useRef<HTMLInputElement>();
  const handleInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const checksummedInput = isAddress(input);
    setSearchQuery(checksummedInput || input);
    fixedList.current?.scrollTo(0);
  }, []);

  const handleEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        const s = debouncedQuery.toLowerCase().trim();
        if (s === native?.symbol?.toLowerCase()) {
          handleCurrencySelect(native);
        } else if (searchCurrencies.length > 0) {
          if (
            searchCurrencies[0].symbol?.toLowerCase() ===
              debouncedQuery.trim().toLowerCase() ||
            searchCurrencies.length === 1
          ) {
            handleCurrencySelect(searchCurrencies[0]);
          }
        }
      }
    },
    [debouncedQuery, native, searchCurrencies, handleCurrencySelect]
  );

  // menu ui
  const [open, toggle] = useToggle(false);
  const node = useRef<HTMLDivElement>();
  useOnClickOutside(node, open ? toggle : undefined);

  // if no results on main list, show option to expand into inactive
  const filteredInactiveTokens = useSearchInactiveTokenLists(
    !onlyShowCurrenciesWithBalance &&
      (filteredTokens.length === 0 ||
        (debouncedQuery.length > 2 && !isAddressSearch))
      ? debouncedQuery
      : undefined
  );

  // Timeout token loader after 3 seconds to avoid hanging in a loading state.
  useEffect(() => {
    const tokenLoaderTimer = setTimeout(() => {
      setTokenLoaderTimerElapsed(true);
    }, 3000);
    return () => clearTimeout(tokenLoaderTimer);
  }, []);

  return (
    <>

      <ContentWrapper>
        <Trace
          name={InterfaceEventName.TOKEN_SELECTOR_OPENED}
          modal={InterfaceModalName.TOKEN_SELECTOR}
          shouldLogImpression
        >
          <PaddedColumn gap="16px">
            <RowBetween>
              <ThemedText.DeprecatedLargeHeader>
              <div>Choose token</div>

              </ThemedText.DeprecatedLargeHeader>
              <CloseIcon onClick={onDismiss} />
            </RowBetween>
            <Row>
              <SearchInput
                type="text"
                id="token-search-input"
                data-testid="token-search-input"
                placeholder={t`Search`}
                autoComplete="off"
                value={searchQuery}
                ref={inputRef as RefObject<HTMLInputElement>}
                onChange={handleInput}
                onKeyDown={handleEnter}
              />
            </Row>
            {showCommonBases && (
              <CommonBases
                chainId={chainId}
                onSelect={handleCurrencySelect}
                selectedCurrency={selectedCurrency}
                searchQuery={searchQuery}
                isAddressSearch={isAddressSearch}
              />
            )}
          </PaddedColumn>
          <Separator />
          {searchToken && !searchTokenIsAdded ? (
            <Column style={{ padding: "20px 0", height: "100%" }}>
              <CurrencyRow
                currency={searchToken}
                isSelected={Boolean(
                  searchToken &&
                    selectedCurrency &&
                    selectedCurrency.equals(searchToken)
                )}
                onSelect={(hasWarning: boolean) =>
                  searchToken && handleCurrencySelect(searchToken, hasWarning)
                }
                otherSelected={Boolean(
                  searchToken &&
                    otherSelectedCurrency &&
                    otherSelectedCurrency.equals(searchToken)
                )}
                showCurrencyAmount={showCurrencyAmount}
                eventProperties={formatAnalyticsEventProperties(
                  searchToken,
                  0,
                  [searchToken],
                  searchQuery,
                  isAddressSearch
                )}
              />
            </Column>
          ) : searchCurrencies?.length > 0 ||
            filteredInactiveTokens?.length > 0 ||
            isLoading ? (
            <div style={{ flex: "1" }}>
              <AutoSizer disableWidth>
                {({ height }) => (
                  <CurrencyList
                    height={height}
                    currencies={searchCurrencies}
                    otherListTokens={filteredInactiveTokens}
                    onCurrencySelect={handleCurrencySelect}
                    otherCurrency={otherSelectedCurrency}
                    selectedCurrency={selectedCurrency}
                    fixedListRef={fixedList}
                    showCurrencyAmount={showCurrencyAmount}
                    isLoading={isLoading}
                    searchQuery={searchQuery}
                    isAddressSearch={isAddressSearch}
                  />
                )}
              </AutoSizer>
            </div>
          ) : (
            <Column style={{ padding: "20px", height: "100%" }}>
              <ThemedText.DeprecatedMain
                color={theme.textTertiary}
                textAlign="center"
                mb="20px"
              >
                <div>No results found.</div>
              </ThemedText.DeprecatedMain>
            </Column>
          )}
        </Trace>
      </ContentWrapper>
    </>
  );
}
