import { ApolloError, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useMemo } from "react";

import { apolloLimitOHLCsClient } from "./apollo";

const query = gql`
  query GetWhitelistedStatus {
    whitelistStatusUpdateds {
      id
      token
      access
      blockTimestamp
    }
  }
`;

export default function useWhitelistedTokens(
  interval: number,
  connectedChainId: any
): {
  error?: ApolloError;
  isLoading: boolean;
  data: any;
} {
  const {
    data,
    loading: isLoading,
    error,
  } = useQuery(query, {
    pollInterval: interval,
    client: apolloLimitOHLCsClient,
  });
  return useMemo(
    () => ({
      error,
      isLoading,
      data,
    }),
    [data, error, isLoading, connectedChainId]
  );
}
