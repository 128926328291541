import styled from "styled-components"
import { Z_INDEX } from "theme/zIndex"

export const PopoverContainer = styled.div<{ show: boolean }>`
  z-index: ${Z_INDEX.popover};
  pointer-events: none;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: visibility 150ms linear, opacity 150ms linear;
  color: ${({ theme }) => theme.textSecondary};
`

export const ReferenceElement = styled.div`
  display: inline-block;
  height: inherit;
`

export const Arrow = styled.div`
  width: 8px;
  height: 8px;
  z-index: ${Z_INDEX.popover};

  ::before {
    position: absolute;
    width: 8px;
    height: 8px;
    box-sizing: border-box;
    z-index: 9998;

    content: '';
    border: 3px solid transparent;
    border-image: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
    border-image-slice: 1;
    transform: rotate(45deg);
    background: ${({ theme }) => theme.backgroundSurface};
  }

  &.arrow-top {
    bottom: -4px;
    ::before {
      border-top: none;
      border-left: none;
    }
  }

  &.arrow-bottom {
    top: -4px;
    ::before {
      border-bottom: none;
      border-right: none;
    }
  }

  &.arrow-left {
    right: -4px;

    ::before {
      border-bottom: none;
      border-left: none;
    }
  }

  &.arrow-right {
    left: -4px;
    ::before {
      border-right: none;
      border-top: none;
    }
  }
`
