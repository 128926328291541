export const formatLimitPrice = (price: number) => {
  let formattedPriceAsString;

  if (price >= 1000) {
    formattedPriceAsString = price.toFixed(2);
  } else if (price >= 100 && price < 1000) {
    formattedPriceAsString = price.toFixed(3);
  } else if (price >= 1 && price < 100) {
    formattedPriceAsString = price.toFixed(4);
  } else {
    // Convert price to a string
    let priceAsString = price.toString();
    // Find the index of the first non-zero digit after the decimal point
    let indexOfFirstNonZeroAfterDecimal = priceAsString.indexOf(".") + 2;
    while (priceAsString.charAt(indexOfFirstNonZeroAfterDecimal) === "0") {
      indexOfFirstNonZeroAfterDecimal++;
    }
    // Determine the number of characters to include: all characters up to the first non-zero digit after the decimal point + 4 more digits
    let charsToInclude = indexOfFirstNonZeroAfterDecimal + 4;
    // Use substring to include only the required number of characters
    formattedPriceAsString = priceAsString.substring(0, charsToInclude);
  }

  // Convert the string back to a number
  return parseFloat(formattedPriceAsString);
};
