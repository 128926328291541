import { useTheme } from "styled-components";
import {
  ErrorContainer,
  MainContentWrapper,
  NetworkIcon,
  PoolPageWrapper,
  TitleRow,
} from "./styleds";
import { AutoColumn } from "components/Column";
import { ThemedText } from "theme";

export function WrongNetworkCard(props:any) {
  const theme = useTheme();

  return (
    <>
      <PoolPageWrapper>
        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="lg" style={{ width: "100%" }}>
            <TitleRow padding="0">
              <ThemedText.LargeHeader>
                {/* <div>Pools</div> */}
              </ThemedText.LargeHeader>
            </TitleRow>

            <MainContentWrapper>
              <ErrorContainer>
                <ThemedText.DeprecatedBody
                  color={theme.textTertiary}
                  textAlign="center"
                >
                  <NetworkIcon strokeWidth={1.2} />
                { props.content ? <div data-testid="pools-unsupported-err">
                    <div>{props.content}</div>
                  </div>:  <div data-testid="pools-unsupported-err">
                    <div>Your connected network is unsupported.</div>
                  </div>}
                </ThemedText.DeprecatedBody>
              </ErrorContainer>
            </MainContentWrapper>
          </AutoColumn>
        </AutoColumn>
      </PoolPageWrapper>
      {/* <SwitchLocaleLink /> */}
    </>
  );
}
