import { useTokenInfo } from 'hooks/useTokenInfo'
import { TokenImage } from './styleds'


const TokenLogos = ({ address, chainId,  ...restProps }: any) => {
  const info = useTokenInfo(
    chainId,
    address
  );
  return (
    <TokenImage src={info?.icon_url} {...restProps}  />
  )
}

export default TokenLogos