import { createChart } from "lightweight-charts";
import moment from "moment";
import { useEffect, useRef } from "react";
import { myPriceFormatter } from "./myPriceFormatter";
import { formatLimitPrice } from "utils/limitPrice";
import { formatterPrice } from "utils/formatterPrice";

function LineChart(props: any) {
  const { data, isPhoenix, isLimit, selectedQueryType } = props;
  const chartContainerRef = useRef<HTMLDivElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (chartContainerRef.current) {
      const convertDataToAreaSeries = (data: any[], selectedQueryType: any) => {
        if (isLimit) {
          const sortedData = [...data].sort(
            (a, b) => a.periodStartUnix - b.periodStartUnix
          );
          return sortedData.map((item) => {
            return {
              time: item?.periodStartUnix,
              value: parseFloat(item?.close),
            };
          });
        } else {
          if (selectedQueryType === "1M" || selectedQueryType === "1Y") {

            const sortedData = [...data].sort((a, b) => a.date - b.date);
            const seenDates = new Set();
            const uniqueData = sortedData.filter((item) => {
              if (seenDates.has(item.date)) {
                return false; // Skip duplicates
              } else {
                seenDates.add(item.date);
                return true; // Keep unique item
              }
            });
            const mappedData = uniqueData.map((item) => {
              return { time: item?.date, value: parseFloat(item?.close) };
            });
            return mappedData;
          } else {
            // Step 1: Sort the data by periodStartUnix
            const sortedData = [...data].sort(
              (a, b) => a.periodStartUnix - b.periodStartUnix
            );

            // Step 2: Remove duplicates
            const seenTimestamps = new Set();
            const uniqueData = sortedData.filter((item) => {
              if (seenTimestamps.has(item.periodStartUnix)) {
                return false; // Skip duplicates
              } else {
                seenTimestamps.add(item.periodStartUnix);
                return true; // Keep unique item
              }
            });
            console.log(
              "UNIQUE",
              uniqueData.map((item) => {
                return {
                  time: item?.periodStartUnix, // Use Unix timestamp
                  value: parseFloat(item?.close), // Parse and return the close value
                };
              })
            );
            // Step 3: Transform the unique data
            return uniqueData.map((item) => {
              return {
                time: item?.periodStartUnix, // Use Unix timestamp
                value: parseFloat(item?.close), // Parse and return the close value
              };
            });
          }
        }
      };

      const handleResize = () => {
        if (chart && chartContainerRef.current) {
          chart.applyOptions({ width: chartContainerRef.current.clientWidth });
        }
      };

      const chart = createChart(chartContainerRef.current, {
        layout: {
          background: { color: "transparent" },
          textColor: "#929292",
        },
        grid: {
          vertLines: { color: "transparent" },
          horzLines: { color: "#162225" },
        },
        rightPriceScale: {
          borderColor: "#16222",
          visible: false,
        },
        leftPriceScale: {
          borderColor: "#162225",
          visible: true,

          scaleMargins: {
            top: 0.1,
            bottom: 0.1,
          },
        },
        width: chartContainerRef.current.clientWidth,
        height: isLimit ? 350 : 350,
      });

      chart.timeScale().applyOptions({
        borderColor: "#162225",
        barSpacing: 15,
        minBarSpacing: 5,
        timeVisible: true,
      });

      chart.applyOptions({
        crosshair: {
          vertLine: {
            visible: false,
            color: "#FF0000",
            labelVisible: true,
            width: 1,
          },
          horzLine: {
            color: "#32E8BD",
            labelVisible: true,
            width: 1,
            style: 2,
          },
        },
      });

      chart.applyOptions({
        localization: {
          locale: "en-US",
          priceFormatter: (price: any) => formatterPrice(formatLimitPrice(price)),
        },
      });

      chart.applyOptions({
        layout: {
          fontFamily: "'Inter', sans-serif",
          fontSize: 12,
        },
      });

      chart.timeScale().fitContent();

      const AreaSeries = chart.addAreaSeries({
        lineColor: "#35FBCB",
        topColor: "#0E2926",

        bottomColor: "#091113",
        priceFormat: {
          minMove: 0.000001,
          precision: 5,
        },
      });
      const convertedAreaSeriesData = convertDataToAreaSeries(
        data,
        selectedQueryType
      );
      AreaSeries.setData(convertedAreaSeriesData);

      chart.subscribeCrosshairMove((param: any) => {
        if (param.time && tooltipRef.current) {
          const date = new Date(param.time * 1000);

          if (chartContainerRef.current) {
            const shiftedCoordinate = param.point.x;
            const tooltipWidth = 250;
            const containerWidth = chartContainerRef.current.clientWidth;
            let tooltipLeft = shiftedCoordinate;

            if (shiftedCoordinate + tooltipWidth > containerWidth) {
              tooltipLeft = containerWidth - tooltipWidth;
            }

            tooltipRef.current.style.display = "none";
            tooltipRef.current.style.left = tooltipLeft + "px";
            tooltipRef.current.style.backgroundColor = "#030405";
            tooltipRef.current.innerHTML = `
              <div style="display: flex; align-items: center; justofy-content: center;  ">
                <div style="width: 10px; height: 10px;  border-radius: 50%;"></div>
                <span style="color: #fff; font-size: 10px;">Date: ${moment(
                  date
                ).format("MMM DD YYYY hh:mm A")}</span>
              </div>
          
            `;
          }
        }
      });

      window.addEventListener("resize", handleResize);

      return () => {
        window.addEventListener("resize", handleResize);

        chart.remove();
      };
    }

    return () => {};
  }, [data]);

  return (
    <div>
      <div ref={chartContainerRef} style={{ position: "relative" }}>
        <div
          ref={tooltipRef}
          style={{
            position: "absolute",
            width: 175,
            borderRadius: 10,
            color: "#fff",
            zIndex: 10000,
            fontSize: 10,
            display: "none",
            backgroundColor: "black",
            padding: "15px 10px",
          }}
        ></div>
      </div>
    </div>
  );
}

export default LineChart;
