import { useEffect, useState } from "react";

import { usePool } from "./usePool";

export const useGetPoolDetails = (poolIdPool:any) => {
  const { pool: poolInstance } = usePool();
  const [poolDetails, setPoolDetails] = useState<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getPoolDetails = async () => {
      try {
        if (poolInstance && poolIdPool) {
          setLoading(true);

          const PoolDetails = await poolInstance.getPoolDetails(poolIdPool);
          setLoading(false);

          setPoolDetails(PoolDetails);
        }
      } catch (error) {
        console.error("Error initializing All Pool Details:", error);
      } finally {
        setLoading(false);
      }
    };

    getPoolDetails();
  }, [poolInstance,poolIdPool]);

  return {
    poolDetails,
    loading
  };
};
