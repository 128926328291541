import numbro from 'numbro'
  
export const numFormatter = (value: any) => {
  if (!value) {
    return 0
  }

  const absoluteValue = Math.abs(value)
  if (absoluteValue < 1000) {
    return value
  }

  const exponentialValue = absoluteValue.toExponential().toString()
  const valueComponents = exponentialValue.split('e+')
  const exponent = parseInt(valueComponents[1])
  let shortValue = absoluteValue
  let suffix = ''
  if (exponent >= 3 && exponent < 6) {
    shortValue = absoluteValue / Math.pow(10, 3);
    suffix = 'K';
  }
  if (exponent >= 6 && exponent < 9) {
    shortValue = absoluteValue / Math.pow(10, 6)
    suffix = 'M'
  }
  if (exponent >= 9 && exponent < 12) {
    shortValue = absoluteValue / Math.pow(10, 9)
    suffix = 'B'
  }
  if (exponent >= 12 && exponent < 15) {
    shortValue = absoluteValue / Math.pow(10, 12)
    suffix = 'T'
  }
  if (exponent >= 15 && exponent < 18) {
    shortValue = absoluteValue / Math.pow(10, 15)
    suffix = 'QUAD'
  }
  if (exponent >= 18 && exponent < 21) {
    shortValue = absoluteValue / Math.pow(10, 18)
    suffix = 'QUIN'
  }
  if (exponent >= 21 && exponent < 24) {
    shortValue = absoluteValue / Math.pow(10, 21)
    suffix = 'SEX'
  }
  if (exponent >= 24 && exponent < 27) {
    shortValue = absoluteValue / Math.pow(10, 24)
    suffix = 'SEPT'
  }
  if (exponent >= 27) {
    shortValue = absoluteValue / Math.pow(10, 27)
    suffix = 'OCT'
  }

  return (
    shortValue.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }) +
    ' ' +
    suffix
  )
}

// using a currency library here in case we want to add more in future
export const formatDollarAmount = (num: number | undefined, digits = 2, round = true) => {
  if (num === 0) return '$0.00';
  if (!num) return '-';
  if (num < 0.001 && digits <= 3) {
    return '<$0.001';
  }

  let formattedNumber = num;

  // Apply rounding if necessary.
  if (round) {
    const multiplier = Math.pow(10, digits);
    formattedNumber = Math.round(num * multiplier) / multiplier;
  }

  // Convert the number to a string with the specified number of decimal places.
  let formattedString = formattedNumber.toFixed(digits);

  // Check the integer part's length separately from the decimal part.
  const parts = formattedNumber.toString().split('.');
  const integerPart = parts[0];
  const decimalPart = parts[1] || '';

  if (integerPart.length > 8) {
    // If integer part exceeds 8 digits, truncate and append '..'
    formattedString = `${integerPart.substring(0, 8)}..`;
  } else if (formattedString.replace('.', '').length > 8 && integerPart.length <= 8) {
    // This condition is for when the integer part does not exceed but overall might because of decimal
    // Adjusted to correctly handle when overall length exceeds due to decimal places.
    formattedString = `$${integerPart}.${decimalPart.substring(0, Math.max(0, 8 - integerPart.length))}`;
    if (integerPart.length + decimalPart.length > 8) {
      formattedString += '..';
    }
  } else {
    // If no adjustment is needed, format as is but ensure to check rounding
    formattedString = `$${formattedNumber.toFixed(digits)}`;
  }

  return formattedString;
};

export const formatDollarAmountSuffix = (num: number | undefined, digits = 2, round = true) => {
  if (num === 0) return '$0.00'
  if (!num) return '-'
  if (num < 0.001 && digits <= 3) {
    return '<$0.001'
  }

  return numbro(num).formatCurrency({
    average: round,
    mantissa: num > 1000 ? 2 : digits,
    abbreviations: {
      million: 'M',
      billion: 'B',
      trillion:'T'
    },
  })
  // return `$${numFormatter(num)}`
}

export const formatDollarAmountLessThan = (num: number | undefined, digits = 2, round = true) => {
  if (num === 0) return '0.00'
  if (!num) return '-'
  if (num < 0.001 && digits <= 3) {
    return '<0.001'
  }

  return numbro(num).formatCurrency({
    average: round,
    mantissa: num > 1000 ? 2 : digits,
    abbreviations: {
      million: 'M',
      billion: 'B',
      trillion:'T'
    },
  })
  // return `$${numFormatter(num)}`
}


export const formatAmountLessThan = (num: number | undefined, digits = 2, round = true) => {
  if (num === 0) return '0.00';
  if (!num) return '-';
  if (num < 0.001 && digits <= 3) {
    return '<0.001';
  }

  return numbro(num).format({
    average: round,
    mantissa: num > 1000 ? 2 : digits,
    spaceSeparated: true, // Add this option if you want to separate thousands with spaces
    abbreviations: {
      million: 'M',
      billion: 'B',
      trillion: 'T'
    },
  });
}

export const formatAmountChart = (num: number | undefined, digits = 2) => {
  if (num === 0) return '0';
  if (!num) return '-';

  // Handle large numbers with suffixes
  if (Math.abs(num) >= 1e6) {
    return (num / 1e6).toFixed(digits) + 'M';
  }
  if (Math.abs(num) >= 1e3) {
    return (num / 1e3).toFixed(digits) + 'K';
  }

  // Handle numbers between 1 and 10 (exclusive)
  if (num < 10 && num >= 1) {
    return num.toFixed(digits);
  }

  // Handle numbers between 0.001 and 1 (exclusive)
  if (num < 1 && num >= 0.001) {
    const preciseNum = num.toPrecision(digits);
    // Remove trailing zeros after the decimal point
    const formattedNum = preciseNum.replace(/(?:\.0*|(\.\d+?)0+)$/, '$1');
    return formattedNum;
  }

  // Handle numbers less than 0.001
  if (num < 0.001) {
    return '<0.001';
  }

  // Handle numbers with more than 6 decimal places
  if (num.toString().includes('.') && num.toString().split('.')[1].length > 6) {
    return num.toFixed(digits);
  }

  // Default case
  return numFormatter(num); // Assuming numFormatter is defined elsewhere
};


export const formatAmount = (num: number | undefined, digits = 2) => {
  if (num === 0) return '0'
  if (!num) return '-'
  if (num < 0.001) {
    return '<0.001'
  }

 
  return numFormatter(num)
}

export const toFixedNumber = (fixedDecimals: number, number: string | undefined) => {
  if (!number) {
    return 0
  }
	if(number.toString().includes('.')) {
		const numberString = fixedDecimals ? parseFloat(number).toFixed(fixedDecimals) : parseFloat(number)
		return Number(numberString)
	}else{
		return parseFloat(number)
	}

}

export function formatNumberWithSuffix(num: number): string {
  console.log("NU", num)

  if(num == 0){
    return "0";
  }
  if (num < 0.01) {
    return "<0.01";
  }

  if (num < 1000) {
    return num.toFixed(2);
  }

  const suffixes: string[] = ["", "K", "M", "B", "T", "Q", "Qu", "S", "O", "N", "D", "UD", "DD", "TD", "QD", "QuD", "SD", "OD", "ND"];
  const magnitude: number = Math.floor(Math.log10(num) / 3);
  
  // Limiting to the length of suffixes array - 1 to avoid undefined access
  const suffix: string = suffixes[Math.min(magnitude, suffixes.length - 1)];
  
  const scaledNumber: number = num / Math.pow(1000, magnitude);
  
  return scaledNumber.toFixed(2) + suffix;
}
export function formatToSignificant(value:any, digits = 4) {
  if (isNaN(value)) return 'Invalid number';
  return parseFloat(value).toFixed(digits);

}
