import { Trace } from '@uniswap/analytics'
import { InterfacePageName } from '@uniswap/analytics-events'
import { SmallButtonPrimary } from 'components/Button'
import { Link } from 'react-router-dom'
import { ThemedText } from 'theme'
import { useIsDarkMode } from 'theme/components/ThemeToggle'

import darkImage from '../../assets/images/404-page-dark.png'
import lightImage from '../../assets/images/404-page-light.png'
import { Container, Header, Image, NotFoundWrapper } from './notfound.styled'
import { useIsMobile } from 'utils/common'



export default function NotFound() {

  const isDarkMode = useIsDarkMode()
  const isMobile = useIsMobile()

  const Title = isMobile ? ThemedText.LargeHeader : ThemedText.Hero
  const Paragraph = isMobile ? ThemedText.HeadlineMedium : ThemedText.HeadlineLarge

  return (
    <NotFoundWrapper>
      <Trace page={InterfacePageName.NOT_FOUND} shouldLogImpression>
        <Header>
          <Container>
            <Title>404</Title>
            <Paragraph color="textSecondary">
              <div>Page not found!</div>
            </Paragraph>
          </Container>
          <Image src={isDarkMode ? darkImage : lightImage} alt="Liluni" />
        </Header>
        <SmallButtonPrimary as={Link} to="/swap">
          <div>Oops, take me back to Swap</div>
        </SmallButtonPrimary>
      </Trace>
    </NotFoundWrapper>
  )
}
