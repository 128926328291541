import { Currency } from '@uniswap/sdk-core'
import { useMemo } from 'react'

import { useUnsupportedTokens } from './Tokens'


export function useIsSwapUnsupported(currencyIn?: Currency | null, currencyOut?: Currency | null): boolean {
  const unsupportedTokens = useUnsupportedTokens()
  return useMemo(() => {
    if (!unsupportedTokens) {
      return false
    }
    const currencyInUnsupported = Boolean(currencyIn?.isToken && unsupportedTokens[currencyIn.address])
    const currencyOutUnsupported = Boolean(currencyOut?.isToken && unsupportedTokens[currencyOut.address])
    return currencyInUnsupported || currencyOutUnsupported
  }, [currencyIn, currencyOut, unsupportedTokens])
}
